import React from "react";
import "./machineResponse.css";
import "assets/landingpage/assets/css-landing/bootstrap-iso.css";
import "assets/landingpage/assets/css-landing/bootstrap-iso-vendor.css";
import Lottie from "lottie-react";
import AILotte from "assets/lotties/MachineAI/AI.json";
import gradient from "assets/img/MachineAi/gradient.gif";
import machinehackAi from "assets/img/MH-Gen-AI-Logos/MH-Gen-AI-Small-Dark.png";
import { useSelector } from "react-redux";
import Typewriter from "../Typewriter";
import noAttempt from "assets/lotties/MachineAI/noAttemts.json";
import { isMobile } from "react-device-detect";
import LightMachineGenerativeAILogo from "assets/img/MH-Gen-AI-Logos/MH-Gen-AI-Small-lite.png"
export default function MachineResponse({
  getGptData,
  isAttemptsLeft,
  gptData,
  isTyping,
  questionTitle,
  showError,
}) {
  const displayMode = useSelector((store) => store.display.display_mode);
  return (
    <div className={`bootstrap-iso ${isMobile?`genie-effect-mob`:``} genie-effect`}>
      <div
        className={`${
          displayMode === "dark_mode"
            ? "response-section"
            : "light-response-section"
        }  genie-effect__content`}
      >
        {showError ? (
          <div className="p-5 text-center">
            <img className="response-gradient-line" src={gradient} />
            <img
              className="mr-2 mx-2 img-fluid machine-gen-ai-logo"
              src={displayMode === "dark_mode" ? machinehackAi : LightMachineGenerativeAILogo}
              alt="machinehackAi"
            />
            <div className="mx-2">
              <Lottie
                animationData={JSON.parse(JSON.stringify(noAttempt))}
                loop={true}
                style={{ height: "120px" }}
              />
            </div>
            <p className="mb-3 credit-text ">You've used all your 5 credits.</p>
            <p className="mb-3 credit-lower-text">
              Get 50 credits by subscribing to MachineHack Premium!{" "}
              <span> Launching soon</span>.
            </p>
          </div>
        ) : (
          <>
            {" "}
            <h1 className="suggested-header mt-2 p-3 mb-0">
              Powered by{" "}
              <img className="mr-2 img-fluid machine-gen-ai-logo" src={displayMode === "dark_mode" ? machinehackAi : LightMachineGenerativeAILogo} alt="machinehackAi" />
            </h1>
            <p className="suggested-question-title px-3 mb-0">
              {questionTitle}
            </p>
            <img className="response-gradient-line" src={gradient} />
            <div className="machine-response-text d-flex flex-column position-relative">
              {isAttemptsLeft < 5 && !isMobile && (
                <div
                  className="mini-ai-section cursor-pointer"
                  onClick={() => {
                    getGptData();
                  }}
                >
                  <div className="mini-ai-filter"></div>
                  <Lottie
                    animationData={AILotte}
                    loop={true}
                    style={{
                      width: "42%",
                    }}
                  />
                  <span className="mini-ai-text">Ask again</span>
                </div>
              )}
              <div
                className={`d-flex flex-column my-4 mx-4 ${
                  displayMode === "dark_mode"
                    ? ""
                    : "light-responseGeneratedText"
                } responseGeneratedText`}
              >
                <div
                  className={`${
                    displayMode === "dark_mode"
                      ? ""
                      : "light-responseGeneratedHeader"
                  } responseGeneratedHeader`}
                >
                  {gptData && <Typewriter text={gptData} isTyping={isTyping} />}
                </div>
              </div>
            </div>
            {isAttemptsLeft >= 0 && (
              <div className="askAgainCount">
                {5 - isAttemptsLeft} Asks Left
              </div>
            )}{" "}
          </>
        )}
      </div>
      {isAttemptsLeft < 5 && <div className="half-globe-semiCircle"></div>}
    </div>
  );
}
