// Python Backend
export const API = environment_config().API;
//lLLMs Backend 
export const LLMSBackend = environment_config().LLMSBackend
// Bootcamp Backend
export const BootcampBackend = environment_config().BootcampBackend;
// genpact ggogle url
export const GenpactGoogleUrl =environment_config().GenpactGoogleUrl
export const Compiler = environment_config().Compiler;

export const WEB_URL = environment_config().WEB_URL;

export const Enterprise_URL = environment_config().Enterprise_URL;

export const Socket_URL = environment_config().Socket_URL;

export const Socket_Connect_URL = environment_config().Socket_connect_URL;

export const Chat_URL = environment_config().Chat_URL;

export const Socket_Event_URL = environment_config().Socket_Event_URL;

export const Socket_Event_Session_URL = environment_config()
  .Socket_Event_Session_URL;

export const Socket_Hackathon_Teams_URL = environment_config()
  .Socket_Hackathon_Teams_URL;

export const Mh_Coins_Socket = environment_config().Mh_Coins_Socket;

export const LMS_URL  = environment_config().lmsBackend;

function environment_config() {
  if (process.env.REACT_APP_ENV_TYPE === "production") {
    return {
      API: "https://api.machinehack.com",
      BootcampBackend: "https://bootcamp.prod.machinehack.com",
      LLMSBackend:"https://api.dev.llms.machinehack.com",
      Compiler: "https://compiler.machinehack.com",
      WEB_URL: "https://machinehack.com",
      Enterprise_URL: "https://enterprise.machinehack.com",
      Socket_URL: "https://api.machinehack.com/socket/",
      Socket_connect_URL: "https://api.machinehack.com/user",
      Socket_Event_URL: "https://api.machinehack.com/event_notification",
      Socket_Event_Session_URL: "https://api.machinehack.com/event_session",
      Socket_Hackathon_Teams_URL: "https://api.machinehack.com/hackathon_teams",
      Chat_URL: "https://api.machinehack.com/chat",
      Mh_Coins_Socket: "https://api.machinehack.com/mhcoins",
      GenpactGoogleUrl:"https://machinehack.com/hackathons/genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code/overview",
      lmsBackend: "https://api.lms.machinehack.com",
    };
  } else {
    return {
      API: "https://machinehack.futurekrux.com",
      BootcampBackend: "https://bootcamp.futurekrux.com",
      LLMSBackend:"https://api.dev.llms.machinehack.com",
      Compiler: "https://compiler.machinehack.com",
      WEB_URL: "https://staging.machinehack.com",
      Enterprise_URL: "https://staging.enterprise.machinehack.com",
      Socket_URL: "https://machinehack.futurekrux.com/socket/",
      Socket_connect_URL: "https://machinehack.futurekrux.com/user",
      Socket_Event_URL: "https://machinehack.futurekrux.com/event_notification",
      Socket_Event_Session_URL:
        "https://machinehack.futurekrux.com/event_session",
      Socket_Hackathon_Teams_URL:
        "https://machinehack.futurekrux.com/hackathon_teams",
      Chat_URL: "https://machinehack.futurekrux.com/chat",
      Mh_Coins_Socket: "https://machinehack.futurekrux.com/mhcoins",
      GenpactGoogleUrl:"https://development.machinehack.com/hackathons/sustainability_hackathon_lets_crack_the_climate_resilience_code/overview",
      lmsBackend: "https://api.dev.lms.machinehack.com",
    };
  }
}
