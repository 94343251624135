import React from "react";
import globe from "assets/img/MachineAi/mhAiGlobe.svg";
import "assets/landingpage/assets/css-landing/bootstrap-iso.css";
import "assets/landingpage/assets/css-landing/bootstrap-iso-vendor.css";
import Lottie from "lottie-react";
import AILotte from "assets/lotties/MachineAI/AI.json";
import mainGlobe from "assets/img/MachineAi/mainGlobe.svg";
import "./index.css";

export default function MachinehackAI({message}) {
  return (
    <div className="bootstrap-iso machineAI-section">
      <div className="machine-AI-Assistant">
        <div className="globe-bg "></div>
        {/*
            <img
            src={mainGlobe}
            className="img-fluid globe-img"
            alt="globe"
            loading="lazy"
          /> */}
        <Lottie
          animationData={AILotte}
          loop={true}
          className="machine-ai-lottie"
        />
        <span className="machinehack-ai-text">
          {message?message:"MachineHack AI Assist coming up..."}
        </span>
        {/* <img src={globe} className="img-fluid" alt='globe' loading='lazy' style={{ maxWidth: '620px' }} /> */}
      </div>
    </div>
  );
}
