import React from "react";
import { Box, Modal } from "@mui/material";
import { isMobile } from "react-device-detect";

const confimationStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const ConfirmationModal = ({
  showPopup,
  setshowPopup,
  isDarkMode,
  onSuccess,
  title,
  ActionBtnName,
  closeBtnName,
  ModalBody,
  type,
  makeClosebtnActive
}) => {
  return (
    <Modal
      open={showPopup}
      onClose={() => setshowPopup(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={` bootstrap-iso ${
        isDarkMode
          ? isMobile
            ? "mobiledark-ArticlePop-confirm-section"
            : "darkTheme dark-ArticlePop-confirm"
          : isMobile
          ? "mobile-ArticlePop-confirm-section"
          : "ArticlePop-confirm"
      } ${isMobile ? "col-12" : ""}`}
    >
      <Box sx={confimationStyle}>
        <div className="my-4 py-1">
          <p
            className={`${
              isDarkMode ? "dark-confirmation-text" : "confirmation-text"
            } ${type === "delete" ? "pl-4" : "text-center"}`}
          >
            {title}
          </p>
        </div>
        <div
          className={`${
            isDarkMode ? "dark-article-pop-separator" : "article-pop-separator"
          } w-100`}
        ></div>
        <div className="my-4 pl-4">
          <span
            className={`${
              isDarkMode
                ? "dark-confirmation-text-span"
                : "confirmation-text-span"
            }`}
          >
            {ModalBody}
          </span>
          <div
            className={`d-flex align-items-center ${
              type === "delete" ? "justify-content-start" : "justify-content-center"
            } mt-5 my-3`}
          >
            <button
              className={`${
                makeClosebtnActive ? "feed-discard-btn" : "confirmation-btn"
              } mr-3`}
              onClick={onSuccess}
            >
              {ActionBtnName}
            </button>
            <button
              className={`${makeClosebtnActive ? "confirmation-btn" : "feed-discard-btn"}`}
              onClick={() => setshowPopup(false)}
            >
              {closeBtnName}
            </button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};
export default ConfirmationModal;
