let initialState = {
	bootcamps: [],
};

function bootcampReducer(state = initialState, action) {
	if (action.type === "GET_ALL_BOOTCAMPS") {
		return {
			...state,
			bootcamps: action.payload,
		};
	}
	return state;
}


export default bootcampReducer;