import React, { useEffect, useRef, useState } from "react";
import closePNG from "assets/img/close.png";
import { IconButton, Input, InputAdornment } from "@mui/material/";
import "./LoginPageNew.scss";
import { BootcampBackend } from "backend";
import { Info, Visibility, VisibilityOff, West } from "@mui/icons-material";
import "assets/landingpage/assets/css-landing/bootstrap-iso.css";
import "assets/landingpage/assets/css-landing/bootstrap-iso-vendor.css";
import sucessfullSvg from "assets/img/Sucessfull.svg";
import failSvg from "assets/img/Fail.svg";
import { useDispatch } from "react-redux";
import { signup, signin, authenticate, resetPasswordApi } from "auth/helper";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { isAuthenticated } from "auth/helper";
import LoginGIf from "assets/img/For-Side.gif";
import { resetPasswordRequest } from "auth/helper";
import { Companies } from "companies";
import axios from "axios";
import { LMS_URL } from "backend";
import DropdownArrow from "../../views/Assessments/Assets/DropdownArrow";
function LoginPageNew() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isRegisterClicked, setIsRegisterClicked] = useState(false);
  const [isLoginClicked, setIsLoginClicked] = useState(false);
  const [isForgotPasswordClicked, setIsForgotPasswordClicked] = useState(false);
  const [isLoginPage, setIsLoginPage] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [studyPlanData, setStudyPlanData] = useState({
    studyPlanSlug: null,
    studyPlanId: null,
  });
  const [newUserPassword, setNewUserPassword] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [resetConfirmPassword, setResetConfirmPassword] = useState("");
  const [validation, setvalidation] = useState(null);
  const [message, setMessage] = useState(null);
  const [didRedirect, setDidRedirect] = useState(false);
  const [studyPlan, setStudyPlan] = useState([]);
  const [isDarkMode, setIsDarkmode] = useState();
  const [showStudyPlanDropdown, setShowStudyPlanDropdown] = useState(false);
  const user = useSelector((store) => store.user.user);
  const [startY, setStartY] = useState(null);
  const dispatch = useDispatch();
  const hash = useLocation().hash;
  const pathUrl = hash.split("/");
  const loginModalTypeLogin = hash.includes("login");
  const interviewToken = pathUrl[1];
  const history = useHistory();
  const location = useLocation();
  const displayMode = useSelector((store) => store.display.display_mode);
  const modalRef = useRef(null);
  const [type, setType] = useState(false);
  const token = location.pathname.includes("/resetPassword")
    ? location.pathname?.split("/")[3]
    : "";
  const userId = location.pathname.includes("/resetPassword")
    ? location.pathname?.split("/")[2]
    : "";
  const params = useParams();
  const path = "";
  const clearFields = () => {
    setLoginEmail("");
    setLoginPassword("");
    setForgotPasswordEmail("");
    setNewUserEmail("");
    setNewUserName("");
    setNewUserPassword("");
    setNewUserConfirmPassword("");
  };
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowStudyPlanDropdown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const handleLogin = () => {
    setIsForgotPasswordClicked(false);
    history.push("/login");
    // setIsLoginClicked(true);
    // setIsLoginPage(false);
    // setIsForgotPasswordClicked(false);
    // setIsRegisterClicked(false);
    // setShowPassword(false);
    // setvalidation(null);
    // setMessage(null);
    // clearFields();
    // window.location.reload();
  };
  const getStudyPlans = async () => {
    const token = localStorage.getItem("jwt")
      ? JSON.parse(localStorage.getItem("jwt")).token
      : null;
    if (token) {
      const response = await axios.get(
        `${LMS_URL}/v1/playgrounds/getAllStudyPlans`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setStudyPlan(response.data.data);
    }
  };
  const performRedirect = () => {
    if (location.pathname.includes("/signup")) {
      if (user?.company&&studyPlan.length === 0) {
        getStudyPlans();
      }
      return;
    }
    if (isAuthenticated() || didRedirect) {
      var previousPath = localStorage.getItem("prevPath");
      if (
        previousPath === null ||
        previousPath === "/login-page" ||
        previousPath === "/login" ||
        previousPath.startsWith("/register") ||
        previousPath.startsWith("/update/password") ||
        previousPath.startsWith("/resetpassword")
      ) {
        return <Redirect to="/" />;
      } else {
        window.location.replace(previousPath);
      }
    }
  };

  const handleGoogleLogin = () => {
    if (loginModalTypeLogin && interviewToken !== "undefined") {
      localStorage.setItem("interviewToken", token);
      setDidRedirect(true);
    } else {
      localStorage.setItem("prevPath", location.pathname);
      setDidRedirect(true);
    }
    window.location.replace(`${BootcampBackend}/oauth/google/initiate`);
  };

  const handleLinkedinLogin = () => {
    if (loginModalTypeLogin && interviewToken !== "undefined") {
      localStorage.setItem("interviewToken", token);
      setDidRedirect(true);
    } else {
      localStorage.setItem("prevPath", location.pathname);
      setDidRedirect(true);
    }
    window.location.replace(`${BootcampBackend}/oauth/linkedin/initiate`);
  };

  const handleRegister = () => {
    history.push("/signup");
    // setIsRegisterClicked(true);
    // setIsLoginPage(false);
    // setIsLoginClicked(false);
    // setIsForgotPasswordClicked(false);
    // setvalidation(null);
    // setMessage(null);
    // clearFields();
  };

  const handleClose = () => {
    // onClose(false);
    // setIsForgotPasswordClicked(false);
    // setIsLoginClicked(false);
    // setIsLoginPage(true);
    setIsRegisterClicked(false);
    setvalidation(null);
    setMessage(null);
    clearFields();
  };
  const handleGoBack = () => {
    setIsForgotPasswordClicked(false);
    setIsLoginClicked(false);
    setIsLoginPage(true);
    setIsRegisterClicked(false);
    setvalidation(null);
    setMessage(null);
    clearFields();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    setIsForgotPasswordClicked(true);
    setIsLoginClicked(false);
    setIsLoginPage(false);
    setIsRegisterClicked(false);
    setvalidation(null);
    setMessage(null);
    clearFields();
  };
  const handleLoginSubmit = () => {
    if (loginEmail === "" || loginPassword === "") {
      if (loginEmail === "") {
        setvalidation({
          loginEmail: true,
          text: "This field can’t be empty",
        });
      } else {
        setvalidation({
          loginPassword: true,
          text: "This field can’t be empty",
        });
      }
    } else {
      const newUser = {
        Email: loginEmail?.toLowerCase()?.trim(),
        Password: loginPassword,
      };
      signin(newUser)
        .then((response) => {
          if (response?.status) {
            if (response.status === 429) {
              setMessage({
                error: true,
                text: "",
                type: "attempts",
                errorMessage: "Too many attempts, try again later",
              });
            }
            if (response.status === 404) {
              setMessage({
                error: true,
                text: "This email is not associated with any MH accounts",
                type: "user",
                errorMessage: "Please double check your email id.",
              });
            }
            if (response.status === 403) {
              setMessage({
                error: true,
                text: "Wrong credentials entered!",
                type: "password",
                errorMessage: "Please double check your password.",
              });
            }
            if (response.status === 400) {
              setMessage({
                error: true,
                text: "Wrong credentials entered!",
                type: "password",
                errorMessage: response.data.message,
              });
            }
          } else {
            if (token === "") {
              authenticate(response, () => {
                setDidRedirect(true);
                dispatch({
                  type: "SET_CURRENT_USER",
                  payload: response.data,
                  token: response.token,
                });
              });
              handleClose(false);
              setLoginEmail("");
              setLoginPassword("");
              if (path !== "") {
                history.push(path);
                window.location.reload();
              } else {
                window.location.reload();
              }
            } else {
              authenticate(response, () => {
                dispatch({
                  type: "SET_CURRENT_USER",
                  payload: response.data,
                  token: response.token,
                });
              });
              localStorage.setItem("interviewToken", token);
              handleClose(false);
              setLoginEmail("");
              setLoginPassword("");
              history.push(`/interview-lobby/${token}`);
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleSignupSubmit = () => {
    const company = Companies?.find(
      (company) => company?.name === params?.companyName
    );
    if (Object.keys(company || {})?.length === 0) {
      return;
    }
    if (!studyPlanData.studyPlanId || !studyPlanData.studyPlanSlug) {
      setvalidation({
        studyPlanData: true,
        text: "Please select a studyPlan",
      });
      return;
    }
    if (newUserEmail === "" || newUserName === "" || newUserPassword === "") {
      if (newUserName === "") {
        setvalidation({
          newUserName: true,
          text: "This field can’t be empty",
        });
        return;
      }
      if (newUserName.length < 3 || newUserName.length > 70) {
        setvalidation({
          newUserName: true,
          text: "Please enter a valid Name!",
        });
        return;
      }
      if (newUserEmail === "") {
        setvalidation({
          newUserEmail: true,
          text: "This field can’t be empty",
        });
        return;
      }
      if (newUserPassword === "") {
        setvalidation({
          newUserPassword: true,
          text: "This field can’t be empty",
        });
        return;
      }
    }
    if (newUserName.length < 3 || newUserName.length > 70) {
      setvalidation({
        newUserName: true,
        text: "Please enter a valid Name!",
      });
      return;
    }
    if (newUserPassword.length < 9 && newUserPassword !== "") {
      return setMessage({
        error: true,
        errorMessage: "Choose a strong password with atleast 9 characters",
        type: "password strength",
      });
    }
    if (newUserPassword !== newUserConfirmPassword) {
      return;
    }
    if (
      !newUserEmail.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setvalidation({
        newUserEmail: true,
        text: "Please enter a valid email!",
      });
      return;
    } else {
      const newUser = {
        Email: newUserEmail?.toLowerCase(),
        Name: newUserName,
        Password: newUserPassword,
        company: company._id,
        ...studyPlanData,
      };

      signup(newUser)
        .then((response) => {
          if (response?.status) {
            if (response?.status === 409) {
              setMessage({
                info: true,
                infoMessage: "Please check your email or reset your password",
              });
            }
            if (response.status === 429) {
              setMessage({
                error: true,
                text: "",
                type: "attempts",
                errorMessage: "Too many attempts, try again later",
              });
            }
            if (response.status === 400) {
              setMessage({
                error: true,
                text: "",
                type: "server",
                errorMessage: "Internal server error",
              });
            }
          } else {
            // authenticate(response, () => {
            //   dispatch({
            //     type: "SET_CURRENT_USER",
            //     payload: response.data,
            //     token: response.token,
            //   });
            // });
            setMessage({
              sucessfull: true,
              type: "register",
              sucessfullMessage: "Your Account is successfully registered.",
            });
            window.location.reload();
            setTimeout(() => {
              handleClose();
            }, 3000);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSubmit = () => {
    if (forgotPasswordEmail === "") {
      setvalidation({
        forgotPasswordEmail: true,
      });
    } else {
      const user = {
        emailId: forgotPasswordEmail?.toLowerCase(),
      };
      resetPasswordRequest(user)
        .then((response) => {
          if (response?.status) {
            if (response.status === 429) {
              setMessage({
                error: true,
                text: "",
                type: "attempts",
                errorMessage: "Too many attempts, try again later",
              });
            }
            if (response.status === 400) {
              setMessage({
                error: true,
                text: "",
                type: "server",
                errorMessage: "Internal server error",
              });
            }
            if (response.status === 404) {
              setMessage({
                error: true,
                text: "",
                type: "user",
                errorMessage:
                  "This email is not associated with any MH accounts",
              });
            }
          } else {
            setMessage({
              sucessfull: true,
              type: "reset password",
              sucessfullMessage:
                "We have sent you a email with reset password link.",
            });
            setTimeout(() => {
              handleClose();
            }, 3000);
            setForgotPasswordEmail("");
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleResetPassword = () => {
    if (resetPassword === "") {
      setvalidation({
        resetPassword: true,
      });
      return;
    }
    if (resetPassword.length < 9 && resetPassword !== "") {
      return setMessage({
        error: true,
        errorMessage: "Choose a strong password with atleast 9 characters",
        type: "password strength",
      });
    }
    if (resetPassword !== resetConfirmPassword) {
      setMessage({
        error: true,
        type: "password",
        errorMessage: "Passwords don't match.",
      });
      return;
    } else {
      const password = {
        password: resetPassword,
      };
      resetPasswordApi(password, userId, token).then((response) => {
        authenticate(response, () => {
          dispatch({
            type: "SET_CURRENT_USER",
            payload: response.data,
            token: response.token,
          });
        });
        setMessage({
          sucessfull: true,
          type: "reset password",
          sucessfullMessage: "Successfull.",
        });
        // setTimeout(() => {
        //   history.push("/feed");
        //   window.location.reload();
        //   handleClose();
        // }, 3000);
      });
    }
  };

  const handleKeyDownLogin = (event) => {
    if (event.key === "Enter") {
      handleLoginSubmit(event);
    }
  };

  const handleKeyDownRegister = (event) => {
    if (event.key === "Enter") {
      handleSignupSubmit(event);
    }
  };

  useEffect(() => {
    setIsDarkmode(displayMode === "dark_mode");
  }, [displayMode]);
  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchEnd = (e) => {
    const endY = e.changedTouches[0].clientY;
    if (startY && endY - startY > 12) {
      onClose(false);
    }
    setStartY(null);
  };

  useEffect(() => {
    location.pathname.includes("/login")
      ? (setIsLoginClicked(true), setIsRegisterClicked(false))
      : location.pathname.includes("/signup")
      ? (setIsRegisterClicked(true), setIsLoginClicked(false))
      : location.pathname.includes("/resetPassword")
      ? (setType(true), setIsLoginClicked(false), setIsRegisterClicked(false))
      : "";
  }, [location?.pathname]);

  return (
    <>
      <div className="bootstrap-iso login-page-cntr">
        <div className="col-lg-6 col-md-6 col-12 login-left-cntr">
          <div
            className={`${isMobile ? "col-12" : "col-9 pl-2"} pt-5  mx-auto`}
          >
            {/* <p className="text-left loginText fontGray  pb-3">
              Login to MachineHack and start hiring the best talents.
            </p> */}
            {/* <div
              className={`${isMobile ? "" : "mt-4"} d-flex align-items-center`}
            >
              <img
                className={`${isMobile ? "mb-auto" : ""} img-fluid`}
                src={checked}
              />
              <p
                className={`mb-0 left-cntr-list ${isMobile ? "me-5" : "ml-3"}`}
              >
                Platform with 1000+ advanced assessment
              </p>
            </div>
            <div
              className={`${isMobile ? "" : "mt-4"} d-flex align-items-center`}
            >
              <img
                className={`${isMobile ? "mb-auto " : ""} img-fluid`}
                src={checked}
              />
              <p className={`mb-0 left-cntr-list ${isMobile ? "" : "ml-3"}`}>
                Host online hackathon to reach 100k+ developers
              </p>
            </div>
            <div
              className={`${isMobile ? "" : ""} mt-4 d-flex align-items-center`}
            >
              <img
                className={`${isMobile ? "mb-auto me-2" : ""} img-fluid`}
                src={checked}
              />
              <p className={`mb-0 left-cntr-list ${isMobile ? "" : "ml-3"}`}>
                Advanced live coding interview{" "}
              </p>
            </div>
            <div
              className={`${
                isMobile ? "" : ""
              } mt-4 mb-5 d-flex align-items-center`}
            >
              <img
                className={`${isMobile ? "mb-auto me-2" : ""} img-fluid`}
                src={checked}
              />
              <p className={`mb-0 left-cntr-list ${isMobile ? "" : "ml-3"}`}>
                Intensive applicant tracking
              </p>
            </div> */}
          </div>
          <img
            className={`${
              isMobile ? "col-12" : ""
            } gifContainer mr-5 img-fluid`}
            src={LoginGIf}
            height="432"
            width="432"
          />
        </div>
        <div
          className={`${
            isDarkMode ? "darkmainFrame" : ""
          } mainFrame col-lg-6 col-md-6 col-12`}
        >
          {isMobile && (
            <div
              className="mob-drag"
              onTouchStart={handleTouchStart}
              onTouchEnd={handleTouchEnd}
              ref={modalRef}
            ></div>
          )}
          <div className={`${isMobile ? "" : "col-lg-7 col-md-8 mx-auto"}`}>
            {!type ? (
              <>
                {isRegisterClicked ? (
                  <div className="newRegister mb-4">
                    <h5
                      className={`reset-password-header ${
                        isDarkMode ? "whiteText" : ""
                      }`}
                    >
                      Register Now
                    </h5>

                    <div>
                      {/* <span className={`text ${isDarkMode ? "darkText" : ""}`}>Name</span> */}

                      <div
                        className={`${
                          validation?.newUserName
                            ? "error-input"
                            : newUserName
                            ? "inputFilled"
                            : "input"
                        } ${isDarkMode ? "login-dark-input-box" : ""}`}
                      >
                        {!newUserName && !validation?.newUserName && (
                          <span id="mandatory-hint-name" className="input-hint">
                            Name{" "}
                            <span className="mandatory-hint-astrick">*</span>
                          </span>
                        )}
                        <Input
                          className={`w-100 ${
                            isDarkMode ? "login-dark-input-box-text" : ""
                          }`}
                          aria-describedby="mandatory-hint"
                          value={newUserName}
                          onChange={(event) => {
                            setNewUserName(event.target.value);
                            setMessage(null);
                            setvalidation(null);
                          }}
                        />
                      </div>
                      {validation?.newUserName ? (
                        <span className="error-message">
                          {validation?.text}
                        </span>
                      ) : (
                        <p className="error-hidden mb-0"></p>
                      )}
                    </div>
                    <div className="input-margin-container">
                      {/* <span className={`text ${isDarkMode ? "darkText" : ""}`}>Email id</span> */}

                      <div
                        className={`${
                          validation?.newUserEmail
                            ? "error-input"
                            : newUserEmail
                            ? "inputFilled"
                            : "input"
                        } ${isDarkMode ? "login-dark-input-box" : ""}`}
                      >
                        {!newUserEmail && !validation?.newUserEmail && (
                          <span
                            id="mandatory-hint-email"
                            className="input-hint"
                          >
                            Email{" "}
                            <span className="mandatory-hint-astrick">*</span>
                          </span>
                        )}
                        <Input
                          className={`w-100 ${
                            isDarkMode ? "login-dark-input-box-text" : ""
                          }`}
                          value={newUserEmail}
                          onChange={(event) => {
                            setNewUserEmail(event.target.value);
                            setMessage(null);
                            setvalidation(null);
                          }}
                        />
                      </div>
                      {validation?.newUserEmail ? (
                        <span className="error-message">
                          {validation?.text}
                        </span>
                      ) : (
                        <p className="error-hidden mb-0"></p>
                      )}
                    </div>

                    <div className="input-margin-container">
                      {/* <span className={`text ${isDarkMode ? "darkText" : ""}`}>Set Password</span> */}
                      <div>
                        <div
                          className={`${
                            validation?.newUserPassword
                              ? "error-input"
                              : newUserPassword
                              ? "inputFilled"
                              : "input"
                          } ${isDarkMode ? "login-dark-input-box" : ""}`}
                        >
                          {!newUserPassword && !validation?.newUserPassword && (
                            <span
                              id="mandatory-hint-passowrd"
                              className="input-hint"
                            >
                              Set password{" "}
                              <span className="mandatory-hint-astrick">*</span>
                            </span>
                          )}
                          <Input
                            className={`w-100 ${
                              isDarkMode ? "login-dark-input-box-text" : ""
                            }`}
                            type={
                              showPassword
                                ? `text ${isDarkMode ? "darkText" : ""}`
                                : "password"
                            }
                            onChange={(event) => {
                              event.preventDefault();
                              setNewUserPassword(event.target.value);
                              if (
                                event.target.value.length < 9 &&
                                event.target.value !== ""
                              ) {
                                setvalidation({
                                  newUserPassword: true,
                                });
                                setMessage({
                                  type: "password strength",
                                  text:
                                    "Password should contain atleast 9 characters",
                                });
                              }
                              if (
                                event.target.value === "" ||
                                event.target.value.length > 7
                              ) {
                                setMessage(null);
                                setvalidation(null);
                              }
                            }}
                            value={newUserPassword}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  className="no-hover"
                                >
                                  {showPassword ? (
                                    <Visibility className="visibilityIcon" />
                                  ) : (
                                    <VisibilityOff
                                      className={`${
                                        isDarkMode ? "darkVisible" : ""
                                      }`}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </div>
                        {message?.type === "password strength" ? (
                          <span className="error-message">{message.text}</span>
                        ) : (
                          <p className="error-hidden mb-0"></p>
                        )}
                      </div>
                    </div>
                    <div className="input-margin-container">
                      {/* <span className={`text ${isDarkMode ? "darkText" : ""}`}>Confirm Password</span> */}
                      <div>
                        <div
                          className={`${
                            validation?.newUserConfirmPassword
                              ? "error-input"
                              : newUserConfirmPassword
                              ? "inputFilled"
                              : "input"
                          } ${isDarkMode ? "login-dark-input-box" : ""}`}
                        >
                          {!newUserConfirmPassword &&
                            !validation?.newUserConfirmPassword && (
                              <span id="mandatory-hint" className="input-hint">
                                Confirm password{" "}
                                <span className="mandatory-hint-astrick">
                                  *
                                </span>
                              </span>
                            )}
                          <Input
                            className={`w-100 ${
                              isDarkMode ? "login-dark-input-box-text" : ""
                            }`}
                            type={
                              showConfirmPassword
                                ? `text ${isDarkMode ? "darkText" : ""}`
                                : "password"
                            }
                            onChange={(event) => {
                              setNewUserConfirmPassword(event.target.value);
                              if (
                                event.target.value !== newUserPassword &&
                                event.target.value !== ""
                              ) {
                                setvalidation({
                                  newUserConfirmPassword: true,
                                });
                                setMessage({
                                  type: "confirm password",
                                  text: "The passwords should match!",
                                });
                              } else {
                                setMessage(null);
                                setvalidation(null);
                              }
                            }}
                            onKeyDown={handleKeyDownRegister}
                            value={newUserConfirmPassword}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowConfirmPassword(
                                      !showConfirmPassword
                                    );
                                  }}
                                  className="no-hover"
                                >
                                  {showConfirmPassword ? (
                                    <Visibility className="visibilityIcon" />
                                  ) : (
                                    <VisibilityOff
                                      className={`${
                                        isDarkMode ? "darkVisible" : ""
                                      }`}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </div>
                        {message?.type === "confirm password" ? (
                          <span className="error-message">{message.text}</span>
                        ) : (
                          <p className="error-hidden mb-0"></p>
                        )}
                      </div>
                    </div>
                    <div className="input-margin-container position-relative mb-4">
                      <button
                        className="signupDropdownSelect"
                        onClick={() =>
                          setShowStudyPlanDropdown(!showStudyPlanDropdown)
                        }
                      >
                        <span className="mr-1">{studyPlanData?.title?studyPlanData?.title:"Select studyplan"}</span>
                        <span className="ml-auto">
                          <DropdownArrow
                          fontColor={
                            displayMode === "dark_mode"
                              ? "#B5C0D3"
                              : open === "tags"
                              ? "#ffffff"
                              : "#757575"
                          }
                        />
                        </span>
                      </button>
                      {showStudyPlanDropdown && (
                        <div
                          ref={wrapperRef}
                          className="signupDropdown z-dropdown p-0 mt-2 false"
                        >
                          {studyPlan.map((studyPlan) => (
                            <div
                              className="signupDropDownItem cursor-pointer  px-4 py-2"
                              onClick={() => {
                                setStudyPlanData({
                                  studyPlanId: studyPlan._id,
                                  studyPlanSlug: studyPlan.slug,
                                  title:studyPlan.title
                                });
                                setShowStudyPlanDropdown(false);
                              }}
                            >
                              <span>{studyPlan?.title}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                    <div className="register-btn-container text-left">
                      <span
                        className={`${isDarkMode ? "darkText" : ""} link-text`}
                      >
                        By joining MachineHack,
                      </span>
                      <p
                        className={`${
                          isDarkMode ? "darkText" : ""
                        } mb-0 link-text `}
                      >
                        you agree to our{" "}
                        <a
                          className={`${
                            isDarkMode ? "darkText" : ""
                          } termsAndConditionsText link-text `}
                          href="https://machinehack.com/terms-ui
"
                        >
                          Terms of Service
                        </a>
                        <span
                          className={`${
                            isDarkMode ? "darkText" : ""
                          } link-text`}
                        >
                          {" "}
                          and{" "}
                        </span>
                        <a
                          className={`${
                            isDarkMode ? "darkText" : ""
                          } termsAndConditionsText link-text `}
                          href="https://machinehack.com/privacy-ui"
                        >
                          Privacy Policy
                        </a>
                      </p>
                    </div>
                    <div className="">
                      <button
                        className={`register-btn ${
                          newUserEmail &&
                          newUserName &&
                          newUserPassword &&
                          newUserConfirmPassword
                            ? isDarkMode
                              ? "darkBtn darkBorder"
                              : "active-login-btn"
                            : isDarkMode
                            ? "login-inactive-btn"
                            : ""
                        }`}
                        onClick={handleSignupSubmit}
                      >
                        Register Now
                        <div className="register-btn-tansition"></div>
                      </button>
                    </div>
                    <div className="text-center">
                      <span
                        className={`${isDarkMode ? "darkText" : ""} link-text `}
                      >
                        Already have an Account?{" "}
                      </span>
                      <a onClick={handleLogin}>
                        <span className={`login-register-text`}>Login Now</span>
                      </a>
                    </div>
                  </div>
                ) : null}
                {isLoginClicked ? (
                  <div className="login-container mb-4">
                    <h5
                      className={`reset-password-header text-center mt-0 ${
                        isDarkMode ? "whiteText" : ""
                      }`}
                    >
                      Login
                    </h5>

                    <div>
                      <div>
                        <span
                          className={`text ${isDarkMode ? "darkText" : ""}`}
                        >
                          Email
                        </span>
                      </div>
                      <div
                        className={`${
                          validation?.loginEmail ||
                          (message?.type === "user" && message?.error)
                            ? "error-input"
                            : loginEmail
                            ? "inputFilled"
                            : "input"
                        } ${isDarkMode ? "login-dark-input-box" : ""}`}
                      >
                        <Input
                          className={`w-100 ${
                            isDarkMode ? "login-dark-input-box-text" : ""
                          }`}
                          value={loginEmail}
                          placeholder="Email"
                          onChange={(event) => {
                            setLoginEmail(event.target.value);
                            setMessage(null);
                            setvalidation(null);
                          }}
                        />
                      </div>
                      {message?.type === "user" && message?.error ? (
                        <span className="error-message">{message.text}</span>
                      ) : (
                        <p className="error-hidden mb-0"></p>
                      )}
                    </div>
                    <div>
                      <div className="password-lable">
                        <div className={`text ${isDarkMode ? "darkText" : ""}`}>
                          Password
                        </div>
                        <a onClick={handleForgotPassword}>Forgot Password?</a>
                      </div>
                      <div className="password-input">
                        <div
                          className={`${
                            validation?.loginPassword ||
                            (message?.type === "password" && message?.error)
                              ? "error-input"
                              : loginPassword
                              ? "inputFilled"
                              : "input"
                          } ${isDarkMode ? "login-dark-input-box" : ""}`}
                        >
                          <Input
                            className={`w-100 ${
                              isDarkMode ? "login-dark-input-box-text" : ""
                            }`}
                            type={
                              showPassword
                                ? `text ${isDarkMode ? "darkText" : ""}`
                                : "password"
                            }
                            onChange={(event) => {
                              setLoginPassword(event.target.value);
                              setMessage(null);
                              setvalidation(null);
                            }}
                            onKeyDown={handleKeyDownLogin}
                            value={loginPassword}
                            placeholder="Password"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={handleClickShowPassword}
                                  className="no-hover"
                                >
                                  {showPassword ? (
                                    <Visibility className="visibilityIcon" />
                                  ) : (
                                    <VisibilityOff
                                      className={`${
                                        isDarkMode ? "darkVisible" : ""
                                      }`}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </div>
                        {message?.type === "password" && message?.error ? (
                          <span className="error-message">{message.text}</span>
                        ) : (
                          <p className="error-hidden mb-0"></p>
                        )}
                      </div>
                    </div>
                    <div className="register-btn-container mt-5">
                      <button
                        className={`login-btn ${
                          loginEmail && loginPassword
                            ? isDarkMode
                              ? "darkBtn darkBorder"
                              : "active-login-btn"
                            : isDarkMode
                            ? "login-inactive-btn"
                            : ""
                        }`}
                        onClick={handleLoginSubmit}
                      >
                        <span>Login</span>
                      </button>
                    </div>
                    {/* <div className="mt-5 register-btn-container">
                        <span
                          className={`${
                            isDarkMode ? "darkText" : ""
                          } link-text `}
                        >
                          Don’t have an account?{" "}
                        </span>
                        <a onClick={handleRegister}>
                          <b className={`login-register-text`}>Register Now</b>
                        </a>
                      </div> */}
                  </div>
                ) : null}
                {isForgotPasswordClicked ? (
                  <>
                    <div className="reset-password-container mb-4">
                      <h5
                        className={`reset-password-header mb-3 ${
                          isDarkMode ? "whiteText" : ""
                        }`}
                      >
                        Reset Password
                      </h5>
                      <div
                        className={`${
                          isDarkMode ? "darkText" : ""
                        } loginThinText `}
                      >
                        We’ll send a reset link to your registered email
                      </div>
                      <div className="email-input mt-4">
                        <span
                          className={`text ${isDarkMode ? "darkText" : ""}`}
                        >
                          Email
                        </span>

                        <div
                          className={`${
                            validation?.forgotPasswordEmail ||
                            (message?.type === "user" && message?.error)
                              ? "error-input"
                              : forgotPasswordEmail
                              ? "inputFilled"
                              : "input"
                          } ${isDarkMode ? "login-dark-input-box" : ""}`}
                        >
                          <Input
                            className={`w-100 ${
                              isDarkMode ? "login-dark-input-box-text" : ""
                            }`}
                            placeholder="Your email id"
                            value={forgotPasswordEmail}
                            onChange={(event) => {
                              setForgotPasswordEmail(event.target.value);
                              setvalidation(null);
                              setMessage(null);
                            }}
                          />
                        </div>
                        {message?.type === "user" && message?.error ? (
                          <span className="error-message">{message.text}</span>
                        ) : (
                          <p className="error-hidden mb-0"></p>
                        )}
                      </div>
                      <div>
                        <button
                          className={`login-btn ${
                            isDarkMode ? "darkBtn noBorder" : ""
                          }`}
                          onClick={handleSubmit}
                        >
                          <span>Reset Password</span>
                        </button>
                      </div>
                      {/* <div className="mt-4 text-center">
                        <span
                          className={`${
                            isDarkMode ? "darkText" : ""
                          } link-text `}
                        >
                          Don’t have an account?{" "}
                        </span>
                        <a onClick={handleRegister}>
                          <b className={`login-register-text`}>Register Now</b>
                        </a>
                      </div> */}
                    </div>
                  </>
                ) : null}
              </>
            ) : (
              <div className="login-container">
                <h5
                  className={`reset-password-header  ${
                    isDarkMode ? "whiteText" : ""
                  }`}
                >
                  Login with email
                </h5>
                <div className="mt-2">
                  <p
                    className={`${isDarkMode ? "darkText" : ""} loginThinText `}
                  >
                    Your new password must be different from previous used
                    passwords.
                  </p>
                </div>

                <div className="mt-4">
                  <div>
                    <span className={`text ${isDarkMode ? "darkText" : ""}`}>
                      New Password
                    </span>
                  </div>
                  <div
                    className={`${
                      validation?.resetPassword
                        ? "error-input"
                        : resetPassword
                        ? "inputFilled"
                        : "input"
                    } ${isDarkMode ? "login-dark-input-box" : ""}`}
                  >
                    <Input
                      className={`w-100 ${
                        isDarkMode ? "login-dark-input-box-text" : ""
                      }`}
                      type={
                        showPassword
                          ? `text ${isDarkMode ? "darkText" : ""}`
                          : "password"
                      }
                      value={resetPassword}
                      placeholder="Create a strong password"
                      onChange={(event) => {
                        setResetPassword(event.target.value);
                        if (
                          event.target.value.length < 9 &&
                          event.target.value !== ""
                        ) {
                          setvalidation({
                            resetPassword: true,
                          });
                          setMessage({
                            type: "reset password strength",
                            text:
                              "Password should contain atleast 9 characters",
                          });
                        }
                        if (
                          event.target.value === "" ||
                          event.target.value.length > 7
                        ) {
                          setMessage(null);
                          setvalidation(null);
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            className="no-hover"
                          >
                            {showPassword ? (
                              <Visibility className="visibilityIcon" />
                            ) : (
                              <VisibilityOff
                                className={`${isDarkMode ? "darkVisible" : ""}`}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div>
                  {message?.type === "reset password strength" ? (
                    <span className="error-message">{message.text}</span>
                  ) : (
                    <p className="error-hidden mb-0"></p>
                  )}
                </div>
                <div className="mt-4">
                  <div className="password-lable">
                    <div className={`text ${isDarkMode ? "darkText" : ""}`}>
                      Confirm Password
                    </div>
                  </div>
                  <div className="password-input">
                    <div
                      className={`${
                        validation?.resetConfirmPassword
                          ? "error-input"
                          : resetConfirmPassword
                          ? "inputFilled"
                          : "input"
                      } ${isDarkMode ? "login-dark-input-box" : ""}`}
                    >
                      <Input
                        className={`w-100 ${
                          isDarkMode ? "login-dark-input-box-text" : ""
                        }`}
                        type={
                          showConfirmPassword
                            ? `text ${isDarkMode ? "darkText" : ""}`
                            : "password"
                        }
                        onChange={(event) => {
                          setResetConfirmPassword(event.target.value);
                          if (
                            event.target.value !== resetPassword &&
                            event.target.value !== ""
                          ) {
                            setvalidation({
                              resetConfirmPassword: true,
                            });
                            setMessage({
                              type: "confirm reset password",
                              text: "The passwords should match!",
                            });
                          } else {
                            setMessage(null);
                            setvalidation(null);
                          }
                        }}
                        value={resetConfirmPassword}
                        placeholder="Confirm Password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              className="no-hover"
                              onClick={() => {
                                setShowConfirmPassword(!showConfirmPassword);
                              }}
                            >
                              {showConfirmPassword ? (
                                <Visibility className="visibilityIcon" />
                              ) : (
                                <VisibilityOff
                                  className={`${
                                    isDarkMode ? "darkVisible" : ""
                                  }`}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </div>
                    {message?.type === "confirm reset password" ? (
                      <span className="error-message">{message.text}</span>
                    ) : (
                      <p className="error-hidden mb-0"></p>
                    )}
                  </div>
                </div>
                <div className="register-btn-container mt-5 mb-5">
                  <button
                    className={`login-btn ${
                      isDarkMode ? "darkBtn noBorder" : ""
                    }`}
                    onClick={handleResetPassword}
                  >
                    <span>Reset Now</span>
                  </button>
                </div>
              </div>
            )}
          </div>
          {message?.error ? (
            <div className="snackBar">
              <div
                className={`message-container ${
                  isDarkMode ? "darkCardBody" : ""
                }`}
              >
                <div>
                  <div className="failed-message">
                    <img
                      className="mb-1"
                      src={failSvg}
                      alt="fail"
                      loading="lazy"
                    />
                    {message?.type === "user" ? (
                      <span className="ml-2 error-input-snackBar">
                        User does not exists
                      </span>
                    ) : null}
                    {message?.type === "password" ? (
                      <span className="ml-2 error-input-snackBar">
                        Incorrect Password
                      </span>
                    ) : null}
                    {message?.type === "password strength" && message?.error ? (
                      <span className="ml-2 error-input-snackBar">
                        Password strength
                      </span>
                    ) : null}
                    {message?.type === "reset password" && message?.error ? (
                      <span className="ml-2 error-input-snackBar">Error</span>
                    ) : null}
                    {message?.type === "attempts" && (
                      <span className="ml-2 error-input-snackBar">
                        Too many attempts
                      </span>
                    )}
                    {message?.type === "server" && (
                      <span className="ml-2 error-input-snackBar">
                        Server Error
                      </span>
                    )}
                  </div>
                  <span className={`text ${isDarkMode ? "darkText" : ""}`}>
                    {message?.errorMessage}
                  </span>
                </div>

                <div className="message-close-arrow">
                  <button
                    className="btn float-right"
                    onClick={() => {
                      setMessage(null);
                    }}
                  >
                    <img
                      className="close-btn mb-5"
                      src={closePNG}
                      alt="close"
                      loading="lazy"
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {message?.sucessfull ? (
            <div className="snackBar">
              <div
                className={`message-container ${
                  isDarkMode ? "darkCardBody" : ""
                }`}
              >
                <div>
                  <div className="failed-message">
                    <img
                      className="mb-1"
                      src={sucessfullSvg}
                      alt="success"
                      loading="lazy"
                    />

                    <span className="ml-2 sucessfull-input-snackBar">
                      Sucessfull
                    </span>
                  </div>
                  <span className={`text ${isDarkMode ? "darkText" : ""}`}>
                    {message?.sucessfullMessage}
                  </span>
                </div>

                <div className="message-close-arrow">
                  <button
                    className="btn float-right"
                    onClick={() => {
                      setMessage(null);
                    }}
                  >
                    <img
                      className="close-btn mb-5"
                      src={closePNG}
                      alt="close"
                      loading="lazy"
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {message?.info ? (
            <div className="snackBar">
              <div
                className={`message-container ${
                  isDarkMode ? "darkCardBody" : ""
                }`}
              >
                <div>
                  <div className="failed-message">
                    <Info style={{ color: "rgb(0, 122, 255)" }} />
                    <span className="ml-2 info-input-snackBar">
                      Email already exists
                    </span>
                  </div>
                  <span className={`text ${isDarkMode ? "darkText" : ""}`}>
                    {message?.infoMessage}
                  </span>
                </div>

                <div className="message-close-arrow">
                  <button
                    className="btn float-right"
                    onClick={() => {
                      setMessage(null);
                    }}
                  >
                    <img
                      className="close-btn mb-5"
                      src={closePNG}
                      alt="close"
                      loading="lazy"
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {performRedirect()}
    </>
  );
}

export default LoginPageNew;
