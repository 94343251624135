import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import popper from "assets/lotties/hackathon/popper.json";
import green_arrow from "../img/green_arrow.svg";
import "./TopRanker.css";
import { nodataPopperStyle } from "shared/CommonMethods";
import graph from "../img/graph.svg";
import premium from "../img/premium.svg";
import Fire from "assets/lotties/leaderboards/fire.json";
import sword from "assets/lotties/hackathon/sword.json";
import light_arrow from "../img/light_arrow.svg";
import dark_arrow from "../img/dark_arrow.svg";
import { isMobile } from "react-device-detect";
import increment from "../img/increment.svg";
import decrement from "../img/decrement.svg";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ScriptableContext,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton";
import { API } from "backend";
import { isAuthenticated } from "auth/helper";
import axios from "axios";
import { formatDate } from "shared/CommonMethods";
import { useHistory } from "react-router-dom";
import { toTitleCase } from "shared/CommonMethods";
import { useSelector } from "react-redux";
import ProfileCard from "components/ProfileCard/ProfileCard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);
export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    // to remove the labels
    x: {
      display: false,
      ticks: {
        display: false,
      },

      // to remove the x-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    // to remove the y-axis labels
    y: {
      display: false,
      ticks: {
        display: false,
        beginAtZero: true,
      },
      // to remove the y-axis grid
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];
let width, height, gradient;
function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, "#141C38");
    gradient.addColorStop(0.2, "#C0AC3D");
    gradient.addColorStop(0.5, "#C0AC3D");
    gradient.addColorStop(1, "#141C38");
  }

  return gradient;
}
export const formChart = {
  labels,
  datasets: [
    {
      label: "",
      data: ["100", "22", "34", "100", "70", "50", "40", "70", "30", "100"],
      borderColor: function (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          // This case happens on initial chart load
          return;
        }
        return getGradient(ctx, chartArea);
      },
      pointRadius: 0,
      pointHoverRadius: 2,
      borderWidth: 1.5,
      tension: 0.4,
    },
  ],
};
export default function TopRanker(props) {
  const { isDarkMode, hackathonData, hackathonHREFName } = props;
  const currentUser = useSelector((store) => store.user.user);
  const [isLoading, setIsLoading] = useState(true);
  const [isprofileHover, setIsprofileHover] = useState("");
  const [topRankers, setTopRankers] = useState([]);
  const [leaderboard, setLeaderboard] = useState([]);
  const history = useHistory();
  const sortTopRankers = (arr) => {
    if (arr.length === 1) {
      arr.push({ Rank: 2 });
      arr.push({ Rank: 3 });
    }
    if (arr.length === 2) {
      arr.push({ Rank: 3 });
    }
    arr.sort((a, b) => {
      if (a.Rank === 2) {
        return -1;
      } else if (b.Rank === 2) {
        return 1;
      } else if (a.Rank === 1) {
        return -1;
      } else if (b.Rank === 1) {
        return 1;
      } else {
        return 0;
      }
    });
    setTopRankers(arr);
  };
  const goToLeaderboard = () => {
    if (hackathonData.HCategory === "Blogathon") {
      history.push(`/blogathons/${hackathonHREFName}/leaderboard`);
    } else if (hackathonData.HCategory === "Visualization") {
      history.push(
        `/hackathons/visualization/${hackathonHREFName}/leaderboard`
      );
    } else {
      history.push(`/hackathons/${hackathonHREFName}/leaderboard`);
    }
  };
  const getleaderboard = async () => {
    await axios
      .get(
        `${API}/leaderboard/${hackathonHREFName}/${1}${
          isAuthenticated() ? "?user_id=" + isAuthenticated()?.user?._id : ""
        }`
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.error) {
          // console.log(response.data.error);
        } else {
          setLeaderboard(response.data.leaderboard);
          sortTopRankers(response.data.leaderboard?.slice(0, 3));
          let isCurrentUserinLeaderboard = false;
          response.data.leaderboard?.filter((x) => {
            if (x?.User_Oid === isAuthenticated()?.user?._id) {
              isCurrentUserinLeaderboard = true;
            }
          });
          if (!isCurrentUserinLeaderboard && response?.data?.userRank?.Rank) {
            const userRank = {
              BestScore: response?.data?.userRank?.BestScore,
              ImageUrl: currentUser?.Picture,
              LastSubmission: response?.data?.userRank?.LastSubmission,
              LatestScore: response?.data?.userRank?.LatestScore,
              Name: currentUser?.Name,
              ParticipantCode: "",
              Rank: response?.data?.userRank?.Rank,
              User_Oid: isAuthenticated()?.user?._id,
            };
            setLeaderboard((prev) => {
              prev?.splice(5, 0, userRank);
              return prev;
            });
          }
        }
      })
      .catch((err) => {
        setIsLoading(true);
        console.log(err);
      });
  };
  const getLateleaderboard = async () => {
    await axios
      .get(
        `${API}/lateleaderboard/${hackathonHREFName}/${1}${
          isAuthenticated() ? "?user_id=" + isAuthenticated()?.user?._id : ""
        }`
      )
      .then((response) => {
        setIsLoading(false);
        if (response.data.error) {
          // setError(response.data.error);
        } else {
          setLeaderboard(response.data.leaderboard);
          sortTopRankers(response.data.leaderboard?.slice(0, 3));
          let isCurrentUserinLeaderboard = false;
          response.data.leaderboard?.filter((x) => {
            if (x?.User_Oid === isAuthenticated()?.user?._id) {
              isCurrentUserinLeaderboard = true;
            }
          });
          if (!isCurrentUserinLeaderboard && response?.data?.userRank?.Rank) {
            const userRank = {
              BestScore: response?.data?.userRank?.BestScore,
              ImageUrl: currentUser?.Picture,
              LastSubmission: response?.data?.userRank?.LastSubmission,
              LatestScore: response?.data?.userRank?.LatestScore,
              Name: currentUser?.Name,
              ParticipantCode: "",
              Rank: response?.data?.userRank?.Rank,
              User_Oid: isAuthenticated()?.user?._id,
            };
            setLeaderboard((prev) => {
              prev?.splice(5, 0, userRank);
              return prev;
            });
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    if (hackathonData) {
      const ExceptfiveHackathons = [
        "predict_the_price_of_books",
        "predict_the_news_category_hackathon",
        "predict_the_flight_ticket_price_hackathon",
        "predict_the_data_scientists_salary_in_india_hackathon",
        "predicting_house_prices_in_bengaluru",
      ];
      if (hackathonData?.HCategory === "Blogathon") {
        getLateleaderboard();
      } else if (hackathonData?.HCategory === "Visualization") {
        getLateleaderboard();
      } else {
        if (ExceptfiveHackathons.includes(hackathonHREFName)) {
          getleaderboard();
        } else if (hackathonData.HStatus === "Active") {
          getLateleaderboard();
        } else if (hackathonData.HStatus === "Expired") {
          getleaderboard();
        }
      }
    }
  }, [hackathonData]);
  return (
    <div>
      {topRankers?.length > 0 && (
        <>
          <div className="d-flex align-items-center">
            <Lottie
              animationData={sword}
              loop={true}
              style={{
                width: "79px",
              }}
            />
            <span
              className={`mt-4 ${
                isDarkMode ? "dark-sword-text" : "sword-text"
              }`}
            >
              Beat The Toppers
            </span>
          </div>
          <div
            className={`${
              isDarkMode ? "dark_top-activity-hld" : "light_top-activity-hld"
            }`}
          >
            {isLoading ? (
              <div className="d-flex justify-content-between mt-4">
                <div className="mt-3">
                  <Skeleton
                    width="70px"
                    height="70px"
                    borderRadius="50px"
                    className="mt-4"
                  />{" "}
                  <Skeleton
                    width="70px"
                    height="25px"
                    borderRadius="50px"
                    className="mt-4"
                  />{" "}
                </div>
                <div>
                  <Skeleton
                    width="70px"
                    height="70px"
                    borderRadius="50px"
                    className=""
                  />{" "}
                  <Skeleton
                    width="70px"
                    height="25px"
                    borderRadius="50px"
                    className="mt-4"
                  />{" "}
                </div>
                <div className="mt-3">
                  <Skeleton
                    width="70px"
                    height="70px"
                    borderRadius="50px"
                    className="mt-4"
                  />{" "}
                  <Skeleton
                    width="70px"
                    height="25px"
                    borderRadius="50px"
                    className="mt-4"
                  />{" "}
                </div>
              </div>
            ) : (
              <div
                className={`d-flex ${isMobile ? "justify-content-around" : "justify-content-between"}`}
              >
                {topRankers?.map((user, index) => (
                  <div
                    className={`position-relative topRankHld ${
                      user.Rank === 2
                        ? "mr-3 mt-5"
                        : user.Rank === 1
                        ? "mx-2"
                        : user.Rank === 3
                        ? "ml-2 mt-5"
                        : ""
                    }`}
                    key={index}
                  >
                    {user.User_Oid && (
                      <Lottie
                        animationData={popper}
                        loop={true}
                        style={{
                          zIndex: 1,
                          position: "absolute",
                          top: "%6",
                          width: "100%",
                          left: "0%",
                        }}
                      />
                    )}
                    <div className="rank-section">
                      <div
                        className={`${
                          user?.User_Oid ? `hexagonal-image-${user.Rank}` : ""
                        }`}
                      >
                        <div className="hexagonal-image-inner ">
                          {user.ImageUrl && (
                            <img loading="lazy" src={user.ImageUrl} />
                          )}
                        </div>
                      </div>
                      {user?.User_Oid && (
                        <div
                          className={`rank-text position-absolute rank-text-${user.Rank}`}
                        >
                          <span>{user?.Rank}</span>
                        </div>
                      )}
                    </div>
                    <div
                      className={`top-ranker-name-text-one`}
                    >
                      {toTitleCase(user?.Name || user?.name)}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </>
      )}

      {isLoading ? (
        <div className="mt-3">
          <div
            className={`d-flex align-items-center  py-2 ${
              isDarkMode ? "table-header" : "dark-table-header"
            }`}
          >
            <div className="col-2">
              <span className="table-heading-text">Rank </span>
            </div>
            <div className="col-6">
              <span className="table-heading-text">Name</span>
            </div>
            <div className="col-4">
              <span className="table-heading-text">Movements</span>
            </div>
          </div>
          <div className="align-items-center d-flex">
            <div className="d-flex flex-column pr-3 align-items-center">
              <Skeleton width="280px" height="35px" count={10} />
            </div>
          </div>

          <div className={`${isDarkMode ? "" : "light_stepper"}`}></div>
        </div>
      ) : (
        <div className="mt-3">
          {leaderboard?.slice(3, 12)?.length > 0 && (
            <div
              className={`d-flex align-items-center  py-2 ${
                isDarkMode ? "table-header" : "dark-table-header"
              }`}
            >
              <div className="col-2">
                <span className="table-heading-text">Rank </span>
              </div>
              <div className="col-6">
                <span className="table-heading-text">Name</span>
              </div>
              <div className="col-4">
                <span className="table-heading-text">Movements</span>
              </div>
            </div>
          )}
          {leaderboard?.slice(3, 12)?.map((x, i) => {
            return (
              <div
                className={`align-items-center d-flex ${
                  x?.User_Oid === isAuthenticated()?.user?._id
                    ? isDarkMode
                      ? "dark-rankers-list-current"
                      : "ranker-list-current"
                    : isDarkMode
                    ? "dark-rankers-list"
                    : "rank-list"
                } justify-content-between position-relative py-2`}
                key={i}
              >
                <div
                  className={` ${
                    x?.User_Oid === isAuthenticated()?.user?._id
                      ? isDarkMode
                        ? "dark-ranker-text-cuurent"
                        : "ranker-current-text"
                      : ""
                  } dark-ranker-text my-1 `}
                >
                  <span>{x.Rank}</span>
                </div>
                <div
                  className="col-6 ml-5"
                  onMouseLeave={() => setIsprofileHover("")}
                >
                  <div className="ranker-img-section position-relative d-flex align-items-center my-3 hackathonTopperscard">
                    <div
                      className="ranker-image-chat"
                      onMouseEnter={() => setIsprofileHover(x?.User_Oid)}
                    >
                      <img
                        src={x.ImageUrl}
                        loading="lazy"
                        className="img-fluid ranker-img-section-img"
                      />
                      {/* <div className="p-1 position-absolute dark-chat-notification"></div> */}
                    </div>
                    {isprofileHover === x?.User_Oid && (
                      <ProfileCard
                        setIsHovering={setIsprofileHover}
                        userId={x?.User_Oid}
                      />
                    )}
                    {/* <div className="align-items-center green-arrow-right d-flex justify-content-center">
                      <img
                        src={increment}
                        alt="inc"
                        loading="lazy"
                        className="img-fluid increment-text-img"
                      />
                      <div className="increment-text d-flex align-items-center">
                        <span className="">+ {x.inc} </span>
                        <img
                          src={green_arrow}
                          alt="green_arrow"
                          className="img-fluid"
                          loading="lazy"
                        />
                      </div>
                    </div> */}
                  </div>
                  <div className="d-flex align-items-center">
                    <span
                      className={`${
                        isDarkMode
                          ? "dark-ranker-name-text"
                          : "ranker-name-text"
                      } `}
                    >
                      {toTitleCase(x?.Name || x?.name)}
                    </span>
                    {/* {x.premium && (
                      <img src={premium} alt="premium" className="img-fluid" />
                    )} */}
                    {/* <div className="ranker-fireLottie ml-2 d-flex align-items-center mb-1">
                      <Lottie animationData={Fire} loop={true} />
                    </div> */}
                  </div>
                </div>
                <div className="d-flex flex-column pr-3 align-items-center col-4">
                  {/* <Line options={options} data={formChart} /> */}
                  <span
                    className={`ml-3 ${
                      isDarkMode ? "dark-rank-time-text" : "rank-time-text"
                    }`}
                  >
                    {formatDate(x?.LastSubmission || x?.submission_updated)}
                  </span>
                </div>
              </div>
            );
          })}
          <div className={`${isDarkMode ? "" : "light_stepper"}`}></div>
        </div>
      )}
      {topRankers?.length > 0 && (
        <button
          className={`light_showbtn my-3 ${isDarkMode ? "dark_showBtn" : ""}`}
          onClick={goToLeaderboard}
        >
          Show More{" "}
          <img
            src={isDarkMode ? dark_arrow : light_arrow}
            alt="arrow"
            className="img-fluid mx-2"
          />
        </button>
      )}
    </div>
  );
}
