import moment from "moment";
import * as nsfwjs from "nsfwjs";
import HackathonLevel5 from "assets/img/badges/hackathon-level-5.svg";
import HackathonLevel4 from "assets/img/badges/hackathon-level-4.svg";
import HackathonLevel3 from "assets/img/badges/hackathon-level-3.svg";
import HackathonLevel2 from "assets/img/badges/hackathon-level-2.svg";
import HackathonLevel1 from "assets/img/badges/hackathon-level-1.svg";
import BlogLevel5 from "assets/img/badges/blog-level-5.svg";
import BlogLevel4 from "assets/img/badges/blog-level-4.svg";
import BlogLevel3 from "assets/img/badges/blog-level-3.svg";
import BlogLevel2 from "assets/img/badges/blog-level-2.svg";
import BlogLevel1 from "assets/img/badges/blog-level-1.svg";
import AssessmentLevel5 from "assets/img/badges/assessment-level-5.svg";
import AssessmentLevel4 from "assets/img/badges/assessment-level-4.svg";
import AssessmentLevel3 from "assets/img/badges/assessment-level-3.svg";
import AssessmentLevel2 from "assets/img/badges/assessment-level-2.svg";
import AssessmentLevel1 from "assets/img/badges/assessment-level-1.svg";
import PracticeLevel5 from "assets/img/badges/practice-level-5.svg";
import PracticeLevel4 from "assets/img/badges/practice-level-4.svg";
import PracticeLevel3 from "assets/img/badges/practice-level-3.svg";
import PracticeLevel2 from "assets/img/badges/practice-level-2.svg";
import PracticeLevel1 from "assets/img/badges/practice-level-1.svg";
import { store } from "store/store";

export const getInnerHTML = (data) => {
  if (data) {
    const regex = /(<([^>]+)>)/gi;
    return data?.replace(regex, "");
  }
};
export const timer = (seconds, returnAs) => {
  if (seconds === null || seconds === undefined) {
    seconds = 0;
  }
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (minutes > 0) {
    seconds = seconds - minutes * 60;
  }

  if (hours > 0) {
    minutes = minutes - hours * 60;
  }

  switch (returnAs) {
    case "words": {
      return (
        (hours ? `${getFormat(hours)}h` : "00h") +
          ":" +
          (minutes ? `${getFormat(minutes)}m` : "00m") +
          ":" +
          (seconds ? `${getFormat(seconds)}s` : "00s") || "0s"
      );
    }
    case "default":
    default:
      return `${getFormat(hours) + " : "}${getFormat(minutes)} : ${getFormat(
        seconds
      )}`;
  }
};
const getFormat = (v) => {
  return `0${v}`.slice(-2);
};

export const getCountryName = () => {
  let country = new Date().toString().split("(")[1].split(" ")[0];
  return country;
};

export const formatDate = (dateTime) => {
  if (dateTime) {
    const currentDate = moment();
    const timeDifference = currentDate.diff(dateTime, 'minutes');
    
    if (timeDifference < 60) {
      return `${timeDifference} minutes ago`;
    } else if (timeDifference < 1440) {
      const hoursAgo = Math.floor(timeDifference / 60);
      return `${hoursAgo} hours ago`;
    } else if (timeDifference < 43200) {
      const daysAgo = Math.floor(timeDifference / 1440);
      return `${daysAgo} days ago`;
    } else {
      const monthsAgo = Math.floor(timeDifference / 43200);
      return `${monthsAgo} months ago`;
    }
  }
};
export const urlify = (text) => {
  var urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/g;
  return text.replace(urlRegex, function (url) {
    var hyperlink = url;
    if (!hyperlink.match("^https?:")) {
      hyperlink = "http://" + hyperlink;
    }
    return (
      '<a className="" href="' +
      getInnerHTML(url) +
      '" target="_blank rel="noopener" noreferrer>' +
      getInnerHTML(url) +
      "</a>"
    );
  });
};

export const nodataImgStyle = {
  height: 250,
};
export const nodataPopperStyle = {
  height: 66,
};

export const getUserNoOfRanks = (data, rankNumber) => {
  let noOfTimes = 0;
  data?.filter((x) => {
    if (x.rank === rankNumber) {
      noOfTimes = x.noOfPractices;
    }
  });
  return noOfTimes;
};
export const toTitleCase = (str) => {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
export const sortTopthreeRankHolders = (arr) => {
  if (arr.length === 1) {
    arr.push({ currentRank: 2 });
    arr.push({ currentRank: 3 });
  }
  if (arr.length === 2) {
    arr.push({ currentRank: 3 });
  }
  arr.sort((a, b) => {
    if (a.currentRank === 2) {
      return -1;
    } else if (b.currentRank === 2) {
      return 1;
    } else if (a.currentRank === 1) {
      return -1;
    } else if (b.currentRank === 1) {
      return 1;
    } else {
      return 0;
    }
  });
  return arr;
};

export const getRanks = (data) => {
  const ranks = [];
  data?.filter((x) => {
    ranks.push(x?.rank || x?.currentRank);
  });
  ranks?.length === 1 && ranks?.unshift(ranks[0]);
  return ranks?.reverse();
};
export const getSubmissions = (data) => {
  const submissions = [];
  data?.filter((x) => {
    submissions.push(x.totalSubmissions);
  });
  submissions.length === 1 && submissions.unshift(submissions[0]);
  return submissions.reverse();
};
export const daysInYear = (year) => {
  return (year % 4 === 0 && year % 100 > 0) || year % 400 == 0 ? 366 : 365;
};
export const generateAllDatesoftheyear = () => {
  const date = new Date();
  const startDate = new Date(date.setDate(date.getDate() - 365)); // January 1st of the current year
  const endDate = new Date(); // December 31st of the current year

  const datesArray = [];
  for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
    const date = new Date(d);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    datesArray.push(`${date.getFullYear()}-${month}-${day}T00:00:00.000Z`);
  }
  return datesArray;
};

export const getActivityData = (data) => {
  const datesArray = generateAllDatesoftheyear();
  const submissions = data;
  for (const date of datesArray) {
    const existingObj = data?.find((obj) => obj._id?.toString() === date);
    if (!existingObj) {
      submissions?.push({ _id: date, totalSubmissions: 0 });
    }
  }
  submissions?.sort((a, b) => new Date(a._id) - new Date(b._id));
  const graphData = [];
  const label = [];
  submissions?.forEach((element) => {
    graphData.push(element.totalSubmissions);
    label.push(moment(element._id).format("DD MMM"));
  });
  return { labels: label, data: graphData };
};
const nsfw_predictions = ["Porn", "Hentai", "Sexy"];
export const CheckImageNSFW = async (imageFileRef, media) => {
  const img = imageFileRef.current[media?.length - 1];
  const model = await nsfwjs.load("/quant_mid/", { type: "graph" });
  const predictions = await model.classify(img);
  // console.log("prediction",predictions)
  let isImageNsfw = false;
  predictions?.forEach((element, i) => {
    const probability_percentage = (element.probability * 100)?.toFixed(2);
    if (nsfw_predictions.includes(element?.className)) {
      parseFloat(probability_percentage) > 80 && (isImageNsfw = true);
    }
  });
  return isImageNsfw;
};

const hackathoBadges = [
  HackathonLevel1,
  HackathonLevel2,
  HackathonLevel3,
  HackathonLevel4,
  HackathonLevel5,
];
const practiceBadges = [
  PracticeLevel1,
  PracticeLevel2,
  PracticeLevel3,
  PracticeLevel4,
  PracticeLevel5,
];
const assessmentBadges = [
  AssessmentLevel1,
  AssessmentLevel2,
  AssessmentLevel3,
  AssessmentLevel4,
  AssessmentLevel5,
];
const blogbadges = [BlogLevel1, BlogLevel2, BlogLevel3, BlogLevel4, BlogLevel5];

const getBadge = (level, imagesArray) => {
  let image_name = "";
  switch (level) {
    case "GrandMaster":
      image_name = imagesArray[4];
      break;
    case "Master":
      image_name = imagesArray[3];
      break;
    case "Champion":
      image_name = imagesArray[2];
      break;
    case "Apprentice":
      image_name = imagesArray[1];
      break;
    default:
      image_name = imagesArray[0];
      break;
  }
  return image_name;
};

export const getUserBadge = (level, type) => {
  switch (type) {
    case "hackathon":
      return getBadge(level, hackathoBadges);
    case "practice":
      return getBadge(level, practiceBadges);
    case "assessment":
      return getBadge(
        level,
        assessmentBadges,
      );
    case "blog":
      return getBadge(level, blogbadges);
    default:
      break;
  }
};
// Genpact-Google Topbar render condition
const DonotShowTopBarIn = [
  "/hackathons/sustainability_hackathon_lets_crack_the_climate_resilience_code/",
  "/hackathons/genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code/",
  "/datalyze",
  "/enterprise/assessment/",
  "/feedback/"
];
export const showTopBarIn = () => {
  let showTopBar = true;
  DonotShowTopBarIn.forEach((x) => {
    showTopBar =
      showTopBar && (x === "/" ? location.pathname !== x : !location.pathname.includes(x));
  });
  return showTopBar;
};

export const updateCurrentUrl = () => {
  store.dispatch({
    type: "SET_CURRENT_URL",
    payload: window.location.href,
  });
}
export function parseJwt(token) {
  if(token){
    var base64Url = token.split(".")[1];
    var base64 = base64Url?.replace(/-/g, "+")?.replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }
}