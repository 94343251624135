import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import moment from "moment";
import TimerLottie from "assets/lotties/hackathon/timer.json";
import "./countDownTimer.css";

const CountDownTimer = ({ endDate }) => {
  const Ref = useRef(null);
  const [timer, setTimer] = useState("");

  const getTimeRemaining = (e) => {
    const isoDateString = moment(endDate, "DD/MM/YYYY HH:mm:ss");
    const targetDate = moment.utc(isoDateString);
    const diff = targetDate.diff(moment.utc());
    const duration = moment.duration(diff);
    const daysLeft = Math.floor(duration.asDays());
    const hoursLeft = duration.hours();
    const minutesLeft = duration.minutes();
    const secondsLeft = duration.seconds();
    const isExpired = duration.asMilliseconds() < 0;

    return {
      isExpired,
      daysLeft,
      hoursLeft,
      minutesLeft,
      secondsLeft,
    };
  };
  const startTimer = (e) => {
    let {
      isExpired,
      daysLeft,
      hoursLeft,
      minutesLeft,
      secondsLeft,
    } = getTimeRemaining(e);
    if (!isExpired) {
      setTimer(
        (daysLeft > 9 ? daysLeft : "0" + daysLeft) +
          (daysLeft > 1 ? " Days " : " Day ") +
          (hoursLeft > 9 ? hoursLeft : "0" + hoursLeft) +
          (hoursLeft > 1 ? " hrs " : "hr ") +
          (minutesLeft > 9 ? minutesLeft : "0" + minutesLeft) +
          (minutesLeft > 1 ? " mins " : " min ") +
          (secondsLeft > 9 ? secondsLeft : "0" + secondsLeft) +
          " sec "
      );
    }
  };

  const clearTimer = (e) => {
    // setTimer("00 Days 00 hr 00 min 00 sec");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  return (
    <>
      {timer && (
        <div className="hackathon-timer-hld d-flex  justify-content-center">
          <Lottie
            animationData={TimerLottie}
            loop={true}
            style={{
              height: "50px",
              width: "30",
              position: "absolute",
              left: "0px",
              top: "-11px",
            }}
          />
          <h2 className="mb-0">{timer}</h2>
        </div>
      )}
    </>
  );
};

export default CountDownTimer;
