import React from "react";
class OAuthHandlerPage extends React.PureComponent {
  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    const body = {
      message: `Logged in as ${query.get("user_email")}`,
      refresh_token: query.get("refresh_token"),
      token: query.get("token"),
      user: {
        Email: query.get("user_email"),
        _id: query.get("user_id"),
      },
    };

    localStorage.setItem("jwt", JSON.stringify(body));
    localStorage.setItem("loginTime", Date.now());

    if (localStorage.getItem("prevPath")) {
      window.location.href = localStorage.getItem("prevPath");
    } else window.location.href = "/";
  }

  render() {
    return (
      <div>
        <strong>Please wait, we are logging you in...</strong>
      </div>
    );
  }
}

export default OAuthHandlerPage;
