import React from "react";
import pageNotFound from "assets/img/404.svg";
import "./errorPage.css";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

function ErrorPage() {
  const history = useHistory();
  const displayMode = useSelector((store) => store.display.display_mode);
  return (<>
    <Helmet>
      <style>{`body { background-color: ${ displayMode==="dark_mode" ? "#000726" : "#ffffff"} !important; }`}</style>
    </Helmet>
    <div
      className={`bootstrap-iso ${
        displayMode === "dark_mode" ? "dark-bg darkTheme" : ""
      }`}
    >
      <div
        className="d-flex justify-content-center align-items-center flex-column page404Hld"
        style={{ height: "80vh" }}
      >
        <img
          src={pageNotFound}
          className="img-fluid"
          alt="404"
          loading="lazy"
        />
        <h5 className="Page404text">
          The page you’re looking for does not exists
        </h5>
        <button
          className={`mt-4 page404Button ${
            displayMode === "dark_mode" ? "darkBtn noBorder" : ""
          }`}
          onClick={() => history.push(`/`)}
        >
          GO BACK HOME
        </button>
      </div>
    </div>
    </>
  );
}

export default ErrorPage;
