import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "backend";
import { Alert } from "@mui/lab";
import { Link } from "react-router-dom";
import { Pagination } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import ProfileCard from "components/ProfileCard/ProfileCard";
import rank_two from "../../Hackathon/Reuseable/img/rank2.svg";
import rank_three from "../../Hackathon/Reuseable/img/rank3.svg";
import rank_one from "../../Hackathon/Reuseable/img/rank_one.svg";
import latest_badge from "../../Hackathon/Reuseable/img/latest_badge.svg";
import best_badge from "../../Hackathon/Reuseable/img/best_badge.svg";
import light_rank1 from "../../Hackathon/Reuseable/img/light_rank1.svg";
import light_rank2 from "../../Hackathon/Reuseable/img/light_rank2.svg";
import light_rank3 from "../../Hackathon/Reuseable/img/light_rank3.svg";
import current_tag from "../../Hackathon/Reuseable/img/current_tag.svg";
import light_tag from "../../Hackathon/Reuseable/img/light_tag.svg";
import Lottie from "lottie-react";
import Fire from "assets/lotties/leaderboards/fire.json";
import popper from "assets/lotties/hackathon/popper.json";
import { isMobile } from "react-device-detect";
import { formatDate } from "shared/CommonMethods";
import "./HackLB.css";
import "../../Hackathon/Reuseable/HackLB.css";
import { toTitleCase } from "shared/CommonMethods";
import { isAuthenticated } from "auth/helper";

const moment = require("moment");
const rank_image = [rank_one, rank_two, rank_three];
const light_rank = [light_rank1, light_rank2, light_rank3];

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: "16px",
    },
  },
}));

export default function LeaderBoard(props) {
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(30);
  const [leaderBoardCount, setLeaderBoardCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [scroll, setScroll] = React.useState("paper");
  const [isLoading, setIsLoading] = useState(true);
  const [isHovering, setIsHovering] = useState("");
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentLeaderBoard = leaderBoard.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const { isDarkMode } = props;
  const classes = useStyles();
  const formatSubmissionDate = (dateTime) => {
    var string = dateTime;
    var temp = string.split(" ");
    var strdate = temp[0].split("/");
    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];
    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("DD MMM YYYY");
    formattedDate = formattedDate + " " + temp[1];
    return formattedDate;
  };

  //Current LeaderBoard
  const getLeaderBoard = (pageNumber) => {
    axios
      .get(`${API}/lateleaderboard/${props.hackathon.HREFName}/${pageNumber}`)
      .then((response) => {
        setIsLoading(false);
        setSelectedPage(pageNumber);
        if (response.data.error) {
          // console.log(response.data.error);
        } else {
          setLeaderBoardCount(response.data.leaderboardParticipants);
          setLeaderBoard(response.data.leaderboard);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getLeaderBoard(1);
  }, [props.hackathon.HREFName]);

  const handleLeaderBoardChange = (e, value) => {
    setIsLoading(true);
    getLeaderBoard(value);
  };

  const handleClose1 = () => {
    setIsLoading(false);
  };

  return (
    <>
      {props.userRankData.Rank && (
        <div
          className={`myPerformance ${isDarkMode ? "darkmyPerformance" : ""}`}
        >
          <p className="heading px-4 py-2">Your Performance</p>
          <div className="d-flex justify-content-between py-2 px-4">
            <p>
              <span className="myscoretxt">Rank </span>
              <span className="myscorevalue">{props.userRankData.Rank}</span>
            </p>
            {props.userRankData?.Votes && (
              <p>
                <span className="myscoretxt">Likes </span>
                <span className="myscorevalue">{props.userRankData.Votes}</span>
              </p>
            )}
            {props.userRankData.submission_updated && (
              <p>
                <span className="myscoretxt">Last Submission </span>
                <span className="myscorevalue">
                  {formatSubmissionDate(props.userRankData.submission_updated)}
                </span>
              </p>
            )}
          </div>
        </div>
      )}
      {currentLeaderBoard.length > 0 ? (
        <>
          <div className="dark-hackathon-leaderboard web-view">
            <div
              className={`${
                isDarkMode
                  ? "dark-hackathon-leaderboard-head"
                  : "light-table-header"
              }`}
            >
              <div className="dark-user-Ranker-section pb-2">
                <span className="dark-leaderboard-hackathon-header-text">
                  Rank
                </span>
              </div>
              <div
                className={`pb-2 ${
                  isMobile
                    ? "mobile-header-taems-section"
                    : "dark-header-taems-section"
                }`}
              >
                <span className="dark-leaderboard-hackathon-header-text">
                  Participants
                </span>
              </div>
              <div
                className={`align-items-center d-flex flex-column pb-2 ml-1 ${
                  isMobile
                    ? "mobile-leaderboard-strip-latest"
                    : "leaderboard-strip-latest"
                }`}
              >
                <img
                  src={best_badge}
                  alt="badge"
                  className="img-fluid "
                  loading="lazy"
                />
                <span className="dark-leaderboard-hackathon-header-text ">
                  Likes
                </span>
              </div>
              <div className={`ml-4 pb-2 ${isMobile ? "d-none" : ""}`}>
                <span className="dark-leaderboard-hackathon-header-text col-2">
                  Last Submission
                </span>
              </div>
            </div>
            {currentLeaderBoard?.map((x, i) => {
              return (
                <div
                  className={`${
                    isDarkMode ? "dark-table-body" : "light-table-body"
                  } ${
                    x?.Rank > 0 && x?.Rank < 4 ? `rankGradient${x?.Rank}` : ""
                  } ${
                    x?.User_Oid === isAuthenticated()?.user?._id
                      ? isDarkMode
                        ? "dark-leaderboard-current"
                        : "light-leaderboard-current"
                      : ""
                  }  `}
                  key={i}
                >
                  <div
                    className={`${
                      x?.Rank > 0 && x?.Rank < 4
                        ? "dark-user-Ranker-section"
                        : "dark-user-rank-section"
                    }  `}
                  >
                    {x?.Rank > 0 && x?.Rank < 4 ? (
                      <img
                        src={isDarkMode ? rank_image[i] : light_rank[i]}
                        className="img-fluid rank-image"
                        alt="rank-one"
                        loading="lazy"
                      />
                    ) : (
                      <div className="position-relative">
                        <span
                          className={`d-block ${
                            x?.User_Oid === isAuthenticated()?.user?._id ? isDarkMode
                              ? "position-absolute dark-currentuser-text"
                              : "position-absolute light-currentuser-text"
                            : isDarkMode ? "LbPosition" : "light-LbPosition"
                          } `}
                        >
                         {x.Rank}
                        </span>
                        {x?.User_Oid === isAuthenticated()?.user?._id && <img
                          src={isDarkMode ? current_tag : light_tag}
                          alt="current_tag"
                          className="img-fluid"
                          loading="lazy"
                        />}
                      </div>
                    )}
                  </div>
                  <div
                    className={`d-flex align-items-center position-relative ${
                      isMobile
                        ? "mobile-leaderboard-username-section mr-2"
                        : "leaderboard-username-section mr-3"
                    } `}
                  >
                    <div className="position-relative hackathon-user-chat-section">
                      {!isMobile && x.Rank === 1 && (
                        <Lottie
                          animationData={popper}
                          loop={true}
                          style={{
                            zIndex: 10,
                            position: "absolute",
                            top: "-21px",
                            width: "68px",
                            left: "-16px",
                          }}
                          className="cursor-pointer"
                          onMouseEnter={() => {
                            !x?.team_profile_pic && setIsHovering(i);
                          }}
                        />
                      )}
                      <Link
                        to={`/user/${x.User_Oid}`}
                        style={{ textDecoration: "none" }}
                      >
                        <img
                          src={x.ImageUrl}
                          height="35"
                          width="35"
                          className={`${isMobile ? "d-none" : "img-fluid"}`}
                          style={{ borderRadius: "50%" }}
                          alt="image"
                          loading="lazy"
                          onMouseEnter={() => {
                            setIsHovering(i);
                          }}
                        />
                      </Link>
                      {isHovering === i && (
                        <ProfileCard
                          userId={x?.User_Oid}
                          setIsHovering={setIsHovering}
                        />
                      )}
                    </div>
                    <div
                      className={`d-flex position-relative ${
                        isMobile
                          ? "mobile-leader-board-username-section"
                          : "leader-board-username-section"
                      } `}
                    >
                      <span
                        className={`${
                          isDarkMode
                            ? "dark-leader-hackathon-user-text"
                            : "light-leader-hackathon-user-text"
                        } ml-3`}
                      >
                        <Link
                          to={`/user/${x.User_Oid}`}
                          style={{ textDecoration: "none" }}
                          onMouseEnter={() => setIsHovering(i + x.User_Oid)}
                          className={isDarkMode ? "whiteText" : ""}
                        >
                          {toTitleCase(x?.name)}
                        </Link>
                      </span>
                      {isHovering === i + x.User_Oid && (
                        <ProfileCard
                          userId={x?.User_Oid}
                          setIsHovering={setIsHovering}
                        />
                      )}
                    </div>
                    {/* <div className="ranker-fireLottie ml-2 d-flex align-items-center mb-1">
                    <Lottie animationData={Fire} loop={true} />
                  </div> */}
                  </div>
                  <div
                    className={`align-items-center col-1 d-flex justify-content-center ${
                      isMobile
                        ? "mobile-leaderboard-strip-latest"
                        : x?.User_Oid === isAuthenticated()?.user?._id
                        ? "leaderboard-strip-dimension"
                        : "leaderboard-strip-latest"
                    }  ml-5 mr-3`}
                  >
                    <span
                      className={`${
                        isDarkMode ? "dark-latest-text" : "light-latest-text"
                      } `}
                    >
                      {x.Votes}
                    </span>
                  </div>
                  <div className={`ml-4 ${isMobile ? "d-none" : "d-flex"}`}>
                    <p
                      className={`pr-0 ${
                        isDarkMode
                          ? "dark-leaderboard-date-text"
                          : "light-leaderboard-date-text"
                      } `}
                    >
                      {formatDate(x.submission_updated)}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className="col-md-12 mt-4 text-center">
              <div
                className={`row justify-content-center ${
                  isDarkMode
                    ? "hackathon-dashboard darkPagination"
                    : "lightModePagination"
                }`}
              >
                <Pagination
                  count={
                    leaderBoardCount % postsPerPage === 0
                      ? parseInt(leaderBoardCount / postsPerPage)
                      : parseInt(leaderBoardCount / postsPerPage) + 1
                  }
                  defaultPage={1}
                  page={Number(selectedPage)}
                  variant="outlined"
                  shape="rounded"
                  className={classes.root}
                  onChange={handleLeaderBoardChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          </div>

          <div
            className="col-md-12 mobile-view w-100 pl-4 pr-4 pb-2"
            style={{
              backgroundColor: "#fff",
              border: "solid 1px #dfdfdf",
              boxShadow: "3px 3px 10px 5px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className={`row mt-2 lederborad-head pt-3 pb-3 $}`}>
              <div className="col-2 head-text">Hackers</div>
              <div className="col-5 head-text pl-0"> </div>
              <div className="col-2 head-text pl-0">Rank</div>
              <div className="col-3 head-text pl-0">Likes</div>
            </div>

            {currentLeaderBoard.map((row, index) => (
              <div
                className={`row mt-2 lederborad-body ${
                  index % 2 === 0 ? "pt-2 pb-2" : "pb-0"
                }`}
                style={{
                  backgroundColor: `${index % 2 === 0 ? "#f4f4f4" : "#fff"}`,
                }}
              >
                <div className="col-2 body-text">
                  <Link
                    to={`/user/${row.User_Oid}`}
                    style={{ textDecoration: "none" }}
                  >
                    <img
                      src={row.ImageUrl}
                      height="35"
                      width="35"
                      style={{ borderRadius: "50%" }}
                      alt="image"
                      loading="lazy"
                    />
                  </Link>
                </div>
                <div className="col-5 body-text-bold pl-0 pt-2">
                  <Link
                    to={`/user/${row.User_Oid}`}
                    style={{ textDecoration: "none" }}
                  >
                    {toTitleCase(row?.name)}
                  </Link>
                </div>
                <div className="col-2 body-text-bold pt-2">{row.Rank}</div>
                <div className="col-3 body-text pt-2">{row.Votes}</div>
              </div>
            ))}
          </div>

          <div className="col-md-12 mt-4 text-center">
            <div
              className={`row justify-content-center ${
                isDarkMode
                  ? "hackathon-dashboard darkPagination"
                  : "lightModePagination"
              }`}
            >
              <Pagination
                count={
                  leaderBoardCount % postsPerPage === 0
                    ? parseInt(leaderBoardCount / postsPerPage)
                    : parseInt(leaderBoardCount / postsPerPage) + 1
                }
                defaultPage={1}
                page={Number(selectedPage)}
                variant="outlined"
                shape="rounded"
                className={classes.root}
                onChange={handleLeaderBoardChange}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {props.hackathon.coming_soon === true ? (
            <>
              <div className="col-md-12 pl-0 pr-0">
                <div className="shadow p-3 mb-5 bg-white">
                  <div className="row">
                    <div className="col-md-1"></div>
                    <div className="col-md-10">
                      <Alert severity="info">
                        <li
                          style={{
                            listStyleType: "none",
                          }}
                        >
                          <b>
                            The leaderboard will be populated, once the
                            hackathon starts.
                          </b>
                        </li>
                      </Alert>
                    </div>
                    <div className="col-md-1"></div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}
