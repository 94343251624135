import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

import { getAnalytics, logEvent } from "firebase/analytics";
const analytics = getAnalytics();

//global Events
export const joinTelegramGroup = () => {
  return logEvent(analytics, "join_telegram_group");
};
export const viewMHUserProfile = () => {
  return logEvent(analytics, "view_MH_user_profile");
};
// export const goToEnterprise = () => {
//   return logEvent(analytics, "go_to_enterprise");
// };
export const subscribe = () => {
  return logEvent(analytics, "subscribe");
};

export const viewFAQ = () => {
  return logEvent(analytics, "view_FAQ");
};

//blog Events
export const ViewBlog = () => {
  return logEvent(analytics, "view_blog");
};

export const readArticleBlog = (id) => {
  return logEvent(analytics, "read_article_blog", { articleId: id });
};

export const setupBlog = () => {
  return logEvent(analytics, "setup_blog");
};

export const writeArticleBlog = () => {
  return logEvent(analytics, "write_article_blog");
};

export const searchArticleBlog = (searchText) => {
  return logEvent(analytics, "search_article_blog", {
    serchedArticle: searchText,
  });
};

export const followBlog = (id) => {
  return logEvent(analytics, "follow_blog", { blogId: id });
};

export const appreciateArticleBlog = (id) => {
  return logEvent(analytics, "appreciate_article_blog", { articleId: id });
};

export const shareArticleBlog = (articleSlug) => {
  return logEvent(analytics, "share_article_blog", {
    articleSlug: articleSlug,
  });
};

export const commentOnBlog = () => {
  return logEvent(analytics, "comment_on_blog");
};

export const viewAuthorBlog = () => {
  return logEvent(analytics, "view_author_blog");
};

//hackthon Events
export const viewHackathon = () => {
  return logEvent(analytics, "view__hackathon");
};

export const selectHackathon = (eventProperties) => {
  return logEvent(analytics, "select_hackathon", eventProperties);
};

export const viewHomeTabHackathon = () => {
  return logEvent(analytics, "view_home_tab_hackathon");
};

export const viewDataTabHackathon = () => {
  return logEvent(analytics, "view_data_tab_hackathon");
};

export const viewDiscussionsHackathon = () => {
  return logEvent(analytics, "view_data_tab_hackathon");
};

export const viewNotebooksTabHackathon = () => {
  return logEvent(analytics, "view_notebooks_tab_hackathon");
};

export const downloadNotebookHackathon = () => {
  return logEvent(analytics, "download_notebook_hackathon");
};

export const viewLeaderBoardHackathon = () => {
  return logEvent(analytics, "view_leader_board_hackathon");
};

export const participateInHackathon = () => {
  return logEvent(analytics, "participate_in_hackathon");
};

export const submitHiringFormHackathon = () => {
  return logEvent(analytics, "submit_hiring_form_hackathon");
};

export const shareHackathon = () => {
  return logEvent(analytics, "share_hackathon");
};

export const submitSolutionHackathon = () => {
  return logEvent(analytics, "submit_solution_hackathon");
};

export const viewOverviewHackathon = () => {
  return logEvent(analytics, "view_overview_hackathon");
};

export const viewRulesHackathon = (eventProperties) => {
  return logEvent(analytics, "view_rules_hackathon", eventProperties);
};

export const viewEvaluationHackathon = () => {
  return logEvent(analytics, "view_evaluation_hackathon");
};

export const viewPrizeHackathon = () => {
  return logEvent(analytics, "view_prize_hackathon");
};

export const viewSummaryHackathon = () => {
  return logEvent(analytics, "view_summary_hackathon");
};

// data Engineering

export const joinNowDataEngineering = () => {
  return logEvent(analytics, "join_now_data_engineering");
};

// Intertrview Prep
export const viewInterviewPrep = () => {
  return logEvent(analytics, "view_interviewPrep");
};
export const selectInterviewPrep = () => {
  return logEvent(analytics, "select_interviewPrep");
};

export const startInterviewPrep = () => {
  return logEvent(analytics, "start_interviewPrep");
};

export const searchInterviewPrep = () => {
  return logEvent(analytics, "search_interviewPrep");
};

// practice

export const viewPractice = () => {
  return logEvent(analytics, "view_practice");
};

export const searchPractice = () => {
  return logEvent(analytics, "search_practice");
};

export const pickOnePractice = () => {
  return logEvent(analytics, "pick_one_practice");
};

export const searchTopicPractice = () => {
  return logEvent(analytics, "search_topic_practice");
};

export const filterByDifficultyPractice = () => {
  return logEvent(analytics, "filter_by_difficulty_practice");
};

export const viewCourses = () => {
  return logEvent(analytics, "view_courses");
};

export const selectCourse = () => {
  return logEvent(analytics, "select_course");
};

export const ShowFreeCourses = () => {
  return logEvent(analytics, "show_free_courses");
};

export const ShowPremiumCourses = () => {
  return logEvent(analytics, "show_premium_courses");
};

export const startTheCourse = () => { return  logEvent(analytics, "start_the_course"); };

export const pickOneCourse = () => {
  return logEvent(analytics, "pick_one_course");
};

export const searchCourse = () => {
  return logEvent(analytics, "search_course");
};

export const viewDiscussions = () => {
  return logEvent(analytics, "view_discussions");
};

export const searchDiscussion = () => {
  return logEvent(analytics, "search_discussion");
};

export const bookmarkDiscussion = () => {
  return logEvent(analytics, "bookmark_discussion");
};

export const selectDiscussion = () => {
  return logEvent(analytics, "select_discussion");
};

export const replyDiscussion = () => {
  return logEvent(analytics, "reply_discussion");
};

export const copyLinkDiscussion = () => {
  return logEvent(analytics, "copy_link_discussion");
};

export const likeDiscussion = () => {
  return logEvent(analytics, "like_discussion");
};

export const dislikeDiscussion = () => {
  return logEvent(analytics, "dislike_discussion");
};

// events

export const viewEvents = () => {
  return logEvent(analytics, "view_events");
};

export const registerEvents = () => {
  return logEvent(analytics, "register_events");
};

export const attendEvents = () => {
  return logEvent(analytics, "attend_events");
};

export const searchJob = () => {
  return logEvent(analytics, "search_job");
};

export const applyForJob = () => {
  return logEvent(analytics, "apply_job");
};

export const bookmarkJob = () => {
  return logEvent(analytics, "bookmark_job");
};

//subscription

export const viewSubscriptionPremium = () => {
  return logEvent(analytics, "view_subscription_premium");
};

//Mh Coins

export const viewMHCoins = () => {
  return logEvent(analytics, "view_MH_coins");
};

export const earnMHCoins = () => {
  return logEvent(analytics, "earn_MH_coins");
};

export const redeemMHCoins = () => {
  return logEvent(analytics, "redeem_MH_coins");
};
