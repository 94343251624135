import React, { useEffect, useRef } from "react";

const UserSearchList = ({ usersList, onTagUser, setShowUserList }) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowUserList(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  return (
    <div className="usersListCntr" ref={wrapperRef}>
      {usersList?.map((user) => (
        <div
          className="d-flex align-items-center userDetailsCntr px-2 py-2 cursor-pointer"
          key={user?.id}
          onClick={() => onTagUser(user)}
        >
          <img
            src={user?.Picture}
            alt={user?.Name}
            className="img-fluid mr-2 user-profile-picture"
            width={34}
            height={34}
          />
          <div>
            <p className="user-profile-name">{user?.Name}</p>
            <p className="user-details">
              {user?.LatestDesignation + " at " + user?.Organization}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UserSearchList;
