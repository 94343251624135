import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import SingleDiscussionComponent from "./components/SingleDiscussionComponent";
import "assets/landingpage/assets/css-landing/bootstrap-iso.css";
import "assets/landingpage/assets/css-landing/bootstrap-iso-vendor.css";
import "./SingleDiscussion.css";

export default function SingleDiscussion(props) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MachineHack Generative AI</title>
        <meta
          name="description"
          content="Supercharge your generative AI skills with immersive hands-on practice and exhilarating competition. Immerse yourself in our meticulously crafted competitions, assessments, tailored practice sessions, and thrilling tournaments. Showcase your expertise and unlock new levels of collaboration within our vibrant community of talented AI professionals."
        />
        <link rel="canonical" href="https://www.machinehack.com/" />
        <style>{"body { background-color: #fff !important; }"}</style>
      </Helmet>
      <div>
        <div className="bootstrap-iso">
          <div className="body-scroll  landingbody">
            <div className="wrapper clearfix" id="wrapper">
              <div
                className=""
                style={{
                  marginTop: "50px",
                }}
              >
                <SingleDiscussionComponent DiscussionId={props.match.params.discussionId}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
