let initialState = {
	practices: [],
	practice_page_number : 1
};

function practicesReducer(state = initialState, action) {
	if (action.type === "GET_ALL_PRACTICES") {
		return {
			...state,
			practices: action.payload,
		};
	}
	if (action.type === "SET_PRACTICE_PAGE_NUMBER") {
		return {
		  ...state,
		  practice_page_number: action.payload,
		};
	  }
	return state;
}

export default practicesReducer;
