import React from "react";
import errorImg from "assets/img/ProfilePage/error-icon.svg";

export default function ErrMsg(props) {
  return (
    <div className="mt-1 d-flex align-items-start">
      <img
        src={errorImg}
        loading="lazy"
        alt="error"
        className="img-fluid mr-1"
        style={{ verticalAlign: "baseline" }}
      />
      <label className="errTxt mb-0">{props.Message}</label>
    </div>
  );
}
