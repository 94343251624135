let initialState = {
  discussion_page_number : 1
};

function discussionReducer(state = initialState, action) {
  if (action.type === "SET_DISCUSSION_PAGE_NUMBER") {
    return {
      ...state,
      discussion_page_number: action.payload,
    };
  }
  return state;
}

export default discussionReducer;
