/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./index";
import { useHistory, useLocation } from "react-router-dom";

function PrivateRoute({ component: Component, ...rest }) {
  const history = useHistory();
  const location = useLocation();

  const goToLogin = () => {
    localStorage.setItem("prevPath", location.pathname);
    history.push("/login");
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? <Component {...props} /> : <>{goToLogin()}</>
      }
    />
  );
}

export default PrivateRoute;
