import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Picker } from "emoji-mart";
import moment from "moment";
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";
import useAppStateContext from "views/Chat/state";
import { useSocket } from "views/Chat/hooks";
import { getNodeRooms } from "views/Chat/api";
import { getRedisId } from "views/Chat/api";
import { addRoom } from "views/Chat/api";
import { isAuthenticated } from "auth/helper";
import { parseRoomName } from "views/Chat/utils";
import { sendMessageToBackend } from "views/Chat/api";
import { getRooms } from "views/Chat/api";
import { useSelector } from "react-redux";
import { login } from "views/Chat/api";
import { getMessagesFromBackend } from "views/Chat/api";
import { getMessages } from "views/Chat/api";
import { populateUsersFromLoadedMessages } from "views/Chat/utils";
import whiteCross from "assets/img/Bootcamp/whiteCross.svg";
import moreOption from "assets/img/chat/more-options.svg";
import smileyIcon from "assets/img/chat/smiley-icon.svg";
import sendIcon from "assets/img/chat/send-icon-white.svg";
import chevronDown from "assets/img/chat/chevron-down.svg";
import chevronUp from "assets/img/chat/chevron-up.svg";
import compose from "assets/img/chat/compose-Messaage.svg";
import searchBLog from "assets/img/Blog/searchBLog.svg";
import sendIconGrey from "assets/img/chat/send-icon.svg";
import filter from "assets/img/chat/filter.svg";
import senderchatTip from "assets/img/chat/top-vector-tip.svg";
import receiverchatTip from "assets/img/chat/reciever-vector-tip.svg";
import "emoji-mart/css/emoji-mart.css";
import "./messageOverlay.css";
import { searchUserFromBackend } from "views/Chat/api";
import axios from "axios";
import { BootcampBackend } from "backend";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

const MessageOverlay = ({ showMessagelist, setShowMessagelist }) => {
  const currentUser = useSelector((store) => store.user.user);
  const [user, setUser] = useState(null);
  const [isEmojiPicker, setIsEmojiPicker] = useState("");
  const [currentRoom, setCurrentRoom] = React.useState("");
  const [state, dispatch] = useAppStateContext();
  const [conversations, setConversations] = useState([]);
  const [width, setWindowWidth] = useState(0);
  const [loadmoremessage, setLoadmoremessage] = useState("");
  const [searchUser, setSearchUser] = useState("");
  const [searchUserList, setSearchUserList] = useState([]);
  const [newUser, setNewUser] = useState([]);
  const [socket, connected] = useSocket(user, dispatch);
  const messageListElement = useRef(null);
  const inputElementRef = useRef({});
  const room = state.rooms[state.currentRoom];
  const roomId = room?.id;
  const messages = room?.messages;
  const wrapperRef = useRef(null);
  const navigate = useHistory();
  useOutsideAlerter(wrapperRef);
  const location = useLocation();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsEmojiPicker("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const addEmoji = (e, conversationData) => {
    setConversations(
      conversations.filter((x) => {
        if (x.id === conversationData.id) {
          x.sendmessage =
            x.sendmessage === undefined ? e.native : x.sendmessage + e.native;
        }
        return conversations;
      })
    );
    setIsEmojiPicker("");
    const inputElement = inputElementRef?.current[conversationData.id];
    inputElement.focus();
  };

  const sanitizeConf = {
    allowedTags: ["b", "i", "em", "strong", "a", "p", "h1"],
    allowedAttributes: { a: ["href"] },
  };

  const sanitize = (conversationData) => {
    setConversations(
      conversations.filter((x) => {
        if (x.id === conversationData.id) {
          x.sendmessage = sanitizeHtml(x.sendmessage, sanitizeConf);
        }
        return conversations;
      })
    );
  };

  const onOpenEmoji = (conversationData) => {
    setIsEmojiPicker(conversationData?.id);
  };

  const handleText = (e, conversationData) => {
    setConversations(
      conversations.filter((x) => {
        if (x.id === conversationData.id) {
          x.sendmessage = e.target.value;
        }
        return conversations;
      })
    );
  };

  const handlePaste = (e, conversationData) => {
    e.preventDefault();
    var text = e.clipboardData.getData("text/plain");
    setConversations(
      conversations.filter((x) => {
        if (x.id === conversationData.id) {
          x.sendmessage = text;
        }
        return conversations;
      })
    );
  };

  const scrollToTop = useCallback(() => {
    setTimeout(() => {
      if (messageListElement.current) {
        messageListElement.current.scrollTop = 0;
      }
    }, 0);
  }, []);

  const scrollToBottom = useCallback(() => {
    if (messageListElement.current) {
      messageListElement.current.scrollTo({
        top: messageListElement.current.scrollHeight,
      });
    }
  }, []);

  const onMessageSend = useCallback(
    (message, roomId, room) => {
      if (
        typeof message !== "string" ||
        message.trim().length === 0 ||
        !room.chatUser
      ) {
        return;
      }
      if (!socket) {
        /** Normally there shouldn't be such case. */
        console.error("Couldn't send message");
      }
      sendMessageToBackend({
        from: user.userid,
        fromUserType: "user",
        to: room?.chatUser?._id,
        toUserType: "user",
        conversationBody: message,
        conversationType: "text",
        createdAt: new Date(),
      }).then((res) => {
        // newUser?.length > 0 ? socket.emit("new_user",newUser) :
        socket.emit("message", {
          roomId: roomId,
          message,
          from: user.id,
          date: moment(new Date()).unix(),
          userDetails: user,
          currentroom: currentRoom,
          newMessages: ++room.lastMessage.newMessages,
          chatUser: room?.chatUser,
        });
        setLoadmoremessage("");
        setConversations((prev) =>
          prev.filter((x) => {
            if (x.id === roomId) {
              delete x.sendmessage;
            }
            return prev;
          })
        );
        setIsEmojiPicker("");
        setNewUser([]);
        const inputElement = inputElementRef?.current[roomId];
        inputElement.focus();
      });
    },
    [user, socket, newUser]
  );

  const handleKeyDown = (event, conversationData) => {
    // if (event.key === "Enter") {
    //   onMessageSend(
    //     conversationData?.sendmessage,
    //     conversationData.id,
    //     conversationData
    //   );
    // }
    if (event.key === "Enter") {
      document.execCommand("insertLineBreak");
      event.preventDefault();
    }
  };

  const processedRooms = useMemo(() => {
    const roomsList = Object.values(state?.rooms);
    const main = roomsList.filter((x) => x.id === "0");
    let other = roomsList.filter((x) => x.id !== "0");
    if (other.length > 0) {
      const machinehackIndex = other?.findIndex(
        (item) => item?.chatUser?.Email === "support@machinehack.com"
      );
      const sliceMachinehack = other?.splice(machinehackIndex, 1);
      other?.unshift(sliceMachinehack[0]);
    }
    // other = other.sort(
    //   (a, b) => +a.id?.split(":").pop() - +b.id?.split(":").pop()
    // );
    return [...other];
  }, [state.rooms]);

  const onFetchMessages = useCallback(
    (offset = 0, prepend = false, conversations = conversations) => {
      getMessagesFromBackend(
        "user",
        "user",
        room?.chatUser?._id,
        user?.userid,
        offset
      ).then(async (messages) => {
        messages.forEach((element) => {
          (element.roomId = roomId),
            (element.message = element.conversationBody),
            (element.from =
              element.from._id === isAuthenticated()?.user?._id
                ? user.id
                : room.recieverId),
            (element.date = moment(new Date(element.createdAt)).unix());
        });
        dispatch({
          type: prepend ? "prepend messages" : "set messages",
          payload: { id: roomId, messages: messages },
        });
        prepend ? setLoadmoremessage(roomId) : setLoadmoremessage("");
        room.isMinimize = false;
        room.messages = messages;
        !prepend && addToConversations();
        if (messages.length === 0) {
          getMessages(roomId, offset).then(async (messages) => {
            /** We've got messages but it's possible we might not have the cached user entires which correspond to those messages */
            await populateUsersFromLoadedMessages(
              state.users,
              dispatch,
              messages
            );

            dispatch({
              type: prepend ? "prepend messages" : "set messages",
              payload: { id: roomId, messages: messages },
            });
            room.isMinimize = false;
            room.messages = messages;
            !prepend && addToConversations();
            if (prepend) {
              // setTimeout(() => {
              //   scrollToTop();
              // }, 10);
            } else {
              //   scrollToBottom();
            }
          });
        }
      });
    },
    [dispatch, roomId, state.users]
  );

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);
  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };
  const responsive = {
    imac: width >= 2560,
    medium: width >= 1600,
    large: width >= 1920,
    small: width >= 1440,
  };

  const handleScroll = (e, convo) => {
    if (e.currentTarget.scrollTop === 0) {
      setCurrentRoom(convo.id);
      setLoadmoremessage(convo.id);

      onFetchMessages(convo.offset, true, conversations);
    }
  };

  const addToConversations = () => {
    const limit = responsive.imac ? 7 : 4;
    if (conversations?.some((item) => item.id === "new")) {
      const index = conversations.findIndex((convo) => convo.id === "new");
      conversations[index] = room;
      setConversations(conversations);
      return;
    }
    const conversationData =
      conversations.length === 0
        ? [...conversations, room]
        : conversations?.some((item) => room.id === item.id)
        ? conversations
        : [...conversations, room];
    setConversations(
      conversations?.some((item) => room.id === item.id)
        ? conversations
        : [...conversations, room]
    );
    if (limit === conversationData.length) {
      setConversations(
        conversationData.filter((x) => {
          if (room.id === x.id) {
            x.isMinimize = false;
          } else {
            x.isMinimize = true;
          }
          return conversationData;
        })
      );
    } else if (limit < conversationData.length) {
      conversationData.filter((x) => {
        if (room.id === x.id) {
          x.isMinimize = false;
        } else {
          x.isMinimize = true;
        }
      });
      !conversations?.some((item) => room.id === item.id) &&
        conversationData.shift();
      setConversations(conversationData);
    }
    // focusInput();
  };

  useEffect(() => {
    if (roomId === undefined) {
      return;
    }
    if (parseInt(state.currentRoom)) {
      !conversations.some((item) => room?.id === item?.id) && onFetchMessages();
    }
  }, [state.currentRoom, dispatch]);

  useEffect(() => {
    if (isAuthenticated() && Object.keys(currentUser).length !== 0) {
      login(
        currentUser.Name ||
          JSON.parse(localStorage.getItem("jwt")).user.Email.split("@")[0],
        JSON.parse(localStorage.getItem("jwt")).user._id
      ).then((user) => {
        setUser(user);
        dispatch({ type: "set user", payload: { ...user, online: true } });
      });
    }
  }, [currentUser?._id]);

  /** Socket joins specific rooms once they are added */
  useEffect(() => {
    if (user === null) {
      /** We are logged out */
      /** But it's necessary to pre-populate the main room, so the user won't wait for messages once he's logged in */
      return;
    }
    if (connected) {
      /**
       * The socket needs to be joined to the newly added rooms
       * on an active connection.
       */
      const newRooms = [];
      Object.keys(state.rooms).forEach((roomId) => {
        const room = state.rooms[roomId];
        if (room.connected) {
          return;
        }
        newRooms.push({ ...room, connected: true });
        socket.emit("room.join", room.id);
      });
      if (newRooms.length !== 0) {
        dispatch({ type: "set rooms", payload: newRooms });
      }
    } else {
      /**
       * It's necessary to set disconnected flags on rooms
       * once the client is not connected
       */
      const newRooms = [];
      Object.keys(state.rooms).forEach((roomId) => {
        const room = state.rooms[roomId];
        if (!room.connected) {
          return;
        }
        newRooms.push({ ...room, connected: false });
      });
      /** Only update the state if it's only necessary */
      if (newRooms.length !== 0) {
        dispatch({ type: "set rooms", payload: newRooms });
      }
    }
  }, [user, connected, dispatch, socket, state.rooms, currentRoom]);

  /** Populate default rooms when user is not null */
  useEffect(() => {
    /** @ts-ignore */
    if (Object.values(state.rooms).length === 0 && user !== null) {
      /** First of all fetch online users. */
      // getOnlineUsers().then((users) => {
      //   dispatch({
      //     type: "append users",
      //     payload: users,
      //   });
      // });
      /** Then get rooms from Node API. */
      getNodeRooms(JSON.parse(localStorage.getItem("jwt")).user._id).then(
        (rooms) => {
          rooms?.result?.data?.chats?.filter((x, index) => {
            if (x.chatUser) {
              getRedisId(
                x.chatUser?.Name || x.chatUser?.Email?.split("@")[0],
                x?.chatUser?._id
              )
                .then((y) => {
                  addRoom(y.id, user.id).then((res) => {
                    const payload = [];
                    x.id = user.id.toString() + ":" + y.id.toString();
                    x.name = x.chatUser.Name;
                    payload.push({
                      id: res.room_id,
                      name: x.chatUser.Name,
                      chatUser: x.chatUser,
                      recieverId: y.id,
                      lastMessage: {
                        message: x.lastMessage,
                        roomId: res.room_id,
                        from:
                          user.id === isAuthenticated()?.user?._id
                            ? user.id
                            : y.id,
                        date: moment(
                          new Date(x.createdAt || x.updatedAt)
                        ).unix(),
                      },
                    });
                    dispatch({
                      type: "set rooms",
                      payload,
                    });
                  });
                })
                .catch((e) => console.log(e.message));
            }
          });
        }
      );
      /** Then get rooms. */
      user?.id &&
        getRooms(user.id).then((rooms) => {
          const payload = [];
          rooms.forEach(({ id, names }) => {
            payload.push({ id, name: parseRoomName(names, user.username) });
          });
          if (rooms.length === 0) {
            payload.push({ id: "0", name: ["General"] });
          }
          /** Here we also can populate the state with default chat rooms */
          dispatch({
            type: "set rooms",
            payload,
          });
          // dispatch({ type: "set current room", payload: payload[1].id });
        });
    }
  }, [dispatch, state.rooms, user]);

  useEffect(() => {
    if (
      JSON.stringify(Object.values(state.rooms)) !==
      JSON.stringify(conversations)
    ) {
      setConversations((prev) =>
        prev.filter((x) => {
          if (Object.keys(state.rooms).some((item) => x.id === item)) {
            const SocketData = state.rooms[x.id];
            // SocketData.isMinimize = false;
            Object.assign(x, SocketData);
          }
          return prev;
        })
      );
    }
  }, [state.rooms, currentRoom]);

  const searchMHUser = async () => {
    const paramData = {
      current_page: 1,
      limit: 20,
      searchTerm: searchUser,
    };
    searchUserFromBackend(paramData).then((res) => {
      setSearchUserList(res.result.data);
    });
  };

  // Search for MH User
  useEffect(() => {
    const searchuserdata = setTimeout(() => {
      if (searchUser.length > 0) {
        searchMHUser();
      }
      if (searchUser.length === 0) {
        setSearchUserList([]);
      }
    }, 1000);
    return () => clearTimeout(searchuserdata);
  }, [searchUser]);

  const onbubbleExpandCollapse = (convo) => {
    const limit = responsive.imac ? 7 : 4;
    if (limit === conversations.length) {
      conversations.shift();
    }
    setConversations(
      conversations.filter((x) => {
        if (x.id === convo.id) {
          x.isMinimize = !x.isMinimize;
        }
        // else {
        //   x.isMinimize = true;
        // }
        return conversations;
      })
    );
  };

  const messageSeen = () => {
    if (room && room?.lastMessage) {
      room.lastMessage.newMessages = 0;
      dispatch({
        type: "set rooms",
        payload: [room],
      });
    }
  };

  const composeNewmessage = (event) => {
    event.stopPropagation();
    if (conversations.some((item) => item?.newmessage)) {
      setConversations((prev) =>
        prev.filter((x) => {
          if (x.newmessage) {
            x.isMinimize = !x.isMinimize;
          }
          return prev;
        })
      );
      return;
    }
    const limit = 4;
    const conversationsData = conversations;
    if (limit === conversations.length) {
      conversationsData.shift();
      conversationsData.filter((x) => {
        x.isMinimize = true;
      });
    }
    const NewMessage = {
      id: "new",
      isMinimize: false,
      newmessage: true,
    };
    setConversations([...conversationsData, NewMessage]);
  };

  const createRoomforNewuser = (new_user) => {
    const paramData = {
      addToChatList: new_user?.user_id,
      userType: "user",
    };
    axios
      .get(
        `${BootcampBackend}/chat/getChatList/${isAuthenticated().user._id}`,
        {
          params: paramData,
        }
      )
      .then((response) => {
        getRedisId(
          new_user?.Name || new_user?.Email?.split("@")[0],
          new_user?.user_id
        )
          .then((x) => {
            addRoom(state.users[user?.id]?.id, x.id).then((roomId) => {
              const users = [];
              users.push(state.users[user?.id]?.username);
              users.push(x.username);
              const payload = [];
              payload.push({
                id: roomId.room_id,
                name: x?.username,
                chatUser: { ...new_user, _id: new_user.user_id },
                lastMessage: {
                  message: "",
                  roomId: roomId.room_id,
                  from: x.id,
                  date: moment(new Date()).unix(),
                },
                recieverId: x.id,
              });
              dispatch({
                type: "set rooms",
                payload,
              });
              dispatch({ type: "set current room", payload: roomId.room_id });
              setSearchUser("");
              setSearchUserList([]);
              setNewUser(payload);
              // setConversations(prev => prev.filter(x=>{
              //   if(x.id === "new"){
              //     return x.id !== "new"
              //   }
              //   return prev
              // }))
            });
          })
          .catch((e) => console.log(e.message));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const expandChatList = () => {
    setShowMessagelist(!showMessagelist);
    if (
      !showMessagelist &&
      !processedRooms.some(
        (item) => item?.chatUser?.Email === "support@machinehack.com"
      )
    ) {
      const new_user = {
        user_id: "625d02b0e0a57433d10fb696",
        Name: "MachineHack Support",
        Email: "support@machinehack.com",
        Picture: "https://cdn.machinehack.com/default/M.gif",
      };
      const paramData = {
        addToChatList: new_user?.user_id,
        userType: "user",
      };
      axios
        .get(
          `${BootcampBackend}/chat/getChatList/${isAuthenticated().user._id}`,
          {
            params: paramData,
          }
        )
        .then((response) => {
          getRedisId(
            new_user?.Name || new_user?.Email?.split("@")[0],
            new_user?.user_id
          )
            .then((x) => {
              addRoom(state.users[user?.id]?.id, x.id).then((roomId) => {
                const users = [];
                users.push(state.users[user?.id]?.username);
                users.push(x.username);
                const payload = [];
                payload.push({
                  id: roomId.room_id,
                  name: x?.username,
                  chatUser: { ...new_user, _id: new_user.user_id },
                  lastMessage: {
                    message: "",
                    roomId: roomId.room_id,
                    from: x.id,
                    date: moment(new Date()).unix(),
                  },
                  recieverId: x.id,
                });
                dispatch({
                  type: "set rooms",
                  payload,
                });
              });
            })
            .catch((e) => console.log(e.message));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const DonotShowChatIn = ["/practices/", "/enterprise/assessment/", "/datalyze"];
  const showChatfunc = () => {
    let showChat = true;
    DonotShowChatIn.forEach((x) => {
      showChat =
        showChat &&
        (x === "/" ? location.pathname !== x : !location.pathname.includes(x));
    });
    return showChat;
  };

  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <div
          className="site-wide-chat-container"
          style={{ display: `${showChatfunc() ? "" : "none"}` }}
        >
          <div
            className={`site-wide-chat-list-cntr ${
              showMessagelist ? "" : "site-wide-chat-list-cntr-minimize"
            }`}
          >
            <div className={`site-wide-chat-header`} onClick={expandChatList}>
              <span className="heading">
                Messaging <span className="beta-badge">Beta</span>
              </span>
              <div className="heading-controls">
                <img
                  src={compose}
                  className="img-fluid compose"
                  alt="compose-message"
                  onClick={composeNewmessage}
                />
                <img
                  src={showMessagelist ? chevronDown : chevronUp}
                  className="img-fluid chevron"
                  alt="chevron"
                />
              </div>
            </div>
            {/* <div className="search-messages">
      <div className="search-messages-input-hld">
        <div className="">
          <img
            src={searchBLog}
            className="img-fluid"
            alt="search"
            width="15"
            height="15"
          />
          <input
            type="text"
            className="form-control"
            placeholder="Search"
          />
        </div>
        <img src={filter} className="img-fluid" alt="filter" />
      </div>
    </div> */}
            <div className="site-wide-chat-list-hld">
              {processedRooms?.map((chat) => (
                <div
                  className="message-card cursor-pointer"
                  onClick={() => {
                    conversations.some((item) => chat.id === item?.id) &&
                      setConversations((prev) =>
                        prev.filter((x) => {
                          if (x.id === chat.id) {
                            x.isMinimize = !x.isMinimize;
                          }
                          return prev;
                        })
                      );
                    dispatch({ type: "set current room", payload: chat.id });
                  }}
                  key={chat.id}
                >
                  <div className="image-hld">
                    <img
                      src={chat?.chatUser?.Picture}
                      className="img-fluid"
                      alt="pic"
                    />
                  </div>
                  <div className="user-details-card">
                    <div className="user-details-card-hld">
                      <div className="user-new-message-card">
                        <h3 className="m-0 chatusername">
                          {chat?.chatUser?.Name}
                        </h3>
                        {chat?.lastMessage?.from !== user?.id &&
                          chat?.lastMessage?.newMessages > 0 && (
                            <span className="newmessagecount">New</span>
                          )}
                      </div>
                      <p className="chatlastmessagetime">
                        {moment.unix(chat?.lastMessage?.date).format("LT")}
                      </p>
                    </div>
                    <div className="newMessageHld">
                      <p
                        className="lastmessage"
                        dangerouslySetInnerHTML={{
                          __html: chat?.lastMessage?.message,
                        }}
                      ></p>
                      {/* {chat?.lastMessage?.from !== user.id &&
                chat?.lastMessage?.newMessages > 0 && (
                  <span className="newmessagecount">
                    {chat?.lastMessage?.newMessages}
                  </span>
                )} */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {conversations?.map((convo, index) => (
            <React.Fragment key={convo?.id || index}>
              {
                <div
                  className={`site-wide-conversation-overlay ${
                    convo?.isMinimize
                      ? "site-wide-conversation-overlay-minimize"
                      : ""
                  }`}
                  key={convo?.id}
                >
                  <div
                    className={`site-wide-conversation-overlay-header ${
                      convo?.lastMessage?.from !== user?.id &&
                      convo?.lastMessage?.newMessages > 0
                        ? "site-wide-conversation-overlay-highlight-header"
                        : ""
                    }`}
                    onClick={() => onbubbleExpandCollapse(convo)}
                  >
                    <div className="site-wide-conversation-overlay-header-hld">
                      {!convo?.newmessage && (
                        <img
                          src={convo?.chatUser?.Picture}
                          className="img-fluid conversation-user-image"
                          alt="userImage"
                          onClick={() =>
                            navigate.push(
                              `/user/${convo?.chatUser?._id}`
                            )
                          }
                        />
                      )}
                      <p className="conversation-user-name">
                        {convo?.newmessage
                          ? "New Message"
                          : convo?.chatUser?.Name || convo?.name}
                      </p>
                    </div>

                    <div className="">
                      {/* <img
                src={moreOption}
                className="img-fluid cursor-pointer more-option"
                alt=""
              /> */}
                      <img
                        src={whiteCross}
                        className="img-fluid cursor-pointer close-icon"
                        alt=""
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch({ type: "set current room", payload: "" });
                          setConversations(
                            conversations.filter((x) => {
                              if (x?.id === convo?.id) {
                                return x?.id !== convo?.id;
                              }
                              return conversations;
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="converzation-messages-input-hld">
                    <div className="conversations-hld">
                      {convo?.newmessage && (
                        <div className="conversations-search-user-hld">
                          <img
                            src={searchBLog}
                            alt="search"
                            className="img-fluid searchUserImg"
                          />
                          <input
                            type="text"
                            value={searchUser}
                            onChange={(e) => setSearchUser(e.target.value)}
                            placeholder="Search for MH user"
                            className="searchUser"
                          />
                          <div className="searuserlist">
                            <div className="searuserlisthld">
                              {searchUserList.map((user) => (
                                <div
                                  className="searchusercard"
                                  key={user.user_id}
                                  onClick={() => createRoomforNewuser(user)}
                                >
                                  <div className="searchImgHld">
                                    <img
                                      src={
                                        user?.Picture ||
                                        "https://s3.ap-south-1.amazonaws.com/cdn.machinehack.dev/default/S.gif?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA4OZIV247L3SL57HI%2F20221118%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20221118T055846Z&X-Amz-Expires=600&X-Amz-Signature=a779602ae6973f20964517f69acb849276d6dd4a6e01b09b99f92095a90b7523&X-Amz-SignedHeaders=host"
                                      }
                                      alt="user"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="">
                                    <p className="searchusername">
                                      {user?.Name}
                                    </p>
                                    <div className="searchUserdetails">
                                      <p className="searchusername">
                                        {user?.LatestDesignation}
                                      </p>
                                      {user?.Organization && (
                                        <p> @ {user?.Organization}</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="conversations-hld-scrollabel"
                        onScroll={(e) => handleScroll(e, convo)}
                      >
                        {convo?.messages?.map((message, x) => {
                          const key =
                            message.message + message.date + message.from + x;
                          if (+message.from !== +user.id) {
                            return (
                              <div className="receiverHld" key={key}>
                                {message.message !== "chat is established" && (
                                  <>
                                    <img
                                      src={convo?.chatUser?.Picture}
                                      alt=""
                                      className="img-fluid userImg"
                                    />
                                    <div className="text-left mb-1 receiver">
                                      {/* <h3>{convo?.chatUser?.Name || convo?.name}</h3> */}
                                      {message.message !==
                                        "chat is established" && (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: message.message,
                                          }}
                                        ></span>
                                      )}
                                      {/* <img
                            src={receiverchatTip}
                            alt="tip"
                            className="img-fluid chat-tip"
                          /> */}
                                      <span className="time">
                                        {moment.unix(message.date).format("LT")}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                          return (
                            <div className="senderHld" key={key}>
                              {message.message !== "chat is established" && (
                                <div className="text-left mb-1  sender">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: message.message,
                                    }}
                                  ></span>
                                  <span className="time">
                                    {moment.unix(message.date).format("LT")}
                                  </span>
                                </div>
                              )}
                            </div>
                          );
                        })}
                        {loadmoremessage !== convo.id && (
                          <AlwaysScrollToBottom
                            processedRooms={processedRooms}
                            currentRoom={state.currentRoom}
                            messageSeen={messageSeen}
                          />
                        )}
                      </div>
                    </div>
                    <div className="send-messagehld">
                      <div className="textareahld">
                        <ContentEditable
                          className={`textarea ${
                            convo.sendmessage ? "" : "placeholder"
                          }`}
                          html={convo.sendmessage || ""}
                          onChange={(e) => handleText(e, convo)}
                          onClick={() => setCurrentRoom(convo.id)}
                          innerRef={(element) =>
                            inputElementRef?.current &&
                            (inputElementRef.current[convo?.id] = element)
                          }
                          onBlur={() => sanitize(convo)}
                          onPaste={(e) => handlePaste(e, convo)}
                          onKeyDown={handleKeyDown}
                        />
                      </div>
                      <div className="send-message">
                        <span
                          className="smilehld"
                          onClick={() => onOpenEmoji(convo)}
                        >
                          <img
                            src={smileyIcon}
                            alt="smiley"
                            className="img-fluid"
                            style={{ width: "20px" }}
                          />
                        </span>

                        {/* <input
                  className={`textarea ${
                    convo.sendmessage ? "" : "placeholder"
                  }`}
                  placeholder="Start typing..."
                  html={convo.sendmessage || ""}
                  onChange={(e) => handleText(e, convo)}
                  onClick={() => setCurrentRoom(convo.id)}
                  ref={(element) =>
                    inputElementRef?.current &&
                    (inputElementRef.current[convo?.id] = element)
                  }
                  onBlur={() => sanitize(convo)}
                /> */}
                        <p className="sendmessageinfo">Please click to send</p>
                        {isEmojiPicker === convo?.id && (
                          <div className="emoji-picker" ref={wrapperRef}>
                            <Picker
                              onSelect={(e) => addEmoji(e, convo)}
                              showSkinTones={false}
                              showPreview={false}
                            />
                          </div>
                        )}
                        <div
                          className={`sendIcon-hld cursor-pointer ${
                            convo?.sendmessage === undefined ||
                            convo?.sendmessage === "" ||
                            convo.newmessage
                              ? ""
                              : "bg-color"
                          }`}
                          onClick={() =>
                            onMessageSend(convo?.sendmessage, convo.id, convo)
                          }
                        >
                          <img
                            src={
                              convo?.sendmessage === undefined ||
                              convo?.sendmessage === "" ||
                              convo.newmessage
                                ? sendIconGrey
                                : sendIcon
                            }
                            alt="smiley"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

const AlwaysScrollToBottom = ({ messageSeen }) => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  useEffect(() => {
    messageSeen();
  }, []);
  return <div ref={elementRef} />;
};
export default MessageOverlay;
