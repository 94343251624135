import { combineReducers, createStore } from "redux";
import authReducer from "./reducers/authReducer";
import userReducer from "./reducers/userReducer";
import hackathonsReducer from "./reducers/hackathonsReducer";
import globalRankingReducer from "./reducers/globalRankingReducer";
import practicesReducer from "./reducers/practicesReducer";
import bootcampReducer from './reducers/bootcampReducer';
import discussionReducer from "./reducers/discussionReducer";
import eventReducer from "./reducers/eventReducer";
import subscriptionReducer from "./reducers/subscriptionReducer";
import displayReducer from "./reducers/displayReducer";

let reducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  hackathons: hackathonsReducer,
  globalRankings: globalRankingReducer,
  practices: practicesReducer,
  bootcamps: bootcampReducer,
  discussion: discussionReducer,
  events: eventReducer,
  subscription: subscriptionReducer,
  display: displayReducer
});

let store = createStore(
  reducer
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
  //   console.log("dispatched: ", store.getState());
});

function stateMapper(state) {
  return state;
}

export { store, stateMapper };
