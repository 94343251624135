import React, { useEffect } from "react";
import success from "assets/img/Profile_new/toast-success.svg";
import cancel from "assets/img/Profile_new/toast-close.svg";
import closeiconDark from "assets/img/Profile_new/white-cross.svg";
import DarkTickicon from "assets/img/Profile_new/toast-dark-tick.svg";
import WarningIconWhite from "assets/img/circled-warning-white.svg";
import WarningIconRed from "assets/img/circled-warning-red.svg";
import CircledTick from "assets/img/circled-tick-white.svg";

export default function ToastMessages(props) {
  const { toastMessage, setToastMessage, isDarkMode } = props;
  useEffect(() => {
    toastMessage.duration !== 0 &&
    setTimeout(() => {
      setToastMessage({ variant: "",show: false, message: "", duration: 0 });
    }, toastMessage.duration);
  }, []);
  return (
    <div className={`col-6 ToastMessageCntr position-fixed ${isDarkMode ? "DarkToastMessageCntr" : " "}`}>
      <div className="ToastMessageHld m-1">
        <div>
          <img
            src={toastMessage?.variant === "success" ? isDarkMode ? CircledTick : success : isDarkMode ? WarningIconWhite : WarningIconRed}
            className="img-fluid"
            loading="lazy"
            alt="success"
          />
          <span className="ml-3 ToastMessageText">
            {toastMessage?.message}
          </span>
        </div>
        <div
          onClick={() =>
            setToastMessage({ show: false, message: "", duration: 0 })
          }
        >
          <img
            className="mr-1 img-fluid"
            src={isDarkMode ? closeiconDark : cancel}
            loading="lazy"
            alt="close"
          />
        </div>
      </div>
    </div>
  );
}
