import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { authenticate, isAuthenticated } from "auth/helper";
import { Redirect, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function GoogleLogin(props) {
  const [didRedirect, setDidRedirect] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    var user = {
      Email: `${props.match.params.email}`,
      _id: props.match.params.user_id,
    };

    var tokenReg = {
      message: `Logged in as ${props.match.params.email}`,
      token: props.match.params.token,
      refresh_token: props.match.params.token,
      user: {
        Email: `${props.match.params.email}`,
        _id: props.match.params.user_id,
      },
    };
    authenticate(tokenReg, () => {
      dispatch({
        type: "SET_CURRENT_USER",
        payload: user,
        token: tokenReg,
      });
      setDidRedirect(true);
    });
  }, []);

  const performRedirect = () => {
    if (isAuthenticated() && didRedirect) {
      var previousPath = localStorage.getItem("prevPath");
      var InterviewPath = localStorage.getItem("interviewToken");
      if (InterviewPath && InterviewPath !== "undefined") {
        if (!isAuthenticated()) {
          localStorage.setItem("prevPath", `/interview-lobby/${InterviewPath}`);
          history.push("/login");
        } else {
          <Redirect to={`/interview-lobby/${InterviewPath}`} />;
        }
      }
      if (
        previousPath === null ||
        previousPath === "/login-page" ||
        previousPath === "/login" ||
        previousPath.startsWith("/register") ||
        previousPath.startsWith("/update/password")
      ) {
        
          return <Redirect to="/" />;
        
      } 
      else {
        window.location.replace(previousPath);
      }
    }
  };

  return (
    <div>
      <p style={{ color: "#e9505c" }}>redirecting ...</p>
      {performRedirect()}
    </div>
  );
}
