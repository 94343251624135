import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import likeIcon from "assets/img/like-icon.svg";
import likedIcon from "assets/img/likedicon.svg";
import viewIcon from "assets/img/view-icon.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import hoverImage from "assets/img/hoverViewImage.svg";
import "./DashboardList.css";
import { API } from "backend";
import axios from "axios";
import { LoadingShape } from "react-rainbow-components";
import { isAuthenticated } from "auth/helper";
import Pagination from "@mui/lab/Pagination";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import CircularProgress from "@mui/material/CircularProgress";
import LoginPageNew from "views/LoginPage/LoginPageNew";
import darkView from "assets/img/dark-view.svg";
import DarkInActiveLike from "assets/img/Feed/thumc_article.svg";
import DarkActiveLike from "assets/img/white-filled-thumb.svg";
import { toTitleCase } from "shared/CommonMethods";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: "16px",
    },
  },
}));
const DashboardList = (props) => {
  const [dashboards, setDashboards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardCount, setDashboardCount] = useState("");
  const [postsPerPage, setPostsPerPage] = useState(9);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isLoadingPagination, setIsLoadingPagination] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const { isDarkMode } = props;
  const history = useHistory();
  const location = useLocation();

  const classes = useStyles();

  const getAllDashboards = (pageNumber, selectedSort) => {
    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("jwt"));
    axios
      .get(
        `${API}/submissions/visualizations/${
          props.hackathonHREFName
        }/${pageNumber}${
          selectedSort ? "?sort=" + selectedSort : "?sort=Latest"
        }${user ? "&user_id=" + user.user._id : ""}`
      )
      .then((response) => {
        setIsLoading(false);
        setIsLoadingPagination(false);
        setSelectedPage(pageNumber);
        setDashboardCount(response.data.all_dashboards);
        setDashboards(response.data.message);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const likeDashboard = (data) => {
    if (isAuthenticated()) {
      const user = JSON.parse(localStorage.getItem("jwt"));
      axios
        .put(
          `${API}/submissions/visualization/vote/${props.hackathonHREFName}/${data.user_info._id}?vote=1&votedby=${user.user._id}`
        )
        .then((response) => {
          setDashboards(
            dashboards.filter((x) => {
              if (x === data) {
                x.user_info.user_vote = !data.user_info.user_vote;
                x.user_info.user_vote
                  ? x.user_info.Votes++
                  : x.user_info.Votes--;
              }
              return dashboards;
            })
          );
        })
        .catch((err) => console.log(err));
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };

  const watchDashboard = (data) => {
    axios
      .put(
        `${API}/submissions/visualization/update_watch/${props.hackathonHREFName}/${data.user_info._id}`
      )
      .then((response) => {
        history.push(
          `/hackathons/visualization/${props.hackathonHREFName}/${data.user_info._id}`
        );
      })
      .catch((err) => console.log(err));
  };

  const handleLeaderBoardChange = (e, value) => {
    setIsLoadingPagination(true);
    getAllDashboards(value, null);
  };

  const handleClose1 = () => {
    setIsLoadingPagination(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllDashboards(1, null);
  }, []);
  const arr = [1, 2, 3];
  return (
    <>
      <div
        className={`mb-5 hackathon-dashboard ${
          isDarkMode ? "darkMode" : "rules lightMode"
        }`}
      >
        <div className="d-flex justify-content-between align-items-center heading-hld">
          <h1 className={`dashboard-heading ${isDarkMode ? "whiteText" : ""}`}>
            All Dashboards ({dashboardCount})
          </h1>
          <FormControl
            style={{ minWidth: "150px" }}
            className={`${isDarkMode ? "dropDownDark" : ""}`}
          >
            <Select
              labelId="sort-upload-date"
              defaultValue={"Latest"}
              onChange={(event) => getAllDashboards(1, event.target.value)}
            >
              <MenuItem value={"Latest"}>Latest</MenuItem>
              <MenuItem value={"Oldest"}>Oldest</MenuItem>
              <MenuItem value={"Least Liked"}>Least Liked</MenuItem>
              <MenuItem value={"Most Liked"}>Most Liked</MenuItem>
              <MenuItem value={"Most Viewed"}>Most Viewed</MenuItem>
              <MenuItem value={"Least Viewed"}>Least Viewed</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="row" style={{ padding: "40px" }}>
          {isLoading && (
            <>
              {arr.map((item) => (
                <div className="col-lg-4">
                  <LoadingShape shape="square" variant="image" />
                </div>
              ))}
            </>
          )}
          {!isLoading && (
            <>
              {dashboards.map((item) => (
                <div className="col-lg-4" key={item.user_info.User_Oid}>
                  <div className="dashboard-list-hld mb-4">
                    <div className="dashboardImagehld">
                      <object
                        data={item.url}
                        type="application/pdf"
                        width="100%"
                        height="100%"
                        className="dashboardImage"
                      >
                        <p>
                          <a href={item.url}></a>
                        </p>
                      </object>
                      <div className="overlayImage">
                        <img
                          src={hoverImage}
                          alt="overlay"
                          className="img-fluid hoverIframImage"
                          onClick={() => watchDashboard(item)}
                          loading="lazy"
                        />
                      </div>
                      <div className="userImageHld">
                        <img
                          src={item.user_info.user_picture}
                          alt="user-picture"
                          className="img-fluid userImage"
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="visual-hack-details px-3">
                      <div className="">
                        <Link
                          to={`/user/${
                            item.user_info && item.user_info.User_Oid
                          }`}
                          style={{ textDecoration: "none" }}
                        >
                          <p
                            className={`userName my-1 ${
                              isDarkMode ? "whiteText" : ""
                            }`}
                          >
                            {toTitleCase(item.user_info?.name)}
                          </p>
                        </Link>
                      </div>
                      <div className="likeNViews d-flex align-items-center">
                        <div className={`likeshld ${item.user_info.user_vote ? "active-likeshld" : ""}`}>
                          <img
                            src={
                              item.user_info.user_vote
                                ? isDarkMode
                                  ? DarkActiveLike
                                  : likedIcon
                                : isDarkMode
                                ? DarkInActiveLike
                                : likeIcon
                            }
                            alt="like"
                            className={`img-fluid like-icon ${
                              !item.user_info.user_vote
                                ? isDarkMode
                                  ? "like-width-14"
                                  : ""
                                : ""
                            }`}
                            onClick={() => likeDashboard(item)}
                            loading="lazy"
                          />
                          <span className={`${item.user_info.user_vote ? isDarkMode ? "whiteText" : "" : isDarkMode ? "darkText" : ""}`}>
                            {item.user_info.Votes}
                          </span>
                        </div>
                        <img
                          src={isDarkMode ? darkView : viewIcon}
                          className="img-fluid"
                          alt="view"
                          loading="lazy"
                        />
                        <span className={`ml-2 ${isDarkMode ? "darkText" : ""}`}>
                          {item.user_info.watch}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div
          className={`row justify-content-center pb-4 ${
            isDarkMode ? "hackathon-dashboard" : "lightModePagination"
          }`}
        >
          <Pagination
            count={
              dashboardCount % postsPerPage === 0
                ? parseInt(dashboardCount / postsPerPage)
                : parseInt(dashboardCount / postsPerPage) + 1
            }
            defaultPage={1}
            page={Number(selectedPage)}
            variant="outlined"
            shape="rounded"
            className={classes.root}
            onChange={handleLeaderBoardChange}
            showFirstButton
            showLastButton
          />
        </div>
      </div>

      <Dialog
        open={isLoadingPagination}
        onClose={handleClose1}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableBackdropClick="true"
      >
        <DialogContent className="submission-dialog">
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <CircularProgress color="secondary" style={{ marginTop: "20px" }} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default DashboardList;
