import { isAuthenticated } from "auth/helper";
import axios from "axios";
import { BootcampBackend, LMS_URL } from "../../backend";

function GetAuthorizationConfig() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : null,
    },
  };
}

export const userDetailsService = {
  getAllInfo: async (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${LMS_URL}/users/userHoverInfo/${id}${
            isAuthenticated()
              ? `?logedInUser=${isAuthenticated()?.user?._id}`
              : ""
          }`
        )
        .then((response) => {
          return resolve(response.data.data);
        })
        .catch((err) => {
          console.log(err)
          // return reject(err);
        });
    });
  },
  updateSiteTheme: async(body) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${BootcampBackend}/userInfo/v1/updateUserSiteSetting`,body)
        .then((response) => {
          return resolve(response.data.result.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }
};
