import React, { useCallback, useEffect, useRef, useState } from "react";
import * as toxicity from "@tensorflow-models/toxicity";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import image_pic from "assets/img/Feed/pic.svg";
import polls from "assets/img/Feed/polls.svg";
import article from "assets/img/Feed/article.svg";
import {BootcampBackend, LMS_URL} from "backend";
import { isMobile } from "react-device-detect";
import axios from "axios";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmationModal from "../ConfirmationPopup/ConfirmationPopup";
import { feedService } from "services/rest/feeds.service";
import ContentEditable from "react-contenteditable";
import WhiteInto from "assets/img/white-into.svg";
import "../FeedArticleInput/FeedArticleInput.css";
import "../FeedUserArticle/FeedUserArticle.css";
import "../PollsModal/PollsModal.css";
import * as tf from "@tensorflow/tfjs";
import "@tensorflow/tfjs-backend-webgl";
import { CheckImageNSFW } from "shared/CommonMethods";
import { useHistory } from "react-router-dom";
import UserSearchList from "./UsersSearchList";
import { getInnerHTML } from "shared/CommonMethods";
tf.setBackend("webgl");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

const CreatePostPopup = ({
  open,
  setOpen,
  isDarkMode,
  setShowToast,
  feedData,
  setFeedData,
  user,
  category,
  href,
  hname,
  fileInputRef,
  media,
  setMedia,
  onSelectFile,
  selectedFeed,
  imageFileRef,
  setShowPollModal,
}) => {
  const userSubscriptionData = useSelector(
    (store) => store.subscription.subscriptionData
  );
  const [confirmPop, setConfirmPop] = useState(false);
  const [discussionContent, setDiscussionContent] = useState("");
  const [IsValid, setIsValid] = useState(false);
  const [CheckuserInputLoader, setCheckuserInputLoader] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUser, setTotalUser] = useState(0);
  const [searchUser, setSearchUser] = useState("");
  const [showUserList, setShowUserList] = useState(false);
  const contentEditableRef = useRef(null);
  const elementRef = useRef();
  const history = useHistory();
  const handleImageLoaded = async () => {
    setCheckuserInputLoader(true);
    const isImageNsfw = await CheckImageNSFW(imageFileRef, media);
    if (isImageNsfw) {
      media[media?.length - 1].nsfw = true;
      setMedia(media);
      setIsValid(false);
      setShowToast({
        show: true,
        message: "this image is NSFW",
        duration: 3000,
        variant: "warning",
      });
    } else {
      media?.some((x) => x.nsfw) ? setIsValid(false) : setIsValid(true);
    }
    setCheckuserInputLoader(false);
  };

  const getUsersToTag = async (value, pageNum) => {
    const user_input_arr = getInnerHTML(value)?.split(" ");
    const search_word = user_input_arr?.filter((x) => x.includes("@"));
    await axios
      .get(
        `${BootcampBackend}/userInfo/v1/userInfoSearch/${
          search_word[search_word?.length - 1]?.replace("@", "")?.replaceAll("&nbsp;","") || "a"
        }?limit=30&current_page=${pageNum}`
      )
      .then((searchRes) => {
        setSearchUser(search_word[search_word?.length - 1]);
        setShowUserList(true);
        setUsersList(searchRes?.data?.result?.data);
        setTotalUser(searchRes?.data?.result?.totalCount);
        setCurrentPage(pageNum);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const optimizedFn = useCallback(debounce(getUsersToTag), []);

  const onhandlePost = (e) => {
    // if (e.target.value.includes("@")) {
    //   optimizedFn(e.target.value, 1);
    // }
    setDiscussionContent(e.target.value);
    setIsValid(false);
  };

  const handleButtonClick = () => {
    media?.length < 5 && fileInputRef.current.click();
  };
  const checkuserContent = () => {
    setCheckuserInputLoader(true);
    const threshold = 0.9;
    toxicity.load(threshold).then((model) => {
      model.classify(discussionContent?.trim()).then((predictions) => {
        setCheckuserInputLoader(false);
        var isConetentvalid = true;
        predictions?.forEach((element) => {
          if (element?.results[0]?.match) {
            isConetentvalid = false;
            setShowToast({
              variant: "warning",
              show: true,
              message: "Stay professional. Mind your language use.",
              duration: 8000,
            });
          }
        });
        isConetentvalid && media?.some((x) => x.nsfw) === false
          ? setIsValid(true)
          : setIsValid(false);
      });
    });
  };
  const createPost = async (requestBody) => {
    await axios
      .post(`${LMS_URL}/v1/feed/createDiscussion`, requestBody)
      .then((response) => {
        const userDetails = {
          Name: user?.Name,
          Picture: user?.Picture,
          UserName: user?.UserName,
          userId: user?._id,
        };
    
        const createPostRes = response?.data?.data;
        // createPostRes.user = userDetails;
        // createPostRes.userSubscribed = userSubscriptionData?._id ? true : false;
        requestBody?.postType === "media" &&
          (createPostRes.media = response?.data?.data?.media);
        setOpen(false);
        setFeedData((prev) => [createPostRes, ...prev]);
        // setShowToast({
        //   show: true,
        //   message: "Succefully Posted",
        //   duration: 2000,
        // });
        setMedia([]);
        setDiscussionContent("");
      });
  };
  const handlePost = async () => {
    const requestBody = {
      content: discussionContent,
      media: media,
      postType: media.length > 0 ? "media" : "content",
      category: category,
      hname: hname || "",
      href: href || "",
    };
    if (media?.length > 0) {
      const formData = new FormData();
      const S3Links = [];
      for (const element of media) {
        formData.set("media", element);
        const uploadMedia = await feedService.uploadMedia(formData);
        if (uploadMedia) {
          S3Links.push(uploadMedia?.data);
        }
      }
      requestBody.media = S3Links;
    }
    createPost(requestBody);
  };
  const onEditPost = async () => {
    const requestBody = {
      content: discussionContent,
      media: media,
      postType: media.length > 0 ? "media" : "content",
    };
    if (media?.length > 0) {
      const formData = new FormData();
      const S3Links = [];
      for (const element of media) {
        if (element?.size) {
          formData.set("media", element);
          const uploadMedia = await feedService.uploadMedia(formData);
          if (uploadMedia) {
            S3Links.push(uploadMedia?.data);
          }
        } else {
          S3Links.push(element);
        }
      }
      requestBody.media = S3Links;
    }
    await axios
      .put(
        `${LMS_URL}/v1/feed/updateDiscussion/${selectedFeed?._id}`,
        requestBody
      )
      .then((response) => {
        setOpen(false);
        Object.keys(selectedFeed || {})?.length > 0 && category === "Bootcamp"
          ? setFeedData((prev) => ({
              ...prev,
              media: requestBody.media,
              content: discussionContent,
            }))
          : setFeedData((prev) =>
              prev.filter((x) => {
                if (x._id === selectedFeed?._id) {
                  x.media = requestBody.media;
                  x.content = discussionContent;
                }
                return prev;
              })
            );
        setShowToast({
          show: true,
          message: "Changes saved",
          duration: 2000,
          variant: "success",
        });
      });
  };
  const handleOpenBlogs = () => {
    if (user?._id) {
      axios
        .get(`${LMS_URL}/blogs/blogUserByUserId/${user._id}`)
        .then((response) => {
          if (response.data.data) {
            history.push(`/create-story`);
          } else {
            history.push(`/blogs/onboard`);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    }
  };
  const onTagUser = (user) => {
    // first soln
    // const selection = window.getSelection();
    // // get the start and end index of selection
    // // remember user can select backwards
    // let startIndex = selection.anchorOffset;
    // let endIndex = selection.focusOffset;
    // if (startIndex > endIndex) {
    //   startIndex = selection.focusOffset;
    //   endIndex = selection.anchorOffset;
    // }
    // // now get the text before and after the selection
    // const beforeSelection = discussionContent.substring(0, startIndex);
    // const afterSelection = discussionContent.substring(
    //   endIndex,
    //   discussionContent.length
    // );
    // const result =
    //   beforeSelection?.replace("@","") +
    //   `<a className="text-bold">${user?.Name}</a>` +
    //   afterSelection?.replace("@","");
    //5th soln
    const tag_user = `<a>${user?.Name}</a>`
    setDiscussionContent(prev => prev?.replace(searchUser,tag_user));
    contentEditableRef?.current?.focus();
    setShowUserList(false);
  };
  useEffect(() => {
    if (selectedFeed) {
      setDiscussionContent(selectedFeed?.content || "");
    }
  }, [selectedFeed]);

  useEffect(() => {
    const userContent = setTimeout(() => {
      discussionContent?.trim()?.length > 2 && checkuserContent();
    }, 200);
    return () => clearTimeout(userContent);
  }, [discussionContent]);

  useEffect(() => {
    media.length > 0 && media[media?.length - 1].size && handleImageLoaded();
  }, [media]);

  return (
    <>
      <Modal
        open={open}
        className={`create-post-popup bootstrap-iso ${
          isDarkMode
            ? isMobile
              ? "darkTheme mobiledark-ArticlePop-section-section"
              : "darkTheme dark-ArticlePop-section"
            : isMobile
            ? "mobile-ArticlePop-section-section"
            : "ArticlePop-section"
        } ${isMobile ? "col-12" : ""}`}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown
      >
        <Box sx={style}>
          <div className=" cursor-pointer d-flex justify-content-between mx-4 mt-3 align-items-center">
            <div className="mt-2 mb-3 position-relative">
              <span className={`${isDarkMode ? "whiteText" : ""} poll-header`}>
                {selectedFeed ? "Edit Post" : "Create a Post"}
              </span>
              <div
                className={`${
                  isDarkMode ? "dark-highlighter" : ""
                } addPollTextBottom mt-3`}
              ></div>
            </div>
            <CloseIcon
              className={`text--end ${isDarkMode ? "modal-cancel" : ""} mb-1`}
              onClick={() =>
                selectedFeed ? setOpen(false) : setConfirmPop(true)
              }
            />
          </div>
          <div
            className={`${
              isDarkMode
                ? "dark-article-pop-separator"
                : "article-pop-separator"
            } w-100`}
          ></div>
          <div
            className={`article-pop-text my-3 mx-4 p-3 d-flex flex-wrap position-relative`}
          >
            <ContentEditable
              innerRef={contentEditableRef}
              html={discussionContent}
              onChange={onhandlePost}
              disabled={false}
              className={`create-post-content-edit col-12 px-0 ${
                isDarkMode ? "whiteText" : ""
              } ${media?.length > 0 ? "min-height-50" : ""}`}
              placeholder="Start a AI-assistive discussion..."
              id="contentedit"
              // onPaste={handlePaste}
              // onKeyDown={handleKeyDown}
            />
            {showUserList && (
              <UserSearchList
                usersList={usersList}
                onTagUser={onTagUser}
                setShowUserList={setShowUserList}
              />
            )}
            {media?.map((image, index) => (
              <div
                className={`position-relative selectedImage ${
                  index === 0 ? "col-12 px-0" : "selectedSmallImg mr-2"
                }`}
                key={index}
              >
                <img
                  src={image?.size ? URL.createObjectURL(image) : image}
                  alt="preview"
                  loading="lazy"
                  className={`img-fluid mt-2 `}
                  ref={(element) =>
                    imageFileRef?.current &&
                    (imageFileRef.current[index] = element)
                  }
                />
                <div
                  className="uploaded-img-remove cursor-pointer"
                  onClick={() => {
                    setMedia((prev) =>
                      prev.filter((x) => {
                        return image !== x;
                      })
                    );
                    media?.some((x) => x.nsfw) && IsValid
                      ? setIsValid(false)
                      : setIsValid(true);
                  }}
                >
                  <img src={WhiteInto} alt="close" className="img-fluid" />
                </div>
              </div>
            ))}
            <div ref={elementRef} />
          </div>
          <div
            className={`${
              isMobile ? "feed-InputField-btn" : ""
            } d-flex align-items-center justify-content-between mb-3`}
          >
            {/* <div className="d-flex align-items-center justify-content-between"> */}
            <span
              onClick={() => handleButtonClick()}
              className={`${
                isDarkMode
                  ? "whiteText dark-feedArticleUserTags"
                  : "feedArticleUserTags"
              } ${
                media?.length === 5 ? "diabled-btn" : ""
              } d-flex align-items-center justify-content-center cursor-pointer article-action-btn-section ml-2 text-center py-1`}
            >
              <img
                src={image_pic}
                alt="icon"
                className="img-fluid mr-2"
                laoding="lazy"
              />{" "}
              Photo
            </span>
            <input
              type="file"
              accept="image/png, image/jpeg"
              ref={fileInputRef}
              className="d-none"
              onChange={(e) => {
                onSelectFile(e);
                media?.length > 0 && elementRef?.current?.scrollIntoView();
                setIsValid(true);
              }}
            />
            {category === "General" && (
              <>
                <span
                  className={`${
                    isDarkMode
                      ? "whiteText dark-feedArticleUserTags"
                      : "feedArticleUserTags"
                  } ${
                    isMobile ? "px-0" : ""
                  } article-action-btn-section d-flex align-items-center justify-content-center col-3 text-center py-1 cursor-pointer`}
                  onClick={() => {
                    setShowPollModal(true);
                    setOpen(false);
                  }}
                >
                  <img
                    src={polls}
                    alt="icon"
                    className="img-fluid mr-2"
                    laoding="lazy"
                  />{" "}
                  Polls
                </span>
                <span
                  className={`${
                    isDarkMode
                      ? "whiteText dark-feedArticleUserTags"
                      : "feedArticleUserTags"
                  } d-flex align-items-center justify-content-center cursor-pointer mr-3 article-action-btn-section-write text-center py-1`}
                  onClick={handleOpenBlogs}
                >
                  <img
                    src={article}
                    alt="icon"
                    className="img-fluid mr-2"
                    laoding="lazy"
                  />{" "}
                  Write an article
                </span>
              </>
            )}
            {/* </div>   */}
            <div className="text-center mr-4 py-2">
              {CheckuserInputLoader ? (
                <button
                  className={`feed-discard-btn py-1 poll-post-btn d-flex justify-content-center`}
                >
                  <CircularProgress
                    className="circular-loader"
                    style={{
                      width: "20px",
                      height: "20px",
                      color: `${isDarkMode ? "#6540d7" : "#f04f5e"}`,
                    }}
                  />
                </button>
              ) : (
                <>
                  {" "}
                  {selectedFeed ? (
                    <>
                      {(discussionContent !== "" || media?.length > 0) &&
                      IsValid ? (
                        <button
                          className={`${
                            isDarkMode ? "darkBtn" : ""
                          } poll-post-btn py-1`}
                          onClick={onEditPost}
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          className={`feed-discard-btn py-1 poll-post-btn`}
                        >
                          Save
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      {(discussionContent !== "" || media?.length > 0) &&
                      IsValid ? (
                        <button
                          className={`${
                            isDarkMode ? "darkBtn" : ""
                          } poll-post-btn py-1`}
                          onClick={handlePost}
                        >
                          Post
                        </button>
                      ) : (
                        <button
                          className={`feed-discard-btn py-1 poll-post-btn`}
                        >
                          Post
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Box>
      </Modal>
      <ConfirmationModal
        showPopup={confirmPop}
        setshowPopup={setConfirmPop}
        isDarkMode={isDarkMode}
        onSuccess={() => {
          setOpen(false);
          setConfirmPop(false);
          setDiscussionContent("");
          setMedia([]);
        }}
        title="Are you sure you want to discard the post?"
        ActionBtnName="Yes, Discard"
        closeBtnName="Keep the post"
        ModalBody="This post will be deleted immediately. You can't undo this action."
        type="discard-post"
        makeClosebtnActive={true}
      />
    </>
  );
};
export default CreatePostPopup;
