import React, { useCallback, useEffect, useState } from "react";
import "assets/landingpage/assets/css-landing/bootstrap-iso.css";
import "assets/landingpage/assets/css-landing/bootstrap-iso-vendor.css";
import "./ProfileCard.css";
import premiumTick from "assets/img/premiumTick.svg";
import profileGeoLocation from "assets/img/profileGeoLocation.svg";
import profileCardExp from "assets/img/profileCardExp.svg";
import followIcon from "assets/img/followIcon.svg";
import messageIcon from "assets/img/messageIcon.svg";
import followedIcon from "assets/img/followedIcon.svg";
import axios from "axios";
import { BootcampBackend } from "backend";
import { useHistory } from "react-router-dom";
import { userDetailsService } from "services/rest/userProfile.service";
import { isAuthenticated } from "auth/helper";
import { login } from "views/Chat/api";
import { getRedisId } from "views/Chat/api";
import { addRoom } from "views/Chat/api";
import useAppStateContext from "views/Chat/state";
import { useUser } from "views/Chat/hooks";
import { useSelector } from "react-redux";
import { toTitleCase } from "shared/CommonMethods";
import { getUserBadge } from "shared/CommonMethods";
import { API } from "backend";
import { motion } from "framer-motion/dist/framer-motion";
import LightGraph from "assets/img/Profile_new/light-graph-icon.svg";
import DarkGraph from "assets/img/Profile_new/dark-graph-icon.svg";
import { LMS_URL } from "backend";

export default function ProfileCard(props) {
  const { userId, setIsHovering } = props;
  const [userInformation, setUserInformation] = useState({});
  const [height, setHeight] = useState(0);
  const [badgeHover, setBadgeHover] = useState({});
  const user = useSelector((store) => store.user.user);
  const history = useHistory();
  const displayMode = useSelector((store) => store.display.display_mode);
  const [Badges, setBadges] = useState([
    {
      _id: "1",
      type: "Hackathon",
      tier: "",
      slug: "hackathon",
    },
    {
      _id: "2",
      type: "Assessment",
      tier: "",
      slug: "assessment",
    },
    {
      _id: "3",
      type: "Practice",
      tier: "",
      slug: "practice",
    },
    {
      _id: "4",
      type: "Blogs",
      tier: "",
      slug: "blog",
    },
  ]);
  const getAllUserInfo = async () => {
    try {
      const userInfo = await userDetailsService.getAllInfo(userId);
      if (userInfo) {
        setUserInformation(userInfo);
        setBadges((prev) =>
          prev.filter((x) => {
            switch (x.slug) {
              case "assessment":
                x.tier = userInfo?.assessmentTier || "Novice";
                break;
              case "practice":
                x.tier = userInfo?.practiceTier || "Novice";
                break;
              case "blog":
                x.tier = userInfo?.blogsTier || "Novice";
                break;
              default:
                break;
            }
            return prev;
          })
        );
        const element = document.getElementById("profileDesignation");
        setHeight(element?.getBoundingClientRect()?.height);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const updateUserFollowData = async () => {
    await axios
      .put(`${LMS_URL}/blogs/updateBlogUserFollowData/${userId}`, {
        actionType: userInformation?.followed ? "unFollow" : "follow",
      })
      .then((res) => {
        setUserInformation({
          ...userInformation,
          followed: !userInformation?.followed,
          followers: userInformation?.followed ? userInformation?.followers - 1 : userInformation?.followers + 1
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { dispatch } = useAppHandlers();
  const createRoom = () => {
    if (!isAuthenticated()) {
      return;
    }
    var reciever;
    var sender;
    const paramData = {
      addToChatList: userId,
      userType: "user",
    };
    axios
      .get(
        `${BootcampBackend}/chat/getChatList/${isAuthenticated().user._id}`,
        {
          params: paramData,
        }
      )
      .then((response) => {
        login(
          userInformation?.Name || isAuthenticated().user.Email.split("@")[0],
          isAuthenticated().user._id
        )
          .then((x) => {
            reciever = x;
            getRedisId(userInformation?.Name, userId)
              .then((x) => {
                sender = x;
                addRoom(reciever.id, sender.id).then((roomId) => {
                  const users = [];
                  users.push(reciever.username);
                  users.push(sender.username);
                  const payload = [];
                  payload.push({
                    id: roomId.room_id,
                    name: [reciever.username],
                  });
                  dispatch({
                    type: "set rooms",
                    payload,
                  });
                  history.push(`/user/${userId}`);
                });
              })
              .catch((e) => console.log(e.message));
          })
          .catch((e) => console.log(e.message));
      });
  };

  const getUserHackathonTier = async () => {
    await axios.get(`${API}/user/newprofile/${userId}`).then((response) => {
      if (response.status === 201 || response.status === 200) {
        setBadges((prev) =>
          prev.filter((x) => {
            if (x.slug === "hackathon") {
              x.tier = response.data.user?.Level;
            }
            return prev;
          })
        );
      } else {
        console.error(response.statusText);
      }
    });
  };

  const UserLocation = () => {
    return (
      <div className="d-flex align-items-center mt-2 ml-3 px-3">
        <span
          className={`profileCardLocationText text-truncate no-wrap ${
            displayMode === "dark_mode" ? "whiteText" : ""
          }`}
        >
          <img
            src={profileGeoLocation}
            alt="profileGeoLocation"
            loading="lazy"
            className="img-fluid mr-2"
          />
          {userInformation?.city ? userInformation?.city : "..."}
        </span>
        <span
          className={`ml-5 profileCardLocationText text-truncate no-wrap ${
            displayMode === "dark_mode" ? "whiteText" : ""
          }`}
        >
          <img
            src={profileCardExp}
            alt="profileCardExp"
            loading="lazy"
            className="img-fluid mr-2"
          />
          {userInformation?.Experience
            ? `${userInformation?.Experience} Years`
            : "..."}
        </span>
      </div>
    );
  };

  useEffect(() => {
    if (userId) {
      getAllUserInfo();
      getUserHackathonTier();
    }
  }, []);
  return (
    <>
      {Object.keys(userInformation).length > 0 && (
        <div
          className={`profileCard position-absolute`}
          onMouseLeave={() => setIsHovering("")}
        >
          <div className="align-items-center d-flex flex-coloumn px-3 pt-3">
            <img
              src={userInformation?.Picture}
              className="img-fluid userHoverImg"
              alt="user-img"
              loading="lazy"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/user/${userId}`);
              }}
            />
            <div>
              <div className="d-flex flex-column">
                <span
                  className={`profileUserCardName ml-3 truncate-line-clamp-profile-card-name ${
                    displayMode === "dark_mode" ? "whiteText" : ""
                  }`}
                >
                  {toTitleCase(userInformation?.Name)}
                  {userInformation.subscription === "success" && (
                    <img
                      src={premiumTick}
                      alt="premiumTick"
                      className="img-fluid ml-2"
                      loading="lazy"
                    />
                  )}
                </span>

                <span
                  className={`profileUserCardDesignation truncate-line-clamp-profile-card ml-3 ${
                    displayMode === "dark_mode" ? "whiteText" : ""
                  }`}
                  id="profileDesignation"
                >
                  {userInformation?.LatestDesignation}
                  {userInformation?.Organization && userInformation?.LatestDesignation ?
                   ` at ${userInformation?.Organization}`: userInformation?.Organization ?userInformation?.Organization:``}
                </span>
              </div>
              {height < 20 && <UserLocation />}
            </div>
          </div>
          {height > 20 && <UserLocation />}
          <hr className="profileCardDivider mx-3"></hr>
          <div className="align-items-center d-flex justify-content-between px-3">
            <div>
              <span
                className={`profileCardInfoText ${
                  displayMode === "dark_mode" ? "whiteText" : ""
                }`}
              >
                {userInformation?.followers
                  ? userInformation?.followers
                  : "..."}
              </span>
              <p
                className={`profileCardInfoTextDesc ${
                  displayMode === "dark_mode" ? "whiteText" : ""
                }`}
              >
                Followers
              </p>
            </div>
            <div>
              <span
                className={`profileCardInfoText ${
                  displayMode === "dark_mode" ? "whiteText" : ""
                }`}
              >
                {userInformation?.Articles ? userInformation?.Articles : "..."}
              </span>
              <p
                className={`profileCardInfoTextDesc ${
                  displayMode === "dark_mode" ? "whiteText" : ""
                }`}
              >
                Articles
              </p>
            </div>
            <div>
              <span
                className={`profileCardInfoText ${
                  displayMode === "dark_mode" ? "whiteText" : ""
                }`}
              >
                {userInformation?.hackathons
                  ? userInformation?.hackathons
                  : "..."}
              </span>
              <p
                className={`profileCardInfoTextDesc ${
                  displayMode === "dark_mode" ? "whiteText" : ""
                }`}
              >
                Hackathons
              </p>
            </div>
            <div>
              <span
                className={`profileCardInfoText ${
                  displayMode === "dark_mode" ? "whiteText" : ""
                }`}
              >
                ...
              </span>
              <p
                className={`profileCardInfoTextDesc ${
                  displayMode === "dark_mode" ? "whiteText" : ""
                }`}
              >
                Ranking
              </p>
            </div>
          </div>
          {isAuthenticated() && (
            <div className="d-flex px-3 pb-3">
              {userId === user?._id ? (
                <></>
              ) : (
                <button
                  className={
                    userInformation?.followed
                      ? displayMode === "dark_mode"
                        ? "darkBtn darkBorderRadius noBorder px-3 dark-cardFollowBtnFollowed"
                        : "cardFollowBtnFollowed d-flex align-items-center"
                      : "align-items-center cardFollowBtn d-flex"
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    updateUserFollowData();
                  }}
                >
                  {/* {userInformation?.followed && <img
                    src={followedIcon}
                    alt="icon"
                    loading="lazy"
                    className={`img-fluid ${
                      displayMode === "dark_mode" && userInformation?.followed
                        ? "whiteArrow"
                        : ""
                    }`}
                  />} */}
                  {userInformation?.followed ? "Following" : "Follow"}
                </button>
              )}
              {/* <button
                className={`cardMessageBtn ml-auto ${
                  displayMode === "dark_mode" ? "darkBtn" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  createRoom();
                }}
              >
                Message
                <img
                  src={messageIcon}
                  alt="icon"
                  loading="lazy"
                  className="img-fluid profileCardBtnImg"
                />
              </button> */}
            </div>
          )}
          <div className="d-flex justify-content-center align-items-center badgesHld py-4">
            {Badges?.map((item,index) => (
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{
                  duration: 0.7,
                  delay: index * 0.05,
                }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 30 },
                }}
                whileHover={{
                  scale: 1.07,
                  transition: { duration: 0.6 },
                }}
                className="position-relative"
                onMouseEnter={() => setBadgeHover(item)}
                onMouseLeave={() => setBadgeHover({})}
                key={index}
              >
                {item?.tier && (
                  <img
                    src={getUserBadge(item?.tier, item?.slug)}
                    alt="badges"
                    className={`${item?._id !== "1" && "badgesImgHld"}`}
                  />
                )}
                {badgeHover?._id === item?._id && (
                  <div className="ProfileCardbadgeToolTip">
                    <img
                      src={displayMode === "dark_mode" ? DarkGraph : LightGraph}
                      className="img-fluid mr-1"
                      alt="graph"
                    />
                    {badgeHover?.tier}
                  </div>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
const useAppHandlers = () => {
  const [state, dispatch] = useAppStateContext();

  const onUserLoaded = useCallback(
    (user) => {
      if (user !== null) {
        if (!state.users[user.id]) {
          dispatch({ type: "set user", payload: { ...user, online: true } });
        }
      }
    },
    [dispatch, state.users]
  );

  const { user, onLogIn, onLogOut, loading } = useUser(onUserLoaded, dispatch);

  return {
    dispatch,
  };
};
