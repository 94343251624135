import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { isAuthenticated } from "auth/helper";
import Pagination from "@mui/lab/Pagination";
import { makeStyles } from "@mui/styles";
import "./DiscussionList.css";
import LoginPageNew from "views/LoginPage/LoginPageNew";
import { useSelector } from "react-redux";
import { bookmarkDiscussion } from "shared/FirebaseEventMethods";
import ProfileCard from "components/ProfileCard/ProfileCard";
import Skeleton from "react-loading-skeleton";
import CreatePostPopup from "views/Feed/Components/CreatePostPopup/CreatePostPopup";
import ToastMessages from "views/ProfilePage/ToastMessage";
import { feedService } from "services/rest/feeds.service";
import { likeDiscussion } from "shared/FirebaseEventMethods";
import addnewImg from "assets/img/AddNewTopic.svg";
import addSvg from "assets/img/DiscussionList/add.svg";
import noDiscussionImg from "assets/img/Practice/no-discussion.svg";
import noiDiscussionDark from "assets/img/hackathon/no-discussion-dark.svg";
import circleAddImg from "assets/img/Practice/circle-add.svg";
import light_dis_bookmark from "assets/img/Feed/light_dis_bookmark.svg";
import light_active_bookmark from "assets/img/Feed/bookmark-gray.svg";
import bookmark from "assets/img/Feed/bookmark.svg";
import bookmarkActive from "assets/img/Feed/dark-filled-bookmark.svg";
import bef_like from "assets/img/Feed/thumc_article.svg";
import comment from "assets/img/Feed/comment.svg";
import thumsUp from "assets/img/Feed/thumsUp.svg";
import dark_thumsUP from "assets/img/Feed/dark_thumsUP.svg";
import light_dis_message from "assets/img/Feed/light_dis_message.svg";
import lightInactiveLike from "assets/img/Feed/light-inactive-like.svg";
import { isMobile } from "react-device-detect";
import nothingFound from "assets/lotties/hackathon/nothingFound.json";
import nothingFoundLight from "assets/lotties/hackathon/nothingFoundLight.json";
import Lottie from "lottie-react";
const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: "20px !important",
      display: "flex",
      justifyContent: "center",
    },
  },
}));

export default function DiscussionList(props) {
  const {
    bootcampCourseId,
    hackathonCategory,
    hackathonHREFName,
    discussionList,
    totalCount,
    setDiscussionId,
    practiceId,
    isLoading,
    currentPageNumber,
    loadAllDiscussionNew,
    isDiscussionLoading,
    category,
    hname,
  } = props;
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [allDiscussions, setAllDiscussions] = useState([]);
  const [discussionsCount, setDiscussionsCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [isHovering, setIsHovering] = useState("");
  const [showToast, setShowToast] = useState({
    show: false,
    message: "",
    duration: 0,
    variant: "",
  });
  const [media, setMedia] = useState([]);
  const fileInputRef = useRef(null);
  const displayMode = useSelector((store) => store.display.display_mode);
  const currentUser = useSelector((store) => store.user.user);
  const imageFileRef = useRef({});
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const onSelectFile = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const MAX_FILE_SIZE = 2048; // 2MB
      const fileSizeKiloBytes = e.target.files[0].size / 1024;
      if (fileSizeKiloBytes > MAX_FILE_SIZE) {
        setShowToast({
          variant: "warning",
          show: true,
          message: "File size is greater than maximum limit(2MB)",
          duration: 5000,
        });
        return;
      }
      setMedia((prev) => [...prev, e.target.files[0]]);
    }
  };
  const formatDate = (dateTime) => {
    if (dateTime) {
      var string = dateTime;

      var temp = string.split(" ");
      var strdate = temp[0].split("/");
      var strTime = temp[1].split(":");

      var year = strdate[2];
      var month = strdate[1];
      var day = strdate[0];

      var hour = strTime[0];
      var minute = strTime[1];
      var seconds = strTime[2];

      var differenceFromNow = moment({
        year: Number(year),
        month: Number(month) - 1,
        day: Number(day),
        hour: Number(hour),
        minutes: Number(minute),
        seconds: Number(seconds),
      }).fromNow();

      return differenceFromNow;
    }
  };

  const bookMarkDiscussions = async (e, data) => {
    e.stopPropagation();
    if (isAuthenticated()) {
      bookmarkDiscussion();
      const bookmarkResponse = await feedService.bookmarkFeed(data?._id);
      if (bookmarkResponse) {
        setAllDiscussions(
          allDiscussions.filter((x) => {
            if (x._id === data._id) {
              x.bookmarks = x.bookmarked ? --x.bookmarks : ++x.bookmarks;
              x.bookmarked = x.bookmarked ? false : true;
            }
            return allDiscussions;
          })
        );
      }
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };

  const onLikeDiscussion = async (e, data) => {
    e.stopPropagation();
    if (isAuthenticated()) {
      likeDiscussion();
      const requestdata = {
        type: "like",
      };
      const bookmarkResponse = await feedService.UpdateReactionsFeed(
        data?._id,
        requestdata
      );
      if (bookmarkResponse) {
        setAllDiscussions(
          allDiscussions.filter((x) => {
            if (x._id === data._id) {
              x.reactions = x.userReaction ? --x.reactions : ++x.reactions;
              x.userReaction = x.userReaction ? null : "like";
            }
            return allDiscussions;
          })
        );
      }
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };
  const handleOpenDiscussion = (discussionId) => {
    if (bootcampCourseId) {
      isAuthenticated()
        ? history.push(
            `/bootcamps/bootcampcourse/${bootcampCourseId}/${discussionId}`
          )
        : localStorage.setItem("prevPath", `/bootcamp/bootcampcourse/${bootcampCourseId}/${discussionId}`),
        history.push("/login");
    }
    if (hackathonCategory && hackathonHREFName) {
      handleOpenHackathon(discussionId);
    }
    if (setDiscussionId) {
      handleOpenPractice(discussionId);
    }
  };
  const handleOpenHackathon = (discussionId) => {
    hackathonCategory === "Blogathon"
      ? history.push(
          `/blogathons/${hackathonHREFName}/discussion/${discussionId}`
        )
      : hackathonCategory === "Visualization"
      ? history.push(
          `/hackathons/visualization/${hackathonHREFName}/discussion/${discussionId}`
        )
      : history.push(
          `/hackathons/${hackathonHREFName}/discussion/${discussionId}`
        );
  };
  const handleOpenPractice = (discussionId) => {
    setDiscussionId(discussionId);
    history.push(`/practices/${practiceId}/discussion/${discussionId}`);
  };
  const handleDiscussionsChange = (e, value) => {
    setSelectedPage(value);
    loadAllDiscussionNew(value);
  };

  useEffect(() => {
    setAllDiscussions(discussionList);
    setDiscussionsCount(totalCount);
    setSelectedPage(currentPageNumber);
  }, [discussionList, totalCount]);

  return (
    <>
      {!isLoading && (
        <div
          className={`${
            category === "Hackathon" && isMobile
              ? `mobile-discussion-list pt-2 pb-0`
              : category === "Hackathon"
              ? `MhnotebookCntr pt-2 pb-2`
              : `pb-3`
          } discussion-list-container`}
        >
          {allDiscussions.length > 0 && (
            <div
              className={`${
                category === "Hackathon" ? `px-3` : `px-2`
              } pb-3 d-flex  align-items-center justify-content-between`}
            >
              <p
                className={`${
                  category === "Hackathon" && isMobile
                    ? "mb-0"
                    : isMobile
                    ? `mb-3`
                    : ``
                } list-discussion-header`}
              >
                Discussions ({allDiscussions?.length})
              </p>
              {allDiscussions.length > 0 && (
                <div
                  className={`discussion-list-btn px-4 py-1 d-flex cursor-pointer`}
                  onClick={(e) => setOpen(true)}
                >
                  <p className="mb-0 mr-2" style={{ color: "#fff" }}>
                    New Topic
                  </p>
                  <img
                    src={addSvg}
                    alt="add"
                    className="img-fluid"
                    width="15"
                    loading="lazy"
                  />
                </div>
              )}
            </div>
          )}
          {isDiscussionLoading ? (
            <>
              <div className="d-flex pt-3 pb-3 align-items-center">
                <div className="col-md-1 list-img-hld">
                  <Skeleton circle={true} height="40px" />
                </div>
                <div className="col-md-7">
                  <Skeleton className="my-2" />
                  <div>
                    <Skeleton />
                  </div>
                </div>
                <div
                  className="col-md-4 d-flex justify-content-end  align-items-end"
                  style={{ maxHeight: "50px" }}
                >
                  <Skeleton className="mr-2" />
                  <Skeleton className="mr-2" />
                  <Skeleton className="mr-2" />
                </div>
              </div>
            </>
          ) : (
            <>
              {allDiscussions && allDiscussions.length > 0 ? (
                <>
                  {allDiscussions.map((discussionItem, index) => (
                    <div
                      className={`${
                        isMobile ? `mobile-discussion-listItem mb-3 ` : `pb-3`
                      } discussion-list-item cursor-pointer d-flex ${
                        isMobile ? "flex-wrap" : ""
                      }  pt-3  ${index != 0 ? "" : ""} ${
                        selectedIndex === index ? "shadowHover" : ""
                      } `}
                      key={discussionItem._id}
                      onMouseOver={(e) => setSelectedIndex(index)}
                      onMouseOut={(e) => {
                        setSelectedIndex(-1);
                      }}
                      onMouseLeave={() => setIsHovering("")}
                      onClick={() => handleOpenDiscussion(discussionItem._id)}
                    >
                      <div
                        className={`${
                          isMobile ? `px-2` : `col-md-1`
                        } position-relative list-img-hld`}
                      >
                        <img
                          className=" "
                          src={discussionItem?.user?.Picture}
                          alt={discussionItem?.user?.Name}
                          loading="lazy"
                          onMouseOver={() => {
                            setIsHovering(discussionItem?._id);
                          }}
                          width={isMobile ? 34 : 55}
                          height={isMobile ? 34 : 55}
                        />
                        {isHovering === discussionItem?._id && (
                          <ProfileCard
                            userId={discussionItem?.user?.userId}
                            setIsHovering={setIsHovering}
                          />
                        )}
                      </div>
                      <div
                        className={`${
                          isMobile
                            ? `col-10 d-flex flex-column-reverse px-1`
                            : `col-md-8`
                        }`}
                      >
                        <div
                          className={`list-discussion-content text-truncate ${
                            displayMode === "dark_mode" ? "whiteText" : ""
                          }`}
                          dangerouslySetInnerHTML={{
                            __html: discussionItem.content,
                          }}
                        ></div>
                        <div className="d-flex align-items-center flex-wrap">
                          <span
                            className={`${
                              isMobile ? "w-100 mobile-discussion-bold" : ""
                            } list-discussion-gray-text text-capitalize ${
                              isMobile ? `` : `mr-1`
                            }`}
                            onMouseEnter={() => setIsHovering(index)}
                          >
                            {discussionItem?.user?.Name}
                          </span>
                          {(!isMobile || category === "Hackathon") && (
                            <>
                              {" "}
                              {!isMobile && (
                                <div className="list-dot mx-1"></div>
                              )}
                              <span
                                className={`list-discussion-gray-text ${
                                  isMobile ? "mr-1" : "mx-1"
                                }`}
                              >
                                Updated{" "}
                                {moment(discussionItem.updatedAt).fromNow()}
                              </span>
                              <div className={`list-dot mx-1`}></div>
                              <span
                                className={`list-discussion-gray-text mx-1`}
                              >
                                Posted{" "}
                                {moment(discussionItem.createdAt).fromNow()}
                              </span>
                            </>
                          )}
                          {isHovering === index && (
                            <ProfileCard
                              userId={discussionItem?.user?.userId}
                              setIsHovering={setIsHovering}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className={`${
                          isMobile
                            ? `mobile-discussion-action justify-content-around mt-2 px-4 py-2`
                            : `justify-content-between`
                        }  col-md-3 d-flex align-items-end`}
                      >
                        <div
                          className={`d-flex align-items-center`}
                          onClick={(e) => onLikeDiscussion(e, discussionItem)}
                        >
                          <img
                            src={
                              displayMode === "dark_mode"
                                ? discussionItem?.userReaction === "like"
                                  ? dark_thumsUP
                                  : bef_like
                                : discussionItem?.userReaction === "like"
                                ? thumsUp
                                : lightInactiveLike
                            }
                            alt="likes"
                            className="discussion-list-img mr-2"
                            width="15"
                            height="15"
                            loading="lazy"
                          />
                          <span className="list-reaction-text list-discussion-gray-text">
                            {discussionItem?.reactions}
                          </span>
                        </div>
                        <div className={`d-flex align-items-center`}>
                          <img
                            className="discussion-list-img mr-2"
                            src={
                              displayMode === "dark_mode"
                                ? comment
                                : light_dis_message
                            }
                            alt="views"
                            width="20"
                            height="20"
                            loading="lazy"
                          />{" "}
                          <span className="list-reaction-text list-discussion-gray-text">
                            {" "}
                            {discussionItem.comments}
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <img
                            src={
                              displayMode === "dark_mode"
                                ? discussionItem.bookmarked
                                  ? bookmarkActive
                                  : bookmark
                                : discussionItem.bookmarked
                                ? light_active_bookmark
                                : light_dis_bookmark
                            }
                            className="discussion-list-img mr-2"
                            onClick={(e) =>
                              bookMarkDiscussions(e, discussionItem)
                            }
                            style={{ cursor: "pointer" }}
                            alt="bookmark"
                            loading="lazy"
                            width={20}
                            height={20}
                          />
                          <span className="list-reaction-text list-discussion-gray-text">
                            {discussionItem.bookmarks}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  {discussionsCount > 10 && (
                    <div
                      className={`row justify-content-center ${
                        displayMode === "dark_mode"
                          ? "darkPagination "
                          : "lightModePagination"
                      }`}
                    >
                      <Pagination
                        count={
                          discussionsCount % 10 === 0
                            ? parseInt(discussionsCount / 10)
                            : parseInt(discussionsCount / 10) + 1
                        }
                        defaultPage={1}
                        page={Number(selectedPage)}
                        variant="outlined"
                        shape="rounded"
                        className={classes.root}
                        onChange={handleDiscussionsChange}
                        showFirstButton
                        showLastButton
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="mb-4">
                    <div className="text-center">
                      {/* <img
                        src={
                          displayMode === "dark_mode"
                            ? noiDiscussionDark
                            : noDiscussionImg
                        }
                        alt="no-discussion"
                        className={`noDiscussionImg img-fluid pb-4`}
                        loading="lazy"
                      /> */}
                      <Lottie
                        animationData={
                          displayMode === "dark_mode"
                            ? nothingFound
                            : nothingFoundLight
                        }
                        loop={true}
                        style={{
                          height: "90px",
                          margin: "auto",
                        }}
                      />

                      {/* <p className="list-discussion-gray-bold list-no-discussion-gray-text mb-1">
                        No Discussion!
                      </p> */}
                      <p className="mb-0 mt-3 pb-3 list-no-discussion-gray-text">
                        Jumpstart Conversations on Our Platform
                      </p>
                      <div className="row justify-content-center mt-4">
                        <div
                          className={`discussion-list-btn px-4 py-1 d-flex cursor-pointer`}
                          onClick={(e) => setOpen(true)}
                        >
                          <span className="mb-0 mr-2" style={{ color: "#fff" }}>
                            New Topic
                          </span>
                          <img
                            src={addSvg}
                            alt="add"
                            className="img-fluid"
                            width="15"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}{" "}
            </>
          )}
        </div>
      )}
      <CreatePostPopup
        open={open}
        setOpen={setOpen}
        isDarkMode={displayMode === "dark_mode"}
        feedData={allDiscussions}
        setFeedData={setAllDiscussions}
        setShowToast={setShowToast}
        category={category}
        href={hackathonHREFName}
        hname={hname}
        user={currentUser}
        fileInputRef={fileInputRef}
        media={media}
        setMedia={setMedia}
        onSelectFile={onSelectFile}
        imageFileRef={imageFileRef}
      />
      {showToast.show && (
        <ToastMessages
          toastMessage={showToast}
          setToastMessage={setShowToast}
          isDarkMode={displayMode === "dark_mode"}
        />
      )}
    </>
  );
}
