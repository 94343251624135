import React from "react";
//import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./assets/scss/material-kit-react.scss";
import { store } from "store/store";
import App from "App";
import ReactDOM from "react-dom/client";
const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
