import axios from "axios";
import { BootcampBackend, LMS_URL } from "../../backend";

function GetAuthorizationConfig() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : null,
    },
  };
}

export const feedService = {
  uploadMedia: async (formData) => {
    return new Promise((resolve, reject) => {
      const options = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(`${LMS_URL}/v1/feed/feedMediaUpload`, formData, options)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  FetchFeed: async (paramData) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${LMS_URL}/v1/feed/getAllDiscussions`, {
          params: paramData,
        })
        .then((response) => {        
          return resolve(response.data.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  bookmarkFeed: async (feedId) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`${LMS_URL}/v1/feed/bookmarkDiscussion/${feedId}`)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  UpdateReactionsFeed: async (feedId, data) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${LMS_URL}/v1/feed/updateDiscussionReaction/${feedId}`,
          data
        )
        .then((response) => {
          return resolve(response.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  fetchSpecificFeed: async (feedId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${LMS_URL}/v1/feed/getSpecificDiscussion/${feedId}`)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  addView: async (feedId) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${LMS_URL}/v1/feed/addViewDiscussion/${feedId}`)
        .then((response) => {
          return resolve(response.data.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  addUniqueView: async (feedId) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${LMS_URL}/v1/feed/addUniqueViewDiscussion/${feedId}`)
        .then((response) => {
          return resolve(response.data.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  DeleteFeed: async (feedId) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${LMS_URL}/v1/feed/deleteDiscussion/${feedId}`)
        .then((response) => {
          return resolve(response.data.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
};
