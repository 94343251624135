import axios from "axios";
import logo from "assets/img/machineHack-logo.jpg";
import { BootcampBackend } from "backend";
import { store } from "store/store";
import { getCountryName } from "shared/CommonMethods";
import { getInnerHTML } from "shared/CommonMethods";

const prodConfig = {
  apiKey: "rzp_live_Me4eUrG6InhGxG",
};
const devConfig = {
  apiKey: "rzp_test_52Pv3q2JKauxce",
};
const config =
  process.env.REACT_APP_ENV_TYPE === "production" ? prodConfig : devConfig;
export const initPayment = (data) => {
  var options = {
    key: config.apiKey,
    subscription_id: data?.razorpay_subscription_id,
    interval: data?.interval,
    name: data.name,
    description: data.description,
    image: logo,
    handler: function (response) {
      checkPaymentSubscription(response);
    },
    prefill: {
      name: data.Name,
      email: data.Email,
      contact: data.Mobile,
    },
    theme: {
      color: "#F44D5E",
    },
    modal: {
      ondismiss: () => {
        console.log("Payment Cancelled");
      },
    },
  };
  var rzp = new window.Razorpay(options);
  rzp.open();
};

export const initProductPayment = (data) => {
  var options = {
    key: config.apiKey,
    amount: data.amountPayable,
    currency: getCountryName() === "India" ? "INR" : "USD",
    name: data?.courseId?.title,
    description: getInnerHTML(data?.courseId?.description)?.slice(0,255),
    image: logo,
    order_id: data.razorPayOrderId,
    handler: function (response) {
      checkPaymentStatus(response);
    },
    prefill: {
      name: data?.userId?.Name,
      email: data?.userId?.Email,
      contact: data?.userId?.Mobile,
    },
    theme: {
      color: "#F44D5E",
    },
      modal: {
      ondismiss: () => {
        console.log("Payment Cancelled");
      },
    },
  };
  var rzp = new window.Razorpay(options);
  rzp.open();
};

const checkPaymentSubscription = async (request) => {
  const requestData = {
    razorpay_subscription_id: request.razorpay_subscription_id,
    razorpay_payment_id: request.razorpay_payment_id,
    razorpay_signature: request.razorpay_signature,
  };
  await axios
    .post(`${BootcampBackend}/subscriptions/subscriptionCallback`, requestData)
    .then((response) => {
      store.dispatch({
        type: "USER_PAYMENT_STATUS",
        payload: true,
      });
    })
    .catch((err) => {
      console.log("Err: ", err);
    });
};
export const isProductPremium = (data) => {
  return data?.premium;
};
export const isProductNotPremium = (data) => {
  return !isProductPremium(data);
};
export const isProductSubscribed = (data) => {
  return data.subscriptionType === "free" || data?.razorpay_payment_id ? true : false;
};
const checkPaymentStatus = async (request) => {
  const requestData = {
    razorpay_order_id: request.razorpay_order_id,
    razorpay_payment_id: request.razorpay_payment_id,
    razorpay_signature: request.razorpay_signature,
  };
  await axios
    .post(`${BootcampBackend}/bootcamp/purchaseVerify`, requestData)
    .then((response) => {
      response?.data?.result?.data?.status === "paid" && (response.data.result.data.payment_status = true)
      store.dispatch({
        type: "PRODUCT_PAYMENT_STATUS",
        payload: response?.data?.result?.data,
      });
    })
    .catch((err) => {
      console.log("Err: ", err);
    });
};