import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import chimpGIF from "assets/img/Footer/Hackathon-request-final-1.gif";
import { API } from "backend";
import axios from "axios";
import { isMobile } from "react-device-detect";
import MuiAlert from "@mui/lab/Alert";
import { createStyles, makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import "./requestHackathonPopup.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      overflow: "visible",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: "8px",
      marginRight: "8px",
      width: 400,
    },

    formControl: {
      margin: "8px",
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: "8px",
    },
  })
);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const RequestHackathonPopup = (props) => {
  const { openHostHackathon, setOpenHostHackathon } = props;
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const [Name, setName] = useState("");
  const [status, setStatus] = useState(false);
  const [usererror, setError] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [description, setDescription] = useState("");
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [designationError, setDesignationError] = useState("");

  const handleCloseHostHackathon = () => {
    setOpenHostHackathon(false);
    setName("");
    setCompany("");
    setEmail("");
    setDesignation("");
    setPhoneNumber("");
    setDescription("");
    setNameError("");
    setEmailError("");
    setCompanyError("");
    setDesignationError("");
  };
  const handleCloseMsg = () => {
    setStatus(!status);
    setSuccess(false);
  };
  const history = useHistory();

  const requestHackathon = () => {
    var regName = /^[a-zA-Z\s]{3,30}$/;
    if (Name === null || Name === "" || !regName.test(Name.trim())) {
      if (Name === null || Name === "") {
        setNameError("Name should not be blank.");
      }
      if (!regName.test(Name.trim())) {
        setNameError("Name should contain atleast 3 alphabets.");
      }
      return;
    } else {
      setNameError("");
    }
    if (email === "" || email === null || email === undefined) {
      setEmailError("Email should not be blank");
      return;
    } else {
      setEmailError("");
    }
    if (company === "" || company === null || company === undefined) {
      setCompanyError("Company should not be blank");
      return;
    } else {
      setCompanyError("");
    }
    if (
      designation === "" ||
      designation === null ||
      designation === undefined
    ) {
      setDesignationError("Designation should not be blank");
      return;
    } else {
      setCompanyError("");
    }
    let paramData = {};
    paramData.name = Name;
    paramData.official_email = email;
    paramData.company = company;
    paramData.designation = designation;
    paramData.number = phoneNumber;
    paramData.message = description;
    axios
      .post(`${API}/requesthackathon`, paramData)
      .then((res) => {
        if (res.data) setSuccess(true);
        setSuccessMsg("You request has been reecorded successfully!");
        handleCloseHostHackathon();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openHostHackathon}
      onClose={handleCloseHostHackathon}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      className={`bootstrap-iso hackathonHostModal`}
      maxwidth="xl"
      keepMounted
    >
      <Fade in={openHostHackathon}>
        <div className="hackathonHostCntr">
          {message ? (
            <SnackbarContent
              message={
                <span>
                  {" "}
                  <b> {message} </b>
                </span>
              }
              close
              color="success"
              icon="info_outline"
            />
          ) : (
            ""
          )}
          {status ? (
            <Snackbar
              open={status}
              autoHideDuration={15000}
              onClose={handleCloseMsg}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <div>
                <Alert onClose={handleCloseMsg} severity="error">
                  {usererror}
                </Alert>
              </div>
            </Snackbar>
          ) : (
            ""
          )}
          {success ? (
            <Snackbar
              open={status}
              autoHideDuration={15000}
              onClose={handleCloseMsg}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <div>
                <Alert onClose={handleCloseMsg} severity="success">
                  {successMsg}
                </Alert>
              </div>
            </Snackbar>
          ) : (
            ""
          )}
          <h2 className="requestHackathonHeading py-3">
            Request a Hackathon
            <IconButton
              aria-label="close"
              style={{
                position: "absolute",
                right: "13px",
                top: "10px",
              }}
              onClick={handleCloseHostHackathon}
            >
              <CloseIcon />
            </IconButton>
          </h2>
          <div className="requesthackathonbody">
            <div className="row">
              <div className="col-md-7 pl-0">
                <p className="mb-0" style={{ fontSize: "16px", color: "#000" }}>
                  Opportunity to
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#000",
                    fontWeight: "bold",
                  }}
                >
                  challenge and assess thousand of ML/AI Developers.
                </p>
                <p>
                  MachineHack helps you to get the right talent from the largest
                  global ML and DS Community.
                </p>
                <div className="row mt-4 pt-4">
                  <div className="col-4 pr-0">
                    <p className="mb-1 red-text-bold">100k+</p>
                    <p className="text-black-small">Registered Developers</p>
                  </div>
                  <div className="col-4 pr-0">
                    <p className="mb-1 red-text-bold">75+</p>
                    <p className="text-black-small">Hackathons Hosted</p>
                  </div>
                  <div className="col-4 pr-0">
                    <p className="mb-1 red-text-bold">1000+</p>
                    <p className="text-black-small">Developers Hired</p>
                  </div>
                </div>
              </div>
              <div className="col-md-5 form-request">
                <div className="request-hackathon-form">
                  <div className="w-100 fix-width">
                    <label style={{ color: "#000" }}>
                      Name<span style={{ color: "#f44d5e" }}>*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      id="name"
                      className="w-100 text-box-popup"
                      placeholder="Your Name"
                      value={Name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                    {nameError !== "" && (
                      <label className="error-msg mb-0 mt-1">{nameError}</label>
                    )}
                  </div>
                  <div className="w-100 mt-3 fix-width">
                    <label style={{ color: "#000" }}>
                      Offical Email
                      <span style={{ color: "#f44d5e" }}>*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      id="email"
                      className="w-100 text-box-popup"
                      placeholder="Official Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                    {emailError !== "" && (
                      <label className="error-msg mb-0 mt-1">
                        {emailError}
                      </label>
                    )}
                  </div>
                  <div className="w-100 mt-3 fix-width">
                    <label style={{ color: "#000" }}>
                      Company<span style={{ color: "#f44d5e" }}>*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      id="company"
                      className="w-100 text-box-popup"
                      placeholder="Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                    />
                    {companyError !== "" && (
                      <label className="error-msg mb-0 mt-1">
                        {companyError}
                      </label>
                    )}
                  </div>
                  <div className="w-100 mt-3 fix-width">
                    <label style={{ color: "#000" }}>
                      Designation<span style={{ color: "#f44d5e" }}>*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      id="designation"
                      className="w-100 text-box-popup"
                      placeholder="Designation"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                    />
                    <br />
                    {designationError !== "" && (
                      <label className="error-msg mb-0 mt-1">
                        {designationError}
                      </label>
                    )}
                  </div>
                  <div className="w-100 mt-3 fix-width">
                    <label style={{ color: "#000" }}>
                      Phone Number (optional)
                    </label>
                    <br />
                    <input
                      type="text"
                      id="phonenumber"
                      className="w-100 text-box-popup"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                  </div>

                  <div className="w-100 mt-3 fix-width">
                    <label style={{ color: "#000" }}>Message</label>
                    <br />
                    <textarea
                      rows={4}
                      cols={10}
                      className="w-100 textarea-popup"
                      placeholder="Type your message here"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>

                  <div className="mt-4" style={{ textAlign: "center" }}>
                    <button
                      className="w-100"
                      onClick={(e) => requestHackathon()}
                      style={{
                        backgroundColor: "#f44d5e",
                        color: "#fff",
                        border: "none",
                        padding: "10px 30px 10px 30px",
                        borderRadius: "5px",
                        fontWeight: "bold",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {isMobile ? (
              ""
            ) : (
              <img
                src={chimpGIF}
                className="chimp-gif"
                alt="chimp"
                loading="lazy"
              />
            )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export default RequestHackathonPopup;
