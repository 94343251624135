import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Link, useHistory } from "react-router-dom";
import { API } from "backend";
import axios from "axios";
import { Pagination } from "@mui/material";
import { isAuthenticated } from "auth/helper";
import { toTitleCase } from "shared/CommonMethods";
import { getInnerHTML } from "shared/CommonMethods";
import SearchIcon from "assets/img/landingpage/search-grey.svg";
import CloseImg from "assets/img/landingpage/close-img.svg";
import RightArrow from "assets/img/landingpage/right-arrow.svg";
import DarkCircledCross from "assets/img/landingpage/white-circled-cross.svg";
import DarkSearchIcon from "assets/img/landingpage/darkSearchIcon.svg";
import WhiteArrow from "assets/img/landingpage/white-arrow.svg";
import "./searchModal.css";

const SearchModal = ({
  showSearchModal,
  setShowSearchModal,
  isDarkMode,
}) => {
  const [path, setPath] = useState("");
  const [search, setSearch] = useState([]); //For Search In Array
  const [filter, setFilter] = useState(); //For Filters
  const [query, setQuery] = useState(""); //For User Input Query
  const [pageNo, setPageNo] = useState(); //For Page Number
  const [sPageNo, setSPageNo] = useState(); //For Page Number
  const [pagesCount, setPagesCount] = useState();
  const [sPagesCount, setSPagesCount] = useState(); //Page count for Single Request Funcion
  const [resp, setResp] = useState(false);
  const [sResp, setSResp] = useState(false);
  const [sSearchKey, setSSearchKey] = useState();
  const [res, setRes] = useState([]); //Store API Response
  const [sRes, setSRes] = useState([]); //Store API Response - Single Request
  const [sort, setSort] = useState(); //For Sorting
  const [firstReq, setFirstReq] = useState(false);
  const history = useHistory();

  const sendRequest = (n) => {
    const APIString = API + "/site/search/";
    var flt,
      firstReqKey = "";

    if (filter) {
      if (filter.includes("week")) flt = "7";
      else if (filter.includes("3")) flt = "90";
      else flt = "30";
    }

    axios
      .get(
        `${APIString}${
          search.length == 0
            ? `all`
            : search.includes("Assessments")
            ? "mocks"
            : search.includes("Courses")
            ? "bootcamps"
            : search.join("+").toLowerCase()
        }/${query}${n ? "/" + n : "/1"}${flt ? "?filter=" + flt : ""}${
          flt && sort ? "&sort=" + sort : sort ? "?sort=" + sort : ""
        }`
      )
      .then((response) => {
        if (response.status === 200) {
          firstReqKey = countUniqueKey(
            response &&
              response.data &&
              response.data.message &&
              response.data.message.count
          );
          setSSearchKey(firstReqKey);
          response.data.message.count
            ? (setResp(true),
              setRes(
                {
                  rs: response.data.message.search,
                  fltr: response.data.message.count,
                },
                setPagesCount(response.data.total_records),
                sendSingleRequest(firstReqKey, 1),
                setSResp(false),
                setFirstReq(true)
              ))
            : setResp(false);
        } else {
          setResp(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const countUniqueKey = (arr) => {
    const counts = {};
    arr.forEach((el) => {
      counts[el.key] = (counts[el.key] || 0) + 1;
    });
    return Object.keys(counts)[0];
  };
  const handlePageChange = (e, value) => {
    sendSingleRequest(sSearchKey, value);
  };

  const sendSingleRequest = (key, n) => {
    setSPageNo(n);
    const APIString = API + "/site/search/";
    var flt = "";
    if (filter) {
      if (filter.includes("week")) flt = "7";
      else if (filter.includes("3")) flt = "90";
      else flt = "30";
    }

    axios
      .get(
        `${APIString}${key}/${query}${n ? "/" + n : "/1"}${
          flt ? "?filter=" + flt : ""
        }${flt && sort ? "&sort=" + sort : sort ? "?sort=" + sort : ""}`
      )
      .then((response) => {
        if (response.status === 200) {
          response.data.message.count
            ? (setSResp(true),
              setSRes(
                {
                  srs: response.data.message.search,
                  sfltr: countUnique(response.data.message.search),
                },
                setSPagesCount(
                  response.data.total_records > 10000
                    ? 10000
                    : response.data.total_records
                )
              ))
            : setSResp(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const countUnique = (arr) => {
    const counts = {};
    arr.forEach((el) => {
      counts[el.Category] = (counts[el.Category] || 0) + 1;
    });

    return counts;
  };
  const handleSeachDown = (event) => {
    if (event.key === "Enter" && query?.length > 2) {
      sendRequest(1);
    }
  };
  const routeToSearchedPages = (data) => {
    switch (data?.Category) {
      // case "hackathons":
      //   if (data?._source?.HCategory === "Blogathon") {
      //     window.open(`/blogathons/${data?._source?.HREFName}/overview`);
      //   } else if (data?._source?.HCategory === "Visualization") {
      //     window.open(
      //       `/hackathons/visualization/${data?._source?.HREFName}/overview`
      //     );
      //   } else {
      //     window.open(`/hackathons/${data?._source?.HREFName}/overview`);
      //   }
      //   break;
      // case "jobs":
      //   window.open(`/jobs/?currentJobId=${data._source.job_id}`);
      //   break;
      // case "notebooks":
      //   window.open(
      //     `/hackathons/${data._source.category}/notebook/${data._source.notebook_id}`
      //   );
      //   break;
      // case "discussions":
      //   isAuthenticated()
      //     ? window.open(
      //         `/posts/${data?._source?.discussion_id}`,
      //         "_blank"
      //       )
      //     : (localStorage.setItem("prevPath", `/posts/${data?._source?.discussion_id}`),
      //        history.push("/login"));
      //   break;
      case "bootcamps":
        window.open(`/bootcamps/${data?._source?.bootcamp_id}`);
        break;
      case "practices":
        window.open(`/practices/${data?._source?.slug}/description`);
        break;
      case "mocks":
        window.open(`/assessments`);
        break;
      default:
        break;
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showSearchModal}
      onClose={setShowSearchModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      className={`bootstrap-iso searchModal ${isDarkMode ? "darkTheme" : ""}`}
      keepMounted
    >
      <Fade in={showSearchModal}>
        <div className="searchModalHld">
          <div className="searchcard">
            <div className="d-flex align-items-center w-100">
              <img
                src={isDarkMode ? DarkSearchIcon : SearchIcon}
                alt="search"
                className="img-fluid mr-2"
              />
              {search?.length > 0 && (
                <div className="selectedModuleshld">
                  {search?.map((x, i) => (
                    <span
                      className={`selectedModules mr-2 ${
                        isDarkMode ? "whiteText" : ""
                      }`}
                      onClick={() => setSearch(search.filter((e) => e != x))}
                      key={i}
                    >
                      {x}
                      <img
                        src={isDarkMode ? DarkCircledCross : CloseImg}
                        alt=""
                        className="img-fluid"
                      />
                    </span>
                  ))}
                </div>
              )}
              <input
                type="text"
                className={`search-input w-100 ${
                  isDarkMode ? "whiteText" : ""
                }`}
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                onKeyDown={handleSeachDown}
                value={query}
                placeholder="Search in MachineHack..."
                required
              />
            </div>
            {resp && query?.length > 0 ? (
              <img
                src={isDarkMode ? DarkCircledCross : CloseImg}
                className="img-fluid mr-2"
                alt="cross"
                onClick={() => {
                  setQuery("");
                  setResp(false);
                }}
                width={20}
              />
            ) : (
              <button
                className={`searchButton ${
                  query?.length > 2 ? "activesearchButton" : ""
                }`}
                onClick={() => {
                  if (query?.length > 2) {
                    setResp(false);
                    setPageNo("1");
                    setSPageNo("1");
                    setSPagesCount("");
                    setPagesCount("");
                    sendRequest(1);
                  }
                }}
              >
                <img
                  src={query?.length > 2 ? WhiteArrow : RightArrow}
                  alt="right-arrow"
                  className="img-fluid"
                />
              </button>
            )}
          </div>
          <div className="diffModules px-4">
            <span
              className={`smallText-search ${isDarkMode ? "whiteText" : ""}`}
            >
              Search in
            </span>
            <div className="mb-3">
              {[
                // "Hackathons",
                "Assessments",
                "Courses",
                "Practices",
                // "Jobs",
                // "Notebooks",
                "Discussions",
              ].map((ele, i) => (
                <span
                  className={`modules ${
                    search.includes(ele)
                      ? `activeModules ${isDarkMode ? "blackText" : ""}`
                      : `${isDarkMode ? "darkText" : ""}`
                  }`}
                  onClick={() => {
                    if (!search.includes(ele)) {
                      setSearch([ele, ...search]);
                    }
                  }}
                  key={i}
                >
                  {ele}
                </span>
              ))}
            </div>
          </div>
          {query.length > 3 && resp && (
            <>
              <span
                className={`search-results-text pl-4 ${
                  isDarkMode ? "darkText" : ""
                }`}
              >
                Search results for
              </span>
              <span
                className={`queryHighlight ${isDarkMode ? "darkText" : ""}`}
              >
                {" "}
                “{query}” ({pagesCount})
              </span>
            </>
          )}
          {resp && query.length > 3 && (
            <div className="search-results-cntr mt-2">
              <div className={`d-flex px-4 ${isDarkMode ? "darkBorder" : ""}`}>
                <div className="py-4 leftabs px-0">
                  <ul className="pl-0">
                    {res &&
                      res.fltr &&
                      res.fltr.map((ele, i) =>(
                        <React.Fragment key={i}>
                          {ele.key !== "hackathons" && ele.key!=="jobs" && ele.key!=="notebooks" && ele.key!=="discussions"? (
                            <li
                              className={`w-100 ${
                                ele.key === sSearchKey
                                  ? "search-active-tab"
                                  : ""
                              }`}
                              key={i}
                            >
                              <span
                                onClick={() => (
                                  setSSearchKey(ele.key),
                                  sendSingleRequest(ele.key, 1),
                                  setFirstReq(false)
                                )}
                                data-toggle="tab"
                              >
                                {ele.key === "mocks"
                                  ? "Assessments"
                                  : ele.key === "bootcamps"
                                  ? "Courses"
                                  : ele.key}{" "}
                                ({ele.doc_count}){" "}
                              </span>
                            </li>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      )
                      )}
                  </ul>
                </div>
                {firstReq || sResp ? (
                  <div className="w-100 searchDatas">
                    <div className="ml-4 tab-content py-4">
                      {sRes &&
                        sRes.sfltr &&
                        Object.keys(sRes.sfltr).map((ele, i) => (
                          <div
                            className="tab-pane show active"
                            key={i}
                            id={ele}
                          >
                            {sRes.srs &&
                              sRes.srs.map((el, i) => (
                                <div
                                  key={i}
                                  className="searchCardhld"
                                  onClick={() => routeToSearchedPages(el)}
                                >
                                  {el?.Category === "practices" ? (
                                    <p
                                      className={`searchCardcapsule mb-0 ${
                                        isDarkMode ? "whiteText" : ""
                                      }`}
                                    >
                                      {el?._source?.difficulty}
                                    </p>
                                  ) : (
                                    <p
                                      className={`searchTitle mb-0 ${
                                        isDarkMode ? "whiteText" : ""
                                      }`}
                                    >
                                      {toTitleCase(el?._source?.Name) ||
                                        toTitleCase(el?._source?.UserName) ||
                                        el?._source?.companyId?.companyName ||
                                        el?._source?.HName ||
                                        el?._source?.title}
                                    </p>
                                  )}
                                  <p
                                    className={`mb-0 searchDescription text-truncate ${
                                      isDarkMode ? "darkText" : ""
                                    }`}
                                  >
                                    {el?._source?.Comment ||
                                      el?._source?.Topic ||
                                      el?._source?.jobTitle ||
                                      getInnerHTML(
                                        el?._source?.HProfile?.overview
                                      ) ||
                                      getInnerHTML(el?._source?.description) ||
                                      el?._source?.questionTitle}
                                  </p>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {sPagesCount ? (
                <div className="col-md-12 mt-4 text-center">
                  <div
                    className={`row justify-content-center ${
                      isDarkMode ? "darkPagination" : "lightModePagination"
                    }`}
                  >
                    {
                      <>
                        <Pagination
                          count={
                            sPagesCount % 10 === 0
                              ? parseInt(sPagesCount / 10)
                              : parseInt(sPagesCount / 10) + 1
                          }
                          defaultPage={1}
                          page={sPageNo}
                          variant="outlined"
                          shape="rounded"
                          onChange={handlePageChange}
                          showFirstButton
                          showLastButton
                        />
                      </>
                    }
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default SearchModal;
