import React, { useEffect } from "react";
import Lottie from "lottie-react";
import CoinLottie from "assets/lotties/Feed/Coin.json";
import WarningLottie from "assets/lotties/Feed/Warning.json";
import { isAuthenticated } from "auth/helper";
import { useHistory } from "react-router";
import { motion } from "framer-motion/dist/framer-motion";

function MhCoinsPopup(props) {
  const { isDarkMode, closeModal } = props;
  const history = useHistory();
  const onViewCoins = () => {
    history.push(`/myaccount/${isAuthenticated().user._id}?tab=coins`);
    closeModal(false);
  };
  useEffect(() => {
    const closePopup = setTimeout(() => {
      closeModal(false);
    }, 5000);
    return () => clearTimeout(closePopup);
  }, []);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`mh-coins-popup-cntr ${isDarkMode ? "darkTheme" : ""}`}
    >
      <div className={`mh-coins-popup ${props?.coinsData?.transactionType === "debit" ? "mh-coins-red-bg" : "mh-coins-orange-bg"}`}>
        <div className="mh-coin-and-text">
          <Lottie
            animationData={props?.coinsData?.transactionType === "debit" ? WarningLottie : CoinLottie}
            loop={true}
            className={` ${props?.coinsData?.transactionType === "debit" ? "warning-lottie" : "coin-lottie"}`}
          />
          <p className="mh-coins-text">
            {props?.coinsData?.transactionType === "debit" ? "Oops! You have lost" : "Wow! You Have Earned"} {(props?.coinsData?.coins || props.coins)?.toString()?.replace("-","")} MH
            Coins
          </p>
        </div>
        <button onClick={onViewCoins}>View Coins</button>
      </div>
    </motion.div>
  );
}

export default MhCoinsPopup;
