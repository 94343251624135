import React, { useEffect, useRef, useState } from "react";
import AILotte from "assets/lotties/MachineAI/AI.json";
import Lottie from "lottie-react";
import halfGlobe from "assets/img/MachineAi/halfGlobe.svg";
import downArrow from "assets/img/MachineAi/downArrow.svg";
import upArrow from "assets/img/MachineAi/upArrow.svg";
import { Alert, Box, Modal, Snackbar } from "@mui/material";
import MachinehackAI from "views/MachineAI";
import MachineResponse from "views/MachineAI/Component/machineResponse/MachineResponse";
import axios from "axios";
import { BootcampBackend } from "backend";
import { isAuthenticated } from "auth/helper";
import "../../Practices/PracticeQuestion.css";
import "./MachinehackAiSearch.css";
import { isMobile } from "react-device-detect";
import { useHistory, useLocation } from "react-router-dom";
const machineStyle = {
  position: "absolute",
  bottom: "-20%",
  left: "42%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
};
const placeholderValue = [
  "Why should I participate in this competition?",
  "What are the benefits of participating ?",
  "Is there any certification?",
  "what is this competition about?",
];
function getText(html){
  const divContainer= document.createElement("div");
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || "";
}


export default function MachinehackAiSearch({
  searchValue,
  setSearchValue,
  hname,
  overview,
  hackathonId,
}) {
  const [showProcessing, setShowProcessing] = useState(false);
  const [showToggleBtn, setShowToggleBtn] = useState(false);
  const [attemptsLeft, setAttemptsLeft] = useState(0);
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [gptResponse, setGptResponse] = useState([]);
  const [showError, setShowError] = useState(false);
  const [textIndex, setTextIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [isDelete, setIsDelete] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const timeoutRef = useRef(null);
  const displayedTextRef = useRef("");
  const handleChange = (e) => {
    e.stopPropagation();
    setSearchValue(e.target.value);
  };
  const getAskAttempt = async (hackathonId) => {
    const response = await axios.get(
      `${BootcampBackend}/v1/practicAiTrack/getPracticeAiTrack/${hackathonId}?type=hackathon`,
      {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt"))?.token,
        },
      }
    );
    setAttemptsLeft(response?.data?.result?.data.attempts);
  };

  const handleAsk = async (searchProp) => {
    const hackathonData = overview? getText(overview) :""
    if (isAuthenticated()) {
      //  step1. check attempsts if < 5
      if (attemptsLeft < 5) {
        const searchText =
          searchValue?.length > 0 || searchProp
            ? searchProp ? searchProp: searchValue
            : placeholderValue[textIndex];
        setShowProcessing(true);
        try {
          const response = await axios.put(
            `${BootcampBackend}/v1/practicAiTrack/updateAiTrackModule/hackathon`,
            {
              id: hackathonId,
              question: searchText,
              wrongAnswer: hackathonData,
              title: hname,
            },
            {
              timeout: 40000, // 40 seconds timeout
            }
          );

          if (response.data?.result?.data?.aiExplanation) {
            setGptResponse(response.data.result.data.aiExplanation);
            setAttemptsLeft(response.data.result.data?.attempts);
            setTimeout(() => {
              setIsTyping(true);
              setShowResponseModal(true);
              setShowToggleBtn(true);
            }, 2000);
          }
          setShowProcessing(false);
        } catch (error) {
          setShowProcessing(false);
        }
      } else {
        setShowError(true);
      }
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };

  useEffect(() => {
    getAskAttempt(hackathonId);
  }, []);


  useEffect(() => {
    if (searchValue.length === 0) {
      const isEmptyDisplay = displayedTextRef.current === "";
      const shouldDelayNextProcess =
        isDelete &&
        displayedTextRef.current.length === placeholderValue[textIndex].length;

      displayedTextRef.current = placeholderValue[textIndex].substr(0, charIndex);

      timeoutRef.current = setTimeout(
        () => {
          if (isDelete && isEmptyDisplay) {
            setIsDelete(false);
            setTextIndex((prevTextIndex) =>
              prevTextIndex + 1 < placeholderValue.length ? prevTextIndex + 1 : 0
            );
          } else if (
            isDelete ||
            displayedTextRef.current === placeholderValue[textIndex]
          ) {

            setIsDelete(true);
            setCharIndex((prevSubIndex) =>
              prevSubIndex !== -1 ? prevSubIndex - 1 : 0
            );
          } else if (
            !isDelete &&
            displayedTextRef.current !== placeholderValue[textIndex]
          ) {
            setCharIndex((prevCharIndex) =>
              prevCharIndex + 1 <= placeholderValue[textIndex].length
                ? prevCharIndex + 1
                : 0
            );
          }
        },
        shouldDelayNextProcess ? 400 : 100 - Math.random() * 100
      );
    }

    return () => clearTimeout(timeoutRef.current);
  }, [placeholderValue, charIndex, textIndex, isDelete]);
  return (
    <>
      <div className={`${isMobile?`mob-hackathon-ai-search-hld`:``} hackathon-ai-search-hld`}>
        <div className="hackathon-ai-search-hld-inner text-left">
          <input
            className={`machinehack-ai-input ${
              searchValue.length > 0 || showResponseModal || showError || showToggleBtn? `` : ``
            }`}
            type="text"
            value={searchValue}
            placeholder={displayedTextRef.current}
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e?.target?.value) {
                setSearchValue(e.target.value);
                handleAsk(e.target.value);
              }
          }}
          />
          <div
            className="hackathon-ask-btn d-flex align-items-center mx-2 cursor-pointer"
            onClick={(e)=>{e.stopPropagation(); handleAsk()}}
          >
            <Lottie
              animationData={AILotte}
              loop={true}
              style={{
                width: "60%",
              }}
            />{" "}
            Ask
          </div>
        </div>
      </div>
     {!isMobile&&<p className="powered-by-text">Powered by MachineHack Gen AI</p>}
      <Modal
        open={showProcessing}
        onClose={() => setShowProcessing(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="machineAiModal"
      >
        <Box>
          <MachinehackAI />
        </Box>
      </Modal>
      {showToast && (
        <Snackbar
          open={showToast}
          onClose={() => setShowToast(false)}
          autoHideDuration={15000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <div className="hackathonSnackBar">
            <Alert onClose={() => setShowToast(false)} severity="info">
              Something went wrong. Please try again later.
            </Alert>
          </div>
        </Snackbar>
      )}
      <Modal
        open={showResponseModal || showError}
        onClose={() => {
          setShowResponseModal(false);
          setShowError(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="machineAiresponseModal"
      >
        <Box sx={machineStyle}>
          <MachineResponse
            getGptData={handleAsk}
            gptData={gptResponse}
            isAttemptsLeft={attemptsLeft}
            isTyping={isTyping}
            questionTitle={hname}
            showError={showError}
          />
        </Box>
      </Modal>
      {showToggleBtn && (
        <div
          className={`d-flex halfglobePractive align-items-center justify-content-center mt-3`}
          onClick={() => {
            setShowResponseModal(!showResponseModal);
            setIsTyping(false);
          }}
        >
          {!showResponseModal && !showError && (
            <div className="closed-half-globe-semiCircle"></div>
          )}
          <img
            src={halfGlobe}
            alt="half-globe"
            className="img-fluid closed-half-GLobe"
            loading="lazy"
          />
          <img
            src={showResponseModal ? downArrow : upArrow}
            alt="half-globe"
            className="img-fluid machine-responsedownArrow"
            loading="lazy"
          />
        </div>
      )}
    </>
  );
}
