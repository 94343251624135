/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import axios from "axios";
import { API } from "backend";
import { Link, useHistory } from "react-router-dom";
import "./TableStyle.css";
import Run from "assets/img/submit1.png";
import infoIcon from "assets/img/Blog/info-icon.svg";
import { isProductSubscribed } from "subscriptions/Subscriptions";
import lock from "assets/img/Practices/lock.svg";
const moment = require("moment");
var FileSaver = require("file-saver");

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgba(41, 31, 60, 0.6)",
    color: "#ffffff",
    fontSize: "15px",
    fontWeight: "500",
    paddingTop: "10px",
    paddingBottom: "10px",
    border:"none",
    borderBottom: "none !important",
  },
  body: {
    fontSize: "14px",
    paddingTop: "15px",
    paddingBottom: "15px",
    color: "#455461",
    padding: window.innerWidth < 600 ? "10px" : "16px",
    borderBottom: "none",
  },
}))(TableCell);

const useStyles = makeStyles({
  tbody: {
    // border: "1px solid #303242",
  },
});

export default function TableSubmission(props) {
  const hackathonStartDate = props.hackathonStartDate;
  const { submissions } = props;
  const classes = useStyles();
  const user = useSelector((store) => store.user.user);
  const userSubscriptionData = useSelector(
    (store) => store.subscription.subscriptionData
  );
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [toSubmit, showToSubmit] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const { isDarkMode } = props;
  const history = useHistory();

  const StyledTableRow = withStyles((theme) =>
    !isProductSubscribed(userSubscriptionData)
      ? {
          root: {
            padding: "10px 10px",
            "&:nth-of-type(3) ~ &": {
              backgroundColor: isDarkMode ? "#000726" : "#fff",
              position: "relative",
            },
          },
        }
      : {
          root: {
            padding: "10px 10px",
          },
        }
  )(TableRow);

  const downloadFile = (fileID, fileName) => {
    setStart(true);
    setMessageData("File Download Started ...");
    axios({
      method: "GET",
      url: `${API}/submissions/download/${props.hackathon.HREFName}/${user._id}/${fileID}`,
      responseType: "blob",
    })
      .then((response) => {
        FileSaver.saveAs(response.data, fileName);
        setStart(false);
        setEnd(true);
        setMessageData("File Download Completed");
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {submissions && submissions.length > 0 && (
        <>
          <div className={`d-flex justify-content-center ${isDarkMode ? "dark-Submisstion-history-heading" : ""}`}>
            <label
              className={`title-medium-submission m-0 py-3 mb-0 ${
                isDarkMode ? "whiteText" : ""
              }`}
            >
              Submission History
            </label>
          </div>
          <>

            {props.hackathon.HStatus === "Active" ? (
              <>
                <div className="table-data-mobile">
                  {!isProductSubscribed(userSubscriptionData) &&
                    submissions.length > 3 && (
                      <button
                        className="submissionSubscription"
                        onClick={() => history.push(`/subscriptions`)}
                      >
                        <img
                          className="mr-2 img-fluid"
                          src={lock}
                          alt="lock"
                          loading="lazy"
                        />
                        Subscribe
                      </button>
                    )}
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow className="submissionTableHead">
                        <StyledTableCell
                          className={isDarkMode ? "darkText" : ""}
                        >
                          File
                        </StyledTableCell>

                        <StyledTableCell
                          className={isDarkMode ? "darkText" : ""}
                        >
                          Public Score
                        </StyledTableCell>
                        {/* <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
                      </TableRow>
                    </TableHead>

                    <TableBody className={classes.tbody}>
                      {submissions.map((row, index) => {
                        return (
                          <StyledTableRow
                            key={`Activerow${index}`}
                            className={`${
                              !isProductSubscribed(userSubscriptionData) &&
                              "submissionSubscriptionHld"
                            }`}
                          >
                            <StyledTableCell
                              component="th"
                              scope="row"
                              onClick={() => {
                                downloadFile(row._id, row.filename);
                              }}
                            >
                              <Link
                                to="#"
                                style={{
                                  color: "#455461",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                <u className={isDarkMode ? "darkText" : ""}>
                                  {row.filename}
                                </u>
                              </Link>
                            </StyledTableCell>
                            <StyledTableCell
                              className={isDarkMode ? "darkText" : ""}
                            >
                              {row.score !== undefined &&
                              typeof row.score === "number"
                                ? row.score.toFixed(5)
                                : row.score}
                            </StyledTableCell>
                            {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
                          </StyledTableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </div>
                <div className="submissionTableMain table-data-row mb-5">
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    {submissions && submissions.length > 0 && (
                      <TableHead>
                        <TableRow>
                          <StyledTableCell
                            className={isDarkMode ? "whiteText  border-left-0 border-right-0 border-top-0" : ""}
                          >
                            File
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            className={`position-relative ${
                              isDarkMode ? "whiteText  border-left-0 border-right-0 border-top-0" : ""
                            }`}
                          >
                            Submitted On{" "}
                            <img
                              src={infoIcon}
                              className="img-fluid mr-1 infoIcon"
                              alt="info"
                              onMouseOver={() => setShowInfo(!showInfo)}
                              onMouseOut={() => setShowInfo(!showInfo)}
                            />
                            {showInfo && (
                              <div className="popOverHld">
                                <p className="mb-0">
                                  Timestamp is in GMT+5.30 timezone
                                </p>
                              </div>
                            )}
                          </StyledTableCell>
                          {moment(hackathonStartDate).isAfter("2022-04-28") && (
                            <StyledTableCell
                              align="right"
                              className={
                                isDarkMode
                                  ? "whiteText border-left-0 border-right-0"
                                  : "  border-0"
                              }
                            >
                              Submission by
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            align="right"
                            className={
                              isDarkMode ? "whiteText  border-left-0 border-right-0 border-top-0" : "  border-0"
                            }
                          >
                            Comment
                          </StyledTableCell>
                          <StyledTableCell
                            align="right"
                            className={
                              isDarkMode ? "whiteText  border-left-0 border-right-0 border-top-0" : "  border-0"
                            }
                          >
                            Public Score
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody className={classes.tbody}>
                      {submissions.map((row, index) => (
                        <StyledTableRow
                          key={`${row.filename}${index}`}
                          className={`${isDarkMode ? "" : "lightBorder"} ${
                            !isProductSubscribed(userSubscriptionData) &&
                            "submissionSubscriptionHld"
                          }`}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            className={isDarkMode ? "darkBorderBottom" : ""}
                            onClick={() => {
                              downloadFile(row._id, row.filename);
                            }}
                            style={{
                              maxWidth: "130px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              paddingRight: "0",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                color: "#455461",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              <u className="darkText">{row.filename}</u>
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              isDarkMode ? "darkText darkBorderBottom" : ""
                            }
                          >
                            {row.submitted_on}
                          </StyledTableCell>
                          {moment(hackathonStartDate).isAfter("2022-04-28") && (
                            <StyledTableCell
                              className={
                                isDarkMode ? "darkText darkBorderBottom" : ""
                              }
                            >
                              {row?.name}
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            className={
                              isDarkMode ? "darkText darkBorderBottom" : ""
                            }
                          >
                            {row.comment}
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              isDarkMode ? "darkText darkBorderBottom" : ""
                            }
                          >
                            {row.score !== undefined &&
                            typeof row.score === "number"
                              ? row.score.toFixed(5)
                              : row.score}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                  {!isProductSubscribed(userSubscriptionData) &&
                    submissions.length > 3 && (
                      <button
                        className="submissionSubscription"
                        onClick={() => history.push(`/subscriptions`)}
                      >
                        <img
                          className="mr-2 img-fluid"
                          src={lock}
                          alt="lock"
                          loading="lazy"
                        />
                        Subscribe
                      </button>
                    )}
                </div>
              </>
            ) : (
              ""
            )}
            {props.hackathon.HStatus === "Expired" &&
            props.hackathon.Requires_pr_pu === true ? (
              <>
                <div className="table-data-mobile">
                  {!isProductSubscribed(userSubscriptionData) &&
                    submissions.length > 3 && (
                      <button
                        className="submissionSubscription"
                        onClick={() => history.push(`/subscriptions`)}
                      >
                        <img
                          className="mr-2 img-fluid"
                          src={lock}
                          alt="lock"
                          loading="lazy"
                        />
                        Subscribe
                      </button>
                    )}
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>File</StyledTableCell>

                        <StyledTableCell>Public Score</StyledTableCell>
                        {/* <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                      {submissions.map((row) => (
                        <StyledTableRow
                          key={row.filename}
                          className={` ${isDarkMode ? "" : "lightBorder"} ${
                            !isProductSubscribed(userSubscriptionData) &&
                            "submissionSubscriptionHld"
                          }`}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            onClick={() => {
                              downloadFile(row._id, row.filename);
                            }}
                            style={{
                              maxWidth: "130px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              paddingRight: "0",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                color: "#455461",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              <u className={isDarkMode ? "darkText" : ""}>
                                {row.filename}
                              </u>
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell
                            className={isDarkMode ? "darkText" : ""}
                          >
                            {row.score !== undefined &&
                            typeof row.score === "number"
                              ? row.score.toFixed(5)
                              : row.score}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div className="submissionTableMain table-data-row mb-5">
                  {!isProductSubscribed(userSubscriptionData) &&
                    submissions.length > 3 && (
                      <button
                        className="submissionSubscription"
                        onClick={() => history.push(`/subscriptions`)}
                      >
                        <img
                          className="mr-2 img-fluid"
                          src={lock}
                          alt="lock"
                          loading="lazy"
                        />
                        Subscribe
                      </button>
                    )}
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          className={
                            isDarkMode ? "whiteText" : "  border-0"
                          }
                        >
                          File
                        </StyledTableCell>
                        {moment(hackathonStartDate).isAfter("2022-04-28") && (
                          <StyledTableCell
                            align="right"
                            className={
                              isDarkMode ? "whiteText" : "  border-0"
                            }
                          >
                            Submission by
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          align="right"
                          className={
                            isDarkMode ? "whiteText" : "  border-0"
                          }
                        >
                          Submitted On
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={
                            isDarkMode ? "whiteText" : "  border-0"
                          }
                        >
                          Public Score
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={
                            isDarkMode ? "whiteText" : "  border-0"
                          }
                        >
                          Private Score
                        </StyledTableCell>
                        <StyledTableCell
                          align="right"
                          className={
                            isDarkMode ? "whiteText" : "  border-0"
                          }
                        >
                          Comment
                        </StyledTableCell>
                        {/* <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                      {submissions.map((row, index) => (
                        <StyledTableRow
                          key={row.filename}
                          className={`${isDarkMode ? "" : "lightBorder"} ${
                            !isProductSubscribed(userSubscriptionData) &&
                            "submissionSubscriptionHld"
                          }`}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            onClick={() => {
                              downloadFile(row._id, row.filename);
                            }}
                            style={{
                              maxWidth: "130px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              paddingRight: "0",
                            }}
                            className={isDarkMode ? "darkBorderBottom" : ""}
                          >
                            <Link
                              to="#"
                              style={{
                                color: "#455461",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              <u className={isDarkMode ? "darkText" : ""}>
                                {row.filename}
                              </u>
                            </Link>
                          </StyledTableCell>
                          {moment(hackathonStartDate).isAfter("2022-04-28") && (
                            <StyledTableCell
                              className={
                                isDarkMode ? "darkText darkBorderBottom" : ""
                              }
                            >
                              {row?.name}
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            className={
                              isDarkMode ? "darkText darkBorderBottom" : ""
                            }
                          >
                            {row.submitted_on}
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              isDarkMode ? "darkText darkBorderBottom" : ""
                            }
                          >
                            {row.score !== undefined &&
                            typeof row.score === "number"
                              ? row.score.toFixed(5)
                              : row.score}
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              isDarkMode ? "darkText darkBorderBottom" : ""
                            }
                          >
                            {row.pr_score !== undefined &&
                            typeof row.pr_score === "number"
                              ? row.pr_score.toFixed(5)
                              : row.pr_score}
                          </StyledTableCell>
                          <StyledTableCell
                            className={
                              isDarkMode ? "darkText darkBorderBottom" : ""
                            }
                          >
                            {row.comment}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </>
            ) : (
              ""
            )}
            {props.hackathon.HStatus === "Expired" &&
            props.hackathon.Requires_pr_pu === false ? (
              <>
                <div className="table-data-mobile">
                  {!isProductSubscribed(userSubscriptionData) &&
                    submissions.length > 3 && (
                      <button
                        className="submissionSubscription"
                        onClick={() => history.push(`/subscriptions`)}
                      >
                        <img
                          className="mr-2 img-fluid"
                          src={lock}
                          alt="lock"
                          loading="lazy"
                        />
                        Subscribe
                      </button>
                    )}
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>File</StyledTableCell>

                        <StyledTableCell>Public Score</StyledTableCell>
                        {/* <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                      {submissions.map((row, index) => (
                        <StyledTableRow
                          key={row.filename}
                          className={` ${isDarkMode ? "" : "lightBorder"} ${
                            !isProductSubscribed(userSubscriptionData) &&
                            "submissionSubscriptionHld"
                          }`}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            onClick={() => {
                              downloadFile(row._id, row.filename);
                            }}
                            style={{
                              maxWidth: "130px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              paddingRight: "0",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                color: "#455461",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              <u className={isDarkMode ? "darkText" : ""}>
                                {row.filename}
                              </u>
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell
                            className={isDarkMode ? "darkText" : ""}
                          >
                            {row.score !== undefined &&
                            typeof row.score === "number"
                              ? row.score.toFixed(5)
                              : row.score}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div className="submissionTableMain table-data-row mb-5">
                  {!isProductSubscribed(userSubscriptionData) &&
                    submissions.length > 3 && (
                      <button
                        className="submissionSubscription"
                        onClick={() => history.push(`/subscriptions`)}
                      >
                        <img
                          className="mr-2 img-fluid"
                          src={lock}
                          alt="lock"
                          loading="lazy"
                        />
                        Subscribe
                      </button>
                    )}
                  <Table
                    className={classes.table}
                    aria-label="customized table"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>File</StyledTableCell>
                        <StyledTableCell align="right">
                          Submitted On
                        </StyledTableCell>
                        {moment(hackathonStartDate).isAfter("2022-04-28") && (
                          <StyledTableCell align="right">
                            Submission by
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="right">
                          Public Score
                        </StyledTableCell>
                        <StyledTableCell align="right">Comment</StyledTableCell>
                        {/* <StyledTableCell align="right">Protein&nbsp;(g)</StyledTableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                      {submissions.map((row, index) => (
                        <StyledTableRow
                          key={row.filename}
                          className={`${
                            !isProductSubscribed(userSubscriptionData) &&
                            "submissionSubscriptionHld"
                          }`}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            onClick={() => {
                              downloadFile(row._id, row.filename);
                            }}
                            style={{
                              maxWidth: "130px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              paddingRight: "0",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                color: "#455461",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              <u className={isDarkMode ? "darkText" : ""}>
                                {row.filename}
                              </u>
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell
                            className={isDarkMode ? "darkText" : ""}
                          >
                            {row.submitted_on}
                          </StyledTableCell>
                          {moment(hackathonStartDate).isAfter("2022-04-28") && (
                            <StyledTableCell
                              className={
                                isDarkMode ? "darkText darkBorderBottom" : ""
                              }
                            >
                              {row?.name}
                            </StyledTableCell>
                          )}
                          <StyledTableCell
                            className={isDarkMode ? "darkText" : ""}
                          >
                            {row.score !== undefined &&
                            typeof row.score === "number"
                              ? row.score.toFixed(5)
                              : row.score}
                          </StyledTableCell>
                          <StyledTableCell
                            className={isDarkMode ? "darkText" : ""}
                          >
                            {row.comment}
                          </StyledTableCell>
                          {/* <StyledTableCell align="right">{row.protein}</StyledTableCell> */}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </>
            ) : (
              ""
            )}
            {submissions.length === 0 && toSubmit === true ? (
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <br />
                    <h4>No Submission Yet! </h4>
                  </div>
                  <div className="col-md-3"></div>
                  <div className="col-md-6 text-center">
                    <img
                      src={Run}
                      alt=""
                      srcset=""
                      style={{ height: "300px", width: "400px" }}
                      loading="lazy"
                    />
                  </div>
                  <div className="col-md-3"></div>

                  <div className="col-md-12 text-center">
                    <br />
                    <p style={{ color: "#fa3558" }}>
                      <i>Don't miss the Submission!</i>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        </>
      )}
    </>
  );
}
