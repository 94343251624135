import axios from "axios";
import { BootcampBackend } from "backend";
import React, { useCallback, useEffect, useRef, useState } from "react";

const CityDropdown = (props) => {
  const {
    city,
    setCity,
    showParticipateButton,
    currentUser,
    isDarkMode,
    isMandate,
    placeholderValue
  } = props;
  const [citySearchTxt, setCitySearchTxt] = useState("");
  const [listOfCities, setListOfCities] = useState([]);
  const [showCitiesDropdown, setShowCitiesDropdown] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };
  const onCityChange = (value) => {
    setShowCitiesDropdown(true);
    if (value.length > 0) {
      getAllCities(value);
    } else if (value.length === 0) {
      getAllCities("");
    }
  };
  const optimizedFn = useCallback(debounce(onCityChange), []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCitiesDropdown(false);
          setCitySearchTxt("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const getAllCities = async (searchString) => {
    await axios
      .get(
        `${BootcampBackend}/locations/getAllCites${
          searchString ? `?searchString=${searchString}` : ""
        }`
      )
      .then((response) => {
        response.data.result.data?.filter((x) => {
          x.value = x._id;
          x.label = `${x.name}`;
        });
        setListOfCities(response.data.result.data);
      })
      .catch((err) => {
        console.log(err);
        setListOfCities([]);
      });
  };

  useEffect(() => {
    showParticipateButton && getAllCities("");
  }, [showParticipateButton]);

  useEffect(() => {
    currentUser && setCitySearchTxt(currentUser?.city);
  }, [currentUser]);
  return (
    <div className="form-group w-100 position-relative">
      {!citySearchTxt&&<label
        htmlFor="city"
        className={`popup-lable ${isDarkMode ? "darkText" : ""}`}
      >
        City
        {isMandate ? (
          <span style={{ color: "#f44d5e", verticalAlign: "top" }}>*</span>
        ) : (
          <></>
        )}
      </label>}

      <input
        type="text"
        value={citySearchTxt}
        onChange={(e) => {
          setCitySearchTxt(e.target.value);
          optimizedFn(e.target.value);
        }}
        placeholder={placeholderValue?placeholderValue:""}
        id="city"
        name="city"
        className="form-control"
      />
      {showCitiesDropdown && (
        <div className="cityDropdown" ref={wrapperRef}>
          {listOfCities.map((city,index) => (
            <p
              key={`city${index}`}
              onClick={() => {
                setCity(city);
                setShowCitiesDropdown(false);
                setCitySearchTxt(city.name);
              }}
            >
              {city?.name}, {city?.state_name}, {city?.country_name}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default CityDropdown;
