import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Helmet } from "react-helmet";
import backArrowImg from "assets/img/DiscussionSite/Arrow-1.svg";
import commentsRedImg from "assets/img/DiscussionSite/comment-red.svg";
import goldStar from "assets/img/DiscussionSite/goldStar.svg";
import greyStar from "assets/img/DiscussionSite/greyStar.svg";
import likeImg from "assets/img/DiscussionSite/like.svg";
import dislikeGreyImg from "assets/img/DiscussionSite/grey-dislike.svg";
import redLikeImg from "assets/img/DiscussionSite/red-like.svg";
import darkLikeImg from "assets/img/likeDark.svg";
import darkDislikeImg from "assets/img/DiscussionSite/darkDislike.svg";
import replyImg from "assets/img/DiscussionSite/reply.svg";
import copyLinkImg from "assets/img/DiscussionSite/copy-link.svg";
import axios from "axios";
import darkGoldstar from "../../../assets/img/bookmarked-dark.svg";
import darkstar from "../../../assets/img/bookmark-dark.svg";
import { API } from "backend";
import darkEdit from "../../../assets/img/dark-edit-icon.svg";
import darkDelete from "../../../assets/img/dark-delete-icon.svg";
import darkLikeActive from "../../../assets/img/likeDark.svg";
import greylikeImg from "assets/img/DiscussionSite/grey-like-icon.svg";
import dislikeImg from "assets/img/DiscussionSite/Dislike.svg";
import darkCommentIcon from "assets/img/DiscussionSite/commentDark.svg";
import { isAuthenticated } from "auth/helper";
import EditIcon from "assets/img/DiscussionSite/edit-icon.svg";
import DeletedIcon from "assets/img/DiscussionSite/deleted-icon.svg";
import DeleteIcon from "assets/img/DiscussionSite/delete-icon.svg";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import WarningIcon from "assets/img/DiscussionSite/warning-icon.svg";
import LoginPageNew from "views/LoginPage/LoginPageNew";
import { likeDiscussion } from "shared/FirebaseEventMethods";
import ProfileCard from "components/ProfileCard/ProfileCard";
import { useSelector } from "react-redux";
import { motion } from "framer-motion/dist/framer-motion";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const moment = require("moment");
const editorConfiguration = {
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "codeBlock",
    "insertImage",
  ],
  placeholder: "Please be informative and helpful to the community!",
  mediaEmbed: {
    previewsInData: true,
  },
};
const formatDate = (dateTime) => {
  if (dateTime) {
    var string = dateTime;

    var temp = string.split(" ");
    var strdate = temp[0].split("/");
    var strTime = temp[1].split(":");

    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];

    var hour = strTime[0];
    var minute = strTime[1];
    var seconds = strTime[2];

    var differenceFromNow = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
      hour: Number(hour),
      minutes: Number(minute),
      seconds: Number(seconds),
    }).fromNow();

    return differenceFromNow;
  }
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SubSingleDiscussion = ({ props }) => {
  const displayMode = useSelector((store) => store.display.display_mode);
  const [discussionDetails, setDiscussionDetails] = useState({});
  const [mostVoted, setMostVoted] = useState([]);
  const [mostReplies, setMostReplies] = useState([]);
  const [isDarkMode, setIsDarkMode] = useState();
  const [mostViewedTopics, setMostViewedTopics] = useState([]);
  const [comment, setComment] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [isEdit, setIsEdit] = useState("");
  const [title, setTitle] = useState("");
  const [scroll, setScroll] = useState("paper");
  const [open, setOpen] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [firstcomment, setFirstComment] = useState("");
  const [DeleteType, setDeleteType] = useState("");
  const [DeleteData, setDeleteData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTopics, setIsLoadingTopics] = useState(true);
  const [isLoadingReplies, setIsLoadingReplies] = useState(true);
  const [isLoadingVotes, setIsLoadingVotes] = useState(true);
  const [copied, setCopied] = useState("");
  const [commentId, setCommentId] = useState(false);
  const [isHovering, setIsHovering] = useState("");
  const history = useHistory();
  const location = useLocation();
  let query = useQuery();
  const goToDiscussion = () => {
    history.push("/discussions");
  };
  const getDiscussionDetails = async () => {
    let paramData = {};
    isAuthenticated()?.user?._id &&
      (paramData.user_id = isAuthenticated().user._id);
    await axios
      .get(`${API}/discussions/topic/${props.DiscussionId}`, {
        params: paramData,
      })
      .then((response) => {
        setIsLoading(false);
        setDiscussionDetails(response.data.topic);
        setTitle(response.data.topic.Topic);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const updateTopicViews = async () => {
    await axios
      .put(`${API}/discussions/update_views/${props.DiscussionId}`)
      .then((response) => {
        // console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const likeDisLikeAction = (
    data,
    like,
    dislike,
    discussionType,
    commentDetails
  ) => {
    if (isAuthenticated()) {
      let paramData = {};
      isAuthenticated()?.user?._id &&
        (paramData.votedby = isAuthenticated().user._id);
      paramData.upvote = like ? 1 : 0;
      paramData.downvote = dislike ? 1 : 0;
      paramData._id = data._id;
      paramData.type =
        discussionType === "reply_hierarchy" ? "reply" : discussionType;
      likeDiscussion();
      axios
        .put(`${API}/discussions/vote`, paramData)
        .then((response) => {
          if (response.status === 200) {
            discussionType === "topic"
              ? setDiscussionDetails((prevState) => ({
                  ...prevState,
                  upvote: like ? 1 : 0,
                  downvote: like ? 0 : 1,
                  UpVotedby: getUpdatedby(prevState.UpVotedby, like),
                  DownVotedby: getDownvotedby(prevState.DownVotedby, like),
                }))
              : discussionType === "comment"
              ? setDiscussionDetails((prevState) => ({
                  ...prevState,
                  comment_heirarchy: prevState.comment_heirarchy.filter((x) => {
                    if (x._id === data._id) {
                      x.upvote = like ? 1 : 0;
                      x.downvote = like ? 0 : 1;
                      x.UpVotedby = getUpdatedby(x.UpVotedby, like);
                      x.DownVotedby = getDownvotedby(x.DownVotedby, like);
                    }
                    return prevState.comment_heirarchy;
                  }),
                }))
              : discussionType === "reply"
              ? setDiscussionDetails((prevState) => ({
                  ...prevState,
                  comment_heirarchy: prevState.comment_heirarchy.filter((x) => {
                    if (x._id === commentDetails._id) {
                      x.replies_heirarchy.filter((y) => {
                        if (y._id === data._id) {
                          y.upvote = like ? 1 : 0;
                          y.downvote = like ? 0 : 1;
                          y.UpVotedby = getUpdatedby(y.UpVotedby, like);
                          y.DownVotedby = getDownvotedby(y.DownVotedby, like);
                        }
                      });
                    }
                    return prevState.comment_heirarchy;
                  }),
                }))
              : getDiscussionDetails();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };
  const getUpdatedby = (data, like) => {
    const user_id = JSON.parse(localStorage.getItem("jwt"))?.user?._id;
    if (like) {
      if (data?.length !== 0) {
        if (data?.includes(user_id)) {
          return data;
        } else {
          data.push(user_id);
          return data;
        }
      } else {
        data.push(user_id);
        return data;
      }
    } else {
      if (data?.length !== 0) {
        if (data?.includes(user_id)) {
          data.pop(user_id);
          return data;
        }
      } else {
        return data;
      }
    }
  };
  const getDownvotedby = (data, like) => {
    const user_id = JSON.parse(localStorage.getItem("jwt"))?.user?._id;
    if (like) {
      if (data?.includes(user_id)) {
        data.pop(user_id);
        return data;
      } else {
        return data;
      }
    } else {
      if (data.length !== 0) {
        if (data?.includes(user_id)) {
          return data;
        } else {
          data.push(user_id);
          return data;
        }
      } else {
        data.push(user_id);
        return data;
      }
    }
  };

  const bookMarkDiscussions = (
    data,
    discussionType,
    commentDetails,
    replyId
  ) => {
    if (isAuthenticated()) {
      let paramData = {};
      isAuthenticated()?.user?._id &&
        (paramData.user_id = isAuthenticated().user._id);
      paramData.type =
        discussionType === "reply_hierarchy" ? "reply" : discussionType;
      bookMarkDiscussions();
      axios
        .put(`${API}/discussions/update_bookmark/${data._id}`, paramData)
        .then((response) => {
          discussionType === "topic"
            ? setDiscussionDetails((prev) => ({
                ...prev,
                bookmark: discussionDetails.bookmark === 1 ? 0 : 1,
              }))
            : discussionType === "comment"
            ? setDiscussionDetails((prev) => ({
                ...prev,
                comment_heirarchy: prev.comment_heirarchy.filter((x) => {
                  if (x._id === data._id) {
                    x.bookmark === 1 ? (x.bookmark = 0) : (x.bookmark = 1);
                  }
                  return prev.comment_heirarchy;
                }),
              }))
            : discussionType === "reply"
            ? setDiscussionDetails((prev) => ({
                ...prev,
                comment_heirarchy: prev.comment_heirarchy.filter((x) => {
                  if (x._id === commentDetails._id) {
                    x.replies_heirarchy.filter((y) => {
                      if (y._id === data._id) {
                        y.bookmark === 1 ? (y.bookmark = 0) : (y.bookmark = 1);
                      }
                    });
                  }
                  return prev.comment_heirarchy;
                }),
              }))
            : getDiscussionDetails();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };
  const someFunc = (data, id) => {
    Object.entries(data[0]).map(([key, value]) => {
      while (value[0] === "None") {
        value[0] && someFunc(value[0]);
      }
      if (value[1]) {
        if (value[1]._id === id) {
          value[1].bookmark === 1
            ? (value[1].bookmark = 0)
            : (value[1].bookmark = 1);
        }
      }
    });
  };
  const createComment = (topic_data) => {
    if (isAuthenticated()) {
      let paramData = {};
      paramData.topic_id = topic_data._id;
      paramData.comment = firstcomment;
      paramData.postedby = isAuthenticated().user._id;
      axios
        .post(`${API}/discussions/create_comment`, paramData)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            console.log(response);
            setComment("");
            setFirstComment("");
            getDiscussionDetails();
          }
        });
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };
  const updateComment = (topic_data) => {
    let paramData = {};
    paramData.comment_id = topic_data._id;
    paramData.comment = comment;
    paramData.postedby = isAuthenticated().user._id;
    axios
      .put(`${API}/discussions/update_comment`, paramData)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log(response);
          setComment("");
          getDiscussionDetails();
          setIsEdit("");
          setReplyComment("");
        }
      });
  };
  const createReply = (data, parent) => {
    if (isAuthenticated()) {
      let paramData = {};
      paramData.reply = comment;
      paramData.comment_id = parent ? parent._id : data._id;
      paramData.level = data.Level + 1;
      paramData.postedby = isAuthenticated().user._id;
      paramData.parent_reply_id = parent && data._id;
      axios
        .post(`${API}/discussions/create_reply`, paramData)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
          } else {
            console.log(response);
            setComment("");
            getDiscussionDetails();
            setReplyComment("");
          }
        });
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };
  const updateReply = (data) => {
    let paramData = {};
    paramData.reply = comment;
    paramData.reply_id = data._id;
    paramData.postedby = isAuthenticated().user._id;
    axios.put(`${API}/discussions/update_reply`, paramData).then((response) => {
      if (response.data.error) {
        console.log(response.data.error);
      } else {
        console.log(response);
        setComment("");
        getDiscussionDetails();
        setReplyComment("");
        setIsEdit("");
      }
    });
  };
  const updateTopic = () => {
    let paramData = {};
    paramData.topic_id = discussionDetails._id;
    paramData.topic = title;
    paramData.text = comment;
    paramData.postedby = isAuthenticated().user._id;
    axios.put(`${API}/discussions/update_topic`, paramData).then((response) => {
      if (response.data.error) {
        console.log(response.data.error);
      } else {
        console.log(response);
        setComment("");
        getDiscussionDetails();
        setReplyComment("");
        setIsEdit("");
        handleClose();
      }
    });
  };
  const onDeleteComments = (data) => {
    let paramData = {};
    paramData.comment_id = data._id;
    paramData.deletedby = isAuthenticated().user._id;
    axios
      .delete(`${API}/discussions/delete_comment`, { params: paramData })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log(response);
          setComment("");
          getDiscussionDetails();
          setReplyComment("");
          setIsEdit("");
          handleClose();
        }
      });
  };
  const onDeleteReplies = (data) => {
    let paramData = {};
    paramData.reply_id = data._id;
    paramData.deletedby = isAuthenticated().user._id;
    axios
      .delete(`${API}/discussions/delete_reply`, { params: paramData })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          console.log(response);
          setComment("");
          getDiscussionDetails();
          setReplyComment("");
          setIsEdit("");
        }
      });
  };
  const onDeleteTopic = (data) => {
    let paramData = {};
    paramData.topic_id = data._id;
    paramData.deletedby = isAuthenticated().user._id;
    axios
      .delete(`${API}/discussions/delete_topic`, { params: paramData })
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          history.push(`/discussions`);
        }
      });
  };
  const getMostVotes = () => {
    let paramData = {};
    isAuthenticated()?.user?._id &&
      (paramData.user_id = isAuthenticated().user._id);
    paramData.page = 1;
    axios
      .get(`${API}/discussions/most_voted`, {
        params: paramData,
      })
      .then((response) => {
        setMostVoted(response.data.message.slice(0, 5));
        setIsLoadingVotes(false);
      })
      .catch((err) => {
        setIsLoadingVotes(false);
        console.log(err);
      });
  };
  const getMostReplies = () => {
    let paramData = {};
    isAuthenticated()?.user?._id &&
      (paramData.user_id = isAuthenticated().user._id);
    paramData.page = 1;
    axios
      .get(`${API}/discussions/most_replied`, {
        params: paramData,
      })
      .then((response) => {
        setIsLoadingReplies(false);
        setMostReplies(response.data.message.slice(0, 5));
      })
      .catch((err) => {
        setIsLoadingReplies(false);
        console.log(err);
      });
  };
  const getRecentReviewedTopics = () => {
    let paramData = {};
    isAuthenticated()?.user?._id &&
      (paramData.user_id = isAuthenticated().user._id);
    paramData.page = 1;
    axios
      .get(`${API}/discussions/most_viewed`, {
        params: paramData,
      })
      .then((response) => {
        setIsLoadingTopics(false);
        setMostViewedTopics(response.data.message.slice(0, 5));
      })
      .catch((err) => {
        setIsLoadingTopics(false);
        console.log(err);
      });
  };
  const onViewMoreChange = (discussionType) => {
    if (isAuthenticated()) {
      history.push(`/discussions/${discussionType}/${props.DiscussionId}`);
    } else {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    }
  };
  const redirectToUserProfile = (userId) => {
    localStorage.setItem("user_profile_prev", window.location.pathname);
    history.push(`/user/${userId}`);
  };
  const onEditChange = (data) => {
    if (data._id === replyComment) {
      setReplyComment("");
      setIsEdit("");
    } else {
      setIsEdit(data._id);
      setComment(data.Comment ? data.Comment : data.Reply);
      setReplyComment(data._id);
    }
  };
  const onReplyCilck = (dataId) => {
    setIsEdit("");
    setComment("");
    if (dataId === replyComment) {
      setReplyComment("");
    } else {
      setReplyComment(dataId);
    }
  };

  const handleClose = () => {
    setTitle("");
    setComment("");
    setOpen(false);
    setOpenConfirmDeleteDialog(false);
  };

  const hackathonRedirect = (e, data) => {
    e.stopPropagation();
    data.Cateogry.toLowerCase() === "hackathon" &&
      history.push(`/hackathons/${data.SubCategory}/overview`);
    data.Cateogry.toLowerCase() === "practice" &&
      axios
        .get(`${API}/practice/${data.SubCategory}`)
        .then((response) => {
          history.push(
            `/practices/${response.data.module_slug}/${data.SubCategory}`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    data.Cateogry.toLowerCase() === "bootcamp" &&
      axios
        .get(`${API}/discussions/bootcamp/${data.SubCategory}`)
        .then((response) => {
          history.push(`/bootcamps/${response.data.bootcamp._id}`);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const openDeleteConfirmationModal = (isDeleteTopic, deleteData) => {
    setOpenConfirmDeleteDialog(true);
    setDeleteType(isDeleteTopic);
    setDeleteData(deleteData);
  };

  const goback = () => {};

  const openEditTopicModal = () => {
    setComment(discussionDetails.Text);
    setOpen(true);
  };
  const copyLink = (data, type) => {
    setCopied(data._id);
    navigator.clipboard.writeText(
      `${window.location.origin}/discussions/${props.DiscussionId}?${
        type === "comment" ? `commentId=${data._id}` : `replyId=${data._id}`
      }`
    );
    console.log(
      `${window.location.origin}/discussions/${props.DiscussionId}?${
        type === "comment" ? `commentId=${data._id}` : `replyId=${data._id}`
      }`
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const queryString = query.get("commentId")
      ? query.get("commentId")
      : query.get("replyId");
    setCommentId(queryString);
    query.get("commentId") && setCopied(false);
    setIsLoading(true);
    updateTopicViews();
    getDiscussionDetails();
    getMostVotes();
    getMostReplies();
    getRecentReviewedTopics();
  }, [props.DiscussionId]);

  useEffect(() => {
    setIsDarkMode(displayMode === "dark_mode");
  }, [displayMode]);

  return (
    <>
      <Helmet>
        <style>{`body { background-color: ${
          isDarkMode ? "#000726" : "#ffffff"
        } !important; }`}</style>
      </Helmet>
      <div
        className={`container-fluid mt-4 SDfix ${
          isDarkMode ? "darkTheme" : ""
        }`}
      >
        <div
          className={`row ${isMobile ? "" : "pl-5 pt-5"} ${
            isDarkMode ? "dark-bg" : ""
          }`}
        >
          <div className={`${isMobile ? "col-12" : "col-9"}`}>
            {isLoading ? (
              <>
                <div className="pt-3">
                  <Skeleton
                    containerClassName="my-2"
                    height="20px"
                    width="20%"
                    borderRadius="50px"
                  />
                  <hr
                    tyle={{
                      width: "100%",
                      marginTop: "1px",
                      marginRight: "5px",
                      backgroundColor: "#f3f3f3",
                      color: "#f3f3f3",
                    }}
                  />
                </div>
                <div className="row px-3">
                  <Skeleton
                    containerClassName="my-2 col-1"
                    height="60px"
                    width="60px"
                    borderRadius="50px"
                  />
                  <div className="col-md-7 discussion-detail pl-1 pr-1">
                    <Skeleton
                      containerClassName="my-2"
                      height="20px"
                      width="20%"
                      borderRadius="50px"
                    />
                    <p className="discussion-text my-1">
                      <Skeleton
                        containerClassName="my-2"
                        height="15px"
                        width="100%"
                        borderRadius="50px"
                      />
                    </p>
                    <div className="row pl-3 mt-2">
                      <p className="basic-discussion-info">
                        <Skeleton
                          containerClassName="my-2"
                          height="25px"
                          width="70px"
                          borderRadius="50px"
                        />
                      </p>
                      <p className="discseperator ml-2 mr-2 my-2">|</p>
                      <p className="basic-discussion-info">
                        <Skeleton
                          containerClassName="my-2"
                          height="25px"
                          width="70px"
                          borderRadius="50px"
                        />
                      </p>
                      <p className="discseperator ml-2 mr-2 my-2">|</p>
                      <p className="basic-discussion-info">
                        <Skeleton
                          containerClassName="my-2"
                          height="25px"
                          width="70px"
                          borderRadius="50px"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2 offset-2">
                    <div className="row justify-content-end">
                      <div className="col-12 text-right">
                        <p className="basic-discussion-info d-flex px-0 justify-content-end mb-0">
                          <Skeleton
                            containerClassName="my-2 mr-3"
                            height="30px"
                            width="30px"
                            borderRadius="50px"
                          />
                          <Skeleton
                            containerClassName="my-2"
                            height="30px"
                            width="70px"
                            borderRadius="50px"
                          />
                        </p>
                      </div>

                      <div className="col-12">
                        <div className="d-flex">
                          <Skeleton
                            containerClassName="mr-2"
                            height="40px"
                            width="40px"
                            borderRadius="5px"
                          />
                          <Skeleton
                            containerClassName="mr-2"
                            height="40px"
                            width="40px"
                            borderRadius="5px"
                          />
                          <Skeleton
                            containerClassName=""
                            height="40px"
                            width="40px"
                            borderRadius="5px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={`row pl-3 ${isMobile ? "mt-3" : ""}`}>
                  <motion.img
                    whileHover={{
                      x: -5,
                      transition: { duration: 0.3 },
                    }}
                    className={`ml-1 ${isMobile ? "col-1 px-0 mx-0" : ""} ${
                      isDarkMode ? "colorInvert" : ""
                    }`}
                    src={backArrowImg}
                    style={{ height: "20px", cursor: "pointer", width: "30px" }}
                    onClick={(e) => goToDiscussion()}
                    alt="arrow"
                    loading="lazy"
                  />
                  <p
                    className={`discussion-title ml-4 mb-0 ${
                      isMobile ? "col-10 px-0 mx-0" : ""
                    } ${isDarkMode ? "whiteText" : ""}`}
                  >
                    {discussionDetails.Topic}
                  </p>
                </div>
                <hr
                  tyle={{
                    width: "100%",
                    marginTop: "1px",
                    marginRight: "5px",
                    backgroundColor: isDarkMode ? "" : "#f3f3f3",
                    color: isDarkMode ? "" : "#f3f3f3",
                  }}
                />
                <div className="row" onMouseLeave={() => setIsHovering("")}>
                  <div
                    className={`${
                      isMobile ? "col-2 pr-0" : "col-1"
                    } position-relative singleDiscussionImgHover`}
                  >
                    <motion.img
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true }}
                      transition={{
                        type: "spring",
                        stiffness: 120,
                        duration: 0.4,
                      }}
                      variants={{
                        visible: { opacity: 1, scale: 1 },
                        hidden: { opacity: 0, scale: 0 },
                      }}
                      src={discussionDetails.UserPicture}
                      className={`img-circle ${isMobile ? "" : "ml-4"}`}
                      onClick={() =>
                        redirectToUserProfile(discussionDetails.Postedby)
                      }
                      alt="user-pic"
                      loading="lazy"
                      onMouseOver={() => {
                        setIsHovering(discussionDetails?._id);
                      }}
                    />
                    {isHovering === discussionDetails?._id && (
                      <ProfileCard
                        userId={discussionDetails?.Postedby}
                        setIsHovering={setIsHovering}
                      />
                    )}
                  </div>
                  <div className={`${isMobile ? "col-10" : "col-11"}`}>
                    <div
                      className={`row align-items-center position-relative singleDiscussionTextHover ${
                        isMobile ? "px-3" : "pl-4 pr-5"
                      }`}
                    >
                      <label
                        className={`black-small-text-bold ${
                          isMobile ? "mb-0" : "mb-0"
                        } ${isDarkMode ? "whiteText" : ""}`}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          redirectToUserProfile(discussionDetails.Postedby)
                        }
                        onMouseOver={() => {
                          setIsHovering(discussionDetails.Postedby);
                        }}
                      >
                        {discussionDetails.Name}
                      </label>
                      {isHovering === discussionDetails.Postedby && (
                        <ProfileCard
                          userId={discussionDetails?.Postedby}
                          setIsHovering={setIsHovering}
                        />
                      )}
                      <label
                        className={`ml-1 ${isMobile ? "mb-0" : "mb-0"}`}
                        style={{ fontSize: "34px", lineHeight: "20px" }}
                      >
                        ·
                      </label>
                      <label
                        className={`grey-text-small mx-1 ${
                          isMobile ? "mb-0" : "mb-0"
                        } ${isDarkMode ? "darkText" : ""}`}
                      >
                        Posted {formatDate(discussionDetails.created)}
                      </label>
                      {discussionDetails.Postedby ===
                        isAuthenticated()?.user?._id && (
                        <span
                          className="ml-1"
                          onClick={() => openEditTopicModal()}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={EditIcon}
                            alt="edit-icon"
                            className="img-fluid mr-1"
                            loading="lazy"
                          />
                        </span>
                      )}
                      {discussionDetails.Postedby ===
                        isAuthenticated()?.user?._id && (
                        <span
                          className="ml-1"
                          onClick={() =>
                            openDeleteConfirmationModal(
                              "topic",
                              discussionDetails
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={DeleteIcon}
                            alt="delete-icon"
                            className="img-fluid mr-1"
                            loading="lazy"
                          />
                        </span>
                      )}
                      <div
                        className={`row ${isMobile ? "mb-3 px-3" : "ml-auto"}`}
                      >
                        <div
                          className={`position-relative mr-4 ${
                            isDarkMode ? "" : "bookmarkBack"
                          }`}
                        >
                          {discussionDetails.bookmark === 1 ? (
                            <motion.img
                              initial="hidden"
                              whileInView="visible"
                              viewport={{ once: true }}
                              transition={{
                                duration: 0.4,
                              }}
                              variants={{
                                visible: {
                                  opacity: 1,
                                  scale: 1,
                                  rotate: 0,
                                },
                                hidden: {
                                  opacity: 0,
                                  scale: 2,
                                  rotate: 180,
                                },
                              }}
                              src={isDarkMode ? darkGoldstar : goldStar}
                              className={`${
                                isDarkMode ? "" : "position-absolute"
                              } bookmarkStar`}
                              onClick={() =>
                                bookMarkDiscussions(discussionDetails, "topic")
                              }
                              style={{ cursor: "pointer" }}
                              alt="bookmark"
                              loading="lazy"
                            />
                          ) : (
                            <img
                              src={isDarkMode ? darkstar : greyStar}
                              className={`${
                                isDarkMode ? "" : "position-absolute"
                              } bookmarkStar`}
                              onClick={() =>
                                bookMarkDiscussions(discussionDetails, "topic")
                              }
                              style={{ cursor: "pointer" }}
                              alt="bookmark"
                              loading="lazy"
                            />
                          )}
                        </div>
                        <div className="like-dislike-div mt-1">
                          <div
                            className={`pl-2 pr-2 pt-1 pb-1 like-div ${
                              discussionDetails.upvote === 1
                                ? isDarkMode
                                  ? ""
                                  : "isSelectedLikeDislike"
                                : ""
                            }`}
                            onClick={() =>
                              likeDisLikeAction(
                                discussionDetails,
                                true,
                                false,
                                "topic"
                              )
                            }
                          >
                            {discussionDetails.upvote === 1 ? (
                              <motion.img
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={{
                                  duration: 0.4,
                                }}
                                variants={{
                                  visible: {
                                    opacity: 1,
                                    scale: 1,
                                    rotate: 0,
                                  },
                                  hidden: {
                                    opacity: 0,
                                    scale: 1.2,
                                    rotate: -45,
                                  },
                                }}
                                src={isDarkMode ? darkLikeImg : likeImg}
                                className="mr-1"
                                alt="like"
                                loading="lazy"
                              />
                            ) : (
                              <img
                                src={greylikeImg}
                                className="mr-1"
                                alt="like"
                                loading="lazy"
                              />
                            )}
                            {discussionDetails?.UpVotedby?.length}
                          </div>

                          <div
                            className={`pl-2 pr-2 pt-1 dislike-div ${
                              discussionDetails.downvote === 1
                                ? isDarkMode
                                  ? ""
                                  : "isSelectedLikeDislike"
                                : ""
                            }`}
                            onClick={() =>
                              likeDisLikeAction(
                                discussionDetails,
                                false,
                                true,
                                "topic"
                              )
                            }
                          >
                            {discussionDetails.downvote === 1 ? (
                              <motion.img
                                initial="hidden"
                                whileInView="visible"
                                viewport={{ once: true }}
                                transition={{
                                  duration: 0.4,
                                }}
                                variants={{
                                  visible: {
                                    opacity: 1,
                                    scale: 1,
                                    rotate: 0,
                                  },
                                  hidden: {
                                    opacity: 0,
                                    scale: 1.2,
                                    rotate: 45,
                                  },
                                }}
                                src={isDarkMode ? darkDislikeImg : dislikeImg}
                                className="mr-1"
                                alt="like"
                                loading="lazy"
                              />
                            ) : (
                              <img
                                src={dislikeGreyImg}
                                className="mr-1"
                                alt="like"
                                loading="lazy"
                              />
                            )}
                            {discussionDetails?.DownVotedby?.length}
                          </div>
                        </div>
                      </div>

                      <div className="row w-100">
                        <div className="col-12">
                          <div
                            className={`paragraph d-flex flex-wrap ${
                              isDarkMode ? "whiteText dangerouslyText" : ""
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: discussionDetails.Text,
                            }}
                          ></div>
                          <div className={`row ${isMobile ? "" : ""} pl-3`}>
                            <Link to={`/user/${discussionDetails.Postedby}`}>
                              <p
                                className={`basic-discussion-info mb-0 DiscussionName ${
                                  isDarkMode ? "darkText" : ""
                                }`}
                                style={{ color: "#000" }}
                              >
                                <u>{discussionDetails.Name}</u>
                              </p>
                            </Link>
                            <p className="discseperator ml-2 mr-2 mb-0">|</p>

                            {discussionDetails.Cateogry !== "General" && (
                              <>
                                <p
                                  className={`basic-discussion-info mb-0 DiscussionHName ${
                                    isDarkMode ? "darkText" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) =>
                                    hackathonRedirect(e, discussionDetails)
                                  }
                                >
                                  {discussionDetails.HName}
                                </p>
                                <p className="discseperator ml-2 mr-2 mb-0">
                                  |
                                </p>
                              </>
                            )}
                            <p
                              className={`basic-discussion-info mb-0 ${
                                isDarkMode ? "darkText" : ""
                              }`}
                            >
                              {discussionDetails?.comment_heirarchy?.length}{" "}
                              Comment
                              {discussionDetails?.comment_heirarchy?.length > 1
                                ? "s"
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <hr
              tyle={{
                width: "100%",
                marginTop: "1px",
                marginRight: "5px",
                backgroundColor: "#f3f3f3",
                color: "#f3f3f3",
              }}
            />
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-9 pl-4">
                <p
                  className={`black-small-text-bold ${
                    isDarkMode ? "whiteText" : ""
                  }`}
                >
                  Description
                </p>
                <CKEditor
                  editor={Editor}
                  config={editorConfiguration}
                  data={firstcomment}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "200px",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setFirstComment(data);
                  }}
                />
                {firstcomment ? (
                  <button
                    className="post-btn-active"
                    onClick={() => createComment(discussionDetails)}
                  >
                    Reply
                  </button>
                ) : (
                  <button className="post-btn">Reply</button>
                )}
              </div>
            </div>

            {/* Child Div */}
            {discussionDetails?.comment_heirarchy?.length > 0 &&
              discussionDetails.comment_heirarchy.map((comments) => (
                <div className="row" key={comments._id}>
                  <div className="col-md-1"></div>
                  <div className="col-md-11 pl-4">
                    <div
                      className="row pt-4"
                      onMouseLeave={() => setIsHovering("")}
                    >
                      <div
                        className={`text-center position-relative userDiscussingDiscussDEtailsImgHover ${
                          isMobile ? "col-2" : "col-1"
                        }`}
                      >
                        <img
                          src={comments.UserPicture}
                          className="img-circle-reply"
                          onClick={() =>
                            redirectToUserProfile(comments.Postedby)
                          }
                          alt="reply"
                          loading="lazy"
                          onMouseEnter={() => setIsHovering(comments._id)}
                        />
                        {isHovering === comments._id && (
                          <ProfileCard
                            userId={comments.Postedby}
                            setIsHovering={setIsHovering}
                          />
                        )}
                        {/* <div className="commentLine"></div> */}
                      </div>
                      <div className={`${isMobile ? "col-10" : "col-11"}`}>
                        <div
                          className={`row align-items-center mb-1 position-relative userDiscussingDiscussDEtailsTextHover ${
                            isMobile ? "pt-0 pl-3" : ""
                          }`}
                        >
                          <label
                            className={`black-small-text-bold mb-0 ${
                              isDarkMode ? "whiteText" : ""
                            }`}
                            onClick={() =>
                              redirectToUserProfile(comments.Postedby)
                            }
                            onMouseEnter={() =>
                              setIsHovering(comments?.TopicId + comments._id)
                            }
                          >
                            {comments.Name}
                          </label>
                          {isHovering === comments?.TopicId + comments._id && (
                            <ProfileCard
                              userId={comments.Postedby}
                              setIsHovering={setIsHovering}
                            />
                          )}
                          <label
                            className="ml-1 mb-0"
                            style={{ fontSize: "34px", lineHeight: "20px" }}
                          >
                            ·
                          </label>
                          <label
                            className={`grey-text-small mx-1 mb-0 ${
                              isDarkMode ? "darkText" : ""
                            }`}
                          >
                            Posted {formatDate(comments.created)}
                          </label>
                          {comments.Postedby ===
                            isAuthenticated()?.user?._id && (
                            <span
                              className="ml-1"
                              onClick={() => onEditChange(comments)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={isDarkMode ? darkEdit : EditIcon}
                                alt="edit-icon"
                                className="img-fluid mr-1"
                                loading="lazy"
                              />
                            </span>
                          )}
                          {comments.Postedby ===
                            isAuthenticated()?.user?._id && (
                            <span
                              className="ml-1"
                              onClick={() =>
                                openDeleteConfirmationModal(
                                  "comments",
                                  comments
                                )
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={isDarkMode ? darkDelete : DeleteIcon}
                                alt="delete-icon"
                                className="img-fluid mr-1"
                                loading="lazy"
                              />
                            </span>
                          )}
                        </div>
                        <div
                          className={`${isDarkMode ? "" : ""} ${
                            commentId === comments._id ? "commentHighlight" : ""
                          }`}
                        >
                          <div className="row">
                            <div
                              className={`col-md-8 pl-0 paragraph ${
                                isMobile ? "pl-3" : ""
                              } ${isDarkMode ? "dangerouslyText" : ""}`}
                              dangerouslySetInnerHTML={{
                                __html: comments.Comment,
                              }}
                            ></div>
                          </div>
                          <div className="row commenterFeatureSection">
                            <div
                              className={`like-dislike-div ${
                                isMobile ? "pl-3" : ""
                              }`}
                            >
                              <div
                                className={`pl-2 pr-2 pt-1 pb-1 like-div ${
                                  comments?.upvote === 1
                                    ? isDarkMode
                                      ? ""
                                      : "isSelectedLikeDislike"
                                    : ""
                                }`}
                                onClick={() =>
                                  likeDisLikeAction(
                                    comments,
                                    true,
                                    false,
                                    "comment"
                                  )
                                }
                              >
                                {comments?.upvote === 1 ? (
                                  <motion.img
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    transition={{
                                      duration: 0.4,
                                    }}
                                    variants={{
                                      visible: {
                                        opacity: 1,
                                        scale: 1,
                                        rotate: 0,
                                      },
                                      hidden: {
                                        opacity: 0,
                                        scale: 1.2,
                                        rotate: 45,
                                      },
                                    }}
                                    src={isDarkMode ? darkLikeImg : likeImg}
                                    className="mr-1"
                                    alt="like"
                                    loading="lazy"
                                  />
                                ) : (
                                  <img
                                    src={greylikeImg}
                                    className="mr-1"
                                    alt="like"
                                    loading="lazy"
                                  />
                                )}

                                {comments?.UpVotedby?.length}
                              </div>

                              <div
                                className={`pl-2 pr-2 pt-1 dislike-div ${
                                  comments?.downvote === 1
                                    ? isDarkMode
                                      ? ""
                                      : "isSelectedLikeDislike"
                                    : ""
                                }`}
                                onClick={() =>
                                  likeDisLikeAction(
                                    comments,
                                    false,
                                    true,
                                    "comment"
                                  )
                                }
                              >
                                {" "}
                                {comments?.downvote === 1 ? (
                                  <motion.img
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    transition={{
                                      duration: 0.4,
                                    }}
                                    variants={{
                                      visible: {
                                        opacity: 1,
                                        scale: 1,
                                        rotate: 0,
                                      },
                                      hidden: {
                                        opacity: 0,
                                        scale: 1.2,
                                        rotate: 45,
                                      },
                                    }}
                                    src={
                                      isDarkMode ? darkDislikeImg : dislikeImg
                                    }
                                    className="mr-1"
                                    alt="like"
                                    loading="lazy"
                                  />
                                ) : (
                                  <img
                                    src={dislikeGreyImg}
                                    className="mr-1"
                                    alt="like"
                                    loading="lazy"
                                  />
                                )}
                                {comments.DownVotedby?.length}
                              </div>
                            </div>
                            <div
                              className={`pl-2 pt-1 ${
                                isDarkMode ? "darkText" : ""
                              }`}
                              onClick={() => onReplyCilck(comments._id)}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={replyImg}
                                className="ml-4 mt-1 mr-2"
                                alt="reply"
                                loading="lazy"
                              />
                              <span
                                className={`discusSubText ${
                                  isDarkMode ? "darkText" : ""
                                }`}
                              >
                                {isMobile ? "" : "Reply"}
                              </span>
                            </div>
                            <div
                              className={`pl-2 pt-1 cursor-pointer ${
                                isDarkMode ? "darkText" : ""
                              }`}
                              style={{ cursor: "pointer" }}
                              onClick={() => copyLink(comments, "comment")}
                            >
                              <img
                                src={copyLinkImg}
                                className="ml-3 mt-1 mr-2"
                                alt="copy"
                                loading="lazy"
                              />
                              <span
                                className={`discusSubText ${
                                  isDarkMode ? "darkText" : ""
                                }`}
                              >
                                {isMobile
                                  ? ""
                                  : copied !== comments._id
                                  ? "Copy Link"
                                  : "Copied!"}
                              </span>
                            </div>
                            <div
                              className={`pl-2 row align-items-center ${
                                isDarkMode ? "darkText" : ""
                              }`}
                            >
                              <div
                                className={`position-relative ml-3 mr-2 ${
                                  isDarkMode ? "" : "bookmarkBack"
                                }`}
                              >
                                {comments.bookmark === 1 ? (
                                  <motion.img
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true }}
                                    transition={{
                                      duration: 0.4,
                                    }}
                                    variants={{
                                      visible: {
                                        opacity: 1,
                                        scale: 1,
                                        rotate: 0,
                                      },
                                      hidden: {
                                        opacity: 0,
                                        scale: 2,
                                        rotate: 180,
                                      },
                                    }}
                                    src={isDarkMode ? darkGoldstar : goldStar}
                                    className={`${
                                      isDarkMode ? "" : "position-absolute"
                                    } bookmarkStar`}
                                    onClick={() =>
                                      bookMarkDiscussions(comments, "comment")
                                    }
                                    style={{ cursor: "pointer" }}
                                    alt="bookmark"
                                    loading="lazy"
                                  />
                                ) : (
                                  <img
                                    src={isDarkMode ? darkstar : greyStar}
                                    className={`${
                                      isDarkMode ? "" : "position-absolute"
                                    } bookmarkStar`}
                                    onClick={() =>
                                      bookMarkDiscussions(comments, "comment")
                                    }
                                    style={{ cursor: "pointer" }}
                                    alt="bookmark"
                                    loading="lazy"
                                  />
                                )}
                              </div>
                              <span
                                className={`discusSubText ${
                                  isDarkMode ? "darkText" : ""
                                }`}
                              >
                                {isMobile ? "" : "Bookmark"}
                              </span>
                            </div>
                          </div>
                        </div>

                        {comments._id === replyComment && (
                          <Ckeditor
                            setComment={(value) => setComment(value)}
                            createReply={
                              comments._id === isEdit
                                ? updateComment
                                : createReply
                            }
                            data={comments}
                            comment={comment}
                          />
                        )}

                        {/* Reply Child Div */}

                        {comments?.replies_heirarchy?.length > 0 &&
                          comments.replies_heirarchy.map(
                            (replies_heirarchy) => (
                              <div
                                className="col-md-12 p-0"
                                key={replies_heirarchy._id}
                              >
                                <div
                                  className="row mt-4"
                                  onMouseLeave={() => setIsHovering("")}
                                >
                                  {replies_heirarchy.Delete && (
                                    <div className="col-md-1 px-0 text-center">
                                      <p
                                        className={`mb-0 deleted-user-hld ${
                                          isDarkMode ? "darkCapsule" : ""
                                        }`}
                                      >
                                        <img
                                          src={DeletedIcon}
                                          alt="deleted-user"
                                          className={`img-fluid mr-1 ${
                                            isDarkMode ? "colorInvert" : ""
                                          }`}
                                          loading="lazy"
                                        />
                                        <span
                                          className={`deletedtxt mr-1 ${
                                            isDarkMode ? "darkText" : ""
                                          }`}
                                        >
                                          Deleted
                                        </span>
                                        <span
                                          className={
                                            isDarkMode ? "darkText" : ""
                                          }
                                        >
                                          The user deleted his reply
                                        </span>
                                      </p>
                                      <div
                                        className="commentLine"
                                        style={{ height: "50px" }}
                                      ></div>
                                    </div>
                                  )}
                                  {!replies_heirarchy.Delete && (
                                    <div
                                      className={`px-0 text-center position-relative userDiscussingDiscussDEtailsImgHover ${
                                        isMobile ? "col-2" : "col-1"
                                      }`}
                                    >
                                      <img
                                        src={replies_heirarchy.UserPicture}
                                        className="img-circle-reply"
                                        onClick={() =>
                                          redirectToUserProfile(
                                            replies_heirarchy.Postedby
                                          )
                                        }
                                        onMouseEnter={() =>
                                          setIsHovering(replies_heirarchy._id)
                                        }
                                        alt="user-pic"
                                        loading="lazy"
                                      />
                                      {isHovering === replies_heirarchy._id && (
                                        <ProfileCard
                                          userId={replies_heirarchy.Postedby}
                                          setIsHovering={setIsHovering}
                                        />
                                      )}
                                      <div className="commentLine"></div>
                                    </div>
                                  )}
                                  <div
                                    className={`${
                                      isMobile ? "col-10" : "col-11"
                                    }`}
                                  >
                                    {!replies_heirarchy.Delete && (
                                      <div
                                        className={`row align-items-center mb-1 position-relative userDiscussingDiscussDEtailsTextHover`}
                                      >
                                        <label
                                          className={`black-small-text-bold mb-0 ${
                                            isDarkMode ? "whiteText" : ""
                                          }`}
                                          onClick={() =>
                                            redirectToUserProfile(
                                              replies_heirarchy.Postedby
                                            )
                                          }
                                          onMouseEnter={() =>
                                            setIsHovering(
                                              replies_heirarchy._id +
                                                replies_heirarchy.Postedby
                                            )
                                          }
                                        >
                                          {replies_heirarchy.Name}
                                        </label>
                                        {isHovering ===
                                          replies_heirarchy._id +
                                            replies_heirarchy.Postedby && (
                                          <ProfileCard
                                            userId={replies_heirarchy.Postedby}
                                            setIsHovering={setIsHovering}
                                          />
                                        )}
                                        <label
                                          className="ml-1 mb-0"
                                          style={{
                                            fontSize: "34px",
                                            lineHeight: "20px",
                                          }}
                                        >
                                          ·
                                        </label>
                                        <label
                                          className={`grey-text-small mx-1 mb-0 ${
                                            isDarkMode ? "darkText" : ""
                                          }`}
                                        >
                                          Posted{" "}
                                          {formatDate(
                                            replies_heirarchy.created
                                          )}
                                        </label>
                                        {replies_heirarchy.Postedby ===
                                          isAuthenticated()?.user?._id && (
                                          <span
                                            className="ml-1"
                                            onClick={() =>
                                              onEditChange(replies_heirarchy)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img
                                              src={
                                                isDarkMode ? darkEdit : EditIcon
                                              }
                                              alt="edit-icon"
                                              className="img-fluid mr-1"
                                              loading="lazy"
                                            />
                                          </span>
                                        )}
                                        {replies_heirarchy.Postedby ===
                                          isAuthenticated()?.user?._id && (
                                          <span
                                            className="ml-1"
                                            onClick={() =>
                                              onDeleteReplies(replies_heirarchy)
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img
                                              src={
                                                isDarkMode
                                                  ? darkDelete
                                                  : DeleteIcon
                                              }
                                              alt="delete-icon"
                                              className="img-fluid mr-1"
                                              loading="lazy"
                                            />
                                          </span>
                                        )}
                                      </div>
                                    )}
                                    <div
                                      className={`${
                                        commentId === replies_heirarchy._id
                                          ? "commentHighlight"
                                          : ""
                                      }`}
                                    >
                                      {!replies_heirarchy.Delete && (
                                        <div className="row pr-2">
                                          <div
                                            className={`col-md-8 pl-0 paragraph ${
                                              isDarkMode ? "darkText" : ""
                                            }`}
                                            dangerouslySetInnerHTML={{
                                              __html: replies_heirarchy.Reply,
                                            }}
                                          ></div>
                                        </div>
                                      )}
                                      {!replies_heirarchy.Delete && (
                                        <div className="row commenterFeatureSection">
                                          <div className="like-dislike-div">
                                            <div
                                              className={`pl-2 pr-2 pt-1 pb-1 like-div ${
                                                replies_heirarchy?.upvote === 1
                                                  ? isDarkMode
                                                    ? ""
                                                    : "isSelectedLikeDislike"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                likeDisLikeAction(
                                                  replies_heirarchy,
                                                  true,
                                                  false,
                                                  "reply",
                                                  comments
                                                )
                                              }
                                            >
                                              {replies_heirarchy?.upvote ===
                                              1 ? (
                                                <motion.img
                                                  initial="hidden"
                                                  whileInView="visible"
                                                  viewport={{ once: true }}
                                                  transition={{
                                                    duration: 0.4,
                                                  }}
                                                  variants={{
                                                    visible: {
                                                      opacity: 1,
                                                      scale: 1,
                                                      rotate: 0,
                                                    },
                                                    hidden: {
                                                      opacity: 0,
                                                      scale: 1.2,
                                                      rotate: 45,
                                                    },
                                                  }}
                                                  src={
                                                    isDarkMode
                                                      ? darkLikeImg
                                                      : likeImg
                                                  }
                                                  className="mr-1"
                                                  alt="like"
                                                  loading="lazy"
                                                />
                                              ) : (
                                                <img
                                                  src={greylikeImg}
                                                  className="mr-1"
                                                  alt="like"
                                                  loading="lazy"
                                                />
                                              )}

                                              {
                                                replies_heirarchy.UpVotedby
                                                  .length
                                              }
                                            </div>

                                            <div
                                              className={`pl-2 pr-2 pt-1 dislike-div ${
                                                replies_heirarchy?.downvote ===
                                                1
                                                  ? isDarkMode
                                                    ? ""
                                                    : "isSelectedLikeDislike"
                                                  : ""
                                              }`}
                                              onClick={() =>
                                                likeDisLikeAction(
                                                  replies_heirarchy,
                                                  false,
                                                  true,
                                                  "reply",
                                                  comments
                                                )
                                              }
                                            >
                                              {replies_heirarchy?.downvote ===
                                              1 ? (
                                                <motion.img
                                                  initial="hidden"
                                                  whileInView="visible"
                                                  viewport={{ once: true }}
                                                  transition={{
                                                    duration: 0.4,
                                                  }}
                                                  variants={{
                                                    visible: {
                                                      opacity: 1,
                                                      scale: 1,
                                                      rotate: 0,
                                                    },
                                                    hidden: {
                                                      opacity: 0,
                                                      scale: 1.2,
                                                      rotate: 45,
                                                    },
                                                  }}
                                                  src={
                                                    isDarkMode
                                                      ? darkDislikeImg
                                                      : dislikeImg
                                                  }
                                                  className="mr-1"
                                                  alt="like"
                                                  loading="lazy"
                                                />
                                              ) : (
                                                <img
                                                  src={dislikeGreyImg}
                                                  className="mr-1"
                                                  alt="like"
                                                  loading="lazy"
                                                />
                                              )}

                                              {
                                                replies_heirarchy.DownVotedby
                                                  ?.length
                                              }
                                            </div>
                                          </div>
                                          <div
                                            className={`pl-2 pt-1 discusSubText ${
                                              isDarkMode ? "darkText" : ""
                                            }`}
                                            onClick={() =>
                                              onReplyCilck(
                                                replies_heirarchy._id
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          >
                                            <img
                                              src={replyImg}
                                              className="ml-4 mt-1 mr-2"
                                              alt="reply"
                                              loading="lazy"
                                            />
                                            {isMobile ? "" : "Reply"}
                                          </div>
                                          {/* <div className="pl-2 pt-1">
                                            <img
                                              src={copyLinkImg}
                                              className="ml-3 mt-1 mr-2"
                                            />
                                            Copy Link
                                          </div> */}
                                          <div
                                            className={`pl-2 pt-1 cursor-pointer discusSubText ${
                                              isDarkMode ? "darkText" : ""
                                            }`}
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              copyLink(
                                                replies_heirarchy,
                                                "reply"
                                              )
                                            }
                                          >
                                            <img
                                              src={copyLinkImg}
                                              className="ml-3 mt-1 mr-2"
                                              alt="copy"
                                              loading="lazy"
                                            />
                                            {isMobile
                                              ? ""
                                              : copied !== replies_heirarchy._id
                                              ? "Copy Link"
                                              : "Copied!"}
                                          </div>
                                          <div
                                            className={`pl-2 row align-items-center discusSubText ${
                                              isDarkMode ? "darkText" : ""
                                            }`}
                                          >
                                            <div
                                              className={`position-relative ml-3 mr-2 ${
                                                isDarkMode ? "" : "bookmarkBack"
                                              }`}
                                            >
                                              {replies_heirarchy.bookmark ===
                                              1 ? (
                                                <motion.img
                                                  initial="hidden"
                                                  whileInView="visible"
                                                  viewport={{ once: true }}
                                                  transition={{
                                                    duration: 0.4,
                                                  }}
                                                  variants={{
                                                    visible: {
                                                      opacity: 1,
                                                      scale: 1,
                                                      rotate: 0,
                                                    },
                                                    hidden: {
                                                      opacity: 0,
                                                      scale: 2,
                                                      rotate: 180,
                                                    },
                                                  }}
                                                  src={
                                                    isDarkMode
                                                      ? darkGoldstar
                                                      : goldStar
                                                  }
                                                  className={`${
                                                    isDarkMode
                                                      ? ""
                                                      : "position-absolute"
                                                  } bookmarkStar`}
                                                  onClick={() =>
                                                    bookMarkDiscussions(
                                                      replies_heirarchy,
                                                      "reply",
                                                      comments
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  alt="bookmark"
                                                  loading="lazy"
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    isDarkMode
                                                      ? darkstar
                                                      : greyStar
                                                  }
                                                  className={`${
                                                    isDarkMode
                                                      ? ""
                                                      : "position-absolute"
                                                  } bookmarkStar`}
                                                  onClick={() =>
                                                    bookMarkDiscussions(
                                                      replies_heirarchy,
                                                      "reply",
                                                      comments
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                  alt="bookmark"
                                                  loading="lazy"
                                                />
                                              )}
                                            </div>
                                            {isMobile ? "" : "Bookmark"}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* <div className="d-flex col-11 ml-auto p-0 mt-3 pt-1" style={{borderTop: "1px solid #e4e4e4"}}>
                                          <span className="mr-4 viewMoreThread">View 15 more thread <img src={viewmoreIcon} alt="view-more-icon" className="img-fluid viewmoreIcon ml-1"/></span><span className="viewMoreThread">Goto Topic</span>
                                          </div> */}
                                    {replies_heirarchy._id === replyComment && (
                                      <Ckeditor
                                        setComment={(value) =>
                                          setComment(value)
                                        }
                                        createReply={
                                          replies_heirarchy._id === isEdit
                                            ? updateReply
                                            : createReply
                                        }
                                        data={replies_heirarchy}
                                        parent={comments}
                                        comment={comment}
                                      />
                                    )}
                                    {/* N number of Reply */}
                                    {isMobile ? (
                                      <>
                                        <div className="moreReplies">
                                          {
                                            replies_heirarchy?.Hierarchy[
                                              replies_heirarchy?._id
                                            ]?.length
                                          }{" "}
                                          More replies
                                        </div>
                                      </>
                                    ) : (
                                      replies_heirarchy?.Hierarchy[
                                        replies_heirarchy?._id
                                      ]?.length > 0 &&
                                      replies_heirarchy?.Hierarchy[
                                        replies_heirarchy?._id
                                      ].map((hierarchy, index) => (
                                        <div key={index}>
                                          <Hierarchy
                                            isHovering={isHovering}
                                            setIsHovering={setIsHovering}
                                            hierarchy={hierarchy}
                                            commentDetails={comments}
                                            isDarkMode={isDarkMode}
                                            bookMarkDiscussions={
                                              bookMarkDiscussions
                                            }
                                            replyId={replies_heirarchy?._id}
                                            likeDisLikeAction={
                                              likeDisLikeAction
                                            }
                                            replyComment={replyComment}
                                            setReplyComment={(value) =>
                                              onReplyCilck(value)
                                            }
                                            setComment={(value) =>
                                              setComment(value)
                                            }
                                            createReply={createReply}
                                            onEditChange={onEditChange}
                                            comment={comment}
                                            updateReply={updateReply}
                                            isEdit={isEdit}
                                            onDeleteReplies={onDeleteReplies}
                                            copyLink={copyLink}
                                            copied={copied}
                                            commentId={commentId}
                                            redirectToUserProfile={
                                              redirectToUserProfile
                                            }
                                          />
                                        </div>
                                      ))
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Right Side Content */}
          <div className={`${isMobile ? "col-12" : "col-3"}`}>
            <div className="row">
              <div className="col-12 right-side-container">
                {isLoadingTopics ? (
                  <RightColumnLoader isDarkMode={isDarkMode} />
                ) : (
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{
                      duration: 0.4,
                    }}
                    variants={{
                      visible: { opacity: 1, x: 0 },
                      hidden: { opacity: 0, x: 30 },
                    }}
                    className="py-2"
                  >
                    <div
                      className={`row title-container ${
                        isMobile ? "mx-2 mt-4" : "mr-4"
                      } ${isDarkMode ? "darkTableHeader borderCards" : ""}`}
                    >
                      <p
                        className={`right-title ml-3 mt-3 ${
                          isDarkMode ? "whiteText" : ""
                        }`}
                      >
                        Most Viewed
                      </p>
                    </div>

                    {mostViewedTopics.length > 0 ? (
                      <>
                        <div
                          className={`row content-block pt-3 contentBoxDis ${
                            isMobile ? "mx-2" : "mr-4 "
                          } ${
                            isDarkMode
                              ? "darkBorder dark-card-bg-color rounded-0"
                              : ""
                          }`}
                        >
                          {mostViewedTopics.map((topic, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={`${isMobile ? "col-2" : "col-2"}`}
                                onClick={() => {
                                  isAuthenticated()
                                    ? history.push(`/discussions/${topic._id}`)
                                    : (localStorage.setItem(
                                        "prevPath",
                                        `/discussions/${topic._id}`
                                      ),
                                      history.push("/login"));
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className=""
                                  src={topic.UserPicture}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                  alt="user-pic"
                                  loading="lazy"
                                />
                              </div>
                              <div
                                className={`${isMobile ? "col-10" : "col-10"}`}
                                onClick={() => {
                                  isAuthenticated()
                                    ? history.push(`/discussions/${topic._id}`)
                                    : (localStorage.setItem(
                                        "prevPath",
                                        `/discussions/${topic._id}`
                                      ),
                                      history.push("/login"));
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <p
                                  className={`right-side-content ${
                                    index === mostViewedTopics.length - 1
                                      ? "mb-3"
                                      : "mb-0"
                                  } ${isDarkMode ? "whiteText" : ""} `}
                                  style={{ cursor: "pointer" }}
                                >
                                  {topic.Topic}
                                </p>
                              </div>
                              {index === mostViewedTopics.length - 1 ? (
                                <></>
                              ) : (
                                <hr
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    backgroundColor: isDarkMode
                                      ? ""
                                      : "#f3f3f3",
                                    color: isDarkMode ? "" : "#f3f3f3",
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}

                          <div className="col-md-12 text-center mb-3">
                            <button
                              className={`btn-view-more ${
                                isDarkMode ? "darkCapsule" : ""
                              }`}
                              onClick={() =>
                                onViewMoreChange("most_viewed_topics")
                              }
                            >
                              View More
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>No Most Viewed Topics</>
                    )}
                  </motion.div>
                )}

                {isLoadingReplies ? (
                  <RightColumnLoader isDarkMode={isDarkMode} />
                ) : (
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{
                      duration: 0.4,
                      delay: 0.5,
                    }}
                    variants={{
                      visible: { opacity: 1, x: 0 },
                      hidden: { opacity: 0, x: 30 },
                    }}
                    className="py-2"
                  >
                    <div
                      className={`row title-container-no-border ${
                        isMobile ? "mx-2 mt-4" : "mr-4"
                      }  ${isDarkMode ? "darkTableHeader borderCards" : ""}`}
                    >
                      <p
                        className={`right-title ml-3 mt-3 ${
                          isDarkMode ? "whiteText" : ""
                        }`}
                      >
                        Most Replied
                      </p>
                    </div>
                    {mostReplies.length > 0 ? (
                      <>
                        <div
                          className={`row content-block pt-3 contentBoxDis ${
                            isMobile ? "mx-2" : "mr-4"
                          } ${
                            isDarkMode
                              ? " dark-card-bg-color darkBorder rounded-0"
                              : ""
                          }`}
                        >
                          {mostReplies.map((reply, index) => (
                            <React.Fragment key={index}>
                              <div
                                className={`${isMobile ? "col-2" : "col-2"}`}
                                onClick={() => {
                                  isAuthenticated()
                                    ? history.push(`/discussions/${reply._id}`)
                                    : (localStorage.setItem(
                                        "prevPath",
                                        `/discussions/${reply._id}`
                                      ),
                                      history.push("/login"));
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className=""
                                  src={reply.UserPicture}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                  alt="user-pic"
                                  loading="lazy"
                                />
                              </div>
                              <div
                                className={`${isMobile ? "col-10" : "col-10"}`}
                                onClick={() => {
                                  isAuthenticated()
                                    ? history.push(`/discussions/${reply._id}`)
                                    : (localStorage.setItem(
                                        "prevPath",
                                        `/discussions/${reply._id}`
                                      ),
                                      history.push("/login"));
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <p
                                  className={`right-side-content mb-1 ${
                                    isDarkMode ? "whiteText" : ""
                                  }`}
                                >
                                  {reply.Topic}
                                </p>
                                <div className="row">
                                  <div
                                    className={
                                      isDarkMode
                                        ? "px-2 col-2 darkComment"
                                        : "col-2"
                                    }
                                  >
                                    <img
                                      src={
                                        isDarkMode
                                          ? darkCommentIcon
                                          : commentsRedImg
                                      }
                                      alt="comments"
                                      loading="lazy"
                                    />
                                  </div>
                                  <div className="col-10 pl-1">
                                    <p
                                      style={{ fontSize: "14px" }}
                                      className={`${
                                        index === mostReplies.length - 1
                                          ? "mb-3"
                                          : "mb-0"
                                      }`}
                                    >
                                      <span
                                        className={`${
                                          isDarkMode ? "darkText" : ""
                                        }`}
                                      >
                                        {" "}
                                        {reply.Size}{" "}
                                        {reply.Size > 1 ? "replies" : "reply"}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {index === mostReplies.length - 1 ? (
                                <></>
                              ) : (
                                <hr
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    backgroundColor: isDarkMode
                                      ? ""
                                      : "#f3f3f3",
                                    color: isDarkMode ? "" : "#f3f3f3",
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}

                          <div className="col-md-12 text-center mb-3">
                            <button
                              className={`btn-view-more ${
                                isDarkMode ? "darkCapsule" : ""
                              }`}
                              onClick={() => onViewMoreChange("most_replied")}
                            >
                              View More
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>No Most Replied Topics</>
                    )}
                  </motion.div>
                )}

                {isLoadingVotes ? (
                  <RightColumnLoader isDarkMode={isDarkMode} />
                ) : (
                  <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    transition={{
                      duration: 0.4,
                    }}
                    variants={{
                      visible: { opacity: 1, x: 0 },
                      hidden: { opacity: 0, x: 30 },
                    }}
                    className="py-2"
                  >
                    <div
                      className={`row title-container-no-border ${
                        isMobile ? "mx-2 mt-4" : "mr-4"
                      }  ${isDarkMode ? " darkTableHeader borderCards" : ""}`}
                    >
                      <p
                        className={`right-title ml-3 mt-3 ${
                          isDarkMode ? "whiteText" : ""
                        }`}
                      >
                        Most Voted
                      </p>
                    </div>
                    {mostVoted.length > 0 ? (
                      <>
                        <div
                          className={`row content-block pt-3 contentBoxDis ${
                            isMobile ? "mx-2" : "mr-4"
                          } ${
                            isDarkMode ? "dark-card-bg-color  darkBorder" : ""
                          }`}
                        >
                          {mostVoted.map((most_voted, index) => (
                            <React.Fragment key={index}>
                              <div
                                className="col-2"
                                onClick={() => {
                                  isAuthenticated()
                                    ? history.push(
                                        `/discussions/${most_voted._id}`
                                      )
                                    : (localStorage.setItem(
                                        "prevPath",
                                        `/discussions/${most_voted._id}`
                                      ),
                                      history.push("/login"));
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <img
                                  className=""
                                  src={most_voted.UserPicture}
                                  style={{
                                    height: "30px",
                                    width: "30px",
                                    borderRadius: "50%",
                                  }}
                                  alt="user-picture"
                                  loading="lazy"
                                />
                              </div>
                              <div
                                className="col-10"
                                onClick={() => {
                                  isAuthenticated()
                                    ? history.push(
                                        `/discussions/${most_voted._id}`
                                      )
                                    : (localStorage.setItem(
                                        "prevPath",
                                        `/discussions/${most_voted._id}`
                                      ),
                                      history.push("/login"));
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <p
                                  className={`right-side-content mb-1 ${
                                    isDarkMode ? "whiteText" : ""
                                  }`}
                                >
                                  {most_voted.Topic}
                                </p>
                                <div className="row align-items-center">
                                  <div className="pl-3">
                                    <label className="like-details-label">
                                      <img
                                        src={
                                          isDarkMode
                                            ? darkLikeActive
                                            : redLikeImg
                                        }
                                        style={{ height: "14px" }}
                                        alt="like"
                                        loading="lazy"
                                      />{" "}
                                      {most_voted.Votes}
                                    </label>
                                  </div>
                                  <div className="">
                                    <p
                                      style={{ fontSize: "14px" }}
                                      className={`${
                                        index === mostVoted.length - 1
                                          ? "mb-3"
                                          : "mb-0"
                                      }`}
                                    ></p>
                                  </div>
                                </div>
                              </div>
                              {index === mostVoted.length - 1 ? (
                                <></>
                              ) : (
                                <hr
                                  style={{
                                    width: "100%",
                                    marginTop: "10px",
                                    backgroundColor: isDarkMode
                                      ? ""
                                      : "#f3f3f3",
                                    color: isDarkMode ? "" : "#f3f3f3",
                                  }}
                                />
                              )}
                            </React.Fragment>
                          ))}

                          <div className="col-md-12 text-center mb-3">
                            <button
                              className={`btn-view-more ${
                                isDarkMode ? "darkCapsule" : ""
                              }`}
                              onClick={() => onViewMoreChange("most_voted")}
                            >
                              View More
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>No Most Voted Topics</>
                    )}{" "}
                  </motion.div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        fullWidth={true}
        className={`postTopic ${isDarkMode ? "darkTheme" : ""}`}
        disableEnforceFocus={true}
      >
        <DialogTitle
          style={{
            display: "flex",
          }}
          id="customized-dialog-title"
          className={isDarkMode ? "darkCardBody" : ""}
          onClose={() => setOpen(false)}
        >
          <Typography
            variant="h6"
            style={{
              margin: "0px",
              alignContent: "start",
              fontWeight: "bold",
              textTransform: "none",
            }}
            className={isDarkMode ? "darkText" : ""}
          >
            Update Topic
          </Typography>

          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: "0px",
              top: "10px",
            }}
            className={isDarkMode ? "whiteArrow" : ""}
            onClick={() => setOpen(false)}
          >
            <CloseIcon className={isDarkMode ? "colorInvert" : ""} />
          </IconButton>
        </DialogTitle>

        <DialogContent
          dividers={scroll === "paper"}
          className={isDarkMode ? "darkCardBody" : ""}
        >
          <DialogContentText id="scroll-dialog-description">
            <p
              style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}
              className={isDarkMode ? "darkText" : ""}
            >
              Title<sup>*</sup>
            </p>
            <input
              type="text"
              placeholder="Give a title to your description"
              style={{
                width: "100%",
                minHeight: "50px",
                border: isDarkMode ? "1px solid #39394c" : "1px solid #e5e5e5",
                borderRadius: isDarkMode ? "50px" : "10px",
                background: isDarkMode ? "inherit" : "",
                fontSize: "16px",
                paddingLeft: "10px",
                color: isDarkMode ? "#b5c0d3" : "",
              }}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <br />
            <p
              style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}
              className={isDarkMode ? "darkText" : ""}
            >
              Description<sup>*</sup>
            </p>
            <CKEditor
              editor={Editor}
              config={editorConfiguration}
              data={discussionDetails.Text}
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setComment(data);
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
              {title !== "" && comment !== "" && (
                <button
                  type="button"
                  style={{
                    backgroundColor: isDarkMode ? "#6540d7" : "#f44d5e",
                    color: "#fff",
                    borderRadius: "50px",
                    fontSize: "16px",
                    fontWeight: "500",
                    padding: "10px 58px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={updateTopic}
                >
                  Post
                </button>
              )}
              {title === "" ||
                (comment === "" && (
                  <button
                    type="button"
                    style={{
                      backgroundColor: isDarkMode ? "#303043" : "#c4c4c4",
                      color: isDarkMode ? "#fff" : "#000",
                      borderRadius: "50px",
                      fontSize: "16px",
                      fontWeight: "500",
                      padding: "10px 58px",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Post
                  </button>
                ))}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openConfirmDeleteDialog}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={` ${
          isDarkMode ? "darkConfirmDeleteModal" : "confirmDeleteModal"
        } `}
        maxWidth="md"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className={`discussion-filter ${
            isDarkMode ? "darkCardBody whiteText darkTheme" : ""
          } `}
        >
          <img
            src={WarningIcon}
            alt="filter"
            className="img-fluid filter-img"
            loading="lazy"
          />
          <span className={isDarkMode ? "whiteText" : ""}>
            {" "}
            Delete {DeleteType === "topic" ? "Topic" : "Comment"}
          </span>
          <IconButton
            aria-label="close"
            style={{
              position: "absolute",
              right: "0px",
              top: "5px",
            }}
            className={`${isDarkMode ? "whiteText" : ""}`}
            onClick={handleClose}
          >
            <CloseIcon className={isDarkMode ? "" : ""} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          className={isDarkMode ? "darkCardBody darkTheme" : ""}
        >
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <p className={isDarkMode ? "darkText" : ""}>
              Are you sure you want delete this{" "}
              {DeleteType === "topic" ? "topic" : "comment"}?
            </p>
            <p>If you delete it cannot be restored</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className={isDarkMode ? "darkCardBody marginBottomFixDark" : ""}
        >
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: "#dfdfdf",
              color: "#000",
              borderRadius: "5px",
              width: "100px",
              textTransform: "capitalize",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              DeleteType === "topic"
                ? onDeleteTopic(DeleteData)
                : onDeleteComments(DeleteData)
            }
            style={{
              backgroundColor: isDarkMode ? "#6540d7" : "#f04f5f",
              color: "#fff",
              borderRadius: "5px",
              width: "100px",
              textTransform: "capitalize",
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default function SingleDiscussionComponent(props) {
  return (
    <>
      <BrowserView>
        <SubSingleDiscussion props={props} />
      </BrowserView>
      <MobileView>
        <SubSingleDiscussion props={props} />
      </MobileView>
    </>
  );
}

const Hierarchy = (props) => {
  const isDarkMode = props.isDarkMode;
  const hierarchyData = props.hierarchy;
  const commentDetails = props.commentDetails;
  const replyId = props.replyId;
  const history = useHistory();
  return (
    <>
      {hierarchyData.length > 0 && typeof hierarchyData === "object" && (
        <>
          {hierarchyData.map((item, index) => (
            <React.Fragment key={index}>
              {Object.entries(item).map(([key, value], index) => (
                <React.Fragment key={index}>
                  {value[1] && (
                    <div className="col-md-12 p-0">
                      <div
                        className="row mt-4"
                        onMouseLeave={() => props.setIsHovering("")}
                      >
                        <div className="">
                          {value[1].Delete && (
                            <div className="col-md-1 px-0 text-center">
                              <p className={`mb-0 deleted-user-hld`}>
                                <img
                                  src={DeletedIcon}
                                  alt="deleted-user"
                                  className={`img-fluid mr-1 ${
                                    isDarkMode ? "colorInvert" : ""
                                  }`}
                                  loading="lazy"
                                />
                                <span
                                  className={`deletedtxt mr-1 ${
                                    isDarkMode ? "whiteText" : ""
                                  }`}
                                >
                                  Deleted
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  The user deleted his reply
                                </span>
                              </p>
                              <div
                                className="replyLine"
                                style={{ height: "50px" }}
                              ></div>
                            </div>
                          )}
                        </div>
                        {!value[1].Delete && (
                          <div
                            className={`px-0 text-center position-relative userDiscussingDiscussDEtailsImgHover ${
                              isMobile ? "col-2" : "col-1"
                            }`}
                          >
                            <img
                              src={value[1].UserPicture}
                              className="img-circle-reply"
                              onClick={() =>
                                props.redirectToUserProfile(value[1].Postedby)
                              }
                              alt="user-pic"
                              loading="lazy"
                              onMouseEnter={() =>
                                props.setIsHovering(value[1]._id)
                              }
                            />
                            <div className="replyLine"></div>
                            {props.isHovering === value[1]._id && (
                              <ProfileCard
                                userId={value[1].Postedby}
                                setIsHovering={props.setIsHovering}
                              />
                            )}
                          </div>
                        )}
                        <div className={`${isMobile ? "col-10" : "col-11"}`}>
                          {!value[1].Delete && (
                            <div
                              className={`row align-items-center mb-1 position-relative userDiscussingDiscussDEtailsTextHover`}
                            >
                              <label
                                className={`black-small-text-bold mb-0 ${
                                  isDarkMode ? "whiteText" : ""
                                }`}
                                onClick={() =>
                                  props.redirectToUserProfile(value[1].Postedby)
                                }
                                onMouseEnter={() =>
                                  props.setIsHovering(
                                    value[1]?._id + value[1].Postedby
                                  )
                                }
                              >
                                {value[1].Name}
                              </label>
                              {props.isHovering ===
                                value[1]?._id + value[1].Postedby && (
                                <ProfileCard
                                  userId={value[1].Postedby}
                                  setIsHovering={props.setIsHovering}
                                />
                              )}
                              <label
                                className="ml-1 mb-0"
                                style={{
                                  fontSize: "34px",
                                  lineHeight: "20px",
                                }}
                              >
                                ·
                              </label>
                              <label
                                className={`grey-text-small mx-1 mb-0 ${
                                  isDarkMode ? "darkText" : ""
                                }`}
                              >
                                Posted {formatDate(value[1].created)}
                              </label>
                              {value[1].Postedby ===
                                isAuthenticated()?.user?._id && (
                                <span
                                  className="ml-1"
                                  onClick={() => props.onEditChange(value[1])}
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={isDarkMode ? darkEdit : EditIcon}
                                    alt="edit-icon"
                                    className="img-fluid mr-1"
                                    loading="lazy"
                                  />
                                </span>
                              )}
                              {value[1].Postedby ===
                                isAuthenticated()?.user?._id && (
                                <span
                                  className="ml-1"
                                  onClick={() =>
                                    props.onDeleteReplies(value[1])
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={isDarkMode ? darkDelete : DeleteIcon}
                                    alt="delete-icon"
                                    className="img-fluid mr-1"
                                    loading="lazy"
                                  />
                                </span>
                              )}
                            </div>
                          )}
                          <div
                            className={`${
                              props.commentId === value[1]._id
                                ? "commentHighlight"
                                : ""
                            }`}
                          >
                            {!value[1].Delete && (
                              <div className="row pr-2">
                                <div
                                  className={`col-md-8 pl-0 paragraph ${
                                    isDarkMode ? "darkText" : ""
                                  }`}
                                  dangerouslySetInnerHTML={{
                                    __html: value[1].Reply,
                                  }}
                                ></div>
                              </div>
                            )}
                            {!value[1].Delete && (
                              <div className="row">
                                <div className="like-dislike-div">
                                  <div
                                    className={`pl-2 pr-2 pt-1 pb-1 like-div ${
                                      value[1]?.upvote === 1
                                        ? isDarkMode
                                          ? ""
                                          : "isSelectedLikeDislike"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      props.likeDisLikeAction(
                                        value[1],
                                        true,
                                        false,
                                        "reply_hierarchy",
                                        commentDetails
                                      )
                                    }
                                  >
                                    {" "}
                                    {value[1]?.upvote === 1 ? (
                                      <motion.img
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        transition={{
                                          duration: 0.4,
                                        }}
                                        variants={{
                                          visible: {
                                            opacity: 1,
                                            scale: 1,
                                            rotate: 0,
                                          },
                                          hidden: {
                                            opacity: 0,
                                            scale: 1.2,
                                            rotate: 45,
                                          },
                                        }}
                                        src={isDarkMode ? darkLikeImg : likeImg}
                                        className="mr-1"
                                        alt="like"
                                        loading="lazy"
                                      />
                                    ) : (
                                      <img
                                        src={greylikeImg}
                                        className="mr-1"
                                        alt="like"
                                        loading="lazy"
                                      />
                                    )}
                                    {value[1].UpVotedby.length}
                                  </div>

                                  <div
                                    className={`pl-2 pr-2 pt-1 dislike-div ${
                                      value[1]?.downvote === 1
                                        ? isDarkMode
                                          ? ""
                                          : "isSelectedLikeDislike"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      props.likeDisLikeAction(
                                        value[1],
                                        false,
                                        true,
                                        "reply_hierarchy",
                                        commentDetails
                                      )
                                    }
                                  >
                                    {" "}
                                    {value[1]?.downvote === 1 ? (
                                      <motion.img
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        transition={{
                                          duration: 0.4,
                                        }}
                                        variants={{
                                          visible: {
                                            opacity: 1,
                                            scale: 1,
                                            rotate: 0,
                                          },
                                          hidden: {
                                            opacity: 0,
                                            scale: 1.2,
                                            rotate: 45,
                                          },
                                        }}
                                        src={
                                          isDarkMode
                                            ? darkDislikeImg
                                            : dislikeImg
                                        }
                                        className="mr-1"
                                        alt="like"
                                        loading="lazy"
                                      />
                                    ) : (
                                      <img
                                        src={dislikeGreyImg}
                                        className="mr-1"
                                        alt="like"
                                        loading="lazy"
                                      />
                                    )}
                                    {value[1].DownVotedby?.length}
                                  </div>
                                </div>
                                <div
                                  className={`pl-2 pt-1 ${
                                    isDarkMode ? "darkText" : ""
                                  }`}
                                  onClick={() =>
                                    props.setReplyComment(value[1]._id)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <img
                                    src={replyImg}
                                    className="ml-4 mt-1 mr-2"
                                    alt="reply"
                                    loading="lazy"
                                  />
                                  {isMobile ? "" : "Reply"}
                                </div>
                                {/* <div className="pl-2 pt-1">
                          <img src={copyLinkImg} className="ml-3 mt-1 mr-2" />
                          Copy Link
                        </div> */}
                                <div
                                  className={`pl-2 pt-1 cursor-pointer ${
                                    isDarkMode ? "darkText" : ""
                                  }`}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    props.copyLink(value[1], "reply")
                                  }
                                >
                                  <img
                                    src={copyLinkImg}
                                    className="ml-3 mt-1 mr-2"
                                    alt="copy"
                                    loading="lazy"
                                  />
                                  {isMobile
                                    ? ""
                                    : props.copied !== value[1]._id
                                    ? "Copy Link"
                                    : "Copied!"}
                                </div>
                                <div
                                  className={`pl-2 row align-items-center ${
                                    isDarkMode ? "darkText" : ""
                                  }`}
                                >
                                  <div
                                    className={`position-relative ml-3 mr-2 ${
                                      isDarkMode ? "" : "bookmarkBack"
                                    }`}
                                  >
                                    {value[1].bookmark === 1 ? (
                                      <motion.img
                                        initial="hidden"
                                        whileInView="visible"
                                        viewport={{ once: true }}
                                        transition={{
                                          duration: 0.4,
                                        }}
                                        variants={{
                                          visible: {
                                            opacity: 1,
                                            scale: 1,
                                            rotate: 0,
                                          },
                                          hidden: {
                                            opacity: 0,
                                            scale: 2,
                                            rotate: 180,
                                          },
                                        }}
                                        src={
                                          isDarkMode ? darkGoldstar : goldStar
                                        }
                                        className={`${
                                          isDarkMode ? "" : "position-absolute"
                                        } bookmarkStar`}
                                        onClick={() =>
                                          props.bookMarkDiscussions(
                                            value[1],
                                            "reply_hierarchy",
                                            commentDetails,
                                            replyId
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                        alt="bookmark"
                                        loading="lazy"
                                      />
                                    ) : (
                                      <img
                                        src={isDarkMode ? darkstar : greyStar}
                                        className={`${
                                          isDarkMode ? "" : "position-absolute"
                                        } bookmarkStar`}
                                        onClick={() =>
                                          props.bookMarkDiscussions(
                                            value[1],
                                            "reply_hierarchy",
                                            commentDetails,
                                            replyId
                                          )
                                        }
                                        style={{ cursor: "pointer" }}
                                        alt="bookmark"
                                        loading="lazy"
                                      />
                                    )}
                                  </div>
                                  Bookmark
                                </div>
                              </div>
                            )}
                            {/* <div className="dummyLine"></div> */}
                          </div>
                          {value[1]._id === props.replyComment && (
                            <Ckeditor
                              setComment={(value) => props.setComment(value)}
                              createReply={
                                value[1]._id === props.isEdit
                                  ? props.updateReply
                                  : props.createReply
                              }
                              data={value[1]}
                              parent={commentDetails}
                              comment={props.comment}
                            />
                          )}
                          <Hierarchy
                            hierarchy={value[0]}
                            isHovering={props.isHovering}
                            setIsHovering={props.setIsHovering}
                            commentDetails={commentDetails}
                            isDarkMode={isDarkMode}
                            bookMarkDiscussions={(value) =>
                              props.bookMarkDiscussions(
                                value,
                                "reply_hierarchy",
                                commentDetails,
                                replyId
                              )
                            }
                            likeDisLikeAction={(value, like) =>
                              like
                                ? props.likeDisLikeAction(
                                    value,
                                    true,
                                    false,
                                    "reply_hierarchy",
                                    commentDetails
                                  )
                                : props.likeDisLikeAction(
                                    value,
                                    false,
                                    true,
                                    "reply_hierarchy",
                                    commentDetails
                                  )
                            }
                            replyComment={props.replyComment}
                            setReplyComment={(id) => props.setReplyComment(id)}
                            createReply={props.createReply}
                            setComment={(value) => props.setComment(value)}
                            onEditChange={props.onEditChange}
                            comment={props.comment}
                            updateReply={props.updateReply}
                            isEdit={props.isEdit}
                            onDeleteReplies={props.onDeleteReplies}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

const Ckeditor = (props) => {
  return (
    <div className="mt-3 col-10 pl-0" style={{ marginLeft: "-15px" }}>
      <CKEditor
        editor={Editor}
        config={editorConfiguration}
        data={`${props.comment ? props.comment : ""}`}
        onReady={(editor) => {
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "height",
              "200px",
              editor.editing.view.document.getRoot()
            );
          });
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          props.setComment(data);
        }}
      />
      {props.comment ? (
        <button
          className="post-comment-btn-active"
          onClick={() => props.createReply(props.data, props.parent)}
        >
          Reply
        </button>
      ) : (
        <button className="post-comment-btn">Reply</button>
      )}
    </div>
  );
};

const RightColumnLoader = (props) => {
  const isDarkMode = props.isDarkMode;
  return (
    <>
      {Array.from(Array(5), (_, i) => (
        <div
          className={`row mr-4 py-2 align-items-center ${
            isDarkMode ? "darkBorder" : ""
          }`}
          key={i}
        >
          <div className="col-md-2">
            <Skeleton
              containerClassName="my-2 col-1"
              height="60px"
              width="60px"
              borderRadius="50px"
            />
          </div>
          <div className="col-md-9 offset-1">
            <p className="right-side-content mt-2">
              <Skeleton
                containerClassName="my-2 col-1"
                height="15px"
                width="90px"
                borderRadius="50px"
              />
              <Skeleton
                containerClassName="my-2 col-1"
                height="15px"
                width="150px"
                borderRadius="50px"
              />
            </p>
          </div>
        </div>
      ))}
    </>
  );
};
