import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import googleOneTap from "google-one-tap";
import { Enterprise_URL } from "backend";
import LoginPageNew from "views/LoginPage/LoginPageNew";
import { isAuthenticated } from "auth/helper";
import { InterviewJobService } from "services/rest/interview.service";
import { socket } from "socket/Socket";
import axios from "axios";
import { isMobile } from "react-device-detect";
import { API, BootcampBackend } from "backend";
import { store } from "store/store";
import setAuthToken from "auth/helper/setToken";
import { getUserData } from "user/helper/userapicall";
import { userDetailsService } from "services/rest/userProfile.service";
import SearchModal from "./Sections/SearchModal";
import NotificationModal from "./Sections/NotificationModal";
import UserProfileCard from "./Sections/UserProfileCard";
import MobileHeader from "./Sections/MobileHeader";
import MHLogo from "assets/img/MH-Gen-AI-Logos/MH-Gen-AI-Lite.png";
import MHDarkLogo from "assets/img/MH-Gen-AI-Logos/MH-Gen-AI-Dark.png";
import LeaderboardIcon from "assets/img/Profile_new/rankSymGolden.svg";
import SearchIcon from "assets/img/landingpage/search-icon.svg";
import EnterpriseIcon from "assets/img/landingpage/enterprise.svg";
import premiumIcon from "assets/img/landingpage/premiumicon.svg";
import NotificationIcon from "assets/img/landingpage/notification-icon.svg";
import Messageicon from "assets/img/landingpage/message-icon.svg";
import ChevronDown from "assets/img/landingpage/chevron-down.svg";
import LightActiveNotification from "assets/img/landingpage/light-active-notification.svg";
import DarkLeaderboardImg from "assets/img/landingpage/darkLeaderboardIcon.svg";
import DarkSearchIcon from "assets/img/landingpage/darkSearchIcon.svg";
import darkPremiumIcon from "assets/img/landingpage/darkPremiumIcon.svg";
import DarkNotificationIcon from "assets/img/landingpage/dark-notofication-icon.svg";
import DarkMessageIcon from "assets/img/landingpage/dark-message-icon.svg";
import DarkChevronIcon from "assets/img/landingpage/white-chevron-down.svg";
import DarkEnterpriseIcon from "assets/img/landingpage/dark-enterprise-icon.svg";
import DarkActiveNotification from "assets/img/landingpage/dark-active-notification.svg";
import DarkActiveMessageIcon from "assets/img/landingpage/dark-active-message-icon.svg";
import ActiveMessageIcon from "assets/img/landingpage/active-message-icon.svg";
import "./header.css";
import TopHeader from "./Sections/TopHeader";
import { Companies } from "companies";
import { parseJwt } from "shared/CommonMethods";

const options = {
  client_id:
    "1003748489568-mj5co2sbqn86gud262fi6knmmjqoietr.apps.googleusercontent.com", // required
  auto_select: false, // optional
  cancel_on_tap_outside: false, // optional
  context: "signin", // optional
};

const InitializeOneTap = () => {
  const checkLocation = window.location.href;
  var temp = checkLocation.split("/");
  if (temp[3] === "" || temp[3] === "enterprise") {
    return;
  } else {
    googleOneTap(options, async (oneTap) => {
      const response = await axios.post(
        `${BootcampBackend}/oauth/one-tap-login`,
        {
          idToken: oneTap.credential,
        }
      );
      localStorage.setItem("jwt", JSON.stringify(response.data.result));
      localStorage.setItem("loginTime", Date.now());
      window.location.reload();
      return;
    });
  }
  return;
};

if (localStorage.getItem("jwt")) {
  let jwt = JSON.parse(localStorage.getItem("jwt"));
  setAuthToken(jwt.token);
  let userId = isAuthenticated().user._id;
  getUserData(userId)
    .then((response) => {
      if (response?.data?.data) {
        store.dispatch({
          type: "GET_USER_INFO",
          payload: response.data.data,
        });
      }
    })
    .catch((err) => console.log(err));
} else {
  InitializeOneTap();
}

const Mhproducts = [
  {
    id: "1",
    name: "AI Assessment",
    slug: "assessments",
  },
  // {
  //   id: "2",
  //   name: "AI Hackathon",
  //   slug: "hackathons",
  // },
  {
    id: "3",
    name: "AI Practice",
    slug: "practices",
  },
  {
    id: "4",
    name: "AI Courses",
    slug: "bootcamps",
  },
  {
    id: "4",
    name: "AI Blogs",
    slug: "blogs",
  },
  {
    id: "5",
    name: "Playground",
    slug: "playground",
  },
  // {
  //   id: "5",
  //   name: "Discussions",
  //   slug: "discussions",
  // },
];
const Header = () => {
  const currentUser = useSelector((store) => store.user.user);
  const displayMode = useSelector((store) => store.display.display_mode);
  const [unSeenCount, setUnSeenCount] = useState(0);
  const [NotificationData, setNotificationData] = useState([]);
  const [isNewNotification, setIsNewNotification] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [userGlobalRankingData, setuserGlobalRankingData] = useState({});
  const location = useLocation();
  const history = useHistory();
  const current_nav = location?.pathname?.split("/")[1];
  const goToSubscription = () => {
    history.push(`/subscriptions`);
  };
  const goToMessaging = () => {
    history.push(`/myaccount/${currentUser?._id}?tab=my-messages`);
  };
  const goToEnterprise = () => {
    window.open(Enterprise_URL);
  };
  const unseenNotification = (data) => {
    let unseenCount = 0;
    data.filter((x) => {
      if (!x.is_seen) {
        unseenCount += 1;
      }
    });
    setUnSeenCount(unseenCount);
  };
  const getAllNotifications = async () => {
    try {
      const notificationResponse = await InterviewJobService.getAllNotification();
      setNotificationData(notificationResponse.data.message.slice(0, 100));
      notificationResponse.data.message.slice(0, 100).filter((x) => {
        if (!x.is_seen) {
          setIsNewNotification(true);
        }
      });
      unseenNotification(notificationResponse.data.message.slice(0, 100));
    } catch (error) {
      console.log(error);
    }
  };
  const DonotShowHeaderIn = [
    "/events/",
    "/enterprise",
    "/",
    "/mh-wordle",
    "/genpact-ai-mh-wordle",
    "/feed",
    "/datalyze",
  ];
  const isGenpactGoogleHackathon = () => {
    const hackathonUrl =
      "/hackathons/sustainability_hackathon_lets_crack_the_climate_resilience_code/";
    const hackathonUrlSecond =
      "/hackathons/genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code/";
    return (
      location.pathname.includes(hackathonUrl) ||
      location.pathname.includes(hackathonUrlSecond)
    );
  };
  const showHeaderfunc = () => {
    let showHeader = true;
    DonotShowHeaderIn.forEach((x) => {
      showHeader =
        showHeader &&
        (x === "/" ? location.pathname !== x : !location.pathname.includes(x));
    });
    return showHeader;
  };
  const toggleDrawer = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(!openDrawer);
  };
  const getCurrentUserInfo = async () => {
    try {
      const response = await getUserData(currentUser._id)
          if (response.status === 201 || response.status === 200) {
            setuserGlobalRankingData((prev) => ({
              ...prev,
              hackathonTier: response.data.data?.Level,
            }));
            const user_data = currentUser;
            user_data.hackathonTier = response.data.data?.Level;
            user_data.GlobalRank = response.data.data?.GlobalRank;
            user_data.Total_count = response.data.data?.Total_count;
            store.dispatch({
              type: "GET_USER_INFO",
              payload: user_data,
            });
          } else {
            console.error(response?.statusText);
          }
    } catch (error) {
      console.log(error);
    }
  };
  const getCurrentUserTier = async () => {
    const userTierInfo = await userDetailsService.getAllInfo(currentUser._id);
    if (userTierInfo) {
      setuserGlobalRankingData((prev) => ({
        ...prev,
        practiceTier: userTierInfo?.practiceTier || "Novice",
        assessmentTier: userTierInfo?.assessmentTier || "Novice",
        blogsTier: userTierInfo?.blogsTier || "Novice",
      }));
    }
  };
  useEffect(() => {
    if (currentUser._id) {
      getCurrentUserInfo();
      getCurrentUserTier();
    }
  }, [currentUser?._id]);
  useEffect(() => {
    // isAuthenticated() && getAllNotifications();
    getSocketNotifications();
  }, []);

  const getSocketNotifications = () => {
    socket.on("connect", () => {
      // console.log(socket.connected);
    });
    socket.on("ConnectionResponse", (payload) => {
      // console.log(payload);
    });
    socket.on("GeneralNotificationResponse", (payload) => {
      setIsNewNotification(true);
      setNotificationData((prevState) => [payload].concat(prevState));
      setUnSeenCount((prevState) => prevState + 1);
    });
    socket.on("UserNotificationResponse", (payload) => {
      if (payload.User_Oid === isAuthenticated().user._id) {
        setIsNewNotification(true);
        setNotificationData((prevState) => [payload].concat(prevState));
        setUnSeenCount((prevState) => prevState + 1);
      }
    });
    socket.on("DeleteNotificationResponse", (delete_payload) => {
      // console.log(delete_payload);
      getAllNotifications();
    });
  };
  useEffect(() => {
    const theme = localStorage.getItem("Theme");
    store.dispatch({
      type: "SET_DISPLAY_MODE",
      payload: theme ? theme : "dark_mode",
    });
  }, []);
  // Genpact-Google Topbar - get the browser url to set the marginTop
  useEffect(() => {
    store.dispatch({
      type: "SET_CURRENT_URL",
      payload: window.location.href,
    });
  }, [window.location.href]);
  return (
    <div style={{ visibility: `${showHeaderfunc() ? "visible" : "hidden"}` }}>
      {isMobile ? (
        <>
          {/* {!isGenpactGoogleHackathon()&&<TopHeader hederType={"main"}/>} */}
          {/* use this classname if there are any top headers "multi-header-container" */}
          <div
            className={`header-container ${
              displayMode === "dark_mode"
                ? "darkHeader-container darkTheme"
                : ""
            }`}
          >
            <span
              className="text-center"
              onClick={toggleDrawer}
              style={{ width: "50px" }}
            >
              <i className="fa fa-bars" aria-hidden="true"></i>
            </span>
            <Link className="" to="/feed">
              <img
                className="mh-logo img-fluid"
                src={displayMode === "dark_mode" ? MHDarkLogo : MHLogo}
                alt="Machine Hack Logo"
                loading="lazy"
              />
            </Link>
            {isAuthenticated() ? (
              <div
                className="currentuserHld"
                onClick={() => setShowUserProfile(!showUserProfile)}
              >
                <img
                  src={currentUser?.Picture}
                  alt="user"
                  className="img-fluid userPicture"
                />
                <img
                  src={
                    displayMode === "dark_mode" ? DarkChevronIcon : ChevronDown
                  }
                  alt="chevron-down"
                  className={`img-fluid ${
                    showUserProfile ? "chevron-up" : "chevron-down"
                  }`}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </>
      ) : (
        <>
          {/* {!isGenpactGoogleHackathon()&&<TopHeader hederType={"main"}/>} */}
          {/* use this classname if there are any top headers "multi-header-container" */}
          <div
            className={`header-container ${
              displayMode === "dark_mode"
                ? "darkHeader-container darkTheme"
                : ""
            }`}
          >
            {isAuthenticated() ? (
              <>
                <div className="d-flex align-items-center">
                  <Link className="" to="/feed">
                    <img
                      className="mh-logo img-fluid"
                      src={displayMode === "dark_mode" ? MHDarkLogo : MHLogo}
                      alt="Machine Hack Logo"
                      loading="lazy"
                    />
                  </Link>
                  {Mhproducts?.map((item,index) => (
                    <Link
                      to={`/${item.slug}`}
                      className={`headerItems ${
                        current_nav === item?.slug ? "activeHeaderItem" : ""
                      }`}
                      key={`${item?.id}${index}`}
                    >
                      <span>{item?.name}</span>
                    </Link>
                  ))}
                  <button
              className="leaderboardbutton"
              onClick={() => history.push(`/leaderboard`)}
            >
              <img
                src={
                  displayMode === "dark_mode"
                    ? DarkLeaderboardImg
                    : LeaderboardIcon
                }
                className="img-fluid"
                alt="icon"
              />
              <span>Leaderboards</span>
            </button>
                </div>
                <div className="align-items-center">
                  <img
                    src={
                      displayMode === "dark_mode" ? DarkSearchIcon : SearchIcon
                    }
                    alt="search"
                    className="img-fluid searchicon"
                    onClick={() => setShowSearchModal(!showSearchModal)}
                  />
                  {/* <div className="premiumHld align-items-center">
              <span className="premiumIcon">
                <img
                  src={
                    displayMode === "dark_mode" ? darkPremiumIcon : premiumIcon
                  }
                  alt="premium"
                  className="img-fluid"
                  loading="lazy"
                />
              </span>
              <span className={`premiumText`} onClick={goToSubscription}>
                Premium
              </span>
            </div> */}
                  {isAuthenticated() ? (
                    <>
                      {/* <div
                  className={`notifyMsghld ${
                    showNotificationModal ? "ActivenotifyMsghld" : ""
                  }`}
                  onClick={() =>
                    setShowNotificationModal(!showNotificationModal)
                  }
                >
                  <img
                    src={
                      showNotificationModal
                        ? displayMode === "dark_mode"
                          ? DarkActiveNotification
                          : LightActiveNotification
                        : displayMode === "dark_mode"
                        ? DarkNotificationIcon
                        : NotificationIcon
                    }
                    alt="notify"
                    className="img-fluid"
                  />
                  {isNewNotification && unSeenCount > 0 && (
                    <div className="newNotificationnotify"></div>
                  )}
                </div>
                <div
                  className={`notifyMsghld ${
                    location.search?.includes("my-messages")
                      ? "ActivenotifyMsghld"
                      : ""
                  }`}
                  onClick={goToMessaging}
                >
                  <img
                    src={
                      displayMode === "dark_mode"
                        ? location.search?.includes("my-messages")
                          ? DarkActiveMessageIcon
                          : DarkMessageIcon
                        : location.search?.includes("my-messages")
                        ? ActiveMessageIcon
                        : Messageicon
                    }
                    alt="notify"
                    className="img-fluid"
                  />
                </div> */}
                      <div
                        className="currentuserHld"
                        onClick={() => setShowUserProfile(!showUserProfile)}
                      >
                        <img
                          src={currentUser?.Picture}
                          alt="user"
                          className="img-fluid userPicture"
                        />
                        <img
                          src={
                            displayMode === "dark_mode"
                              ? DarkChevronIcon
                              : ChevronDown
                          }
                          alt="chevron-down"
                          className={`img-fluid ${
                            showUserProfile ? "chevron-up" : "chevron-down"
                          }`}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <button
                        className="EnterpriseBtn"
                        onClick={goToEnterprise}
                      >
                        <img
                          src={
                            displayMode === "dark_mode"
                              ? DarkEnterpriseIcon
                              : EnterpriseIcon
                          }
                          alt="enterprise"
                          className="img-fluid"
                        />
                        <span>Enterprises</span>
                      </button>
                      {/* <button className="signInBtn" onClick={onSignInClick}>
                  Sign in
                </button> */}
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className="logo-center">
                <img
                  className="mh-logo img-fluid"
                  src={displayMode === "dark_mode" ? MHDarkLogo : MHLogo}
                  alt="Machine Hack Logo"
                  loading="lazy"
                />
              </div>
            )}
          </div>
        </>
      )}
      {showUserProfile && (
        <UserProfileCard
          setShowUserProfile={setShowUserProfile}
          isDarkMode={displayMode === "dark_mode"}
          currentUser={currentUser}
          userGlobalRankingData={userGlobalRankingData}
        />
      )}
      <NotificationModal
        setShowNotificationModal={() => setShowNotificationModal(false)}
        showNotificationModal={showNotificationModal}
        isDarkMode={displayMode === "dark_mode"}
        unSeenCount={unSeenCount}
        NotificationData={NotificationData}
        setNotificationData={setNotificationData}
        setIsNewNotification={setIsNewNotification}
        setUnSeenCount={setUnSeenCount}
        unseenNotification={unseenNotification}
        currentUser={currentUser}
      />
      <SearchModal
        showSearchModal={showSearchModal}
        setShowSearchModal={() => setShowSearchModal(false)}
        isDarkMode={displayMode === "dark_mode"}
      />
      <MobileHeader
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        isDarkMode={displayMode === "dark_mode"}
        toggleDrawer={toggleDrawer}
        currentUser={currentUser}
      />
    </div>
  );
};
export default Header;
