import { isAuthenticated } from "auth/helper";
import axios from "axios";
import { API, BootcampBackend, Socket_URL } from "../../backend";

function GetAuthorizationConfig() {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("jwt")
        ? JSON.parse(localStorage.getItem("jwt")).token
        : null,
    },
  };
}

export const InterviewJobService = {
  acceptOrRecjectInterview: async (interviewData, body) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${BootcampBackend}/interview/acceptanceFromCandidateUpdate/${interviewData.interview_id}/${interviewData.interview_round_id}`,
          body,
          GetAuthorizationConfig()
        )
        .then((response) => {
          return resolve(response.data.result);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  getAllNotification: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${Socket_URL}notification/user/get?user_id=${isAuthenticated()?.user?._id}`
        )
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  isSeenNotification: async (notificationData) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${Socket_URL}notification/is_seen/update`, notificationData
        )
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
  getAllUserNotification: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${BootcampBackend}/interview/getInterviewsUserSide`
        )
        .then((response) => {
          return resolve(response);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  },
};