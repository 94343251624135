import { Socket_Event_URL } from "backend";
import { Socket_Hackathon_Teams_URL } from "backend";
import { LMS_URL } from "backend";
import { Mh_Coins_Socket } from "backend";
import { Socket_Event_Session_URL } from "backend";
import { Socket_Connect_URL } from "backend";
import io from "socket.io-client";

export const socket = io.connect(Socket_Connect_URL, {
  // secure: true,
  reconnectionDelay: 10000,
  randomizationFactor: 0.5,
  reconnectionDelayMax: 100000,
  reconnectionAttempts: 100,
  transports: ["websocket"],
  "sync disconnect on unload": true,
  // rememberTransport: false
  // reconnection: false,
  // reconnectionDelay: 50000,
  // reconnectionLimit: 10,
  // timeout: 1000,
});

export const EventSocket = io.connect(Socket_Event_URL, {
  reconnectionDelay: 10000,
  randomizationFactor: 0.5,
  reconnectionDelayMax: 100000,
  reconnectionAttempts: 100,
  transports: ["websocket"],
  "sync disconnect on unload": true,
});

export const EventSessionSocket = io.connect(Socket_Event_Session_URL, {
  reconnectionDelay: 10000,
  randomizationFactor: 0.5,
  reconnectionDelayMax: 100000,
  reconnectionAttempts: 100,
  transports: ["websocket"],
  "sync disconnect on unload": true,
});

export const HackathonTeamsSocket = io.connect(Socket_Hackathon_Teams_URL, {
  reconnectionDelay: 10000,
  randomizationFactor: 0.5,
  reconnectionDelayMax: 100000,
  reconnectionAttempts: 100,
  transports: ["websocket"],
  "sync disconnect on unload": true,
});

export const MhcoinsSocket = io.connect(Mh_Coins_Socket, {
  reconnectionDelay: 10000,
  randomizationFactor: 0.5,
  reconnectionDelayMax: 100000,
  reconnectionAttempts: 100,
  transports: ["websocket"],
  "sync disconnect on unload": true,
});

export const LeaderboardSocket = io.connect(LMS_URL, {
  reconnectionDelay: 10000,
  randomizationFactor: 0.5,
  reconnectionDelayMax: 100000,
  reconnectionAttempts: 100,
  transports: ["websocket"],
  "sync disconnect on unload": true,
});