import React from "react";
import Drawer from "@mui/material/Drawer";
import { makeStyles } from "@mui/styles";
import { viewSubscriptionPremium } from "shared/FirebaseEventMethods";
import { ViewBlog } from "shared/FirebaseEventMethods";
import { viewInterviewPrep } from "shared/FirebaseEventMethods";
import { viewCourses } from "shared/FirebaseEventMethods";
import { isAuthenticated } from "auth/helper";
import { useHistory } from "react-router-dom";
import Lottie from "lottie-react";
import coin from "assets/lotties/Feed/Coin.json";
import assessemnt from "assets/img/Feed/assessment.svg";
import practice from "assets/img/Feed/practice.svg";
import blogs from "assets/img/Feed/blogs.svg";
import course from "assets/img/Feed/course.svg";
import hackathon from "assets/img/Feed/hackathon.svg";
import crown from "assets/img/Feed/crown.svg";
import "../../Feed/Components/FeedHeader/FeedHeader.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: "16px",
    },
  },
  mobileUserProfileDrawer: {
    width: "60%",
  },
  userdetalsHld: {
    display: "flex",
    padding: "8px 20px 8px 25px",
    borderBottom: "1px solid #e4e4e4",
    alignItems: "center",
  },
  userOptionsHld: {
    // padding: "10px",
  },
  userOptions: {
    fontWeight: "500",
    color: "#737070",
    fontSize: "13px",
    cursor: "pointer",
    padding: "8px 20px 8px 30px",
    marginBottom: "0px",
  },
  userHighlightOption: {
    fontWeight: "500",
    padding: "10px 20px 10px 30px",
    color: "#e4950f",
    fontSize: "13px",
    cursor: "pointer",
    marginBottom: "0px",
  },
}));

const navigator = [
  {
    name: "AI Assessments",
    img: assessemnt,
    route: "assessments",
  },
  // {
  //   name: "AI Hackathons",
  //   img: hackathon,
  //   route: "hackathons",
  // },
  {
    name: "AI Practice",
    img: practice,
    route: "practices",
  },
  {
    name: "AI Courses",
    img: course,
    route: "bootcamps",
  },
  {
    name: "AI Jobs",
    img: assessemnt,
    route: "jobs",
  },
  {
    name: "AI Blogs",
    img: blogs,
    route: "blogs",
  },
];

const MobileHeader = ({
  openDrawer,
  setOpenDrawer,
  isDarkMode,
  toggleDrawer,
  currentUser,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const MobileHeaderLists = [
    {
      id: "1",
      name: "Premium",
      slug: "/subscriptions",
      show: true,
      firebaseEvents: () => viewSubscriptionPremium(),
    },
    {
      id: "2",
      name: "Blogs",
      slug: "/blogs",
      show: true,
      firebaseEvents: () => ViewBlog(),
    },
    {
      id: "3",
      name: "Assessments",
      slug: "/assessments",
      show: true,
      firebaseEvents: () => viewInterviewPrep(),
    },
    // {
    //   id: "4",
    //   name: "Hackathons",
    //   slug: "/hackathons",
    //   show: true,
    //   firebaseEvents: () => {},
    // },
    {
      id: "5",
      name: "Practices",
      slug: "/practices",
      show: true,
      firebaseEvents: () => {},
    },
    {
      id: "6",
      name: "Courses",
      slug: "/bootcamps",
      show: true,
      firebaseEvents: () => viewCourses(),
    },
    // {
    //   id: "7",
    //   name: "Discussions",
    //   slug: "/discussions",
    //   show: true,
    //   firebaseEvents: () => {},
    // },
    // {
    //   id: "8",
    //   name: "Jobs",
    //   slug: "/jobs",
    //   show: true,
    //   firebaseEvents: () => {},
    // },
    // {
    //   id: "9",
    //   name: "Store",
    //   slug: "/store",
    //   show: true,
    //   firebaseEvents: () => {},
    // },
    // {
    //   id: "10",
    //   name: "Orders",
    //   slug: `/myaccount/${currentUser?._id}?tab=orders`,
    //   show: isAuthenticated(),
    //   firebaseEvents: () => {},
    // },
    // {
    //   id: "11",
    //   name: "Subscriptions",
    //   slug: `/myaccount/${currentUser?._id}?tab=subscriptions`,
    //   show: isAuthenticated(),
    //   firebaseEvents: () => {},
    // },
    // {
    //   id: "12",
    //   name: "Coins",
    //   slug: `/myaccount/${currentUser?._id}?tab=coins`,
    //   show: isAuthenticated(),
    //   firebaseEvents: () => {},
    // },
  ];
  return (
    // <Drawer
    //   anchor="left"
    //   open={openDrawer}
    //   onClose={toggleDrawer}
    //   classes={{ paper: classes.mobileUserProfileDrawer }}
    //   className="mobHeaderDrawer"
    // >
    //   <div role="presentation" className={isDarkMode ? "darkTheme" : ""}>
    //     {isAuthenticated() && (
    //       <div
    //         className={`${classes.userdetalsHld} ${
    //           isDarkMode ? "darkCardBody" : ""
    //         }`}
    //         onClick={() => {
    //           history.push(`/user/${currentUser._id}`);
    //           setOpenDrawer(false);
    //         }}
    //       >
    //         <img
    //           className="ml-2 user-image-container"
    //           src={currentUser.Picture}
    //           alt="pic"
    //           width="30"
    //           height="30"
    //           style={{ borderRadius: "50px", marginRight: "10px" }}
    //           loading="lazy"
    //         />
    //         <h6 className={isDarkMode ? "darkText" : ""}>{currentUser.Name}</h6>
    //       </div>
    //     )}
    //     <div
    //       className={`${classes.userOptionsHld} ${
    //         isDarkMode ? "darkCardBody drawerHeightFix" : ""
    //       }`}
    //     >
    //       {MobileHeaderLists?.map((item) => (
    //         <React.Fragment key={item?.id}>
    //           {item?.show && (
    //             <p
    //               className={classes.userOptions}
    //               onClick={() => {
    //                 history.push(item.slug);
    //                 setOpenDrawer(false);
    //                 item.firebaseEvents();
    //               }}
    //             >
    //               {item?.name}
    //             </p>
    //           )}
    //         </React.Fragment>
    //       ))}
    //     </div>
    //   </div>
    // </Drawer>
    <Drawer
      anchor={"left"}
      open={openDrawer}
      onClose={toggleDrawer}
      className={`feed-nav-toggle bootstrap-iso ${isDarkMode ? "dark-feed-nav darkTheme" : ""}`}
      onOpen={""}
    >
      <div className="">
        {isAuthenticated() && (
          <div className="align-items-end d-flex dark-nav-bar-info-section py-3 px-3">
            <div className="d-flex flex-column w-100">
              <img
                src={currentUser?.Picture}
                alt="user-icon"
                className="img-fluid nav-user-img"
                loading="lazy"
              />
              <span
                className={`${
                  isDarkMode ? "dark-nav-user-text" : "nav-user-text"
                } `}
              >
                {currentUser?.Name}
              </span>
              {currentUser?.UserName && (
                <span
                  className={`dark-nav-user-url ${
                    isDarkMode ? "darkText" : ""
                  }`}
                >
                  {currentUser?.UserName}
                </span>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <Lottie
                animationData={coin}
                loop={true}
                style={{
                  width: "23%",
                  display: "block",
                }}
              />
              <span className="nav-coin-count">{currentUser?.coins}</span>
            </div>
          </div>
        )}
        <div className={`nav-route-mob nav-route`}>
          {navigator.map((x, i) => {
            return (
              <div
                className="nav-route-section py-3"
                key={i}
                onClick={() => {
                  history.push(`/${x.route}`);
                  setOpenDrawer(false);
                }}
              >
                <img
                  src={x.img}
                  alt="user-icon"
                  className={`img-fluid px-4 ${
                    isDarkMode ? "iconToWhite" : ""
                  }`}
                  loading="lazy"
                />
                <span
                  className={`nav-route-span ${isDarkMode ? "whiteText" : ""}`}
                >
                  {x.name}
                </span>
              </div>
            );
          })}
        </div>
        <div
          className="nav-premium-btn text-center"
          onClick={() => {
            history.push("/subscriptions");
            setOpenDrawer(false);
          }}
        >
          <img
            src={crown}
            alt="user-icon"
            className="img-fluid px-3"
            loading="lazy"
          />
          <span className="">Buy Premium Now</span>
        </div>
      </div>
    </Drawer>
  );
};
export default MobileHeader;
