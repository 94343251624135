let initialState = {
  display_mode: "",
  url: ""
};

function displayReducer(state = initialState, action) {
  if (action.type === "SET_DISPLAY_MODE") {
    return {
      ...state,
      display_mode: action.payload,
    };
  }
  if (action.type === "SET_CURRENT_URL") {
    return {
      ...state,
      url: action.payload,
    };
  }
  return state;
}

export default displayReducer;
