import React, { useState, useEffect, useCallback } from "react";
import Button from "components/CustomButtons/Button.js";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { isAuthenticated } from "auth/helper";
import { useSelector } from "react-redux";
import { getUserInfo } from "user/helper/userapicall";
import axios from "axios";
import { API } from "backend";
import { useHistory, useLocation } from "react-router-dom";
import MuiAlert from "@mui/lab/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import rightArrow from "assets/img/rightArrowFix.svg";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import { updtaeUserSub } from "user/helper/userapicall";
import { useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import GridItem from "components/Grid/GridItem.js";
import { useDropzone } from "react-dropzone";
import Grid from "@mui/material/Grid";
import fileIcon from "./img/file-icon.svg";
import downloadIcon from "./img/download-icon.svg";
import resumeIcon from "assets/img/ProfilePage/resume_hack_icon.svg";
import { RadioGroup } from "@mui/material";
import Radio from "@mui/material/Radio";
import selectedFileImg from "assets/img/Submission/selectedFile.svg";
import closeIconImg from "assets/img/Submission/closeIcon.svg";
import LoginPageNew from "views/LoginPage/LoginPageNew";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { BootcampBackend } from "backend";
import ErrMsg from "./ErrMsg";
import { motion } from "framer-motion/dist/framer-motion";
import { isMobile } from "react-device-detect";
import dataSubmitBtn from "../Reuseable/img/data-submit.svg";
import DownloadBtn from "../Reuseable/img/notebook-download-icon.svg";
import submissionFile from "../Reuseable/img/darksubmissionFile.svg";
import darkCloseFile from "../Reuseable/img/close-file.svg";
import Typography from "@mui/material/Typography";
import DarkFileIcon from "../Reuseable/img/dark-file.svg";
import GenpactGooglePopup from "./Popups/GenpactGooglePopup/GenpactGooglePopup";
import CityDropdown from "./CityDropDown/CityDropDown";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function HackathonData(props) {
  const { hackathonHREFName, isDarkMode, socialCampaignTracking } = props;
  const dispatch = useDispatch();
  const [checkBox, setCheckBox] = React.useState(false);
  const [statusRegister, setStatusRegister] = useState(false);
  const [messageRegister, setMessageRegister] = useState("");
  const [isEmployee, setIsEmployee] = React.useState(false);
  const currentUser = useSelector((store) => store.user.user);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [usererror, setError] = useState("");
  const [check, setCheck] = React.useState(false);
  const [status, setStatus] = useState(false);
  const [hackathon, setHackathon] = useState({});
  const [uploadResume, setUploadResume] = React.useState(true);
  const [resume, setSelectedFile] = useState([]);
  const [messageData, setMessageData] = useState("");
  const [start, setStart] = useState(false);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Location, setLocation] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Experience, setExperience] = useState("");
  const [LatestDesignation, setLatestDesignation] = useState("");
  const [Mobile, setMobile] = useState("");
  const [openProfileUpadate, setOpenProfileUpadate] = React.useState(false);
  const [profileNameError, setProfileNameError] = React.useState(false);
  const [nameError, setNameError] = useState("");
  const [isNameError, setIsNameError] = useState(false);
  const [showRocketCapitalCrypto, setShowRocketCapitalCrypto] = useState(false);
  const [linkedIn, setLinkedIn] = useState("");
  const [metaWalletAddress, setMetaWalletAddress] = useState("");
  const [github, setGithub] = useState("");
  const [error, seterror] = useState(false);
  const [linkedInErr, setLinkedInErr] = useState(false);
  const [twitterErr, setTwitterErr] = useState(false);
  const [githubErr, setGithubErr] = useState(false);
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const stepper = [1, 2];
  const [city, setCity] = useState({});
  const [designationError, setDesignationError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [showCustomPopup, setShowCustomPopup] = useState("");
  const location = useLocation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedFile([...resume, ...acceptedFiles]);
    },
    [resume]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxFiles: 1,
    accept: { "application/pdf": [".pdf"] },
  });
  const isFileTooLarge = acceptedFiles[0]?.size > 2000000;

  const handleIsEmployeeChange = (event) => {
    setIsEmployee(!isEmployee);
  };

  const handleCloseMsg = () => {
    setStatus(!status);
  };

  const handleCloseMsgRegister = () => {
    setStatusRegister(false);
  };

  const handleCloseProfileUpdate = () => {
    setOpenProfileUpadate(false);
  };

  function HiringImageIcon(props) {
    return (
      <div>
        <img
          className="mr-4 img-fluid"
          src={resumeIcon}
          alt="resume"
          loading="lazy"
        />
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
          Drag and drop your Resume{" "}
          {isEmployee && <span style={{ color: "red" }}>*</span>}
        </p>
        <p style={{ fontSize: "14px" }}>
          Allowed formats word and PDF - Max 1mb
        </p>
      </div>
    );
  }

  const handleClickOpen = (scrollType) => () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
      return;
    }

    setName(currentUser.Name);
    setEmail(currentUser.Email);
    // setLocation(currentUser.Location);
    setExperience(currentUser.Experience);
    setLatestDesignation(currentUser.LatestDesignation);
    setOrganization(currentUser.Organization);
    setMobile(currentUser.Mobile);
    setCity({
      name: currentUser.city,
      state_name: currentUser.state,
      country_name: currentUser.country,
    });
    if (hackathon.HREFName === "rocket_capital_crypto_forecasting") {
      setShowRocketCapitalCrypto(true);
    } else {
      hackathon.HREFName ===
        "sustainability_hackathon_lets_crack_the_climate_resilience_code" ||
      hackathon.HREFName ===
        "genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code"
        ? setShowCustomPopup("genpact-google")
        : setOpen(true);
    }
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var showParticipateButton = false;

  if (currentUser.ranks !== undefined) {
    showParticipateButton = !currentUser.ranks.hasOwnProperty(
      props.showParticipate
    );
  }

  const rocketCapitalCryptoRegister = () => {
    if (!linkedIn?.includes("https://www.linkedin.com/")) {
      setLinkedInErr(true);
      setMessage("Please enter valid LinkedIn");
      return;
    }
    if (github && !github?.includes("https://github.com/")) {
      setGithubErr(true);
      setMessage("Please enter valid Github");
      return;
    }
    if (twitter && !twitter?.includes("https://www.twitter.com")) {
      setTwitterErr(true);
      setMessage("Please enter valid Twitter");
      return;
    }
    const requestBody = {
      name: Name,
      email: currentUser.Email,
      phone: Mobile,
      organization: Organization,
      designation: LatestDesignation,
      linkedIn: linkedIn,
      github: github,
      discord: discord,
      Twitter: twitter,
    };
    axios
      .post(
        `${BootcampBackend}/hackathons/registerForRocketCaptialHackathon`,
        requestBody
      )
      .then((response) => {
        RegisterHackathon();
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        console.log(err);
        seterror(true);
        setMessage(err.response.data.result.message);
      });
  };

  const rocketCapitalCryptoSubmit = () => {
    const requestBody = {
      metaMaskWalletAddress: metaWalletAddress?.toLowerCase(),
    };
    axios
      .put(
        `${BootcampBackend}/hackathons/updateRocketCapitalRegistration`,
        requestBody
      )
      .then((response) => {
        participateAPIcall();
      })
      .catch((err) => {
        console.log(err);
        seterror(true);
        setMessage(err.response.data.result.message);
      });
  };
  const RegisterHackathon = async () => {
    await axios
      .post(`${API}/register/${props.hackathonHREFName}`)
      .then((response) => {
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setMessage(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const ParticipateHackathonNew = () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      showParticipateButton = !showParticipateButton;
      var regPhoneno = /^\d{10}$/;
      var regName = /^[a-zA-Z\s]{1,30}$/;
      var regExperience = /^\d{1,2}$/;
      var regLatestDesignation = /^[a-zA-Z\s]{3,50}$/;
      var regLocation = /^[a-zA-Z\s]{2,50}$/;
      var regOrganization = /^[a-zA-Z\s]{3,100}$/;

      if (Name === "" || Name === null || !regName.test(Name)) {
        setIsNameError(true);
        setNameError(
          "Name cannot be blank and it should contain alphabets only."
        );
        return;
      }

      if (Object.keys(city).length === 0) {
        setError("Current city should be required");
        setStatus(true);
        return;
      }

      if (Organization === null || Organization === "") {
        //Do nothing
      } else if (!regOrganization.test(Organization.trim())) {
        setError(
          "Organization should be an alphabet and contain at least 3 characters"
        );
        setStatus(true);
        return;
      }

      if (Experience === null || Experience === "") {
        //Do Nothing
      } else if (!regExperience.test(Experience.trim())) {
        setError(
          "Total Experience should be a number and not more than 2 digits."
        );
        setStatus(true);
        return;
      }

      if (LatestDesignation === null || LatestDesignation === "") {
        //Do nothing
      } else if (!regLatestDesignation.test(LatestDesignation.trim())) {
        setError(
          "Latest Designation should be an alphabet and contain atleast 3 characters"
        );
        setStatus(true);
        return;
      }

      if (Mobile === null || Mobile === "") {
        // Do nothing
      } else if (!regPhoneno.test(Mobile.trim())) {
        setError("Phone number should be of 10 digits");
        setStatus(true);
        return;
      }

      const formData = new FormData();
      formData.append("Name", Name);
      city.country_name && formData.append("country", city.country_name);
      city.state_name && formData.append("state", city.state_name);
      city.name && formData.append("city", city.name);
      currentUser.currentCtc &&
        formData.append("currentCtc", currentUser.currentCtc);
      currentUser.noticePeriod &&
        formData.append("noticePeriod", currentUser.noticePeriod);
      // formData.append("Location", Location === null ? "" : Location);
      Organization &&
        formData.append(
          "Organization",
          Organization === null ? "" : Organization
        );
      Experience &&
        formData.append("Experience", Experience === null ? "" : Experience);
      LatestDesignation &&
        formData.append(
          "LatestDesignation",
          LatestDesignation === null ? "" : LatestDesignation
        );
      Mobile && formData.append("Mobile", Mobile === null ? "" : Mobile);
      currentUser?.skills?.length > 0 &&
        currentUser?.skills.filter((x) => {
          formData.append("skills", x);
        });

      updtaeUserSub(formData, currentUser._id)
        .then((response) => {
          if (response.data.error) {
            setError(response.data.error);
            setStatus(true);
          } else {
            console.log(response.data.user);

            setMessage(response.data.message);

            participateAPIcall();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const participateAPIcall = () => {
    socialCampaignTracking();
    axios
      .post(`${API}/participate/${props.hackathonHREFName}`)
      .then((response) => {
        setMessage(response.data.message);
        if (
          props.hackathonHREFName ===
          "machine_learning_hackathon_in_association_with_amazon_web_services"
        ) {
          localStorage.setItem("awspopup", true);
        }
        if (localStorage.getItem("participateStatus") === "false") {
          localStorage.setItem("participateStatus", true);
        }

        if (hackathon.Hiring_hackathon === true) {
          axios
            .post(
              `${API}/update_interested_candidates/${
                props.hackathonHREFName
              }?hiring=${
                props.hackathonHREFName ===
                "predict_the_fastest_car_to_win_the_race"
                  ? isEmployee
                  : uploadResume
              }`
            )
            .then((response) => {
              window.location.href = `${hackathon.Dataset}`;
              window.location.reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          window.location.href = `${hackathon.Dataset}`;
          window.location.reload();
        }
      });
  };

  const updateResume = () => {
    if (resume.length === 0 || resume.length > 1) {
      setStatus(true);
      setError("Please Upload resume in .pdf format!  ");
    } else {
      setStart(true);
      setMessageData("File Uplaod Started ...");

      const config = {
        onUploadProgress: (progress) => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
          if (totalSizeInMB < 1) {
            setMessageData(
              `${loadedSizeInMB * 1000} KB out of uploaded ${
                totalSizeInMB * 1000
              } KB`
            );
          } else if (loadedSizeInMB < 1) {
            setMessageData(
              `${loadedSizeInMB * 1000} KB out of uploaded ${totalSizeInMB} MB`
            );
          } else {
            setMessageData(
              `${loadedSizeInMB} MB out of uploaded ${totalSizeInMB} MB`
            );
          }
        },

        headers: {
          "content-type": "multipart/form-data",
        },
      };

      const formData = new FormData();
      formData.append("resume", resume[0]);
      formData.append("postedby", currentUser._id);

      axios
        .post(`${API}/createresume`, formData, config)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            getUserInfo(currentUser._id)
              .then((response) => {
                setName(response.data.user.Name);
                // setLocation(response.data.user.Location);
                setOrganization(response.data.user.Organization);
                setExperience(response.data.user.Experience);
                setMobile(response.data.user.Mobile);
                setLatestDesignation(response.data.user.LatestDesignation);
              })
              .catch((err) => console.log(err));
            setStart(true);
            setError(response.data.error);
            setStatus(true);
            setOpenProfileUpadate(true);

            setOpen(false);
          } else {
            setMessageData(response.data.message);
            setStart(true);
            ParticipateHackathon();
          }
        })
        .catch((error) => {
          console.log("this is error", error);
        });
    }
  };

  const updateUserDataAndResume = () => {
    const formData = new FormData();
    formData.append("Name", Name || currentUser?.Name);
    city.country_name && formData.append("country", city.country_name);
    city.state_name && formData.append("state", city.state_name);
    city.name && formData.append("city", city.name);
    currentUser.currentCtc &&
      formData.append("currentCtc", currentUser.currentCtc);
    currentUser.noticePeriod &&
      formData.append("noticePeriod", currentUser.noticePeriod);
    formData.append("Organization", Organization || currentUser.Organization);
    formData.append("Experience", Experience || currentUser.Experience);
    formData.append(
      "LatestDesignation",
      LatestDesignation || currentUser.LatestDesignation
    );
    formData.append("Mobile", Mobile || currentUser.Mobile);
    currentUser?.skills?.length > 0 &&
      currentUser?.skills.filter((x) => {
        formData.append("skills", x);
      });

    updtaeUserSub(formData, currentUser._id)
      .then((response) => {
        if (response.data.error) {
          setError(response.data.error);
          setStatus(true);
        } else {
          if (resume.length > 0 && resume[0]?.size) {
            updateResume();
          } else {
            ParticipateHackathon();
          }
          setMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const UploadResumeHiringHackathon = () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      if (currentUser.Name === "" || currentUser.Name === null) {
        var regName = /^[a-zA-Z\s]{1,30}$/;

        if (Name === "" || Name === null || !regName.test(Name)) {
          setIsNameError(true);
          setNameError(
            "Name cannot be blank and it should contain alphabets only."
          );
          return;
        }

        const formData = new FormData();
        formData.append("Name", Name);
        city.country_name && formData.append("country", city.country_name);
        city.state_name && formData.append("state", city.state_name);
        city.name && formData.append("city", city.name);
        currentUser.currentCtc &&
          formData.append("currentCtc", currentUser.currentCtc);
        currentUser.noticePeriod &&
          formData.append("noticePeriod", currentUser.noticePeriod);
        // formData.append("Location", currentUser.Location);
        currentUser.Organization &&
          formData.append("Organization", currentUser.Organization);
        currentUser.Experience &&
          formData.append("Experience", currentUser.Experience);
        currentUser.LatestDesignation &&
          formData.append("LatestDesignation", currentUser.LatestDesignation);
        currentUser.Mobile && formData.append("Mobile", currentUser.Mobile);
        currentUser?.skills?.length > 0 &&
          currentUser?.skills.filter((x) => {
            formData.append("skills", x);
          });

        updtaeUserSub(formData, currentUser._id)
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
              setStatus(true);
            } else {
              if (resume.length > 0 && resume[0]?.size) {
                updateResume();
              } else {
                ParticipateHackathon();
              }
              setMessage(response.data.message);
            }
          })
          .catch((err) => console.log(err));
      } else if (hackathonHREFName === "indus_os_app_discovery_challenge") {
        var regName = /^[a-zA-Z\s]{3,30}$/;
        var regLatestDesignation = /^[a-zA-Z\s]{3,50}$/;
        var regOrganization = /^[a-zA-Z\s]{3,100}$/;
        const regPhone = /^[0-9]{5,15}$/;
        if (Name === "" || Name === null || !regName.test(Name)) {
          setIsNameError(true);
          setNameError(
            "Name cannot be blank and it should contain alphabets only."
          );
          return;
        }
        if (
          LatestDesignation === null ||
          LatestDesignation === "" ||
          !regLatestDesignation.test(LatestDesignation.trim())
        ) {
          setDesignationError(
            "Latest Designation should be an alphabet and contain atleast 3 characters"
          );
          return;
        } else {
          setDesignationError("");
        }
        if (
          Organization === null ||
          Organization === "" ||
          !regOrganization.test(Organization.trim())
        ) {
          setOrganizationError(
            "Organization should be an alphabet and contain at least 3 characters"
          );
          return;
        } else {
          setOrganizationError("");
        }
        if (Mobile === null || Mobile === "" || !regPhone.test(Mobile.trim())) {
          setMobileError("Phone number should be numbers");
          return;
        } else {
          setMobileError("");
        }
        const formData = new FormData();
        formData.append("Name", Name);
        Organization && formData.append("Organization", Organization);
        currentUser.Experience &&
          formData.append("Experience", currentUser.Experience);
        LatestDesignation &&
          formData.append("LatestDesignation", LatestDesignation);
        Mobile && formData.append("Mobile", Mobile);
        city.country_name && formData.append("country", city.country_name);
        city.state_name && formData.append("state", city.state_name);
        city.name && formData.append("city", city.name);
        currentUser.currentCtc &&
          formData.append("currentCtc", currentUser.currentCtc);
        currentUser.noticePeriod &&
          formData.append("noticePeriod", currentUser.noticePeriod);
        currentUser?.skills?.length > 0 &&
          currentUser?.skills.filter((x) => {
            formData.append("skills", x);
          });
        updtaeUserSub(formData, currentUser._id)
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
              setStatus(true);
            } else {
              if (resume.length > 0) {
                updateResume();
              } else {
                ParticipateHackathon();
              }
              // if (isEmployee) {
              //   if (resume.length > 0) {
              //     updateResume();
              //   } else {
              //     ParticipateHackathon();
              //   }
              // } else {
              //   if (resume.length > 0) {
              //     updateResume();
              //   } else {
              //     ParticipateHackathon();
              //   }
              // }
              setMessage(response.data.message);
            }
          })
          .catch((err) => console.log(err));
      } else if (
        hackathonHREFName ===
          "sustainability_hackathon_lets_crack_the_climate_resilience_code" ||
        hackathonHREFName ===
          "genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code"
      ) {
        updateUserDataAndResume();
      } else {
        if (resume.length > 0) {
          updateResume();
        } else {
          ParticipateHackathon();
        }
      }
    }
  };

  const ParticipateHackathon = () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      showParticipateButton = !showParticipateButton;
      socialCampaignTracking();
      axios
        .post(`${API}/participate/${props.showParticipate}`)
        .then((response) => {
          if (response.data.error) {
            getUserInfo(currentUser._id)
              .then((response) => {
                setName(response.data.user.Name);
                // setLocation(response.data.user.Location);
                setOrganization(response.data.user.Organization);
                setExperience(response.data.user.Experience);
                setMobile(response.data.user.Mobile);
                setLatestDesignation(response.data.user.LatestDesignation);
                setCity({
                  name: response.data.user.city,
                  state_name: response.data.user.state,
                  country_name: response.data.user.country,
                });
              })
              .catch((err) => console.log(err));
            setError(response.data.error);
            setStatus(true);
            setOpenProfileUpadate(true);
            setOpen(false);
          } else {
            setMessage(response.data.message);
            if (
              props.showParticipate ===
              "machine_learning_hackathon_in_association_with_amazon_web_services"
            ) {
              localStorage.setItem("awspopup", true);
            }
            if (localStorage.getItem("participateStatus") === "false") {
              localStorage.setItem("participateStatus", true);
            }
            if (
              hackathon.Hiring_hackathon === true &&
              (hackathonHREFName !==
                "sustainability_hackathon_lets_crack_the_climate_resilience_code" ||
                hackathonHREFName !==
                  "genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code")
            ) {
              axios
                .post(
                  `${API}/update_interested_candidates/${
                    props.hackathonHREFName
                  }?hiring=${
                    props.hackathonHREFName ===
                    "predict_the_fastest_car_to_win_the_race"
                      ? isEmployee
                      : uploadResume
                  }`
                )
                .then((response) => {
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              window.location.reload();
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSubmit = (event) => {
    setError("");
    if (Name === "" || Name === null) {
      setProfileNameError(true);
    }
    if (Name === "" || Name === null) {
      setError("Name field is required.");
      setStatus(true);
    } else if (Name.length <= 1) {
      setError("Name must be more than 1 characters");
      setStatus(true);
    } else if (Organization.length <= 3) {
      setError("Organization must be more than 3 characters");
      setStatus(true);
    } else if (LatestDesignation.length <= 3) {
      setError("LatestDesignation must be more than 3 characters");
      setStatus(true);
    } else if (Object.keys(city).length === 0) {
      setError("Country must be more than 3 characters");
      setStatus(true);
    } else {
      const formData = new FormData();
      formData.append("Name", Name);
      city.country_name && formData.append("country", city.country_name);
      city.state_name && formData.append("state", city.state_name);
      city.name && formData.append("city", city.name);
      currentUser.currentCtc &&
        formData.append("currentCtc", currentUser.currentCtc);
      currentUser.noticePeriod &&
        formData.append("noticePeriod", currentUser.noticePeriod);
      Organization && formData.append("Organization", Organization);
      Experience && formData.append("Experience", Experience);
      LatestDesignation &&
        formData.append("LatestDesignation", LatestDesignation);
      Mobile && formData.append("Mobile", Mobile);
      currentUser?.skills?.length > 0 &&
        currentUser?.skills.filter((x) => {
          formData.append("skills", x);
        });

      var phoneno = /^[0-9]+$/;
      if (!Mobile.match(phoneno)) {
        setError(`Phone number is invalid `);
        setStatus(true);
      } else {
        updtaeUserSub(formData, currentUser._id)
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
              setStatus(true);
            } else {
              setMessage(response.data.message);

              dispatch({
                type: "GET_UPDATED_USER",
                payload: response.data.user,
              });
              window.location.reload();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const getHackathon = () => {
    axios
      .get(`${API}/hackathon/${props.hackathonHREFName}`)
      .then((response) => {
        setHackathon(response.data.hackathon);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getHackathon();
  }, []);

  const registerCheckRocketCapital = () => {
    axios
      .get(
        `${BootcampBackend}/hackathons/rocketCaptialHackathonRegistrationCheck`
      )
      .then((response) => {
        console.log("registercheck");
        setCurrentStep(2);
      })
      .catch((err) => {
        if (err.response.status === 402) {
          setCurrentStep(1);
        }
      });
  };

  useEffect(() => {
    showParticipateButton &&
      props.hackathonHREFName === "rocket_capital_crypto_forecasting" &&
      registerCheckRocketCapital();
  }, [showParticipateButton]);

  return (
    <>
      {error && (
        <Snackbar open={error} onClose={() => seterror(false)}>
          <div>
            <Alert onClose={() => seterror(false)} severity="error">
              {message}
            </Alert>
          </div>
        </Snackbar>
      )}
      <div className="hackathonData hackathon-content-border">
        <div className={``}>
          {isAuthenticated() &&
            showParticipateButton === false &&
            props.hackathonHREFName !==
              "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing" && (
              <div
                className={`d-flex usersubmissionInfo align-items-center ${
                  isMobile ? "px-2" : "px-4"
                } py-3 justify-content-center`}
              >
                <h5
                  className={`mb-0 ${isMobile ? "mr-2" : "mr-4"} ${
                    isDarkMode ? "darkText" : ""
                  }`}
                >
                  Do Your Submission
                </h5>

                <button
                  type="button"
                  className={`${
                    isDarkMode ? "dark-button" : "dark-button text-white"
                  }`}
                  onClick={() => {
                    isAuthenticated()
                      ? history.push(
                          `/hackathons/${props.hackathonHREFName}/submission`
                        )
                      : (localStorage.setItem(
                          "prevPath",
                          `/hackathons/${props.hackathonHREFName}/submission`
                        ),
                        history.push("/login"));
                  }}
                >
                  Submit Now
                  <img
                    src={isDarkMode ? dataSubmitBtn : rightArrow}
                    className="img-fluid ml-2"
                    alt=""
                  />
                </button>
              </div>
            )}
          <div
            className={`datadarkDownloadHld ${
              isAuthenticated() &&
              showParticipateButton === false &&
              props.hackathonHREFName !==
                "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing"
                ? "border-left-0 border-right-0 borderTop"
                : ""
            }`}
          >
            <h5
              className={`${isMobile ? "col-10" : ""} p-2 m-0 ${
                isDarkMode ? "whiteText" : ""
              }`}
            >
              Machinehack competitions download - {props.hackathon.HName}
            </h5>
            {hackathon && hackathon.HData && (
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.5 }}
                variants={{
                  visible: { opacity: 1, x: 0 },
                  hidden: { opacity: 0, x: 20 },
                }}
                className={`${
                  isMobile ? "col-9" : ""
                } hackathonDataDesc px-4 py-3 ${
                  isDarkMode ? "dark-overview" : "mx-4 overview "
                }`}
                dangerouslySetInnerHTML={{
                  __html: hackathon.HData.about,
                }}
              ></motion.div>
            )}
            {hackathon &&
              hackathon.HData &&
              hackathon.HData.files &&
              hackathon.HData.files.map((file, index) => (
                <p
                  className={`${
                    isMobile ? "px-2" : "px-4"
                  } py-2 m-0 d-flex justify-content-between ${
                    index === 0
                      ? "border-left-0 border-right-0 borderBottom borderTop"
                      : "borderBottom"
                  }`}
                  key={index}
                >
                  <span>
                    <img
                      src={isDarkMode ? DarkFileIcon : fileIcon}
                      className="img-fluid mr-1"
                      alt="file"
                      loading="lazy"
                    />
                    {file.name}
                  </span>
                  <span>{file.size}kb</span>
                </p>
              ))}
            <p
              className={`text-center pb-2 px-4 mb-3 pt-3 mt-4 col-lg-10 mx-auto col-12 ${
                isDarkMode ? "darkText" : ""
              }`}
            >
              By clicking "Download" you agree to be bound to our competition
              rules, privacy policies and terms. Please check the rules under
              Home tab before participating.
            </p>
            <div className="d-flex flex-column justify-content-center text-center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBox}
                    onChange={() => {
                      hackathon.Submission_Closed === false
                        ? setCheckBox(!checkBox)
                        : setCheckBox(false);
                    }}
                    name="checkedB"
                    style={{ color: `${isDarkMode ? "#B5C0D3" : "#e8505b"}` }}
                  />
                }
                className={`${isDarkMode ? "darkText" : ""}`}
                label="Agree Terms & Conditions"
              />
              <div className="text-center mb-4">
                {checkBox ? (
                  <>
                    {(!isAuthenticated() &&
                      props.showButtonFlag &&
                      hackathon.coming_soon === false) ||
                    (showParticipateButton &&
                      props.showButtonFlag &&
                      hackathon.coming_soon === false) ? (
                      <button
                        className=""
                        onClick={handleClickOpen("paper")}
                        style={{
                          background: isDarkMode
                            ? "#6045d7"
                            : "linear-gradient(121.13deg, #DC4E5A 20.17%, #E2595B 31.41%, #F57F5F 71.17%, #FD8E61 92.78%)",
                          color: isDarkMode ? "#000 !important" : "white",
                        }}
                      >
                        Download All{" "}
                        <img
                          src={isDarkMode ? downloadIcon : DownloadBtn}
                          className={`img-fluid ml-2 dark-download-white`}
                          alt="file"
                          loading="lazy"
                        />
                      </button>
                    ) : (
                      <a href={hackathon.Dataset} download>
                        <button
                          className=""
                          style={{
                            background: isDarkMode
                              ? "linear-gradient(283.18deg, #7554EC -10.6%, #E75996 63.41%, #FF8841 97.5%)"
                              : "linear-gradient(121.13deg, #DC4E5A 20.17%, #E2595B 31.41%, #F57F5F 71.17%, #FD8E61 92.78%)",
                            color: isDarkMode ? "#fff" : "#fff",
                            border: "none",
                          }}
                        >
                          Download All{" "}
                          <img
                            src={isDarkMode ? downloadIcon : DownloadBtn}
                            className={`img-fluid ml-1 colorLightInvert`}
                            alt="file"
                            loading="lazy"
                            style={{ width: "20px", height: "15px" }}
                          />
                        </button>
                      </a>
                    )}
                  </>
                ) : (
                  <button className="">
                    Download All{" "}
                    <img
                      src={isDarkMode ? downloadIcon : DownloadBtn}
                      className={`img-fluid ml-2 dark-download-white`}
                      alt="file"
                      loading="lazy"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openProfileUpadate}
        onClose={handleCloseProfileUpdate}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={`${isDarkMode === "dark_mode" ? "darkTheme" : ""}`}
      >
        {message ? (
          <SnackbarContent
            message={
              <span>
                {" "}
                <b> {message} </b>
              </span>
            }
            close
            color="success"
            icon="info_outline"
          />
        ) : (
          ""
        )}
        {status ? (
          <Snackbar
            open={status}
            autoHideDuration={15000}
            onClose={handleCloseMsg}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <div>
              <Alert onClose={handleCloseMsg} severity="error">
                {usererror}
              </Alert>
            </div>
          </Snackbar>
        ) : (
          ""
        )}
        <DialogTitle id="scroll-dialog-title">
          Update Profile{" "}
          <small style={{ color: "#E8290B", fontSize: "10px" }}>
            All Field Required *
          </small>
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "10px" }}
                value={Name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                label="Organization"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "10px" }}
                value={Organization}
                onChange={(e) => {
                  setOrganization(e.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                label="Experience"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "10px" }}
                value={Experience}
                onChange={(e) => {
                  setExperience(e.target.value);
                }}
              />
              <TextField
                id="outlined-basic"
                label="Designation"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "10px" }}
                value={LatestDesignation}
                onChange={(e) => {
                  setLatestDesignation(e.target.value);
                }}
              />
              <CityDropdown
                city={city}
                setCity={setCity}
                showParticipateButton={showParticipateButton}
                currentUser={currentUser}
                isDarkMode={isDarkMode}
              />
              <TextField
                id="outlined-basic"
                label="10 digit Mobile Number"
                variant="outlined"
                fullWidth
                style={{ marginBottom: "10px" }}
                value={Mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              />
            </GridItem>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseProfileUpdate}
            style={{ backgroundColor: "#e9505c" }}
          >
            Cancel
          </Button>
          <Button onClick={handleSubmit} style={{ backgroundColor: "#019031" }}>
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={`${
          hackathon.Hiring_hackathon === true
            ? hackathonHREFName === "indus_os_app_discovery_challenge"
              ? "sm"
              : "md"
            : "sm"
        }`}
        style={{ height: "100vh" }}
        className={`${
          hackathon.Hiring_hackathon === true ? "HiringModal" : ""
        } ${isDarkMode ? "dark-hiring-acceptance-form darkTheme" : ""}`}
      >
        {message ? (
          <SnackbarContent
            message={
              <span>
                {" "}
                <b> {message} </b>
              </span>
            }
            close
            color="success"
            icon="info_outline"
          />
        ) : (
          ""
        )}
        {status ? (
          <Snackbar
            open={status}
            autoHideDuration={15000}
            onClose={handleCloseMsg}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <div>
              <Alert onClose={handleCloseMsg} severity="error">
                {usererror}
              </Alert>
            </div>
          </Snackbar>
        ) : (
          ""
        )}

        {start && (
          <Snackbar open={start} onClose={handleClose}>
            <div>
              <Alert onClose={handleClose} severity="success">
                {messageData}
              </Alert>
            </div>
          </Snackbar>
        )}

        {statusRegister ? (
          <Snackbar
            open={statusRegister}
            autoHideDuration={15000}
            onClose={handleCloseMsgRegister}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <div>
              <Alert onClose={handleCloseMsgRegister} severity="success">
                {messageRegister}
              </Alert>
            </div>
          </Snackbar>
        ) : (
          ""
        )}
        <DialogTitle
          id="scroll-dialog-title"
          style={{
            textAlign: "left",
            padding: "45px 24px 10px",
          }}
        >
          <Typography
            variant="h6"
            style={{
              margin: "0px",
              alignContent: "start",
              fontWeight: "bold",
              textTransform: "none",
            }}
            className={isDarkMode ? "darkText" : ""}
          >
            {hackathon.Hiring_hackathon === true
              ? " Hiring Form"
              : "Acceptance Form"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {hackathon.Hiring_hackathon === true ? (
            <>
              <Grid container style={{ marginBottom: "10px" }}>
                {hackathonHREFName !== "indus_os_app_discovery_challenge" && (
                  <Grid item xs={12} lg={6} md={6} style={{ padding: "20px" }}>
                    <p
                      style={{ marginTop: "8px" }}
                      className={`${
                        isDarkMode ? "darkText" : "current-employed"
                      }`}
                    >
                      <span style={{ color: "red" }}>*</span>Are you interested
                      in future opportunities
                      {props.hackathonHREFName ===
                        "wipro_sustainability_machine_learning_challenge" &&
                        " with"}
                      {props.hackathonHREFName ===
                        "wipro_sustainability_machine_learning_challenge" && (
                        <span> Wipro</span>
                      )}
                      ?
                    </p>
                    <FormControl
                      component="fieldset"
                      className="hiring-radio-btns"
                    >
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={isEmployee}
                        onChange={handleIsEmployeeChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={
                            <Radio
                              style={{
                                color: isDarkMode ? "#B5C0D3" : "#EF4F5E",
                              }}
                            />
                          }
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            <Radio
                              style={{
                                color: isDarkMode ? "#B5C0D3" : "#EF4F5E",
                              }}
                            />
                          }
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                    {hackathonHREFName === "indus_os_app_discovery_challenge" &&
                      hackathon.Hiring_hackathon === true &&
                      isEmployee && (
                        <>
                          <div className="form-group w-100">
                            <br />
                            {!Name && (
                              <label
                                for="email"
                                style={{ color: "black", fontSize: "16px" }}
                              >
                                Name<span style={{ color: "red" }}>* </span>
                              </label>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              value={Name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          {nameError !== "" && <ErrMsg Message={nameError} />}
                          <br />
                          <div className="form-group w-100">
                            {!currentUser.Email && (
                              <label
                                for="email"
                                style={{ color: "black", fontSize: "16px" }}
                              >
                                Email<span style={{ color: "red" }}>* </span>
                              </label>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              value={currentUser.Email}
                              onChange={(e) => setEmail(e.target.value)}
                              disabled
                            />
                          </div>
                          <br />
                          <div className="form-group w-100">
                            {!LatestDesignation && (
                              <label
                                for="designation"
                                style={{ color: "black", fontSize: "16px" }}
                              >
                                Designation
                                <span style={{ color: "red" }}>* </span>
                              </label>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              id="designation"
                              value={LatestDesignation}
                              onChange={(e) => {
                                setLatestDesignation(e.target.value);
                                setDesignationError("");
                              }}
                            />
                          </div>
                          {designationError !== "" && (
                            <ErrMsg Message={designationError} />
                          )}
                        </>
                      )}
                    {currentUser &&
                    (currentUser.Name === null || currentUser.Name === "") ? (
                      <>
                        <div className="form-group w-100">
                          {!Name && (
                            <label
                              for="email"
                              style={{ color: "black", fontSize: "16px" }}
                            >
                              <span style={{ color: "red" }}>* </span>Name
                            </label>
                          )}
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                )}
                {hackathonHREFName === "indus_os_app_discovery_challenge" &&
                  hackathon.Hiring_hackathon === true && (
                    <Grid item xs={12} lg={12} md={12}>
                      <div className="form-group w-100">
                        {!Name && (
                          <label
                            for="email"
                            style={{ color: "black", fontSize: "16px" }}
                          >
                            Name<span style={{ color: "red" }}>* </span>
                          </label>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      {nameError !== "" && <ErrMsg Message={nameError} />}
                      <br />
                      <div className="form-group w-100">
                        {!currentUser.Email && (
                          <label
                            for="email"
                            style={{ color: "black", fontSize: "16px" }}
                          >
                            Email<span style={{ color: "red" }}>* </span>
                          </label>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          value={currentUser.Email}
                          onChange={(e) => setEmail(e.target.value)}
                          disabled
                        />
                      </div>
                      <br />
                      <div className="form-group w-100">
                        {!LatestDesignation && (
                          <label
                            for="designation"
                            style={{ color: "black", fontSize: "16px" }}
                          >
                            Designation<span style={{ color: "red" }}>* </span>
                          </label>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          id="designation"
                          value={LatestDesignation}
                          onChange={(e) => {
                            setLatestDesignation(e.target.value);
                            setDesignationError("");
                          }}
                        />
                      </div>
                      {designationError !== "" && (
                        <ErrMsg Message={designationError} />
                      )}
                      <br />
                      <div className="form-group w-100">
                        {!Organization && (
                          <label
                            for="Organisation"
                            style={{ color: "black", fontSize: "16px" }}
                          >
                            Organisation<span style={{ color: "red" }}>* </span>
                          </label>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          id="Organisation"
                          value={Organization}
                          onChange={(e) => {
                            setOrganization(e.target.value);
                            setOrganizationError("");
                          }}
                        />
                      </div>
                      {organizationError !== "" && (
                        <ErrMsg Message={organizationError} />
                      )}
                      <br />
                      <div className="form-group w-100">
                        {!Mobile && (
                          <label
                            for="mobile"
                            style={{ color: "black", fontSize: "16px" }}
                          >
                            Phone<span style={{ color: "red" }}>* </span>
                          </label>
                        )}
                        <input
                          type="number"
                          className="form-control"
                          id="mobile"
                          value={Mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </div>
                      {mobileError !== "" && <ErrMsg Message={mobileError} />}
                    </Grid>
                  )}
                <Grid
                  item
                  xs={12}
                  lg={
                    hackathonHREFName === "indus_os_app_discovery_challenge"
                      ? 12
                      : 6
                  }
                  md={
                    hackathonHREFName === "indus_os_app_discovery_challenge"
                      ? 12
                      : 6
                  }
                  style={{
                    justifyContent: "right",
                    padding: `${
                      hackathonHREFName === "indus_os_app_discovery_challenge"
                        ? "0"
                        : "10px"
                    }`,
                  }}
                  className="resume-drop-zone"
                >
                  {hackathonHREFName === "indus_os_app_discovery_challenge" && (
                    <>
                      <br />
                      <label style={{ color: "black", fontSize: "16px" }}>
                        If you are interested in getting hired at Indus OS,
                        please attach your resume
                      </label>
                    </>
                  )}
                  <div className={isDarkMode ? "dark-file-submission" : ""}>
                    {resume.length > 0 ? (
                      <>
                        <div
                          className={`px-2 ${
                            isDarkMode
                              ? "dark-selectedfilebox"
                              : "selectedfilebox"
                          }`}
                        >
                          <img
                            src={isDarkMode ? submissionFile : selectedFileImg}
                            alt="file"
                            loading="lazy"
                          />
                          <br />
                          <label
                            className={`mt-2 info-text ${
                              isDarkMode ? "darkText" : ""
                            }`}
                          >
                            {`${resume[0].name} selected successfully.`}
                          </label>
                          <div
                            className={isDarkMode ? "dark-close-file-hld" : ""}
                          >
                            <img
                              className={`mt-1 ml-1 ${
                                isDarkMode ? "dark-close-file" : ""
                              }`}
                              src={isDarkMode ? darkCloseFile : closeIconImg}
                              onClick={(e) => setSelectedFile([])}
                              style={{ cursor: "pointer", width: "20px" }}
                              alt="close"
                              loading="lazy"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div
                        className={`container contactUsZone ${
                          isDarkMode ? "darkDropZone" : "dropZoneStyles"
                        } hackathonSub`}
                      >
                        <div
                          {...getRootProps({
                            className:
                              "dropzone dropzone d-flex flex-column justify-content-center text-center align-items-center",
                          })}
                        >
                          <input {...getInputProps()} />
                          <label
                            class={`my-2 ${
                              isDarkMode ? "file-upload-label" : ""
                            }`}
                          >
                            Drag and drop your Resume
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <label
                            className={`text-dark mt-2 ${
                              isDarkMode ? "placeholderText" : ""
                            }`}
                          >
                            {!isDragActive && (
                              <div className={isDarkMode ? "darkText" : ""}>
                                Allowed formats .pdf - Max 2 MB
                              </div>
                            )}
                            {isDragActive && !isDragReject && (
                              <div className={isDarkMode ? "darkText" : ""}>
                                Drop it here....
                              </div>
                            )}
                            {isDragReject && (
                              <div className="text-danger mt-2">
                                File type is not supported
                              </div>
                            )}
                            {isFileTooLarge && (
                              <div className="text-danger mt-2">
                                File is too large.
                              </div>
                            )}
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <form
                className={`update-profile ${
                  isDarkMode ? "dark-update-profile" : ""
                }`}
              >
                <div className="form-group w-100">
                  {!Name && (
                    <label for="name">
                      <span style={{ color: "red" }}>* </span>Name
                    </label>
                  )}
                  <input
                    type="text"
                    className={`form-control ${
                      profileNameError && "err-control"
                    }`}
                    id="name"
                    value={Name}
                    onChange={(e) => {
                      setName(e.target.value), setNameError(false);
                    }}
                  />
                  {profileNameError && (
                    <p className="err-msg">This is a mandatory Field</p>
                  )}
                </div>
                <div className="form-group w-100">
                  {!Organization && (
                    <label for="organization">Organization</label>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    id="organization"
                    value={Organization}
                    onChange={(e) => {
                      setOrganization(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group w-100">
                  {!Experience && <label for="experience">Experience</label>}
                  <input
                    type="text"
                    className="form-control"
                    id="experience"
                    value={Experience}
                    onChange={(e) => {
                      setExperience(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group w-100">
                  {!LatestDesignation && (
                    <label for="designation">Designation</label>
                  )}
                  <input
                    type="text"
                    className="form-control"
                    id="designation"
                    value={LatestDesignation}
                    onChange={(e) => {
                      setLatestDesignation(e.target.value);
                    }}
                  />
                </div>
                <CityDropdown
                  city={city}
                  setCity={setCity}
                  showParticipateButton={showParticipateButton}
                  currentUser={currentUser}
                  isDarkMode={isDarkMode}
                />
                <div className="form-group w-100">
                  {!Mobile && <label for="email">Mobile number</label>}
                  <input
                    type="text"
                    className="form-control"
                    id="number"
                    value={Mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                  />
                </div>
              </form>
            </>
          )}
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {hackathon.Sponsored ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: hackathon.sponsor_terms,
                }}
              />
            ) : (
              ""
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  style={{
                    color: isDarkMode ? "#ffffff" : "#e9505c",
                  }}
                />
              }
              label="Agree Terms & Conditions"
            />
            <p
              className={isDarkMode ? "darkText" : ""}
              style={{ marginBottom: "39px" }}
            >
              By clicking <span>"Agree"</span> you are agreeing to be bound to
              our competition rules,&nbsp;
              <a href="/privacy-ui" target="_blank">
                privacy
              </a>{" "}
              &nbsp;
              <a href="#">policies</a>&nbsp;and &nbsp;
              <a href="/terms-ui" target="_blank">
                terms
              </a>
              . &nbsp;Please check the rules under Home tab before
              participating.
            </p>

            <div
              className="btnsCntr"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              {hackathon.Hiring_hackathon === true ? (
                <>
                  {hackathonHREFName !== "indus_os_app_discovery_challenge" ? (
                    <>
                      {isEmployee ? (
                        <>
                          {check &&
                          Name !== null &&
                          Name !== "" &&
                          resume.length > 0 ? (
                            <>
                              <Button
                                className="hackathon-submit-btn "
                                onClick={
                                  hackathon.Hiring_hackathon === true &&
                                  uploadResume === true
                                    ? UploadResumeHiringHackathon
                                    : ParticipateHackathonNew
                                }
                              >
                                Submit
                              </Button>
                            </>
                          ) : (
                            <Button className="hackathon-submit-btn ">
                              Submit
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {check && Name !== null && Name !== "" ? (
                            <Button
                              className="hackathon-submit-btn "
                              onClick={
                                hackathon.Hiring_hackathon === true &&
                                uploadResume === true
                                  ? UploadResumeHiringHackathon
                                  : ParticipateHackathonNew
                              }
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button className="hackathon-submit-btn ">
                              Submit
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  ) : check &&
                    Name !== null &&
                    Name !== "" &&
                    LatestDesignation !== "" &&
                    LatestDesignation !== null &&
                    Organization !== "" &&
                    Organization !== null &&
                    Mobile !== "" &&
                    Mobile !== null ? (
                    <Button
                      className="hackathon-submit-btn "
                      onClick={
                        hackathon.Hiring_hackathon === true &&
                        uploadResume === true
                          ? UploadResumeHiringHackathon
                          : ParticipateHackathonNew
                      }
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button className="hackathon-submit-btn ">Submit</Button>
                  )}
                </>
              ) : (
                <>
                  {check && Name !== null && Name !== "" ? (
                    <Button
                      className="hackathon-submit-btn "
                      onClick={
                        hackathon.Hiring_hackathon === true &&
                        uploadResume === true
                          ? UploadResumeHiringHackathon
                          : ParticipateHackathonNew
                      }
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button className="hackathon-submit-btn ">Submit</Button>
                  )}
                </>
              )}
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="showdescription"
        open={showRocketCapitalCrypto}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={showRocketCapitalCrypto}>
          <div className="formPopupHld carouselHld bootstrap-iso">
            <div className="text-center position-relative">
              <div className="d-flex justify-content-center stepperHead align-items-center pb-4">
                {stepper.map((step) => (
                  <React.Fragment key={step}>
                    <p
                      className={`stephead mb-0 ${
                        currentStep === step ? "activestep" : ""
                      }`}
                    >
                      {step}
                    </p>
                    {step === 1 && <span></span>}
                  </React.Fragment>
                ))}
              </div>
              {currentStep === 1 && (
                <div className="row text-left">
                  <div className="col-6">
                    <div className="">
                      {!Name && (
                        <label className="popup-lable">
                          Name
                          <span
                            style={{ color: "#f44d5e", verticalAlign: "top" }}
                          >
                            *
                          </span>
                        </label>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="">
                      {!Mobile && (
                        <label className="popup-lable">
                          Mobile Number
                          <span
                            style={{ color: "#f44d5e", verticalAlign: "top" }}
                          >
                            *
                          </span>
                        </label>
                      )}
                      <input
                        type="number"
                        className="form-control"
                        name="mobile"
                        id="mobile"
                        value={Mobile}
                        onChange={(e) => setMobile(e.target.value)}
                      />
                    </div>
                    <div className="mb-4">
                      {!linkedIn && (
                        <label className="popup-lable">
                          LinkedIn{" "}
                          <span
                            style={{ color: "#f44d5e", verticalAlign: "top" }}
                          >
                            *
                          </span>
                          <a
                            style={{ textDecoration: "none", color: "#0092FB" }}
                            target="_blank"
                            className="privacyterms d-block"
                          >
                            please include https prefix
                          </a>
                        </label>
                      )}
                      <input
                        className="form-control mb-0"
                        name="linkedIn"
                        id="linkedIn"
                        type="text"
                        value={linkedIn}
                        onChange={(e) => {
                          setLinkedIn(e.target.value);
                          setLinkedInErr(false);
                        }}
                      />
                      {linkedInErr && (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          {message}
                        </p>
                      )}
                    </div>
                    <div className="">
                      {!Organization && (
                        <label className="popup-lable">Organization</label>
                      )}
                      <input
                        className="form-control"
                        name="organization"
                        id="organization"
                        type="text"
                        value={Organization}
                        onChange={(e) => setOrganization(e.target.value)}
                      />
                    </div>
                    <div className="">
                      {!LatestDesignation && (
                        <label className="popup-lable">Designation</label>
                      )}
                      <input
                        className="form-control"
                        name="designation"
                        id="designation"
                        type="text"
                        value={LatestDesignation}
                        onChange={(e) => setLatestDesignation(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-4">
                      {!github && (
                        <label className="popup-lable">
                          Github{" "}
                          <a
                            style={{ textDecoration: "none", color: "#0092FB" }}
                            target="_blank"
                            className="privacyterms d-block"
                          >
                            please include https prefix
                          </a>
                        </label>
                      )}
                      <input
                        className="form-control mb-0"
                        name="Github"
                        id="Github"
                        type="text"
                        value={github}
                        onChange={(e) => {
                          setGithub(e.target.value);
                          setGithubErr(false);
                        }}
                      />
                      {githubErr && (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          {message}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      {!twitter && (
                        <label className="popup-lable">
                          Twitter{" "}
                          <a
                            style={{ textDecoration: "none", color: "#0092FB" }}
                            target="_blank"
                            className="privacyterms d-block"
                          >
                            please include https prefix
                          </a>
                        </label>
                      )}
                      <input
                        className="form-control mb-0"
                        name="Twitter"
                        id="Twitter"
                        type="text"
                        value={twitter}
                        onChange={(e) => {
                          setTwitter(e.target.value);
                          setTwitterErr(false);
                        }}
                      />
                      {twitterErr && (
                        <p style={{ color: "red", fontSize: "10px" }}>
                          {message}
                        </p>
                      )}
                    </div>
                    <div className="mb-4">
                      {!discord && (
                        <label className="popup-lable">Discord Username</label>
                      )}
                      <input
                        className="form-control mb-0"
                        name="MetaWalletAddress"
                        id="MetaWalletAddress"
                        type="text"
                        value={discord}
                        onChange={(e) => setDiscord(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 2 && (
                <div className="row text-left">
                  <div className="col-6 mx-auto">
                    <div className="mb-4">
                      {!metaWalletAddress && (
                        <label className="popup-lable">
                          Meta mask wallet address{" "}
                          <span
                            style={{ color: "#f44d5e", verticalAlign: "top" }}
                          >
                            *
                          </span>
                        </label>
                      )}
                      <input
                        className="form-control mb-0"
                        name="MetaWalletAddress"
                        id="MetaWalletAddress"
                        type="text"
                        value={metaWalletAddress}
                        onChange={(e) => setMetaWalletAddress(e.target.value)}
                      />
                      <a
                        href="https://competition.rocketcapital.ai/"
                        style={{ textDecoration: "none", color: "#0092FB" }}
                        target="_blank"
                        className="privacyterms"
                      >
                        get your metamask wallet here!
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {currentStep === 1 && (
                <>
                  {Name && Mobile?.length === 10 && linkedIn ? (
                    <button
                      className=""
                      style={{ backgroundColor: "#f44d5e" }}
                      onClick={rocketCapitalCryptoRegister}
                    >
                      Next
                    </button>
                  ) : (
                    <button className="" style={{ backgroundColor: "#CED2D5" }}>
                      Next
                    </button>
                  )}
                </>
              )}
              {currentStep === 2 && (
                <>
                  {metaWalletAddress ? (
                    <button
                      className=""
                      style={{ backgroundColor: "#f44d5e" }}
                      onClick={rocketCapitalCryptoSubmit}
                    >
                      Submit
                    </button>
                  ) : (
                    <button className="" style={{ backgroundColor: "#CED2D5" }}>
                      Submit
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
      <GenpactGooglePopup
        showCustomPopup={showCustomPopup}
        setShowCustomPopup={setShowCustomPopup}
        Name={Name}
        setName={setName}
        Mobile={Mobile}
        setMobile={setMobile}
        city={city}
        setCity={setCity}
        showParticipateButton={showParticipateButton}
        Organization={Organization}
        setOrganization={setOrganization}
        LatestDesignation={LatestDesignation}
        setLatestDesignation={setLatestDesignation}
        isDarkMode={isDarkMode}
        currentUser={currentUser}
        onDrop={onDrop}
        resume={resume}
        setSelectedFile={setSelectedFile}
        check={check}
        setCheck={setCheck}
        UploadResumeHiringHackathon={UploadResumeHiringHackathon}
        message={message}
      />
    </>
  );
}
