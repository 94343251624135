import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import { Alert } from "@mui/material";
import { Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import CityDropdown from "../../CityDropDown/CityDropDown";
import closeIconImg from "assets/img/Submission/closeIcon.svg";
import darkCloseFile from "../../img/close-file.svg";
import selectedFileImg from "assets/img/Submission/selectedFile.svg";
import submissionFile from "../../img/darksubmissionFile.svg";
import "./GenpactGooglePopup.css"
const GenpactGooglePopup = (props) => {
  const {
    showCustomPopup,
    setShowCustomPopup,
    Name,
    setName,
    Mobile,
    setMobile,
    city,
    setCity,
    showParticipateButton,
    Organization,
    setOrganization,
    LatestDesignation,
    setLatestDesignation,
    isDarkMode,
    currentUser,
    onDrop,
    resume,
    setSelectedFile,
    check,
    setCheck,
    UploadResumeHiringHackathon,
    message,
  } = props;
  const [status, setStatus] = useState(false);
  const [usererror, setError] = useState("");

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxFiles: 1,
    accept: { "application/pdf": [".pdf"] },
  });
  const isFileTooLarge = acceptedFiles[0]?.size > 2000000;

  const handleClose = () => {
    setShowCustomPopup("");
  };
  const submitForm = () => {
    var regLatestDesignation = /^[a-zA-Z\s]{3,50}$/;
    var regOrganization = /^[a-zA-Z\s]{3,100}$/;
    var regPhoneno = /^\d{10}$/;
    if (!regOrganization.test(Organization.trim())) {
      setError(
        "Organization should be an alphabet and contain at least 3 characters"
      );
      setStatus(true);
      return;
    }
    if (!regLatestDesignation.test(LatestDesignation.trim())) {
      setError(
        "Latest Designation should be an alphabet and contain atleast 3 characters"
      );
      setStatus(true);
      return;
    }
    if (!regPhoneno.test(Mobile.trim())) {
      setError("Phone number should be of 10 digits");
      setStatus(true);
      return;
    }
    UploadResumeHiringHackathon();
  };
  useEffect(() => {
    currentUser?.Resume_key &&
      setSelectedFile([
        {
          name: currentUser?.Resume_name,
        },
      ]);
  }, [currentUser]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`bootstrap-iso showdescription ${
        isDarkMode ? "darkTheme" : "lightTheme"
      }`}
      open={showCustomPopup === "genpact-google"}
      onClose={handleClose}
      closeAfterTransition
    >
      <Fade in={showCustomPopup === "genpact-google"}>
        <div className="genpact-google-popup two-side-popup bootstrap-iso">
          {message ? (
            <SnackbarContent
              message={
                <span>
                  <b> {message} </b>
                </span>
              }
              close
              color="success"
              icon="info_outline"
            />
          ) : (
            ""
          )}
          {status ? (
            <Snackbar
              open={status}
              autoHideDuration={15000}
              onClose={() => setStatus(!status)}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert onClose={() => setStatus(!status)} severity="error" className="err-alert">
                {usererror}
              </Alert>
            </Snackbar>
          ) : (
            ""
          )}
          <div className="two-side-popup-heading text-center">
            <h5 className={`mb-0 ${isDarkMode ? "whiteText" : ""}`}>
              Participation form
            </h5>
          </div>
          <div className="text-center two-side-popup-body">
            <div className="row text-left mb-3">
              <div className="col-lg-6 col-12">
                <div className="">
                  <label className="popup-lable">
                    Name
                    <span style={{ color: "#f44d5e", verticalAlign: "top" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    value={Name}
                    placeholder="Full name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="">
                  <label className="popup-lable">
                    Mobile Number
                    <span style={{ color: "#f44d5e", verticalAlign: "top" }}>
                      *
                    </span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    name="mobile"
                    placeholder="Mobile Number"
                    id="mobile"
                    value={Mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <CityDropdown
                  city={city}
                  setCity={setCity}
                  showParticipateButton={showParticipateButton}
                  currentUser={currentUser}
                  isDarkMode={isDarkMode}
                  isMandate={true}
                  placeholderValue="City"
                />
                <div className="">
                  <label className="popup-lable">
                    Organization
                    <span style={{ color: "#f44d5e", verticalAlign: "top" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="form-control"
                    name="organization"
                    id="organization"
                    type="text"
                    value={Organization}
                    placeholder="Organization"
                    onChange={(e) => setOrganization(e.target.value)}
                  />
                </div>
                <div className="">
                  <label className="popup-lable">
                    Designation
                    <span style={{ color: "#f44d5e", verticalAlign: "top" }}>
                      *
                    </span>
                  </label>
                  <input
                    className="form-control"
                    name="designation"
                    id="designation"
                    type="text"
                    value={LatestDesignation}
                    placeholder="Designation"
                    onChange={(e) => setLatestDesignation(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <label className="popup-lable">Resume</label>
                <div className={"dark-file-submission"}>
                  {resume.length > 0 && !isFileTooLarge&&!isDragReject ? (
                    <>
                      <div
                        className={`px-2 ${
                          isDarkMode
                            ? "dark-selectedfilebox"
                            : "selectedfilebox"
                        }`}
                      >
                        <img
                          className="submissionFileImg"
                          src={isDarkMode ? submissionFile : selectedFileImg}
                          alt="file"
                          loading="lazy"
                        />
                        <br />
                        <label
                          className={`info-text ${
                            isDarkMode ? "darkText" : ""
                          }`}
                        >
                          {`${resume[0].name} selected successfully.`}
                        </label>
                        <div
                          className={isDarkMode ? "dark-close-file-hld" : ""}
                        >
                          <img
                            className={`mt-1 ml-1 ${
                              isDarkMode ? "dark-close-file" : ""
                            }`}
                            src={isDarkMode ? darkCloseFile : closeIconImg}
                            onClick={(e) => setSelectedFile([])}
                            style={{ cursor: "pointer", width: "20px" }}
                            alt="close"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div
                      className={`container contactUsZone ${
                        isDarkMode ? "darkDropZone" : "dropZoneStyles"
                      } hackathonSub`}
                    >
                      <div
                        {...getRootProps({
                          className:
                            "dropzone dropzone d-flex flex-column justify-content-center text-center align-items-center",
                        })}
                      >
                        <input {...getInputProps()} />
                        <p className={`mb-0 overview ${isDarkMode ? "file-upload-label whiteText" : ""}`}>
                        Select File here
                        </p>
                        <label
                          className={`text-dark  mb-0${
                            isDarkMode ? "placeholderText" : ""
                          }`}
                        >
                          {!isDragActive && (
                            <label
                              className={`formatText ${
                                isDarkMode ? "whiteText" : ""
                              }`}
                            >
                              Files Supported: PDF - Max 2 MB
                            </label>
                          )}
                          {isDragActive && !isDragReject && (
                            <div className={isDarkMode ? "darkText" : ""}>
                              Drop it here....
                            </div>
                          )}
                          {isDragReject && (
                            <div className="text-danger mt-2">
                              File type is not supported
                            </div>
                          )}
                          {isFileTooLarge && (
                            <div className="text-danger mt-2">
                              File is too large.
                            </div>
                          )}
                        </label>
                        {!isDragActive && <button className="chooseFileBtn">Choose File</button>}
                      </div>
                    </div>
                  )}
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={check}
                      onChange={() => {
                        setCheck(!check);
                      }}
                      name="checkedB"
                      style={{
                        color: isDarkMode ? "#ffffff" : "#e9505c",
                        marginLeft: "20px",
                      }}
                      className="ml-0"
                    />
                  }
                  label={
                    <Typography
                      className={`d-inline-block agreeTermstext mb-0 ${
                        isDarkMode ? "whiteText" : "text-black"
                      }`}
                    >
                      Agree Terms & Conditions
                    </Typography>
                  }
                  className="mb-0"
                />
                <p className={isDarkMode ? "darkText termsConditionText" : "termsConditionText"}>
                  By clicking <span>"Agree"</span> you are agreeing to be bound
                  to our competition rules,
                  <a href="/privacy-ui" target="_blank" className="pl-1">
                    privacy policies
                  </a>{" "}
                  and
                  <a href="/terms-ui" target="_blank" className="pl-1">
                    terms
                  </a>
                  . Please check the rules under Home tab before participating.
                </p>
              </div>
            </div>
            <div className="">
              <Button
                onClick={handleClose}
                style={{
                  borderRadius: "50px",
                }}
                className={`cancel-btn post-active-btn mr-2 ${
                  isDarkMode ? "dark-new-topic" : "px-5"
                }`}
              >
                Cancel
              </Button>
              <>
                {check && Name && LatestDesignation && Organization ? (
                  <>
                    <Button
                      style={{
                        backgroundColor: isDarkMode ? "#ffffff" : "#1B1B1B",
                        fontSize: "14px",
                        color: isDarkMode ? "#000000" : "#fff",
                        borderRadius: "50px",
                        width: "25%",
                        border: "none",
                      }}
                      onClick={submitForm}
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <Button
                    style={{
                      backgroundColor: isDarkMode ? "#303043" : "#c4c4c4",
                      fontSize: "14px",
                      color: "#fff",
                      borderRadius: "50px",
                      width: "25%",
                    }}
                  >
                    Submit
                  </Button>
                )}
              </>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export default GenpactGooglePopup;
