import { API ,LMS_URL } from "backend";
import axios from "axios";

export const getUserInfo = (userId) => {
  return axios
    .get(`${API}/user/info/${userId}`)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const getUserData = (userId) => {
  return axios
    .get(`${LMS_URL}/users/v1/${userId}`)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

export const updtaeUserSub = (user, userId) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  return axios
    .put(`${API}/user/update/${userId}`, user, config)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};
export const updateUserData = async(user,fieldType) => {
  let config = {};

  if (fieldType === "Picture"|| fieldType === "resume") {
    config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
  }

  return axios
    .put(`${LMS_URL}/users/v1`, user, config)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};
