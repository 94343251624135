import React from "react";

export default function DropdownArrow({ fontColor }) {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.67435 5.71588L9.71791 1.64326C9.80729 1.55396 9.87824 1.44773 9.92666 1.33068C9.97507 1.21363 10 1.08809 10 0.961285C10 0.834485 9.97507 0.708939 9.92666 0.59189C9.87824 0.474841 9.80729 0.368606 9.71791 0.279313C9.53922 0.100415 9.29752 -3.07066e-08 9.04557 -4.17195e-08C8.79362 -5.27324e-08 8.55191 0.100415 8.37323 0.279313L4.99724 3.67957L1.62125 0.279313C1.44257 0.100414 1.20086 -3.84623e-07 0.948916 -3.95635e-07C0.696971 -4.06648e-07 0.455261 0.100414 0.27658 0.279313C0.188192 0.369064 0.118264 0.475506 0.0708055 0.592534C0.0233469 0.709561 -0.00070957 0.834873 1.61704e-05 0.961285C-0.000709581 1.0877 0.0233468 1.21301 0.0708055 1.33004C0.118264 1.44706 0.188192 1.55351 0.27658 1.64326L4.32014 5.71588C4.40879 5.80591 4.51427 5.87736 4.63048 5.92613C4.7467 5.97489 4.87135 6 4.99724 6C5.12314 6 5.24779 5.97489 5.364 5.92613C5.48022 5.87736 5.58569 5.80591 5.67435 5.71588Z"
        fill={fontColor}
      />
    </svg>
  );
}
