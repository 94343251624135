/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "./index";

function UnProtectedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/feed",
              state: {
                from: props.location
              }
            }}
          />
        )
      }
    />
  );
}

export default UnProtectedRoute;
