import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "assets/Footer/Footer.css";
import errorIcon from "assets/img/Footer/error.svg";
import subscribeSuccessSvg from "assets/img/Footer/subscribeSuccess.svg";
import axios from "axios";
import { API, Enterprise_URL } from "backend";
import { BootcampBackend } from "backend";
import { useHistory } from "react-router-dom";
import amplitude from "amplitude-js";
import { viewHackathon } from "shared/FirebaseEventMethods";
import { viewInterviewPrep } from "shared/FirebaseEventMethods";
import { viewPractice } from "shared/FirebaseEventMethods";
import { viewCourses } from "shared/FirebaseEventMethods";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useSelector } from "react-redux";
import astronaut from "assets/img/Footer/Astronaut.png";
import Lottie from "lottie-react";
import FooterShort from "./FooterShort";
import { isAuthenticated } from "auth/helper";
import { isProductSubscribed } from "subscriptions/Subscriptions";
import jobs from "assets/img/Footer/jobs.svg";
import RequestHackathonPopup from "./RequestHackathonPopup";
import eventLottie from "assets/lotties/footer/event.json";
import coursesLottie from "assets/lotties/footer/courses.json";
import DataSetsLottie from "assets/lotties/footer/dataSets.json";
import TrophyLottie from "assets/lotties/footer/trophy.json";
import crownLottie from "assets/lotties/footer/crown.json";
import fireLottie from "assets/lotties/footer/fire.json";
import facebook from "assets/img/Footer/facebookDark.svg";
import twitter from "assets/img/Footer/twitterDark.svg";
import linkedin from "assets/img/Footer/linkedinDark.svg";
import youtube from "assets/img/Footer/youtubeDark.svg";
import telegram from "assets/img/Footer/telegram-blue.svg";
import hackathon from "assets/img/Footer/hackathon.svg";
import hackathonDark from "assets/img/Footer/hackathonDark.svg";
import "../MessageOverlay/messageOverlay.css";
import { WEB_URL } from "backend";

const Tabs = [
  {
    _id: "1",
    name: "Courses",
    slug: "course",
    route: "/bootcamps",
    lottie: coursesLottie,
  },
  {
    _id: "2",
    name: "Events",
    slug: "event",
    route: "/events",
    lottie: eventLottie,
  },
  {
    _id: "3",
    name: "Datasets",
    slug: "datasets",
    route: "/datasets",
    lottie: DataSetsLottie,
  },
  {
    _id: "4",
    name: "Jobs",
    slug: "jobs",
    route: "/jobs",
    lottie: "",
  },
  {
    _id: "5",
    name: "Tournaments",
    slug: "tournaments",
    route: "/tournaments",
    lottie: TrophyLottie,
  },
];

const SubFooter = () => {
  const displayMode = useSelector((store) => store.display.display_mode);
  const [isDarkmode, setIsDarkmode] = useState(true);
  const [showFooter, setShowFooter] = useState(false);
  const location = useLocation();
  const DonotShowFooterIn = [
    "/practices/",
    "/session/",
    "/",
    "/enterprise",
    "mh-wordle",
    "/feed",
    "/datalyze",
    "/blogs",
    "/login",
    "/signup",
    "/resetPassword",
    "/story/",
    "/studyplansignup"
  ];
  const showFooterfunc = () => {
    let showFooter = true;
    DonotShowFooterIn.forEach((x) => {
      showFooter =
        showFooter &&
        (x === "/" ? location.pathname !== x : !location.pathname.includes(x));
    });
    return showFooter;
  };
  const showShortFooter = () => {
    if (
      location.pathname.includes(`/assessments/`) &&
      location.pathname?.split("/").length === 3
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (displayMode !== "") {
      setIsDarkmode(displayMode === "dark_mode" ? true : false);
    }
    // setShowFooter(showShortFooter());
  }, [displayMode, location]);

  return (
    <>
      <div
        style={{
          display: `${showFooterfunc() || showFooter ? "block" : "none"}`,
        }}
      >
          <FooterMain isDarkmode={isDarkmode} />
      </div>
    </>
  );
};

export default function FooterNew(props) {
  return (
    <>
      <BrowserView>
        <SubFooter />
      </BrowserView>
      <MobileView>
        <SubFooter />
      </MobileView>
    </>
  );
}

export const FooterMain = ({ isDarkmode }) => {
  const [openHostHackathon, setOpenHostHackathon] = useState(false);
  const [subscribeNewsLetter, setSubscribeNewsLetter] = useState("");
  const [validEmail, setValidEmail] = useState("");
  const [subscribeNewsLetterSuccess, setSubscribeNewsLetterSuccess] = useState(
    ""
  );
  const userSubscriptionData = useSelector(
    (store) => store.subscription.subscriptionData
  );
  const history = useHistory();

  const isValidEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const subscribeToNewsLetter = () => {
    if (!isValidEmail(subscribeNewsLetter)) {
      setValidEmail("Please enter a valid email!");
    } else {
      setValidEmail("");
      axios
        .post(`${BootcampBackend}/oauth/subscribeToNewsLetter`, {
          email: subscribeNewsLetter,
        })
        .then((response) => {
          setSubscribeNewsLetterSuccess(response.data.result.message);
        })
        .catch((err) => {
          setSubscribeNewsLetterSuccess(err.message);
          console.log("Err: ", err.message);
        });
    }
  };

  return (
    <>
      <div className="bootstrap-iso">
        <div
          className={`${
            isDarkmode ? `footer-container-dark` : `footer-container-light`
          } footer-container`}
        >
          {/* <div
            className={`${
              isMobile ? `footer-top-section-mob` : ``
            } footer-top-section d-flex justify-content-center`}
          >
            {Tabs?.map((item) => (
              <div
                key={item?._id}
                className={`footer-tabs ${
                  item?.slug
                }-lottie-hld d-flex align-items-center justify-content-center ${
                  isMobile ? `` : `py-1 px-4 mr-5`
                } cursor-pointer`}
                onClick={() => window.open(`${WEB_URL}${item?.route}`)}
              >
                <div
                  className={`${isMobile ? `mr-1` : item?.slug === "datasets" ? "" : item?.slug === "tournaments" ? "mr-1" : `mr-2`} position-relative ${
                    item?.slug === "event" ? "pulse" : ""
                  }`}
                >
                  {item?.slug === "jobs" ? (
                    <img
                      className="jobs-img-hld slowBounce mr-1"
                      src={jobs}
                      alt="jobs"
                    />
                  ) : (
                    <Lottie
                      animationData={JSON.parse(JSON.stringify(item?.lottie))}
                      loop={true}
                      style={
                        isMobile
                          ? { height: "30px" }
                          : {
                              height: item?.slug === "event" ? "30px" : "40px",
                              marginBottom: "5px",
                            }
                      }
                    />
                  )}
                </div>
                <span className="footer-tabs-text footer-bold-text ">
                  {item?.name}
                </span>
              </div>
            ))}
          </div> */}
          {/* <div className="footer-middle-section">
            <div
              className={`container-fluid footer-wrapper ${
                isMobile ? "px-0" : "px-5"
              } ${isDarkmode ? "darkTheme" : ""}`}
            >
              <div className="footer-row d-flex flex-wrap row col-12 mx-0 ">
                <div
                  className={`${
                    isMobile ? `col-6` : `col-12`
                  } col-md-4  col-lg-3 mt-3 pt-1 ${isMobile ? "px-0" : ""}`}
                >
                  <span className="footer-bold-text">Developers</span>
                  <ul className="footer-list mt-4 pl-0">
                    <li>
                      <a className="footer-link-text" href="/hackathons">
                        AI/ML Hackathons
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link-text"
                        href="/assessments"
                        onClick={() => {
                          viewInterviewPrep();
                          return true;
                        }}
                      >
                        Skill Assessments
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link-text"
                        href="/practices"
                        onClick={() => {
                          viewPractice();
                          return true;
                        }}
                      >
                        Unlimited Practice
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link-text"
                        href="/bootcamps"
                        onClick={() => {
                          viewCourses();
                          return true;
                        }}
                      >
                        Industry Curated Courses
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link-text"
                        href="/hackathons"
                        onClick={() => {
                          amplitude.getInstance().logEvent("viewHackathon");
                          viewHackathon();
                          return true;
                        }}
                      >
                        Weekly Competition
                      </a>
                    </li>
                    {!isMobile && (
                      <div
                        onClick={() => history.push(`/jobs`)}
                        className="footer-bold-text mt-5 d-flex align-items-center pt-3"
                        id="job-link"
                        style={{ cursor: "pointer" }}
                      >
                        <Lottie
                          animationData={fireLottie}
                          loop={true}
                          style={{
                            width: "41px",
                            display: "inline-block",
                          }}
                        />
                        <div className="highlighted-link">
                          <p className="footer-bold-text mb-0">
                            Curated Jobs on
                          </p>
                          <p className="footer-bold-text mb-0">AI/ML</p>
                        </div>
                      </div>
                    )}
                  </ul>
                </div>
                <div
                  className={`${
                    isMobile ? `col-6` : `col-12`
                  } col-md-4 col-lg-3 mt-3 pt-1 ${isMobile ? "px-0 pl-3" : ""}`}
                >
                  <span className="footer-bold-text">Organizations</span>
                  <ul className="footer-list mt-4 pl-0">
                    <li>
                      <a
                        href={`${Enterprise_URL}/jobs`}
                        className="footer-link-text"
                        target="_blank"
                      >
                        Post a Job
                      </a>
                    </li>
                    <li>
                      <a
                        className="footer-link-text"
                        href={`${Enterprise_URL}/assessments`}
                        target="_blank"
                      >
                        Assess Candidate
                      </a>
                    </li>
                    <li>
                      <a className="footer-link-text" href="/assessments">
                        Shortlist Candidates
                      </a>
                    </li>
                    <li>
                      <a className="footer-link-text" href="/events">
                        Live Coding Interview
                      </a>
                    </li>
                    <li>
                      <a className="footer-link-text" href="/leaderboard">
                        Global Leaderboards
                      </a>
                    </li>
                  </ul>
                  {!isMobile && (
                    <div
                      className="footer-bold-text mt-5 d-flex align-items-center cursor-pointer pt-3"
                      id="job-link"
                      onClick={(e) => setOpenHostHackathon(true)}
                    >
                      <div className="highlighted-link">
                        <p className="footer-bold-text mb-0">Host</p>
                        <span className="footer-bold-text mr-2">
                          AI/ML Hackathon
                        </span>
                        <img
                          className="mb-1"
                          src={isDarkmode ? hackathonDark : hackathon}
                          alt="hackathon"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={`contactus-column col-12 col-md-4  col-lg-3 mt-3 pt-1 ${
                    isMobile ? "px-0" : "position-relative"
                  }`}
                >
                  <span className="footer-bold-text">Company</span>
                  <ul className="footer-list mt-4 pl-0">
                    <li>
                      <a className="footer-link-text" href="/about-us">
                        About
                      </a>
                    </li>
                    <li>
                      <a className="footer-link-text" href="/blogs">
                        Blog
                      </a>
                    </li>
                    <li>
                      <div
                        className="contactus-btn mt-3 cursor-pointer"
                        onClick={() => history.push(`/contact-us`)}
                      >
                        <div className="contactus-btn-inner">
                          <p className="contactus-btn-text mb-0">Contact us</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  {!isMobile && (
                    <div className="astronaut-img bounce">
                      {" "}
                      <img src={astronaut} />
                    </div>
                  )}
                </div>
                <div
                  className={`col-12 col-md-6 offset-md-3 offset-lg-0
               col-lg-3 mt-3 ${isMobile && "px-0"}`}
                >
                  <span className="footer-bold-text">Contact</span>
                  <ul className="footer-list mt-4 pl-0">
                    <li>
                      <a
                        className="footer-link-text"
                        href="mailto:support@machinehack.com"
                      >
                        support@machinehack.com
                      </a>
                      <div className="mt-2">
                        <div className="social-media-row row">
                          <div className="facebook-img ml-3 mr-3">
                            <a href="https://www.facebook.com/AnalyticsIndiaMagazine">
                              <img src={facebook} alt="facebook" />
                            </a>
                          </div>
                          <div className="twitter-img mr-3">
                            <a href="https://twitter.com/JoinMachinehack">
                              <img src={twitter} alt="twitter" />
                            </a>
                          </div>
                          <div className="linkedin-img mr-3">
                            <a href="https://www.linkedin.com/company/machine-hack/">
                              <img src={linkedin} alt="linkedin" />
                            </a>
                          </div>
                          <div className="youtube-img mr-3">
                            <a href="https://www.youtube.com/@machinehack">
                              <img src={youtube} alt="youtube" />
                            </a>
                          </div>
                        </div>
                        <div></div>
                      </div>
                      <div className="mt-4">
                        <img
                          src={telegram}
                          alt="telegram"
                          className="footer-telegram-img mr-3"
                        />
                        <a
                          href="https://t.me/+KmgVW57xthI2MjA1"
                          className="footer-semibold-text footer-link-text"
                        >
                          Join our group
                        </a>
                      </div>
                    </li>
                  </ul>
                  <div className="pt-5 pb-1">
                    <span className="footer-bold-text">Stay Updated</span>
                  </div>
                  <div className="footer-subscribe-hld">
                    <span className="footer-medium-text">
                      Don't miss any AI/ML Tech updates
                    </span>
                  </div>
                  <div
                    className={`${
                      isDarkmode ? `dark-subscribe-input-hld` : ``
                    } d-flex subscribe-input-hld mt-4 position-relative`}
                  >
                    <input
                      placeholder="Enter your email"
                      className={`subscribe-input`}
                      value={subscribeNewsLetter}
                      onChange={(e) => {
                        setSubscribeNewsLetter(e.target.value);
                      }}
                    />
                    <button
                      className={`${
                        subscribeNewsLetter.length > 0
                          ? `subscribe-active-btn`
                          : ``
                      } subscribe-btn`}
                      onClick={() => subscribeToNewsLetter()}
                    >
                      Subscribe
                    </button>
                    {validEmail === "Please enter a valid email!" ? (
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#f44d5e",
                          position: "absolute",
                          width: "100%",
                          left: "24px",
                          bottom: "-26px",
                        }}
                      >
                        Please enter a valid email!
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  {subscribeNewsLetterSuccess === "Success" ? (
                    <div className="pt-4">
                      <img
                        className="pr-2"
                        src={subscribeSuccessSvg}
                        alt="success"
                        loading="lazy"
                      />
                      <span className="successText">
                        The form was sent successfully
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                  {subscribeNewsLetterSuccess ===
                  "Request failed with status code 409" ? (
                    <div className="pt-4">
                      <img
                        className="pr-2"
                        src={errorIcon}
                        alt="error"
                        loading="lazy"
                      />
                      <span className="errorMsg">
                        You’re Already Subscribed with us
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div> */}
          <div
            className={`${
              isDarkmode ? `footer-copyright-dark` : ``
            } footer-copyright align-items-center justify-content-between d-flex ${
              isMobile ? `flex-wrap px-2 pt-3` : `px-5`
            }`}
          >
            <a className={`footer-gray-text px-4`}>
              &#169; Copyright Kruxonomy Consulting Pvt Ltd
            </a>
            <div
              className={`d-flex py-3 ${isMobile ? `` : `px-3`} right-section`}
            >
              <a className={`footer-gray-text mr-4`} href="/terms-ui">
                Terms of Service
              </a>
              <a className="footer-gray-text" href="/privacy-ui">
                Privacy Policy
              </a>
              {/* <a className="footer-gray-text" href="/subscription-policy">
                Cancellation & Refund Policy
              </a> */}
            </div>
          </div>
        </div>
      </div>
      {openHostHackathon&&<RequestHackathonPopup
        openHostHackathon={openHostHackathon}
        setOpenHostHackathon={setOpenHostHackathon}
      />}
    </>
  );
};
