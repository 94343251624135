import React, { useEffect, useRef } from "react";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { isAuthenticated } from "auth/helper";
import { formatDate } from "shared/CommonMethods";
import { InterviewJobService } from "services/rest/interview.service";
import rightArrowRedIcon from "assets/img/rightArrowRed.svg";
import CircledTick from "assets/img/landingpage/outline-tick.svg";
import CircledCorss from "assets/img/landingpage/circled-cross.svg";
import CircledTickwithbg from "assets/img/landingpage/circled-tick.svg";
import DarkCircledTick from "assets/img/landingpage/dark-circled-tick.svg";
import DarkCircledCross from "assets/img/landingpage/dark-circled-cross.svg";
import PurpleCircledTick from "assets/img/landingpage/purple-circled-tick.svg";
import "./notificationModal.css";

const NotificationModal = ({
  setShowNotificationModal,
  showNotificationModal,
  isDarkMode,
  unSeenCount,
  NotificationData,
  setNotificationData,
  setIsNewNotification,
  setUnSeenCount,
  unseenNotification,
  currentUser,
}) => {
  const markAllReadNotification = async () => {
    let markallreadNotifications = [];
    NotificationData?.filter((x) => {
      if (!x.is_seen) {
        let notofication_data = {};
        (notofication_data.notification_id = x._id),
          (notofication_data.notification_type = x.data
            ? "Interview"
            : "general");
        markallreadNotifications.push(notofication_data);
      }
    });
    if (markallreadNotifications.length > 0) {
      try {
        const body = {
          user_id: isAuthenticated().user._id,
          notification_id: markallreadNotifications,
        };
        const notificationResponse = await InterviewJobService.isSeenNotification(
          body
        );
        if (notificationResponse) {
          setNotificationData(
            NotificationData.filter((x) => {
              x.is_seen = true;
              return NotificationData;
            })
          );
          setIsNewNotification(false);
          setUnSeenCount(0);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const is_seen_notification = async (data) => {
    if (data.is_seen === false) {
      try {
        const body = {
          user_id: isAuthenticated().user._id,
          notification_id: [
            {
              notification_id: data._id,
              notification_type: data.data ? "Interview" : "general",
            },
          ],
        };
        const notificationResponse = await InterviewJobService.isSeenNotification(
          body
        );
        if (notificationResponse) {
          setNotificationData(
            NotificationData.filter((x) => {
              if (x._id === data._id) {
                x.is_seen = true;
              }
              return NotificationData;
            })
          );
          unseenNotification(
            NotificationData.filter((x) => {
              if (x._id === data._id) {
                x.is_seen = true;
              }
              return NotificationData;
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const interviewDate = (dateTime) => {
    var string = dateTime;
    var temp = string?.split(" ");
    var strdate = temp[0]?.split("/");
    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];
    var formattedDate = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
    }).format("dddd MMM Do, YYYY");
    return formattedDate;
  };
  const interviewTime = (dateTime) => {
    var string = dateTime;
    var temp = string?.split(" ");
    var time = temp[1];
    var formattedDate = moment({
      time: Number(time),
    }).format("h:mm a");
    return formattedDate;
  };
  const onAcceptOrRejectInterview = async (data, acceptanceStatus) => {
    try {
      const paramData = {
        acceptanceFromCandidate: acceptanceStatus,
        candidateName:
          currentUser.Name !== undefined && currentUser.Name !== null
            ? currentUser.Name.split(" ")[0]
            : "",
        candidateEmail: isAuthenticated().user.Email,
      };
      const acceptOrRejectInterview = await InterviewJobService.acceptOrRecjectInterview(
        data,
        paramData
      );
      if (acceptOrRejectInterview) {
        setNotificationData(
          NotificationData.filter((x) => {
            if (x._id === data._id) {
              acceptanceStatus === "accepted"
                ? (x.data.is_accepted = true)
                : (x.data.is_rejected = true);
            }
            return NotificationData;
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDefaultImg = (data) => {
    const filname =
      data?.notification_type?.toLowerCase() === "bootcamp"
        ? "courses"
        : data?.notification_type?.toLowerCase() === ("others" || "jobs")
        ? data?.notification_type?.toLowerCase()
        : data?.notification_type?.toLowerCase() + "s";
    const S3URL = `https://cdn.machinehack.com/notifications_icons/${filname}.png`;
    return S3URL;
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={showNotificationModal}
      onClose={setShowNotificationModal}
      closeAfterTransition
      className={`bootstrap-iso notificationModal ${
        isDarkMode ? "darkTheme" : ""
      }`}
      keepMounted
    >
      <Fade in={showNotificationModal}>
        <div className="notificationModalHld">
          <div
            className={`pb-2 px-3 pt-3 pushNotificationHeadingHld ${
              isDarkMode ? "" : ""
            }`}
          >
            <span
              className={`notificationTitle ${isDarkMode ? "whiteText" : ""}`}
            >
              Notifications{" "}
              {unSeenCount > 0 && (
                <span
                  className={`unreadNotificationCount ${
                    isDarkMode ? "darkText" : ""
                  }`}
                >
                  {unSeenCount}
                </span>
              )}
            </span>
            <span
              className={`markAllread ${isDarkMode ? "darkText" : ""}`}
              onClick={markAllReadNotification}
            >
              Mark All as Read
              <img
                src={isDarkMode ? DarkCircledTick : CircledTick}
                alt="mark-all-read"
                className="img-fluid ml-2"
              />
            </span>
          </div>
          <div
            className={`${
              NotificationData.length > 3
                ? "notificationHeightlisthld"
                : "notificationlisthld"
            }`}
          >
            {NotificationData &&
              NotificationData.map((data, index) => (
                <div className="" key={data._id}>
                  {data.notification_type !== "Hackathon_teams" && (
                    <div
                      className={`d-flex align-items-start notificationcntr ${
                        data.is_seen ? "" : "newNotificationHld"
                      }`}
                      onMouseEnter={() => is_seen_notification(data)}
                    >
                      <img
                        src={getDefaultImg(data)}
                        alt=""
                        className="img-fluid defaultnotifyImg mx-3"
                      />
                      <div
                        className={`NotificationHld mb-0 w-100 pr-3 ${
                          isDarkMode ? "" : ""
                        }`}
                      >
                        <span
                          className={`notificationType notificationTypeText mb-1`}
                        >
                          {data.notification_type}
                        </span>
                        {data?.notification_type === "Interview" ? (
                          <p
                            className={`notificationBody ${
                              isDarkMode ? "whiteText" : ""
                            }`}
                          >
                            {" "}
                            An interview has been schedule{" "}
                            <span className="">by MachineHack</span>
                          </p>
                        ) : (
                          <p
                            className={`notificationBody ${
                              isDarkMode ? "whiteText" : ""
                            }`}
                            dangerouslySetInnerHTML={{
                              __html: data.message,
                            }}
                          ></p>
                        )}
                        {(data?.title || data?.data?.job_role) && (
                          <p
                            className={`notificationMessage mb-2 ${
                              isDarkMode ? "whiteText" : ""
                            }`}
                          >
                            {data?.title || data?.data?.job_role}
                          </p>
                        )}
                        {data?.data?.time_scheduled && (
                          <ul className={`timingsList p-0 d-block mb-2`}>
                            <li className={`${isDarkMode ? "darkText" : ""}`}>
                              {interviewDate(data?.data?.time_scheduled)}
                            </li>
                            <li className={`${isDarkMode ? "darkText" : ""}`}>
                              {interviewTime(data?.data?.time_scheduled)}
                            </li>
                          </ul>
                        )}
                        {data?.data?.Topic && (
                          <p className="notificationMessage mb-2">
                            {data?.data?.Topic}
                          </p>
                        )}
                        {data?.notification_type === "Interview" ? (
                          <div className="d-flex align-items-center">
                            {!data.data.is_accepted && !data.data.is_rejected && (
                              <div className="">
                                <button
                                  className={`notificationCTA mr-2 py-1 ${
                                    isDarkMode ? "darkBtn darkborder" : ""
                                  }`}
                                  onClick={() =>
                                    onAcceptOrRejectInterview(data, "accepted")
                                  }
                                >
                                  Accept Invite
                                </button>
                                <button
                                  className={`refuseBtn py-1 mr-2`}
                                  onClick={() =>
                                    onAcceptOrRejectInterview(data, "rejected")
                                  }
                                >
                                  Refuse
                                </button>
                              </div>
                            )}
                            {data.data.is_accepted && (
                              <button
                                className={`notificationCTA acceptedCta mr-2 py-1`}
                              >
                                <img
                                  src={
                                    isDarkMode
                                      ? PurpleCircledTick
                                      : CircledTickwithbg
                                  }
                                  alt="accepted"
                                  className="img-fluid mr-1"
                                  // style={{
                                  //   width: "14px",
                                  //   verticalAlign: "sub",
                                  // }}
                                  loading="lazy"
                                />
                                Accepted
                              </button>
                            )}
                            {data.data.is_rejected && (
                              <button
                                className={`notificationCTA acceptedCta mr-2 py-1`}
                              >
                                <img
                                  src={
                                    isDarkMode ? DarkCircledCross : CircledCorss
                                  }
                                  alt="accepted"
                                  className="img-fluid mr-1"
                                  // style={{
                                  //   width: "14px",
                                  //   verticalAlign: "sub",
                                  // }}
                                  loading="lazy"
                                />
                                Refused
                              </button>
                            )}

                            <a
                              className="viewDetailsBtn"
                              href={`/myaccount/${currentUser?._id}`}
                            >
                              View Details
                            </a>
                          </div>
                        ) : (
                          <a
                            className={`notificationCTA `}
                            href={data.slug}
                            target="_blank"
                          >
                            {data.notification_type === "Hackathon"
                              ? "Do your Submission"
                              : data.notification_type === "Bootcamp"
                              ? "Start Learning Today!"
                              : data.notification_type === "Practice"
                              ? "Give it a try now"
                              : "View Details"}
                          </a>
                        )}
                        <p
                          className={`notificationTime mb-0 mt-2 ${
                            isDarkMode ? "darkText" : ""
                          }`}
                        >
                          {formatDate(data.created)}
                        </p>
                      </div>
                    </div>
                  )}
                  {data.User_Oid &&
                    data.notification_type === "Hackathon_teams" && (
                      <div className="d-flex align-items-start notificationcntr ">
                        <img
                          src={getDefaultImg(data)}
                          alt=""
                          className="img-fluid defaultnotifyImg mx-3"
                        />
                        <div
                          className={`NotificationHld mb-0 w-100 pr-3 ${
                            isDarkMode ? "" : ""
                          }`}
                          onMouseEnter={() => is_seen_notification(data)}
                        >
                          <span className="notificationType notificationTypeText mb-1">
                            Hackathon
                          </span>
                          <p
                            className={`notificationBody mb-2 ${
                              isDarkMode ? "whiteText" : ""
                            }`}
                          >
                            {data.message}
                          </p>
                          <p className={`notificationMessage ${isDarkMode ? "whiteText" : ""
                            } mb-3`}>
                            A Invitation has been sent by the Team
                            <span className="pl-1">{data.data.team_name}</span>
                          </p>
                          <a className="notificationCTA mt-1" href={data.slug}>
                            View Details
                          </a>
                          <p
                            className={`notificationTime mb-0 mt-2 ${
                              isDarkMode ? "darkText" : ""
                            }`}
                          >
                            {formatDate(data.created)}
                          </p>
                        </div>
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
export default NotificationModal;
