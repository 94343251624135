import React, { useState, useEffect, useMemo } from "react";

const Typewriter = ({ text ,isTyping}) => {
  const textData = useMemo(()=>text,[text])  
  const [displayText, setDisplayText] = useState("");

  const handleType = ()=>{
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      setDisplayText((prevText) => {
        const nextCharacter = textData?.charAt(currentIndex);
        currentIndex++;
        return prevText + nextCharacter;
      });
      if (currentIndex === textData?.length) {
        clearInterval(intervalId);
      }
    }, 30); // Set the typing speed here in milliseconds
    return () => clearInterval(intervalId);
  }
  useEffect(() => {
    if(isTyping){
        handleType()
    }
    else{
        setDisplayText(textData);
    }
  }, []);

  return <p>{displayText}<span className="typewriter-text"></span></p>;
};

export default Typewriter;