let initialState = {
	hackathons: [],
	closed_hackathons: []
};

function hackathonsReducer(state = initialState, action) {
	if (action.type === "GET_ALL_HACKATHONS") {
		return {
			...state,
			hackathons: action.payload,
		};
	}
	if (action.type === "GET_ALL_CLOSED_HACKATHONS") {
		return {
			...state,
			closed_hackathons: [...state.closed_hackathons, ...action.payload]
		};
	}
	return state;
}

export default hackathonsReducer;
