import { API } from "backend";
import axios from "axios";
import { LMS_URL } from "backend";

// Python signup API
// export const signup = (user) => {
//   return axios
//     .post(`${API}/mh/register/user`, user)
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => console.log(err));
// };
export const signup = (user) => {
  return axios
    .post(`${LMS_URL}/signup`, user)
    .then((response) => {
      return response?.data;
    })
    .catch((err) => {
      return err.response;
    });
};
// Python Login API
// export const signin = (user) => {
//   return axios
//     .post(`${API}/login`, user)
//     .then((response) => {
//       return response;
//     })
//     .catch((err) => console.log(err));
// };
export const signin = (user) => {
  return axios
    .post(`${LMS_URL}/login`, user)
    .then((response) => {
      return response?.data;
    })
    .catch((err) => {
      return err.response;
    });
};
export const resetPasswordRequest = (user) => {
  return axios
    .post(`${LMS_URL}/resetPasswordRequest`, user)
    .then((response) => {
      return response?.data;
    })
    .catch((err) => {
      return err.response;
    });
};
export const loginCondition = (id, email) => {
  return axios
    .post(`${API}/events/login/condition/${id}?email=${email}`)
    .then((response) => {
      return response;
    })
    .catch((err) => console.log(err));
};

//authenticate or save json web toke to local storage
export const authenticate = (data, next) => {
  if (typeof window !== "undefined") {
    const userData = {
      "token": data.token,
      "user": {
          "Email": data.data.Email,
          "_id": data.data._id
      }
  }
    localStorage.setItem("jwt", JSON.stringify(userData));
    localStorage.setItem("loginTime", Date.now());
    next();
  }
};

//signout of local storage and remove token
export const signout = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("jwt");

    return axios
      .get(`${LMS_URL}/logout`)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        // console.log(err);
      });
  }
};

export const resetPasswordApi = (password, userId, resetToken) => {
  return axios
    .post(`${LMS_URL}/resetPassword/${userId}/${resetToken}`, password)
    .then((response) => {
      return response.data;
    })
    .catch((err) => console.log(err));
};

export const isAuthenticated = () => {
  if (typeof window === "undefined") {
    return false;
  }

  if (localStorage.getItem("jwt")) {
    if (Number(localStorage.getItem("loginTime")) < Date.now() - 1000 * 86400) {
      localStorage.removeItem("jwt");
      localStorage.removeItem("loginTime");
      window.location.replace("/feed");
    }
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};
