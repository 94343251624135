import React, { useEffect, useRef, useState } from "react";
import Lottie from "lottie-react";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { signout } from "auth/helper";
import { store } from "store/store";
import { logOut } from "views/Chat/api";
import { useHistory } from "react-router-dom";
import { userDetailsService } from "services/rest/userProfile.service";
import { getUserBadge } from "shared/CommonMethods";
import { motion } from "framer-motion/dist/framer-motion";
import GlobalLeaderboardLottie from "assets/lotties/Header/invest-growth.json";
import premiumTick from "assets/img/premiumTick.svg";
import userHackathonImg from "assets/img/landingpage/userhackathons.svg";
import userCoursesImg from "assets/img/landingpage/userCourses.svg";
import userDisscussionsImg from "assets/img/landingpage/userdiscussions.svg";
import userAssessmentsImg from "assets/img/landingpage/userAssessmentImg.svg";
import userMessageImg from "assets/img/landingpage/userMessage.svg";
import MHCoins from "assets/img/landingpage/MachinehackCoin.svg";
import ordersDark from "assets/img/userOrdersDark.svg";
import ordersImg from "assets/img/landingpage/userOrdersImg.svg";
import subDark from "assets/img/userSubscriptionDark.svg";
import userSubscriptions from "assets/img/landingpage/userSubscriptionsImg.svg";
import DarkSignoutIcon from "assets/img/landingpage/dark-signout-icon.svg";
import SignOutIcon from "assets/img/landingpage/signouticon.svg";
import DarkThemeIcon from "assets/img/landingpage/darkThemeIcon.svg";
import LightThemeIcon from "assets/img/landingpage/lightThemeIcon.svg";
import HackathonLevel5 from "assets/img/badges/lb-hackathon-level-5.svg";
import HackathonLevel4 from "assets/img/badges/lb-hackathon-level-4.svg";
import HackathonLevel3 from "assets/img/badges/lb-hackathon-level-3.svg";
import HackathonLevel2 from "assets/img/badges/lb-hackathon-level-2.svg";
import HackathonLevel1 from "assets/img/badges/lb-hackathon-level-1.svg";
import LightGraph from "assets/img/Profile_new/light-graph-icon.svg";
import DarkGraph from "assets/img/Profile_new/dark-graph-icon.svg";
import "./userProfileCard.css";

const UserProfileCard = (props) => {
  const {
    setShowUserProfile,
    isDarkMode,
    currentUser,
    userGlobalRankingData,
  } = props;
  const userSubscriptionData = useSelector(
    (store) => store.subscription.subscriptionData
  );
  const [badgeHover, setBadgeHover] = useState({});
  const [Badges, setBadges] = useState([
    {
      _id: "1",
      type: "Hackathon",
      tier: userGlobalRankingData.hackathonTier,
      slug: "hackathon",
    },
    {
      _id: "2",
      type: "Assessment",
      tier: userGlobalRankingData.assessmentTier,
      slug: "assessment",
    },
    {
      _id: "3",
      type: "Practice",
      tier: userGlobalRankingData.practiceTier,
      slug: "practice",
    },
    {
      _id: "4",
      type: "Blogs",
      tier: userGlobalRankingData.blogsTier,
      slug: "blog",
    },
  ]);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const history = useHistory();
  const profileOptions = [
    // {
    //   name: "Hackathons",
    //   image: userHackathonImg,
    //   route: `/user/${currentUser?._id}?tab=hackathon`,
    // },
    {
      name: "Courses",
      image: userCoursesImg,
      route: `/user/${currentUser?._id}?tab=courses`,
    },
    {
      name: "Discussions",
      image: userDisscussionsImg,
      route: `/user/${currentUser?._id}?tab=discussions`,
    },
    {
      name: "Assessments",
      image: userAssessmentsImg,
      route: `/user/${currentUser?._id}?tab=assessment`,
    },
    // {
    //   name: "Coins",
    //   image: MHCoins,
    //   route: `/store`,
    // },
    // {
    //   name: "Jobs",
    //   image: userMessageImg,
    //   route: `/jobs`,
    // },
  ];
  const getBadge = (level) => {
    let image_name = "";
    switch (level) {
      case "Apprentice":
        image_name = HackathonLevel5;
        break;
      case "GrandMaster":
        image_name = HackathonLevel4;
        break;
      case "Champion":
        image_name = HackathonLevel3;
        break;
      case "Master":
        image_name = HackathonLevel2;
        break;
      default:
        image_name = HackathonLevel1;
        break;
    }
    return image_name;
  };
  const routeToUserSpecificpages = (data) => {
    setShowUserProfile(false);
    history.push(data?.route);
  };
  const logout = () => {
    signout().then(() => {
      setShowUserProfile(false);
      localStorage.removeItem("prevPath");
      history.push(location.pathname);
      dispatch({
        type: "LOGOUT_CURRENT_USER",
      });
      logOut();
      history.push("/");
    });
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowUserProfile(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef);
  const onThemeChange = () => {
    store.dispatch({
      type: "SET_DISPLAY_MODE",
      payload: isDarkMode ? "light_mode" : "dark_mode",
    });
    localStorage.setItem("Theme", isDarkMode ? "light_mode" : "dark_mode");
  };
  useEffect(() => {
    if (Object.keys(userGlobalRankingData || {})?.length > 0) {
      setBadges((prev) =>
        prev.filter((x) => {
          switch (x.slug) {
            case "assessment":
              x.tier = userGlobalRankingData?.assessmentTier || "Novice";
              break;
            case "practice":
              x.tier = userGlobalRankingData?.practiceTier || "Novice";
              break;
            case "blog":
              x.tier = userGlobalRankingData?.blogsTier || "Novice";
              break;
            case "hackathon":
              x.tier =
                userGlobalRankingData?.Level ||
                userGlobalRankingData?.hackathonTier;
              break;
            default:
              break;
          }
          return prev;
        })
      );
    }
  }, [userGlobalRankingData]);
  return (
    <div
      className={`profiledropdown bootstrap-iso ${
        isDarkMode ? "darkprofiledropdown darkTheme" : ""
      }`}
      ref={wrapperRef}
    >
      <div className="d-flex mt-3 px-3 align-items-center username-and-image-hld py-3">
        <img
          className="mr-2 user-image-container cursor-pointer"
          src={currentUser?.Picture}
          alt="user"
          loading="lazy"
          onClick={() => (
            history.push(`/user/${currentUser?._id}`), setShowUserProfile(false)
          )}
        />
        <div className="">
          <p
            className={`username mb-0 cursor-pointer ${
              isDarkMode ? "whiteText" : ""
            }`}
            onClick={() => (
              history.push(`/user/${currentUser?._id}`),
              setShowUserProfile(false)
            )}
          >
            {currentUser.Name !== undefined && currentUser.Name !== null
              ? currentUser.Name.split(" ")[0].charAt(0).toUpperCase() +
                currentUser.Name.split(" ")[0].slice(1, 10).toLowerCase() +
                `${currentUser.Name.split(" ")[0].length > 10 ? "..." : ""}`
              : ""}
            {userSubscriptionData?._id && (
              <img
                src={premiumTick}
                alt="premiumTick"
                className="img-fluid ml-2"
                loading="lazy"
              />
            )}
          </p>
          <div className="d-flex justify-content-between">
            <p
              className={`user-email-address mb-0 text-truncate pr-2 ${
                isDarkMode ? "darkText" : ""
              }`}
            >
              {currentUser?.Email}
            </p>
            <div
              className={`toggle-container ${
                isDarkMode ? "lightbg" : "darkbg"
              }`}
              onClick={onThemeChange}
            >
              <div className={`dialog-button ${isDarkMode ? "disabled" : ""}`}>
                {isDarkMode ? (
                  <img src={LightThemeIcon} alt="dark" className="img-fluid" />
                ) : (
                  <img src={DarkThemeIcon} alt="dark" className="img-fluid" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="profileCardbadges d-flex justify-content-center py-4">
        {/* {Badges?.map((item, index) => (
          <motion.div
            key={index}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.2, delay: index * 0.05 }}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 30 },
            }}
            whileHover={{
              scale: 1.03,
              transition: { duration: 0.6 },
            }}
            className="position-relative cursor-pointer"
            onMouseEnter={() => setBadgeHover(item)}
            onMouseLeave={() => setBadgeHover({})}
          >
            {item?.tier && (
              <img
                src={getUserBadge(item?.tier, item?.slug)}
                alt="badges"
                className={`${item?._id !== "1" ? "badgesImgHld" : ""}`}
              />
            )}
            {badgeHover?._id === item?._id && (
              <div className="ProfileCardbadgeToolTip">
                <img
                  src={isDarkMode ? DarkGraph : LightGraph}
                  className="img-fluid mr-2"
                  alt="graph"
                />
                {badgeHover?.tier}
              </div>
            )}
          </motion.div>
        ))} */}
      </div>
      {!isMobile && (
        <div className="d-flex flex-wrap profileCardbadges">
          {profileOptions.map((item, index) => (
            <div
              className="col-lg-4 px-2 mb-3"
              onClick={() => routeToUserSpecificpages(item)}
              key={index}
            >
              <div
                className={`text-center py-2 profdropOptions position-relative`}
              >
                <img
                  src={item.image}
                  alt="img"
                  className="img-fluid mb-1"
                  loading="lazy"
                />
                {item.name === "Coins" && (
                  <p className="mb-0 noOfcoins">{currentUser.coins}</p>
                )}
                <p className={`mb-0 ${isDarkMode ? "whiteText" : ""}`}>
                  {item.name}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* <div
        className="d-flex align-items-center py-2 px-4 profileSubOptions my-2"
        onClick={() => {
          history.push(`/leaderboard`);
          setShowUserProfile(false);
        }}
      >
        <Lottie
          animationData={GlobalLeaderboardLottie}
          loop={true}
          className="globalLeaderboardImg"
        />
        <p className={`mb-0 dropDownItem pl-2 ${isDarkMode ? "darkText" : ""}`}>
          Global Leaderboards
        </p>
      </div>
      <div
        className="d-flex align-items-center py-2 px-4 profileSubOptions mb-2"
        onClick={() => {
          history.push(`/myaccount/${currentUser._id}?tab=orders`);
          setShowUserProfile(false);
        }}
      >
        <img
          src={isDarkMode ? ordersDark : ordersImg}
          alt="orders"
          loading="lazy"
          className={`img-fluid ${isDarkMode ? "" : ""}`}
          width={18}
        />
        <p className={`mb-0 pl-3 dropDownItem ${isDarkMode ? "darkText" : ""}`}>
          Your Orders
        </p>
      </div>
      <div
        className="d-flex align-items-center py-2 profileSubOptions px-4"
        onClick={() => {
          history.push(`/myaccount/${currentUser._id}?tab=subscriptions`);
          setShowUserProfile(false);
        }}
      >
        <img
          src={isDarkMode ? subDark : userSubscriptions}
          alt="user-subscription"
          className={`img-fluid ${isDarkMode ? "" : ""}`}
          loading="lazy"
          width={20}
          height={20}
        />
        <p className={`mb-0 pl-3 dropDownItem ${isDarkMode ? "darkText" : ""}`}>
          Your Subscriptions
        </p>
      </div> */}
      <div className="text-center mt-3" onClick={logout}>
        <p
          className={`mb-0 pl-2 dropDownItem signOutbtn px-4 py-1 ${
            isDarkMode ? "darkText" : ""
          }`}
        >
          <img
            src={isDarkMode ? DarkSignoutIcon : SignOutIcon}
            alt="signOut"
            className="img-fluid mr-2"
            width={12}
          />
          Sign Out
        </p>
      </div>
    </div>
  );
};
export default UserProfileCard;
