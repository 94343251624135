import amplitude from "amplitude-js";
import { BrowserRouter as Router } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Routes from "routes";
import { HelmetProvider } from "react-helmet-async";
import { isAuthenticated } from "auth/helper";
import { MhcoinsSocket } from "socket/Socket";
import { useSelector } from "react-redux";
import { store } from "store/store";
import { SkeletonTheme } from "react-loading-skeleton";
import Header from "views/LandingPage/Header";
import MessageOverlay from "views/MessageOverlay";
import MhCoinsPopup from "views/popups/MhCoinsPopup";
import "./App.css";

function App() {
  const [showMessagelist, setShowMessagelist] = useState(false);
  const [coinModal, setCoinModal] = useState(false);
  const [coinsData, setCoinsData] = useState({});
  const currentUser = useSelector((store) => store.user.user);
  const displayMode = useSelector((store) => store.display.display_mode);
  const browserUrl = useSelector((store) => store.display.url); // This has the url of the current page, this is created to add the classnames when Topbar is present
  useEffect(() => {
    if (currentUser._id) {
      getSocketMhCoins();
      // Initialize amplitude without userID
      // console.log("settingUserId");
      // const analytics = getAnalytics();
      // setUserId(analytics, "123456");
      let instance = amplitude
        .getInstance()
        .init(process.env.REACT_APP_AMPLITUDE_API_ID, currentUser._id);
    } else {
      // Initialize amplitude with userID
      let instance = amplitude
        .getInstance()
        .init(process.env.REACT_APP_AMPLITUDE_API_ID);
    }
  }, [currentUser._id]);
  const getSocketMhCoins = () => {
    MhcoinsSocket.on("connect", () => {
      // console.log("coins", MhcoinsSocket.connected);
    });
    MhcoinsSocket.on("mhCoinsPopupConnectionResponse", (payload) => {
      // console.log("coinconnection established");
    });
    MhcoinsSocket.on("MhCoinsPopupResponse", (payload) => {
      if (isAuthenticated().user._id === payload.userId) {
        const userData = currentUser;
        const totalCoins =
          parseInt(currentUser.coins) + parseInt(payload.coins);
        userData.coins = totalCoins.toString();
        store.dispatch({
          type: "GET_USER_INFO",
          payload: userData,
        });
        setCoinModal(true);
        setCoinsData(payload);
      }
    });
  };
  const DonotShowHeaderIn = ["/enterprise/assessment"];
  const addMarginTop = () => {
    let showHeader = true;
    DonotShowHeaderIn.forEach((x) => {
      showHeader =
        showHeader &&
        (x === "/" ? location.pathname !== x : !location.pathname.includes(x));
    });
    return showHeader;
  };
  const DonotShowChatIn = ["/practices/"];
  const showChatIn = () => {
    let showChat = true;
    DonotShowChatIn.forEach((x) => {
      showChat =
        showChat &&
        (x === "/" ? location.pathname !== x : !location.pathname.includes(x));
    });
    return showChat;
  };

  return (
    <HelmetProvider>
      <Router>
        {/* Use this classname if there are any topheaders "marginTopExtended" and use function showTopBarIn() if you want to render topheader conditionally */}
        <div
          className={`App ${addMarginTop() ? "marginTop60" : ""}
            ${displayMode === "dark_mode" ? "darkTheme" : "lightTheme"}`}
          style={{
            backgroundColor:
              displayMode === "dark_mode" ? "#000726" : "#ffffff",
          }}
        >
          <Header />
          <SkeletonTheme
            baseColor={`${displayMode === "dark_mode" ? "#0A112F" : "#e5e5e5"}`}
            highlightColor={`${
              displayMode === "dark_mode" ? "#111C3D" : "#ffffff"
            }`}
          >
            <div className="routesHld">
              <Routes />
            </div>
          </SkeletonTheme>

          {coinModal && (
            <MhCoinsPopup
              coinModal={coinModal}
              closeModal={(value) => setCoinModal(value)}
              coinsData={coinsData}
              isDarkMode={displayMode === "dark_mode"}
            />
          )}
          {/* {isAuthenticated() && showChatIn() && (
            <MessageOverlay
              showMessagelist={showMessagelist}
              setShowMessagelist={setShowMessagelist}
            />
          )} */}
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
