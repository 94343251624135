import React, { useEffect, useState, useCallback } from "react";
import { motion, useAnimation } from "framer-motion/dist/framer-motion";
import arrowWhite from "assets/img/subscribeArrow.svg";
import "../HackathonBanner/hackathonbanner.css";
import Button from "components/CustomButtons/Button.js";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import Snackbar from "@mui/material/Snackbar";
import { Helmet } from "react-helmet";
import axios from "axios";
import { API } from "backend";
import { WEB_URL } from "backend";
import { isAuthenticated } from "auth/helper";
import { getUserInfo } from "user/helper/userapicall";
import { updtaeUserSub } from "user/helper/userapicall";
import FormControl from "@mui/material/FormControl";
import GridItem from "components/Grid/GridItem.js";
import { useDropzone } from "react-dropzone";
import Grid from "@mui/material/Grid";
import "../CustomNav.css";
import { RadioGroup } from "@mui/material";
import Radio from "@mui/material/Radio";
import resumeIcon from "assets/img/ProfilePage/resume_hack_icon.svg";
import selectedFileImg from "assets/img/Submission/selectedFile.svg";
import closeIconImg from "assets/img/Submission/closeIcon.svg";
import LoginPageNew from "views/LoginPage/LoginPageNew";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { BootcampBackend } from "backend";
import ErrMsg from "../ErrMsg";
import amplitude from "amplitude-js";
import { participateInHackathon } from "shared/FirebaseEventMethods";
import { submitHiringFormHackathon } from "shared/FirebaseEventMethods";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import MuiAlert from "@mui/lab/Alert";
import CountDownTimer from "components/CountDownTimer/CountDownTimer";
import submissionFile from "../img/darksubmissionFile.svg";
import darkCloseFile from "../img/close-file.svg";
import Typography from "@mui/material/Typography";
import GoogleDarkLogo from "assets/img/dark-theme-google-cloud-logo.svg";
import GoogleLightLogo from "assets/img/light-theme-google-cloud-logo.svg";
import MachinehackAiSearch from "../../../../MachineAI/Component/MachinehackAiSearch";
import { isMobile } from "react-device-detect";
import CityDropdown from "../CityDropDown/CityDropDown";
import GenpactGooglePopup from "../Popups/GenpactGooglePopup/GenpactGooglePopup";
import WhiteCircledTick from "assets/img/Competition/white-circled-tick.svg";
import AILotte from "assets/lotties/MachineAI/AI.json";
import Lottie from "lottie-react";
function ImageIcon(props) {
  return (
    <div>
      <img
        className="mr-4 img-fluid"
        src={resumeIcon}
        alt="resume"
        loading="lazy"
      />
      <p style={{ fontSize: "18px", fontWeight: "bold" }}>
        Drag and drop your Resume
      </p>
      <p style={{ fontSize: "14px" }}>Allowed formats word and PDF - Max 1mb</p>
    </div>
  );
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
//for Genpact | Google for Developers Sustainability Hackathon - Let's crack the climate resilience code!
const isGenpactGoogleHackathon = (hackathonHREFName) => {
  return (
    hackathonHREFName ===
      "sustainability_hackathon_lets_crack_the_climate_resilience_code" ||
    hackathonHREFName ===
      "genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code"
  );
};
const HackathonBanner = ({
  hackathon,
  hackathonHREFName,
  currentUser,
  isDarkMode,
  socialCampaignTracking,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openProfileUpadate, setOpenProfileUpadate] = useState(false);
  const [scroll, setScroll] = useState("paper");
  const [message, setMessage] = useState("");
  const [messageRegister, setMessageRegister] = useState("");
  const [usererror, setError] = useState("");
  const [check, setCheck] = useState(false);
  const [uploadResume, setUploadResume] = useState(true);
  const [status, setStatus] = useState(false);
  const [statusRegister, setStatusRegister] = useState(false);
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Location, setLocation] = useState("");
  const [Organization, setOrganization] = useState("");
  const [Experience, setExperience] = useState("");
  const [LatestDesignation, setLatestDesignation] = useState("");
  const [Mobile, setMobile] = useState("");
  const [resume, setSelectedFile] = useState([]);
  const [messageData, setMessageData] = useState("");
  const [start, setStart] = useState(false);
  const [openHiringForm, setOpenHiringForm] = useState(false);
  const [isEmployee, setIsEmployee] = useState(false);
  const [profileNameError, setProfileNameError] = useState(false);
  const [profileCheck, setProfileCheck] = useState(false);
  const [nameError, setNameError] = useState("");
  const [isNameError, setIsNameError] = useState(false);
  const [path, setPath] = useState("");
  const [showRocketCapitalCrypto, setShowRocketCapitalCrypto] = useState(false);
  const [linkedIn, setLinkedIn] = useState("");
  const [metaWalletAddress, setMetaWalletAddress] = useState("");
  const [github, setGithub] = useState("");
  const [error, seterror] = useState(false);
  const [linkedInErr, setLinkedInErr] = useState(false);
  const [twitterErr, setTwitterErr] = useState(false);
  const [githubErr, setGithubErr] = useState(false);
  const [twitter, setTwitter] = useState("");
  const [discord, setDiscord] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const stepper = [1, 2];
  const [city, setCity] = useState({});
  const [designationError, setDesignationError] = useState("");
  const [organizationError, setOrganizationError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [showCustomPopup, setShowCustomPopup] = useState("");
  const [showMobileSearch, setShowMobileSearch] = useState();
  const history = useHistory();
  const location = useLocation();
  const displayMode = useSelector((store) => store.display.display_mode);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedFile([...resume, ...acceptedFiles]);
    },
    [resume]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxFiles: 1,
    accept: { "application/pdf": [".pdf"] },
  });
  const isFileTooLarge = acceptedFiles[0]?.size > 10000000;

  const handleIsEmployeeChange = (event) => {
    setIsEmployee(!isEmployee);
  };

  var showParticipateButton = false;
  var showRegister = false;

  if (currentUser.ranks !== undefined) {
    showParticipateButton = !currentUser.ranks.hasOwnProperty(
      hackathonHREFName
    );
  } else {
    if (
      currentUser.registered &&
      currentUser.registered.includes(hackathonHREFName) &&
      currentUser.ranks &&
      !currentUser.ranks.hasOwnProperty(hackathonHREFName)
    )
      showParticipateButton = true;
  }

  if (currentUser.registered !== undefined) {
    showRegister = !currentUser.registered.includes(hackathonHREFName);
  }

  const registerCheckRocketCapital = () => {
    axios
      .get(
        `${BootcampBackend}/hackathons/rocketCaptialHackathonRegistrationCheck`
      )
      .then((response) => {
        console.log("registercheck");
        setCurrentStep(2);
      })
      .catch((err) => {
        if (err.response.status === 402) {
          setCurrentStep(1);
        }
      });
  };

  useEffect(() => {
    setName(currentUser.Name);
    setEmail(currentUser.Email);
  }, []);

  useEffect(() => {
    showParticipateButton &&
      hackathonHREFName === "rocket_capital_crypto_forecasting" &&
      registerCheckRocketCapital();
  }, [showParticipateButton]);

  const handleCloseMsg = () => {
    setStatus(!status);
  };
  const handleCloseMsgRegister = () => {
    setStatusRegister(false);
  };

  function HiringImageIcon(props) {
    return (
      <div>
        <img
          className="mr-4 img-fluid"
          src={resumeIcon}
          alt="resume"
          loading="lazy"
        />
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
          Drag and drop your Resume{" "}
          {isEmployee && <span style={{ color: "red" }}>*</span>}
        </p>
        <p style={{ fontSize: "14px" }}>
          Allowed formats word and PDF - Max 1mb
        </p>
      </div>
    );
  }

  const handleClickOpen = (scrollType) => () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      setName(currentUser.Name);
      setEmail(currentUser.Email);
      // setLocation(currentUser.Location);
      setExperience(currentUser.Experience);
      setLatestDesignation(currentUser.LatestDesignation);
      setOrganization(currentUser.Organization);
      setMobile(currentUser.Mobile);
      setTwitter(currentUser.Twitter);
      setGithub(currentUser.GitHub);
      // setCitySearchTxt(currentUser.city);
      setCity({
        name: currentUser.city,
        state_name: currentUser.state,
        country_name: currentUser.country,
      });
      if (hackathon.HREFName === "rocket_capital_crypto_forecasting") {
        setShowRocketCapitalCrypto(true);
      } else {
        hackathon.HREFName ===
        "sustainability_hackathon_lets_crack_the_climate_resilience_code" || hackathon.HREFName === "genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code"
          ? setShowCustomPopup("genpact-google")
          : setOpen(true);
      }
      setScroll(scrollType);
    }
  };

  const handleClickOpenGenPactParticipate = (scrollType) => () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      setName(currentUser.Name);
      setOpen(true);
      setOpenHiringForm(true);
      setScroll(scrollType);
    }
  };

  const handleClose = () => {
    setOpenHiringForm(false);
    setOpen(false);
    setCheck(false);
    setProfileCheck(false);
    setSelectedFile([]);
    setStart(false);
  };

  const handleCloseProfileUpdate = () => {
    setOpenProfileUpadate(false);
  };

  const HiringGenpactHackathon = () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      if (currentUser.Name === "" || currentUser.Name === null) {
        var regName = /^[a-zA-Z\s]{1,30}$/;
        if (Name === "" || Name === null || !regName.test(Name)) {
          setIsNameError(true);
          setNameError(
            "Name cannot be blank and it should contain alphabets only."
          );
          return;
        }
        const formData = new FormData();
        formData.append("Name", Name);
        city.country_name && formData.append("country", city.country_name);
        city.state_name && formData.append("state", city.state_name);
        city.name && formData.append("city", city.name);
        currentUser.Organization &&
          formData.append("Organization", currentUser.Organization);
        currentUser.Experience &&
          formData.append("Experience", currentUser.Experience);
        currentUser.LatestDesignation &&
          formData.append("LatestDesignation", currentUser.LatestDesignation);
        currentUser.Mobile && formData.append("Mobile", currentUser.Mobile);
        currentUser.currentCtc &&
          formData.append("currentCtc", currentUser.currentCtc);
        currentUser.noticePeriod &&
          formData.append("noticePeriod", currentUser.noticePeriod);
        currentUser?.skills?.length > 0 &&
          currentUser?.skills.filter((x) => {
            formData.append("skills", x);
          });
        updtaeUserSub(formData, currentUser._id)
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
              setStatus(true);
            } else {
              console.log(response.data.user);

              setMessage(response.data.message);

              RegisterHackathon();
            }
          })
          .catch((err) => console.log(err));
      } else {
        RegisterHackathon();
      }
    }
  };

  const ParticipateGenpactHackathon = () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      showParticipateButton = !showParticipateButton;
      if (currentUser.Name === "" || currentUser.Name === null) {
        var regName = /^[a-zA-Z\s]{1,30}$/;
        if (Name === "" || Name === null || !regName.test(Name)) {
          setIsNameError(true);
          setNameError(
            "Name cannot be blank and it should contain alphabets only."
          );
          return;
        }
        const formData = new FormData();
        formData.append("Name", Name);
        currentUser.Organization &&
          formData.append("Organization", currentUser.Organization);
        currentUser.Experience &&
          formData.append("Experience", currentUser.Experience);
        currentUser.LatestDesignation &&
          formData.append("LatestDesignation", currentUser.LatestDesignation);
        currentUser.Mobile && formData.append("Mobile", currentUser.Mobile);
        city.country_name && formData.append("country", city.country_name);
        city.state_name && formData.append("state", city.state_name);
        city.name && formData.append("city", city.name);
        currentUser.currentCtc &&
          formData.append("currentCtc", currentUser.currentCtc);
        currentUser.noticePeriod &&
          formData.append("noticePeriod", currentUser.noticePeriod);
        currentUser?.skills?.length > 0 &&
          currentUser?.skills.filter((x) => {
            formData.append("skills", x);
          });
        updtaeUserSub(formData, currentUser._id)
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
              setStatus(true);
            } else {
              setMessage(response.data.message);
              if (Array.isArray(resume) && resume.length > 0) {
                UploadResumeHiringHackathon();
              } else {
                ParticipateHackathon();
              }
            }
          })
          .catch((err) => console.log(err));
      } else {
        if (Array.isArray(resume) && resume.length > 0) {
          UploadResumeHiringHackathon();
        } else {
          ParticipateHackathon();
        }
      }
    }
  };

  const rocketCapitalCryptoRegister = () => {
    if (!linkedIn?.includes("https://www.linkedin.com/")) {
      setLinkedInErr(true);
      setMessage("Please enter valid LinkedIn");
      return;
    }
    if (github && !github?.includes("https://github.com/")) {
      setGithubErr(true);
      setMessage("Please enter valid Github");
      return;
    }
    if (twitter && !twitter?.includes("https://www.twitter.com")) {
      setTwitterErr(true);
      setMessage("Please enter valid Twitter");
      return;
    }
    const requestBody = {
      name: Name,
      email: currentUser.Email,
      phone: Mobile,
      organization: Organization,
      designation: LatestDesignation,
      linkedIn: linkedIn,
      github: github,
      discord: discord,
      Twitter: twitter,
    };
    axios
      .post(
        `${BootcampBackend}/hackathons/registerForRocketCaptialHackathon`,
        requestBody
      )
      .then((response) => {
        RegisterHackathon();
        setCurrentStep(currentStep + 1);
      })
      .catch((err) => {
        console.log(err);
        seterror(true);
        setMessage(err.response.data.result.message);
      });
  };

  const rocketCapitalCryptoSubmit = () => {
    const requestBody = {
      metaMaskWalletAddress: metaWalletAddress?.toLowerCase(),
    };
    axios
      .put(
        `${BootcampBackend}/hackathons/updateRocketCapitalRegistration`,
        requestBody
      )
      .then((response) => {
        participateAPIcall();
      })
      .catch((err) => {
        console.log(err);
        seterror(true);
        setMessage(err.response.data.result.message);
      });
  };

  const ParticipateHackathonNew = () => {
    amplitude.getInstance().logEvent("participateInHackathon");
    participateInHackathon();
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
      setShowLoginModal(true);
    } else {
      showParticipateButton = !showParticipateButton;
      var regPhoneno = /^\d{10}$/;
      var regName = /^[a-zA-Z\s]{1,30}$/;
      var regExperience = /^\d{1,2}$/;
      var regLatestDesignation = /^[a-zA-Z\s]{3,50}$/;
      var regLocation = /^[a-zA-Z\s]{2,50}$/;
      var regOrganization = /^[a-zA-Z\s]{3,100}$/;
      if (Name === "" || Name === null || !regName.test(Name)) {
        setIsNameError(true);
        setNameError(
          "Name cannot be blank and it should contain alphabets only."
        );
        return;
      }
      if (Object.keys(city).length === 0) {
        setError("City should be required");
        setStatus(true);
        return;
      }
      if (Organization === null || Organization === "") {
        //Do nothing
      } else if (!regOrganization.test(Organization.trim())) {
        setError(
          "Organization should be an alphabet and contain at least 3 characters"
        );
        setStatus(true);
        return;
      }
      if (Experience === null || Experience === "") {
        //Do Nothing
      } else if (!regExperience.test(Experience.trim())) {
        setError(
          "Total Experience should be a number and not more than 2 digits."
        );
        setStatus(true);
        return;
      }
      if (LatestDesignation === null || LatestDesignation === "") {
        //Do nothing
      } else if (!regLatestDesignation.test(LatestDesignation.trim())) {
        setError(
          "Latest Designation should be an alphabet and contain atleast 3 characters"
        );
        setStatus(true);
        return;
      }
      if (Mobile === null || Mobile === "") {
        // Do nothing
      } else if (!regPhoneno.test(Mobile.trim())) {
        setError("Phone number should be of 10 digits");
        setStatus(true);
        return;
      }
      const formData = new FormData();
      formData.append("Name", Name);
      city.country_name && formData.append("country", city.country_name);
      city.state_name && formData.append("state", city.state_name);
      city.name && formData.append("city", city.name);
      Organization &&
        formData.append(
          "Organization",
          Organization === null ? "" : Organization
        );
      Experience &&
        formData.append("Experience", Experience === null ? "" : Experience);
      LatestDesignation &&
        formData.append(
          "LatestDesignation",
          LatestDesignation === null ? "" : LatestDesignation
        );
      Mobile && formData.append("Mobile", Mobile === null ? "" : Mobile);
      currentUser.currentCtc &&
        formData.append("currentCtc", currentUser.currentCtc);
      currentUser.noticePeriod &&
        formData.append("noticePeriod", currentUser.noticePeriod);
      currentUser?.skills?.length > 0 &&
        currentUser?.skills.filter((x) => {
          formData.append("skills", x);
        });
      // currentUser?.skills?.length === 0 && formData.append("skills", "")
      updtaeUserSub(formData, currentUser._id)
        .then((response) => {
          if (response.data.error) {
            setError(response.data.error);
            setStatus(true);
          } else {
            console.log(response.data.user);

            setMessage(response.data.message);

            participateAPIcall();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const participateAPIcall = async () => {
    socialCampaignTracking();
    await axios
      .post(`${API}/participate/${hackathonHREFName}`)
      .then((response) => {
        setMessage(response.data.message);
        if (
          hackathonHREFName ===
          "machine_learning_hackathon_in_association_with_amazon_web_services"
        ) {
          localStorage.setItem("awspopup", true);
        }
        if (localStorage.getItem("participateStatus") === "false") {
          localStorage.setItem("participateStatus", true);
        }

        if (hackathon.Hiring_hackathon === true) {
          axios
            .post(
              `${API}/update_interested_candidates/${hackathonHREFName}?hiring=${
                hackathonHREFName === "predict_the_fastest_car_to_win_the_race"
                  ? isEmployee
                  : uploadResume
              }`
            )
            .then((response) => {
              window.location.reload();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          window.location.reload();
        }
      });
  };

  const ParticipateHackathon = () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      showParticipateButton = !showParticipateButton;
      socialCampaignTracking();
      axios
        .post(`${API}/participate/${hackathonHREFName}`)
        .then((response) => {
          if (response.data.error) {
            getUserInfo(currentUser._id)
              .then((response) => {
                setName(response.data.user.Name);
                // setLocation(response.data.user.Location);
                setOrganization(response.data.user.Organization);
                setExperience(response.data.user.Experience);
                setMobile(response.data.user.Mobile);
                setLatestDesignation(response.data.user.LatestDesignation);
                // setCitySearchTxt(response.data.user.city);
                setCity({
                  name: response.data.user.city,
                  state_name: response.data.user.state,
                  country_name: response.data.user.country,
                });
              })
              .catch((err) => console.log(err));
            setError(response.data.error);
            setStatus(true);
            setOpenProfileUpadate(true);
            setOpen(false);
          } else {
            setMessage(response.data.message);
            if (
              hackathonHREFName ===
              "machine_learning_hackathon_in_association_with_amazon_web_services"
            ) {
              localStorage.setItem("awspopup", true);
            }
            if (localStorage.getItem("participateStatus") === "false") {
              localStorage.setItem("participateStatus", true);
            }
            if (
              hackathon.Hiring_hackathon === true &&
              (hackathonHREFName !==
                "sustainability_hackathon_lets_crack_the_climate_resilience_code" || hackathonHREFName !== "genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code")
            ) {
              axios
                .post(
                  `${API}/update_interested_candidates/${hackathonHREFName}?hiring=${
                    hackathonHREFName ===
                    "predict_the_fastest_car_to_win_the_race"
                      ? isEmployee
                      : uploadResume
                  }`
                )
                .then((response) => {
                  window.location.reload();
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              window.location.reload();
            }
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const updateResume = () => {
    if (resume.length === 0 || resume.length > 1) {
      setStatus(true);
      setError("Please Upload resume in .pdf format!  ");
    } else {
      setStart(true);
      setMessageData("File Uplaod Started ...");

      const config = {
        onUploadProgress: (progress) => {
          const { total, loaded } = progress;
          const totalSizeInMB = total / 1000000;
          const loadedSizeInMB = loaded / 1000000;
          const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
          if (totalSizeInMB < 1) {
            setMessageData(
              `${loadedSizeInMB * 1000} KB out of uploaded ${
                totalSizeInMB * 1000
              } KB`
            );
          } else if (loadedSizeInMB < 1) {
            setMessageData(
              `${loadedSizeInMB * 1000} KB out of uploaded ${totalSizeInMB} MB`
            );
          } else {
            setMessageData(
              `${loadedSizeInMB} MB out of uploaded ${totalSizeInMB} MB`
            );
          }
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const formData = new FormData();
      formData.append("resume", resume[0]);
      formData.append("postedby", currentUser._id);
      axios
        .post(`${API}/createresume`, formData, config)
        .then((response) => {
          if (response.data.error) {
            console.log(response.data.error);
            getUserInfo(currentUser._id)
              .then((response) => {
                setName(response.data.user.Name);
                // setLocation(response.data.user.Location);
                setOrganization(response.data.user.Organization);
                setExperience(response.data.user.Experience);
                setMobile(response.data.user.Mobile);
                setLatestDesignation(response.data.user.LatestDesignation);
              })
              .catch((err) => console.log(err));
            setStart(true);
            setError(response.data.error);
            setStatus(true);
            setOpenProfileUpadate(true);
            setOpen(false);
          } else {
            console.log(response.data.message);
            setMessageData(response.data.message);
            setStart(true);
            ParticipateHackathon();
          }
        })
        .catch((error) => {
          console.log("this is error", error);
        });
    }
  };
  const updateUserDataAndResume = () => {
    const formData = new FormData();
    formData.append("Name", Name || currentUser?.Name);
    city.country_name && formData.append("country", city.country_name);
    city.state_name && formData.append("state", city.state_name);
    city.name && formData.append("city", city.name);
    currentUser.currentCtc &&
      formData.append("currentCtc", currentUser.currentCtc);
    currentUser.noticePeriod &&
      formData.append("noticePeriod", currentUser.noticePeriod);
    formData.append("Organization", Organization || currentUser.Organization);
    formData.append("Experience", Experience || currentUser.Experience);
    formData.append(
      "LatestDesignation",
      LatestDesignation || currentUser.LatestDesignation
    );
    formData.append("Mobile", Mobile || currentUser.Mobile);
    currentUser?.skills?.length > 0 &&
      currentUser?.skills.filter((x) => {
        formData.append("skills", x);
      });

    updtaeUserSub(formData, currentUser._id)
      .then((response) => {
        if (response.data.error) {
          setError(response.data.error);
          setStatus(true);
        } else {
          if (resume.length > 0 && resume[0]?.size) {
            updateResume();
          } else {
            ParticipateHackathon();
          }
          setMessage(response.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const UploadResumeHiringHackathon = () => {
    amplitude.getInstance().logEvent("UploadHiringHackathonForm");
    submitHiringFormHackathon();
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      if (currentUser.Name === "" || currentUser.Name === null) {
        var regName = /^[a-zA-Z\s]{1,30}$/;
        if (Name === "" || Name === null || !regName.test(Name)) {
          setIsNameError(true);
          setNameError(
            "Name cannot be blank and it should contain alphabets only."
          );
          return;
        }
        const formData = new FormData();
        formData.append("Name", Name);
        currentUser.Organization &&
          formData.append("Organization", currentUser.Organization);
        currentUser.Experience &&
          formData.append("Experience", currentUser.Experience);
        currentUser.LatestDesignation &&
          formData.append("LatestDesignation", currentUser.LatestDesignation);
        currentUser.Mobile && formData.append("Mobile", currentUser.Mobile);
        city.country_name && formData.append("country", city.country_name);
        city.state_name && formData.append("state", city.state_name);
        city.name && formData.append("city", city.name);
        currentUser.currentCtc &&
          formData.append("currentCtc", currentUser.currentCtc);
        currentUser.noticePeriod &&
          formData.append("noticePeriod", currentUser.noticePeriod);
        currentUser?.skills?.length > 0 &&
          currentUser?.skills.filter((x) => {
            formData.append("skills", x);
          });
        updtaeUserSub(formData, currentUser._id)
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
              setStatus(true);
            } else {
              if (resume.length > 0) {
                updateResume();
              } else {
                ParticipateHackathon();
              }
              setMessage(response.data.message);
            }
          })
          .catch((err) => console.log(err));
      } else if (hackathonHREFName === "indus_os_app_discovery_challenge") {
        var regName = /^[a-zA-Z\s]{3,30}$/;
        var regLatestDesignation = /^[a-zA-Z\s]{3,50}$/;
        var regOrganization = /^[a-zA-Z\s]{3,100}$/;
        const regPhone = /^[0-9]{5,15}$/;
        if (Name === "" || Name === null || !regName.test(Name)) {
          setIsNameError(true);
          setNameError(
            "Name cannot be blank and it should contain alphabets only."
          );
          return;
        }
        if (
          LatestDesignation === null ||
          LatestDesignation === "" ||
          !regLatestDesignation.test(LatestDesignation.trim())
        ) {
          setDesignationError(
            "Latest Designation should be an alphabet and contain atleast 3 characters"
          );
          return;
        } else {
          setDesignationError("");
        }
        if (
          Organization === null ||
          Organization === "" ||
          !regOrganization.test(Organization.trim())
        ) {
          setOrganizationError(
            "Organization should be an alphabet and contain at least 3 characters"
          );
          return;
        } else {
          setOrganizationError("");
        }
        if (Mobile === null || Mobile === "" || !regPhone.test(Mobile.trim())) {
          setMobileError("Phone number should be numbers");
          return;
        } else {
          setMobileError("");
        }
        const formData = new FormData();
        formData.append("Name", Name);
        Organization && formData.append("Organization", Organization);
        currentUser.Experience &&
          formData.append("Experience", currentUser.Experience);
        LatestDesignation &&
          formData.append("LatestDesignation", LatestDesignation);
        Mobile && formData.append("Mobile", Mobile);
        city.country_name && formData.append("country", city.country_name);
        city.state_name && formData.append("state", city.state_name);
        city.name && formData.append("city", city.name);
        currentUser.currentCtc &&
          formData.append("currentCtc", currentUser.currentCtc);
        currentUser.noticePeriod &&
          formData.append("noticePeriod", currentUser.noticePeriod);
        currentUser?.skills?.length > 0 &&
          currentUser?.skills.filter((x) => {
            formData.append("skills", x);
          });
        updtaeUserSub(formData, currentUser._id)
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
              setStatus(true);
            } else {
              if (resume.length > 0) {
                updateResume();
              } else {
                ParticipateHackathon();
              }
              setMessage(response.data.message);
            }
          })
          .catch((err) => console.log(err));
      } else if (
        hackathonHREFName ===
        "sustainability_hackathon_lets_crack_the_climate_resilience_code" || hackathonHREFName === "genpact_google_for_developer_sustainability_hackathon_lets_crack_the_climate_resilience_code"
      ) {
        updateUserDataAndResume();
      } else {
        if (resume.length > 0) {
          updateResume();
        } else {
          ParticipateHackathon();
        }
      }
    }
  };

  const RegisterHackathon = async () => {
    if (!isAuthenticated()) {
      localStorage.setItem("prevPath", location.pathname);
      history.push("/login");
    } else {
      showRegister = !showRegister;
      await axios
        .post(`${API}/register/${hackathonHREFName}`)
        .then((response) => {
          if (response.data.error) {
            getUserInfo(currentUser._id)
              .then((response) => {
                setName(response.data.user.Name);
                // setLocation(response.data.user.Location);
                setOrganization(response.data.user.Organization);
                setExperience(response.data.user.Experience);
                setMobile(response.data.user.Mobile);
                setLatestDesignation(response.data.user.LatestDesignation);
              })
              .catch((err) => console.log(err));
            setError(response.data.error);
            setStatus(true);
            setOpenProfileUpadate(true);
            setOpen(false);
          } else {
            setMessage(response.data.message);
            if (
              hackathonHREFName ===
              "machine_learning_hackathon_in_association_with_amazon_web_services"
            ) {
              localStorage.setItem("awspopup", true);
            }
            if (localStorage.getItem("participateStatus") === "false") {
              localStorage.setItem("participateStatus", true);
            }
            if (
              hackathonHREFName ===
              "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing"
            ) {
              window.location.href = WEB_URL + "/assessments/cds-practice-set";
            } else {
              if (hackathonHREFName !== "rocket_capital_crypto_forecasting") {
                window.location.reload();
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSubmit = (event) => {
    setError("");
    if (Name === "" || Name === null) {
      setProfileNameError(true);
    }
    if (Name === "" || Name === null) {
      setError("Name field is required.");
      setStatus(true);
    } else if (Name.length <= 1) {
      setError("Name must be more than 1 characters");
      setStatus(true);
    } else if (Organization.length <= 3) {
      setError("Organization must be more than 3 characters");
      setStatus(true);
    } else if (LatestDesignation.length <= 3) {
      setError("LatestDesignation must be more than 3 characters");
      setStatus(true);
    } else if (Object.keys(city).length === 0) {
      setError("Location must be more than 3 characters");
      setStatus(true);
    } else {
      const formData = new FormData();
      formData.append("Name", Name);
      city.country_name && formData.append("country", city.country_name);
      city.state_name && formData.append("state", city.state_name);
      city.name && formData.append("city", city.name);
      Organization && formData.append("Organization", Organization);
      Experience && formData.append("Experience", Experience);
      LatestDesignation &&
        formData.append("LatestDesignation", LatestDesignation);
      Mobile && formData.append("Mobile", Mobile);
      currentUser.currentCtc &&
        formData.append("currentCtc", currentUser.currentCtc);
      currentUser.noticePeriod &&
        formData.append("noticePeriod", currentUser.noticePeriod);
      currentUser?.skills?.length > 0 &&
        currentUser?.skills.filter((x) => {
          formData.append("skills", x);
        });
      var phoneno = /^[0-9]+$/;
      if (!Mobile.match(phoneno)) {
        setError(`Phone number is invalid `);
        setStatus(true);
      } else {
        updtaeUserSub(formData, currentUser._id)
          .then((response) => {
            if (response.data.error) {
              setError(response.data.error);
              setStatus(true);
            } else {
              console.log(response.data.user);
              setProfileNameError(false);
              setMessage(response.data.message);
              dispatch({
                type: "GET_UPDATED_USER",
                payload: response.data.user,
              });
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log(err);
            setProfileNameError(false);
          });
      }
    }
  };

  const handleCloseNameError = () => {
    setIsNameError(false);
    setNameError("");
  };

  const formatDate = (hackathonDate) => {
    if (hackathonDate !== undefined && hackathonDate !== "-") {
      var tempDate = hackathonDate.split("/");
      var day = tempDate[0];
      var month = tempDate[1];
      var year = tempDate[2].split(" ")[0];
      const d = new Date(`${year}-${month}-${day}`);
      var days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return day + " " + month[d.getMonth()] + " " + year;
    }
  };

  const DoYourSubmission = () => {
    hackathon.HCategory === "Blogathon"
      ? history.push(`/blogathons/${hackathonHREFName}/submission`)
      : hackathon.HCategory === "Visualization"
      ? history.push(
          `/hackathons/visualization/${hackathonHREFName}/submission`
        )
      : history.push(`/hackathons/${hackathonHREFName}/submission`);
  };

  const controls = useAnimation();
  const tryCon = useAnimation();
  const variants = {
    hover: {
      x: 5,
      transition: { type: "spring", stiffness: 120, duration: 0.1 },
    },
    initial: {
      x: 0,
      transition: { type: "spring", stiffness: 120, duration: 0.1 },
    },
  };

  return (
    <>
      <Helmet>
        <style>{`body { background-color: ${
          displayMode === "dark_mode" ? "#000726" : "#f4f4f4"
        } !important; }`}</style>
      </Helmet>
      <div
        className={`hackathonBannerCntr ${isDarkMode ? "dark-bg" : "light-bg"}`}
      >
        <div className="hackathon-timer mb-4">
          {!isMobile &&
            hackathon?.TimeLeft &&
            hackathon?.HStatus === "Active" && (
              <CountDownTimer endDate={hackathon.HEnddate} />
            )}
        </div>
        {isGenpactGoogleHackathon(hackathonHREFName) ? (
          <div
            className={`${
              isMobile
                ? "hackathon-banner-img-genpact-mob"
                : "hackathon-banner-img-genpact"
            }`}
          >
            <img
              src="https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/genpactimg.jpeg"
              alt=""
            />
          </div>
        ) : (
          <h1
            className={`col-lg-7 col-md-7 col-11 mx-auto hackathon-title ${
              isDarkMode ? "dark-hackathon-title" : "light-hackathon-title"
            }`}
          >
            {hackathon?.HName}
          </h1>
        )}
        <div
          className={`hackathonInfoList d-flex ${
            isMobile ? "flex-column" : ""
          } align-items-center p-0 justify-content-center`}
        >
          <p
            className={`${
              isMobile ? "mb-2" : "mb-0"
            } hackathon-registration-text`}
          >
            Total Registrations - {hackathon.HUsers}{" "}
          </p>
          {!isGenpactGoogleHackathon(hackathonHREFName) && (
            <>
              <div className="px-2">
                {!isMobile && hackathon.company_logo && (
                  <p className="dot mb-0"></p>
                )}
              </div>
              <p className="collabhld mb-0">
                {(hackathonHREFName === "google_cloud_bigquery_hackathon" ||
                  hackathon.company_logo) &&
                  "In collaboration with "}
                {hackathonHREFName === "google_cloud_bigquery_hackathon" ? (
                  <>
                    {isDarkMode ? (
                      <img
                        className="img-fluid incollabLogo ml-2"
                        src={GoogleDarkLogo}
                        alt="hack-banner"
                        loading="lazy"
                      />
                    ) : (
                      <img
                        className="img-fluid incollabLogo ml-2"
                        src={GoogleLightLogo}
                        alt="hack-banner"
                        loading="lazy"
                      />
                    )}
                  </>
                ) : (
                  hackathon.company_logo && (
                    <img
                      className="img-fluid incollabLogo ml-2"
                      src={hackathon.company_logo}
                      alt="hack-banner"
                      loading="lazy"
                    />
                  )
                )}
              </p>
            </>
          )}
        </div>
        {/* {hackathon.total_fund && (
          <h2
            className={`hackathonPrize ${
              isDarkMode ? "" : "lightHackathonPrize"
            }`}
          >
            Top winners will get prizes worth {hackathon.total_fund}
          </h2>
        )} */}

        {/* <motion.button
        onMouseEnter={() => {
          tryCon.start("hover");
        }}
        onMouseLeave={() => {
          tryCon.start("initial");
        }}
        className="dark-button"
      >
        Register now{" "}
        <motion.img
          variants={variants}
          animate={tryCon}
          src={arrowWhite}
          className="ml-3"
          style={{ color: "#ffffff" }}
          alt="arrow"
          loading="lazy"
        />
      </motion.button> */}
        {(!isAuthenticated() &&
          hackathon.coming_soon === true &&
          hackathonHREFName !==
            "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing") ||
        (showRegister &&
          hackathon.coming_soon === true &&
          hackathonHREFName !==
            "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing") ? (
          <motion.button
            onMouseEnter={() => {
              tryCon.start("hover");
            }}
            onMouseLeave={() => {
              tryCon.start("initial");
            }}
            className="dark-button"
            onClick={
              hackathonHREFName === "predict_the_fastest_car_to_win_the_race"
                ? handleClickOpenGenPactParticipate("paper")
                : RegisterHackathon
            }
          >
            Register now
            <motion.img
              variants={variants}
              animate={tryCon}
              src={arrowWhite}
              className="ml-3"
              style={{ color: "#ffffff" }}
              alt="arrow"
              loading="lazy"
            />
          </motion.button>
        ) : (
          ""
        )}
        {(!isAuthenticated() &&
          hackathon.coming_soon === false &&
          hackathonHREFName !==
            "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing") ||
        (showParticipateButton &&
          hackathon.coming_soon === false &&
          hackathonHREFName !==
            "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing" &&
          hackathon.Submission_Closed === false) ? (
          <motion.button
            onMouseEnter={() => {
              controls.start("hover");
            }}
            onMouseLeave={() => {
              controls.start("initial");
            }}
            className="dark-button mt-2"
            onClick={
              hackathonHREFName === "predict_the_fastest_car_to_win_the_race"
                ? handleClickOpenGenPactParticipate("paper")
                : handleClickOpen("paper")
            }
          >
            Participate Now
            <motion.img
              variants={variants}
              animate={tryCon}
              src={arrowWhite}
              className="ml-3"
              style={{ color: "#ffffff" }}
              alt="arrow"
              loading="lazy"
            />
          </motion.button>
        ) : (
          <></>
        )}

        {isAuthenticated() &&
        hackathon.coming_soon === true &&
        showRegister === false &&
        hackathonHREFName !==
          "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing" ? (
          <button className="dark-button mt-2">
            <img
              src={WhiteCircledTick}
              alt="registered"
              className="img-fluid mr-2"
            />{" "}
            Registered
          </button>
        ) : (
          ""
        )}
        {showRegister &&
        hackathonHREFName ===
          "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing" ? (
          <p className="m-0 text-secondary">
            <motion.button
              onMouseEnter={() => {
                controls.start("hover");
              }}
              onMouseLeave={() => {
                controls.start("initial");
              }}
              className="dark-button mt-4"
              onClick={RegisterHackathon}
            >
              PARTICIPATE{" "}
              <motion.img
                variants={variants}
                animate={tryCon}
                src={arrowWhite}
                className="ml-3"
                style={{ color: "#ffffff" }}
                alt="arrow"
                loading="lazy"
              />
            </motion.button>
          </p>
        ) : (
          ""
        )}
        {!showRegister &&
        hackathonHREFName ===
          "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing" ? (
          <p className="m-0 text-secondary">
            {" "}
            <a
              className="dark-button"
              style={{
                background: "#e8505b",
                color: "#fff",
                paddingLeft: "15px",
                paddingRight: "15px",
                borderRadius: "20px",
              }}
              href={WEB_URL + "/assessments/cds-practice-set"}
            >
              PARTICIPATE{" "}
            </a>{" "}
          </p>
        ) : (
          ""
        )}
        {isAuthenticated() &&
          showParticipateButton === false &&
          hackathonHREFName !==
            "chartered_data_scientist_weekend_hackathon_edition_2_the_last_hacker_standing" && (
            <motion.button
              onMouseEnter={() => {
                controls.start("hover");
              }}
              onMouseLeave={() => {
                controls.start("initial");
              }}
              className="dark-button mt-2"
              onClick={DoYourSubmission}
            >
              Do your Submission{" "}
              <motion.img
                variants={variants}
                animate={tryCon}
                src={arrowWhite}
                className="ml-3"
                style={{ color: "#ffffff" }}
                alt="arrow"
                loading="lazy"
              />
            </motion.button>
          )}
        {isMobile && !showMobileSearch && (
          <div
            className="hackathon-float-ask-btn d-flex align-items-center mx-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setShowMobileSearch(true);
            }}
          >
            <Lottie animationData={AILotte} loop={true} />
            Ask
          </div>
        )}
        {isMobile && hackathon?._id && showMobileSearch && (
          <MachinehackAiSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            hname={hackathon?.HName}
            overview={hackathon?.HProfile?.overview}
            hackathonId={hackathon._id}
          />
        )}
        {!isMobile && hackathon?._id && (
          <MachinehackAiSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            hname={hackathon?.HName}
            overview={hackathon?.HProfile?.overview}
            hackathonId={hackathon._id}
          />
        )}
        <Dialog
          open={open}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth={`${
            hackathon.Hiring_hackathon === true
              ? hackathonHREFName === "indus_os_app_discovery_challenge"
                ? "sm"
                : "md"
              : "sm"
          }`}
          style={{ height: "100vh" }}
          className={`${
            hackathon.Hiring_hackathon === true ? "HiringModal" : ""
          } ${isDarkMode ? "dark-hiring-acceptance-form darkTheme" : ""}`}
        >
          {message ? (
            <SnackbarContent
              message={
                <span>
                  {" "}
                  <b> {message} </b>
                </span>
              }
              close
              color="success"
              icon="info_outline"
            />
          ) : (
            ""
          )}
          {status ? (
            <Snackbar
              open={status}
              autoHideDuration={15000}
              onClose={handleCloseMsg}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <div>
                <Alert onClose={handleCloseMsg} severity="error">
                  {usererror}
                </Alert>
              </div>
            </Snackbar>
          ) : (
            ""
          )}

          {start && (
            <Snackbar open={start} onClose={handleClose}>
              <div>
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </div>
            </Snackbar>
          )}

          {statusRegister ? (
            <Snackbar
              open={statusRegister}
              autoHideDuration={15000}
              onClose={handleCloseMsgRegister}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <div>
                <Alert onClose={handleCloseMsgRegister} severity="success">
                  {messageRegister}
                </Alert>
              </div>
            </Snackbar>
          ) : (
            ""
          )}
          <DialogTitle id="scroll-dialog-title">
            <Typography
              variant="h6"
              style={{
                margin: "0px",
                alignContent: "start",
                fontWeight: "bold",
                textTransform: "none",
              }}
              className={isDarkMode ? "darkText" : ""}
            >
              {hackathon.Hiring_hackathon === true
                ? "Hiring Form"
                : "Acceptance Form"}
            </Typography>
          </DialogTitle>
          <DialogContent className="">
            {hackathon.Hiring_hackathon === true ? (
              <>
                <Grid container style={{ marginBottom: "10px" }}>
                  {hackathonHREFName !== "indus_os_app_discovery_challenge" && (
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      md={6}
                      style={{ padding: "20px" }}
                    >
                      <p
                        style={{ marginTop: "8px" }}
                        className={`${
                          isDarkMode ? "darkText" : "current-employed"
                        }`}
                      >
                        <span style={{ color: "red" }}>*</span>Are you
                        interested in future oppo rtunities
                        {hackathonHREFName ===
                          "wipro_sustainability_machine_learning_challenge" &&
                          " with"}
                        {hackathonHREFName ===
                          "wipro_sustainability_machine_learning_challenge" && (
                          <span> Wipro</span>
                        )}
                        ?
                      </p>
                      <FormControl
                        component="fieldset"
                        className="hiring-radio-btns"
                      >
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={isEmployee}
                          onChange={handleIsEmployeeChange}
                        >
                          <FormControlLabel
                            value={true}
                            control={
                              <Radio
                                style={{
                                  color: isDarkMode ? "#B5C0D3" : "#EF4F5E",
                                }}
                              />
                            }
                            label="Yes"
                          />
                          <FormControlLabel
                            value={false}
                            control={
                              <Radio
                                style={{
                                  color: isDarkMode ? "#B5C0D3" : "#EF4F5E",
                                }}
                              />
                            }
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                      {currentUser &&
                      (currentUser.Name === null || currentUser.Name === "") ? (
                        <>
                          <div className="form-group w-100">
                            {!Name && (
                              <label
                                for="email"
                                style={{ color: "black", fontSize: "16px" }}
                              >
                                Name<span style={{ color: "red" }}>* </span>
                              </label>
                            )}
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              value={Name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  )}
                  {hackathonHREFName === "indus_os_app_discovery_challenge" &&
                    hackathon.Hiring_hackathon === true && (
                      <Grid item xs={12} lg={12} md={12}>
                        <div className="form-group w-100">
                          {Name && (
                            <label
                              for="email"
                              style={{
                                color: isDarkMode ? "#b5c0d3" : "black",
                                fontSize: "16px",
                              }}
                            >
                              Name<span style={{ color: "red" }}>* </span>
                            </label>
                          )}
                          <input
                            type="text"
                            className={`form-control ${
                              isDarkMode ? "darkInputBox" : ""
                            }`}
                            id="name"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        {nameError !== "" && <ErrMsg Message={nameError} />}
                        <br />
                        <div className="form-group w-100">
                          {!currentUser.Email && (
                            <label
                              for="email"
                              style={{
                                color: isDarkMode ? "#b5c0d3" : "black",
                                fontSize: "16px",
                              }}
                            >
                              Email<span style={{ color: "red" }}>* </span>
                            </label>
                          )}
                          <input
                            type="text"
                            className={`form-control ${
                              isDarkMode ? "darkInputBox" : ""
                            }`}
                            id="email"
                            value={currentUser.Email}
                            onChange={(e) => setEmail(e.target.value)}
                            disabled
                          />
                        </div>
                        <br />
                        <div className="form-group w-100">
                          {!LatestDesignation && (
                            <label
                              for="designation"
                              style={{
                                color: isDarkMode ? "#b5c0d3" : "black",
                                fontSize: "16px",
                              }}
                            >
                              Designation
                              <span style={{ color: "red" }}>* </span>
                            </label>
                          )}
                          <input
                            type="text"
                            className={` form-control ${
                              isDarkMode ? "darkInputBox" : ""
                            }`}
                            id="designation"
                            value={LatestDesignation}
                            onChange={(e) => {
                              setLatestDesignation(e.target.value);
                              setDesignationError("");
                            }}
                          />
                        </div>
                        {designationError !== "" && (
                          <ErrMsg Message={designationError} />
                        )}
                        <br />
                        <div className="form-group w-100">
                          {!Organization && (
                            <label
                              for="Organisation"
                              style={{
                                color: isDarkMode ? "#b5c0d3" : "black",
                                fontSize: "16px",
                              }}
                            >
                              Organisation
                              <span style={{ color: "red" }}>* </span>
                            </label>
                          )}
                          <input
                            type="text"
                            className={`form-control ${
                              isDarkMode ? "darkInputBox" : ""
                            }`}
                            id="Organisation"
                            value={Organization}
                            onChange={(e) => {
                              setOrganization(e.target.value);
                              setOrganizationError("");
                            }}
                          />
                        </div>
                        {organizationError !== "" && (
                          <ErrMsg Message={organizationError} />
                        )}
                        <br />
                        <div className="form-group w-100">
                          {!Mobile && (
                            <label
                              for="mobile"
                              style={{
                                color: isDarkMode ? "#b5c0d3" : "black",
                                fontSize: "16px",
                              }}
                            >
                              Phone<span style={{ color: "red" }}>* </span>
                            </label>
                          )}
                          <input
                            type="number"
                            className={`form-control ${
                              isDarkMode ? "darkInputBox" : ""
                            }`}
                            id="mobile"
                            value={Mobile}
                            onChange={(e) => setMobile(e.target.value)}
                          />
                        </div>
                        {mobileError !== "" && <ErrMsg Message={mobileError} />}
                      </Grid>
                    )}
                  <Grid
                    item
                    xs={12}
                    lg={
                      hackathonHREFName === "indus_os_app_discovery_challenge"
                        ? 12
                        : 6
                    }
                    md={
                      hackathonHREFName === "indus_os_app_discovery_challenge"
                        ? 12
                        : 6
                    }
                    style={{
                      justifyContent: "right",
                      padding: `${
                        hackathonHREFName === "indus_os_app_discovery_challenge"
                          ? "0"
                          : "10px"
                      }`,
                    }}
                    className="resume-drop-zone"
                  >
                    {hackathonHREFName ===
                      "indus_os_app_discovery_challenge" && (
                      <>
                        <br />
                        <label
                          style={{
                            color: isDarkMode ? "#b5c0d3" : "black",
                            fontSize: "16px",
                            marginBottom: "14px",
                            display: "inline-block",
                          }}
                        >
                          If you are interested in getting hired at Indus OS,
                          please attach your resume
                        </label>
                      </>
                    )}
                    {/* {resume.length > 0 ? (
                    <>
                      <div
                        style={{
                          textAlign: "center",
                          paddingTop: "30px",
                          paddingBottom: "30px",
                          backgroundColor: "#F4F4F4",
                          border: "dashed 2px #dfdfdf",
                        }}
                      >
                        <img src={selectedFileImg} alt="file" loading="lazy" />
                        <p
                          style={{ marginTop: "10px" }}
                        >{`${resume[0].name} selected successfully.`}</p>
                        <img
                          className="mt-1"
                          src={closeIconImg}
                          onClick={(e) => setSelectedFile([])}
                          style={{ cursor: "pointer" }}
                          alt="file"
                          loading="lazy"
                        />
                      </div>
                    </>
                  ) : ( */}
                    <div className={isDarkMode ? "dark-file-submission" : ""}>
                      {resume.length > 0 ? (
                        <>
                          <div
                            className={`px-2 ${
                              isDarkMode
                                ? "dark-selectedfilebox"
                                : "selectedfilebox"
                            }`}
                          >
                            <img
                              src={
                                isDarkMode ? submissionFile : selectedFileImg
                              }
                              alt="file"
                              loading="lazy"
                            />
                            <br />
                            <label
                              className={`mt-2 info-text ${
                                isDarkMode ? "darkText" : ""
                              }`}
                            >
                              {`${resume[0].name} selected successfully.`}
                            </label>
                            <div
                              className={
                                isDarkMode ? "dark-close-file-hld" : ""
                              }
                            >
                              <img
                                className={`mt-1 ml-1 ${
                                  isDarkMode ? "dark-close-file" : ""
                                }`}
                                src={isDarkMode ? darkCloseFile : closeIconImg}
                                onClick={(e) => setSelectedFile([])}
                                style={{ cursor: "pointer", width: "20px" }}
                                alt="close"
                                loading="lazy"
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`container contactUsZone ${
                              isDarkMode ? "darkDropZone" : "dropZoneStyles"
                            } hackathonSub`}
                          >
                            <div
                              {...getRootProps({
                                className:
                                  "dropzone dropzone d-flex flex-column justify-content-center text-center align-items-center",
                              })}
                            >
                              <input {...getInputProps()} />
                              <label
                                class={`my-2 ${
                                  isDarkMode ? "file-upload-label" : ""
                                }`}
                              >
                                Drag and drop your submission file{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <label
                                className={`text-dark mt-2 ${
                                  isDarkMode ? "placeholderText" : ""
                                }`}
                              >
                                {!isDragActive && (
                                  <div className={isDarkMode ? "darkText" : ""}>
                                    Allowed formats .csv .xlsx - Max 10 MB{" "}
                                  </div>
                                )}
                                {isDragActive && !isDragReject && (
                                  <div className={isDarkMode ? "darkText" : ""}>
                                    Drop it here....
                                  </div>
                                )}
                                {isDragReject && (
                                  <div className="text-danger mt-2">
                                    File type is not supported
                                  </div>
                                )}
                                {isFileTooLarge && (
                                  <div className="text-danger mt-2">
                                    File is too large.
                                  </div>
                                )}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    {/* )} */}
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <form
                  className={`update-profile ${
                    isDarkMode ? "dark-update-profile" : ""
                  }`}
                >
                  <div className="form-group w-100">
                    {!Name && (
                      <label for="name">
                        Name<span style={{ color: "red" }}>* </span>
                      </label>
                    )}
                    <input
                      type="text"
                      className={`form-control ${
                        profileNameError && "err-control"
                      }`}
                      id="name"
                      value={Name}
                      onChange={(e) => {
                        setName(e.target.value), setNameError(false);
                      }}
                    />
                    {profileNameError && (
                      <p className="err-msg">This is a mandatory Field</p>
                    )}
                  </div>
                  <div className="form-group w-100">
                    {!Organization && (
                      <label for="organization">Organization</label>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      id="organization"
                      value={Organization}
                      onChange={(e) => {
                        setOrganization(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group w-100">
                    {!Experience && <label for="experience">Experience</label>}
                    <input
                      type="text"
                      className="form-control"
                      id="experience"
                      value={Experience}
                      onChange={(e) => {
                        setExperience(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group w-100">
                    {!LatestDesignation && (
                      <label for="designation">Designation</label>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      id="designation"
                      value={LatestDesignation}
                      onChange={(e) => {
                        setLatestDesignation(e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="form-group w-100 position-relative">
                    <label for="city">City</label>
                    <input
                      type="text"
                      value={citySearchTxt}
                      onChange={onCityChange}
                      placeholder="City"
                      id="city"
                      name="city"
                      className="form-control"
                    />
                    {showCitiesDropdown && (
                      <div className="cityDropdown" ref={wrapperRef}>
                        {listOfCities.map((city) => (
                          <p
                            key={city._id}
                            onClick={() => {
                              setCity(city);
                              setShowCitiesDropdown(false);
                              setCitySearchTxt(city.name);
                            }}
                          >
                            {city?.name}, {city?.state_name},{" "}
                            {city?.country_name}
                          </p>
                        ))}
                      </div>
                    )}
                  </div> */}
                  <CityDropdown
                    city={city}
                    setCity={setCity}
                    showParticipateButton={showParticipateButton}
                    currentUser={currentUser}
                    isDarkMode={isDarkMode}
                  />
                  <div className="form-group w-100">
                    {!Mobile && <label for="email">Mobile number</label>}
                    <input
                      type="text"
                      className="form-control"
                      id="number"
                      value={Mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    />
                  </div>
                </form>
              </>
            )}
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              {hackathon.Sponsored ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: hackathon.sponsor_terms,
                  }}
                />
              ) : (
                ""
              )}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={check}
                    onChange={() => {
                      setCheck(!check);
                    }}
                    name="checkedB"
                    style={{
                      color: isDarkMode ? "#ffffff" : "#e9505c",
                    }}
                  />
                }
                label="Agree Terms & Conditions"
              />
              <p
                className={isDarkMode ? "darkText" : ""}
                style={{ marginBottom: "39px" }}
              >
                By clicking <span>"Agree"</span> you are agreeing to be bound to
                our competition rules,&nbsp;
                <a href="/privacy-ui" target="_blank">
                  privacy policies
                </a>{" "}
                &nbsp;&nbsp;and &nbsp;
                <a href="/terms-ui" target="_blank">
                  terms
                </a>
                . &nbsp;Please check the rules under Home tab before
                participating.
              </p>

              <div
                className="btnsCntr"
                style={{ textAlign: "center", marginTop: "20px" }}
              >
                {hackathon.Hiring_hackathon === true ? (
                  <>
                    {hackathonHREFName !==
                    "indus_os_app_discovery_challenge" ? (
                      <>
                        {isEmployee ? (
                          <>
                            {check &&
                            Name !== null &&
                            Name !== "" &&
                            resume.length > 0 ? (
                              <>
                                <Button
                                  className="hackathon-submit-btn "
                                  onClick={
                                    hackathon.Hiring_hackathon === true &&
                                    uploadResume === true
                                      ? UploadResumeHiringHackathon
                                      : ParticipateHackathonNew
                                  }
                                >
                                  Submit
                                </Button>
                              </>
                            ) : (
                              <Button className="hackathon-submit-btn ">
                                Submit
                              </Button>
                            )}
                          </>
                        ) : (
                          <>
                            {check && Name !== null && Name !== "" ? (
                              <Button
                                className="hackathon-submit-btn "
                                onClick={
                                  hackathon.Hiring_hackathon === true &&
                                  uploadResume === true
                                    ? UploadResumeHiringHackathon
                                    : ParticipateHackathonNew
                                }
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button className="hackathon-submit-btn ">
                                Submit
                              </Button>
                            )}
                          </>
                        )}
                      </>
                    ) : check &&
                      Name !== null &&
                      Name !== "" &&
                      LatestDesignation !== "" &&
                      LatestDesignation !== null &&
                      Organization !== "" &&
                      Organization !== null &&
                      Mobile !== "" &&
                      Mobile !== null ? (
                      <Button
                        className="hackathon-submit-btn "
                        onClick={
                          hackathon.Hiring_hackathon === true &&
                          uploadResume === true
                            ? UploadResumeHiringHackathon
                            : ParticipateHackathonNew
                        }
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button className="hackathon-submit-btn ">Submit</Button>
                    )}
                  </>
                ) : (
                  <>
                    {check && Name !== null && Name !== "" ? (
                      <Button
                        className="hackathon-submit-btn "
                        onClick={
                          hackathon.Hiring_hackathon === true &&
                          uploadResume === true
                            ? UploadResumeHiringHackathon
                            : ParticipateHackathonNew
                        }
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button className="hackathon-submit-btn ">Submit</Button>
                    )}
                  </>
                )}
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openProfileUpadate}
          onClose={handleCloseProfileUpdate}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="update-profile"
          maxWidth="md"
        >
          {message ? (
            <SnackbarContent
              message={
                <span>
                  {" "}
                  <b> {message} </b>
                </span>
              }
              close
              color="success"
              icon="info_outline"
            />
          ) : (
            ""
          )}
          {status ? (
            <Snackbar
              open={status}
              autoHideDuration={15000}
              onClose={handleCloseMsg}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <div>
                <Alert onClose={handleCloseMsg} severity="error">
                  {usererror}
                </Alert>
              </div>
            </Snackbar>
          ) : (
            ""
          )}
          <DialogTitle id="scroll-dialog-title">Update Profile</DialogTitle>
          <DialogContent>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <GridItem xs={12} sm={12} md={12}>
                <form>
                  <div className="form-group w-100">
                    {!Name && (
                      <label for="name">
                        <span>*</span>Name
                      </label>
                    )}
                    <input
                      type="text"
                      className={`form-control ${
                        profileNameError && "err-control"
                      }`}
                      id="name"
                      value={Name}
                      onChange={(e) => {
                        setName(e.target.value), setNameError(false);
                      }}
                    />
                    {profileNameError && (
                      <p className="err-msg">This is a mandatory Field</p>
                    )}
                  </div>
                  <div className="form-group w-100">
                    {!Organization && (
                      <label for="organization">Organization</label>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      id="organization"
                      value={Organization}
                      onChange={(e) => {
                        setOrganization(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group w-100">
                    {!Experience && <label for="experience">Experience</label>}
                    <input
                      type="text"
                      className="form-control"
                      id="experience"
                      value={Experience}
                      onChange={(e) => {
                        setExperience(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group w-100">
                    {!LatestDesignation && (
                      <label for="designation">Designation</label>
                    )}
                    <input
                      type="text"
                      className="form-control"
                      id="designation"
                      value={LatestDesignation}
                      onChange={(e) => {
                        setLatestDesignation(e.target.value);
                      }}
                    />
                  </div>
                  {/* <div className="form-group w-100 position-relative">
                    <label for="city">City</label>
                    <input
                      type="text"
                      value={citySearchTxt}
                      onChange={onCityChange}
                      placeholder="City"
                      id="city"
                      name="city"
                      className="form-control"
                    />
                    {showCitiesDropdown && (
                      <div className="cityDropdown" ref={wrapperRef}>
                        {listOfCities.map((city) => (
                          <p
                            key={city._id}
                            onClick={() => {
                              setCity(city);
                              setShowCitiesDropdown(false);
                              setCitySearchTxt(city.name);
                            }}
                          >
                            {city?.name}, {city?.state_name},{" "}
                            {city?.country_name}
                          </p>
                        ))}
                      </div>
                    )}
                  </div> */}
                  <CityDropdown
                    city={city}
                    setCity={setCity}
                    showParticipateButton={showParticipateButton}
                    currentUser={currentUser}
                    isDarkMode={isDarkMode}
                  />
                  <div className="form-group w-100">
                    {!Mobile && <label for="email">Mobile number</label>}
                    <input
                      type="text"
                      className="form-control"
                      id="number"
                      value={Mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    />
                  </div>
                  <p
                    style={{ marginBottom: "39px" }}
                    className={isDarkMode ? "darkText" : ""}
                  >
                    By clicking <span>"Agree"</span> you are agreeing to be
                    bound to our competition rules,&nbsp;
                    <a href="https://www.machinehack.com/privacy">
                      privacy
                    </a>{" "}
                    &nbsp;
                    <a href="#">policies</a>&nbsp;and &nbsp;
                    <a href="https://www.machinehack.com/terms">terms</a>.
                    &nbsp;Please check the rules under Home tab before
                    participating.
                  </p>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={profileCheck}
                        onChange={() => {
                          setProfileCheck(!profileCheck);
                        }}
                        name="checkedB"
                        style={{
                          color: isDarkMode ? "#ffffff" : "#e9505c",
                        }}
                      />
                    }
                    label="Agree Terms & Conditions"
                  />
                  <div className="btnsCntr" style={{ textAlign: "center" }}>
                    {(profileCheck && Name !== null) || Name === "" ? (
                      <Button
                        className="hackathon-submit-btn "
                        onClick={handleSubmit}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button className="hackathon-submit-btn ">Update</Button>
                    )}
                  </div>
                </form>
              </GridItem>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Dialog
          open={openHiringForm}
          onClose={handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="md"
          style={{ height: "100vh" }}
          className="HiringModal"
        >
          {message ? (
            <SnackbarContent
              message={
                <span>
                  {" "}
                  <b> {message} </b>
                </span>
              }
              close
              color="success"
              icon="info_outline"
            />
          ) : (
            ""
          )}
          {status ? (
            <Snackbar
              open={status}
              autoHideDuration={15000}
              onClose={handleCloseMsg}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <div>
                <Alert onClose={handleCloseMsg} severity="error">
                  {usererror}
                </Alert>
              </div>
            </Snackbar>
          ) : (
            ""
          )}

          {isNameError ? (
            <Snackbar
              open={isNameError}
              autoHideDuration={15000}
              onClose={handleCloseNameError}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <div>
                <Alert onClose={handleCloseNameError} severity="error">
                  {nameError}
                </Alert>
              </div>
            </Snackbar>
          ) : (
            ""
          )}

          {start && (
            <Snackbar open={start} onClose={handleClose}>
              <div>
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </div>
            </Snackbar>
          )}

          {statusRegister ? (
            <Snackbar
              open={statusRegister}
              autoHideDuration={15000}
              onClose={handleCloseMsgRegister}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <div>
                <Alert onClose={handleCloseMsgRegister} severity="success">
                  {messageRegister}
                </Alert>
              </div>
            </Snackbar>
          ) : (
            ""
          )}
          <DialogTitle id="scroll-dialog-title">Acceptance Form</DialogTitle>
          <DialogContent dividers={scroll === "paper"}>
            <Grid container style={{ marginBottom: "10px" }}>
              <Grid item xs={12} lg={6} md={6} style={{ padding: "20px" }}>
                <p style={{ marginTop: "8px" }} className="current-employed">
                  <span style={{ color: "red" }}>*</span>Are you currently
                  employed by{" "}
                  <span>
                    Genpact, Analytics India Magazine or Envision Virgin Racing?
                  </span>
                </p>
                <FormControl component="fieldset" className="hiring-radio-btns">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={isEmployee}
                    onChange={handleIsEmployeeChange}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                {currentUser &&
                (currentUser.Name === null || currentUser.Name === "") ? (
                  <>
                    <div className="form-group w-100">
                      {!Name && (
                        <label
                          for="email"
                          style={{ color: "black", fontSize: "16px" }}
                        >
                          Name<span style={{ color: "red" }}>* </span>
                        </label>
                      )}
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        value={Name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                lg={6}
                md={6}
                style={{
                  justifyContent: "right",
                  padding: "10px",
                }}
                className="resume-drop-zone"
              >
                {resume.length > 0 ? (
                  <>
                    <div
                      style={{
                        textAlign: "center",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        backgroundColor: "#F4F4F4",
                        border: "dashed 2px #dfdfdf",
                      }}
                    >
                      <img src={selectedFileImg} alt="file" loading="lazy" />
                      <p
                        style={{ marginTop: "10px" }}
                      >{`${resume[0].name} selected successfully.`}</p>
                      <img
                        className="mt-1"
                        src={closeIconImg}
                        onClick={(e) => setSelectedFile([])}
                        style={{ cursor: "pointer" }}
                        alt="close"
                        loading="lazy"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`container contactUsZone dropZoneStyles hackathonSub`}
                    >
                      <div
                        {...getRootProps({
                          className:
                            "dropzone dropzone d-flex flex-column justify-content-center text-center align-items-center",
                        })}
                      >
                        <input {...getInputProps()} />
                        <label class={`my-2`}>
                          Drag and drop your submission file{" "}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <label className={`text-dark mt-2`}>
                          {!isDragActive && (
                            <div>Allowed formats .csv .xlsx - Max 10 MB </div>
                          )}
                          {isDragActive && !isDragReject && (
                            <div>Drop it here....</div>
                          )}
                          {isDragReject && (
                            <div className="text-danger mt-2">
                              File type is not supported
                            </div>
                          )}
                          {isFileTooLarge && (
                            <div className="text-danger mt-2">
                              File is too large.
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                    {/* <DropzoneArea
                    acceptedFiles={[".pdf"]}
                    maxFileSize={9000000}
                    Icon={ImageIcon}
                    dropzoneText={""}
                    onChange={(files) => setSelectedFile(files)}
                    showPreviewsInDropzone={false}
                  /> */}
                  </>
                )}
              </Grid>
            </Grid>
            <div style={{ overflow: "hidden" }}>
              <object
                width="100%"
                height="500"
                type="application/pdf"
                data="https://s3.ap-south-1.amazonaws.com/email.machinehack.assets/TERMS+AND+CONDITIONS(2).pdf"
                style={{ marginTop: "-50px" }}
              >
                <p>
                  Insert your error message here, if the PDF cannot be
                  displayed.
                </p>
              </object>
            </div>
            <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
              <ul style={{ padding: "10px 14px" }}>
                <li>
                  I'd like Analytics India Magazine to provide my contact
                  information to Genpact, so that I can receive the latest
                  Genpact news and offers by email, post or telephone. You may
                  unsubscribe from receiving news and offers from the sender at
                  any time by following the instructions in the communications
                  received. Genpact handles your information as described in the{" "}
                  <a
                    target="_blank"
                    href="https://www.genpact.com/about-us/privacy"
                  >
                    Genpact Privacy Notice
                  </a>
                </li>
              </ul>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={check}
                    onChange={() => {
                      setCheck(!check);
                    }}
                    name="checkedB"
                    style={{
                      color: "#e9505c",
                    }}
                  />
                }
                label="*Agree Terms & Conditions"
              />
              <p className={isDarkMode ? "darkText" : "TnCTxt"}>
                By clicking “Agree” you are agreeing to be bound to our
                competition rules,{" "}
                <a href="/privacy-ui" target="_blank">
                  privacy policies
                </a>{" "}
                and{" "}
                <a target="_blank" href="/terms-ui">
                  terms
                </a>
                . Please check the rules under Home tab
              </p>
              <div className="" style={{ textAlign: "center" }}>
                {check ? (
                  <>
                    <Button
                      className="hackathon-submit-btn "
                      onClick={
                        hackathon.coming_soon === true
                          ? HiringGenpactHackathon
                          : ParticipateGenpactHackathon
                      }
                    >
                      Submit
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className="hackathon-submit-btn ">Submit</Button>
                  </>
                )}
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="showdescription"
          open={showRocketCapitalCrypto}
          onClose={handleClose}
          closeAfterTransition
        >
          <Fade in={showRocketCapitalCrypto}>
            <div className="formPopupHld carouselHld bootstrap-iso">
              <div className="text-center position-relative">
                <div className="d-flex justify-content-center stepperHead align-items-center pb-4">
                  {stepper.map((step) => (
                    <React.Fragment key={step}>
                      <p
                        className={`stephead mb-0 ${
                          currentStep === step ? "activestep" : ""
                        }`}
                      >
                        {step}
                      </p>
                      {step === 1 && <span></span>}
                    </React.Fragment>
                  ))}
                </div>
                {currentStep === 1 && (
                  <div className="row text-left">
                    <div className="col-6">
                      <div className="">
                        {!Name && (
                          <label className="popup-lable">
                            Name
                            <span
                              style={{ color: "#f44d5e", verticalAlign: "top" }}
                            >
                              *
                            </span>
                          </label>
                        )}
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          id="name"
                          value={Name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="">
                        {!Mobile && (
                          <label className="popup-lable">
                            Mobile Number
                            <span
                              style={{ color: "#f44d5e", verticalAlign: "top" }}
                            >
                              *
                            </span>
                          </label>
                        )}
                        <input
                          type="number"
                          className="form-control"
                          name="mobile"
                          id="mobile"
                          value={Mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </div>
                      <div className="mb-4">
                        {!linkedIn && (
                          <label className="popup-lable">
                            LinkedIn{" "}
                            <span
                              style={{ color: "#f44d5e", verticalAlign: "top" }}
                            >
                              *
                            </span>
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#0092FB",
                              }}
                              target="_blank"
                              className="privacyterms d-block"
                            >
                              please include https prefix
                            </a>
                          </label>
                        )}
                        <input
                          className="form-control mb-0"
                          name="linkedIn"
                          id="linkedIn"
                          type="text"
                          value={linkedIn}
                          onChange={(e) => {
                            setLinkedIn(e.target.value);
                            setLinkedInErr(false);
                          }}
                        />
                        {linkedInErr && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {message}
                          </p>
                        )}
                      </div>
                      <div className="">
                        {!Organization && (
                          <label className="popup-lable">Organization</label>
                        )}
                        <input
                          className="form-control"
                          name="organization"
                          id="organization"
                          type="text"
                          value={Organization}
                          onChange={(e) => setOrganization(e.target.value)}
                        />
                      </div>
                      <div className="">
                        {!LatestDesignation && (
                          <label className="popup-lable">Designation</label>
                        )}
                        <input
                          className="form-control"
                          name="designation"
                          id="designation"
                          type="text"
                          value={LatestDesignation}
                          onChange={(e) => setLatestDesignation(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-4">
                        {!github && (
                          <label className="popup-lable">
                            Github{" "}
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#0092FB",
                              }}
                              target="_blank"
                              className="privacyterms d-block"
                            >
                              please include https prefix
                            </a>
                          </label>
                        )}
                        <input
                          className="form-control mb-0"
                          name="Github"
                          id="Github"
                          type="text"
                          value={github}
                          onChange={(e) => {
                            setGithub(e.target.value);
                            setGithubErr(false);
                          }}
                        />
                        {githubErr && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {message}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        {!twitter && (
                          <label className="popup-lable">
                            Twitter{" "}
                            <a
                              style={{
                                textDecoration: "none",
                                color: "#0092FB",
                              }}
                              target="_blank"
                              className="privacyterms d-block"
                            >
                              please include https prefix
                            </a>
                          </label>
                        )}
                        <input
                          className="form-control mb-0"
                          name="Twitter"
                          id="Twitter"
                          type="text"
                          value={twitter}
                          onChange={(e) => {
                            setTwitter(e.target.value);
                            setTwitterErr(false);
                          }}
                        />
                        {twitterErr && (
                          <p style={{ color: "red", fontSize: "10px" }}>
                            {message}
                          </p>
                        )}
                      </div>
                      <div className="mb-4">
                        {!discord && (
                          <label className="popup-lable">
                            Discord Username
                          </label>
                        )}
                        <input
                          className="form-control mb-0"
                          name="MetaWalletAddress"
                          id="MetaWalletAddress"
                          type="text"
                          value={discord}
                          onChange={(e) => setDiscord(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {currentStep === 2 && (
                  <div className="row text-left">
                    <div className="col-6 mx-auto">
                      <div className="mb-4">
                        {!metaWalletAddress && (
                          <label className="popup-lable">
                            Meta mask wallet address{" "}
                            <span
                              style={{ color: "#f44d5e", verticalAlign: "top" }}
                            >
                              *
                            </span>
                          </label>
                        )}
                        <input
                          className="form-control mb-0"
                          name="MetaWalletAddress"
                          id="MetaWalletAddress"
                          type="text"
                          value={metaWalletAddress}
                          onChange={(e) => setMetaWalletAddress(e.target.value)}
                        />
                        <a
                          href="https://competition.rocketcapital.ai/"
                          style={{ textDecoration: "none", color: "#0092FB" }}
                          target="_blank"
                          className="privacyterms"
                        >
                          get your metamask wallet here!
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {currentStep === 1 && (
                  <>
                    {Name && Mobile?.length === 10 && linkedIn ? (
                      <button
                        className=""
                        style={{ backgroundColor: "#f44d5e" }}
                        onClick={rocketCapitalCryptoRegister}
                      >
                        Next
                      </button>
                    ) : (
                      <button
                        className=""
                        style={{ backgroundColor: "#CED2D5" }}
                      >
                        Next
                      </button>
                    )}
                  </>
                )}
                {currentStep === 2 && (
                  <>
                    {metaWalletAddress ? (
                      <button
                        className=""
                        style={{ backgroundColor: "#f44d5e" }}
                        onClick={rocketCapitalCryptoSubmit}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        className=""
                        style={{ backgroundColor: "#CED2D5" }}
                      >
                        Submit
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          </Fade>
        </Modal>
        <GenpactGooglePopup
          showCustomPopup={showCustomPopup}
          setShowCustomPopup={setShowCustomPopup}
          Name={Name}
          setName={setName}
          Mobile={Mobile}
          setMobile={setMobile}
          city={city}
          setCity={setCity}
          showParticipateButton={showParticipateButton}
          Organization={Organization}
          setOrganization={setOrganization}
          LatestDesignation={LatestDesignation}
          setLatestDesignation={setLatestDesignation}
          isDarkMode={isDarkMode}
          currentUser={currentUser}
          onDrop={onDrop}
          resume={resume}
          setSelectedFile={setSelectedFile}
          check={check}
          setCheck={setCheck}
          UploadResumeHiringHackathon={UploadResumeHiringHackathon}
          message={message}
        />
      </div>
    </>
  );
};
export default HackathonBanner;
