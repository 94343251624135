import { bool } from "prop-types";

let initialState = {
  eventData: {},
  showbooth: false,
};

function eventReducer(state = initialState, action) {
  if (action.type === "GET_EVENT_DATA") {
    return {
      ...state,
      eventData: action.payload,
    };
  }
  if (action.type === "SHOW_BOOTH") {
    return {
      ...state,
      showbooth: action.payload,
    };
  }
  return state;
}

export default eventReducer;
