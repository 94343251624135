/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import axios, { post } from "axios";
import TableSubmission from "components/Table/TableSubmission";
import { API } from "backend";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import "react-dropzone-uploader/dist/styles.css";
import uploadIconImg from "assets/img/Submission/Upload_Submission.svg";
import selectedFileImg from "assets/img/Submission/selectedFile.svg";
import closeIconImg from "assets/img/Submission/closeIcon.svg";
import submissionSuccessImg from "assets/img/Submission/submissionSuccess.svg";
import successIcon from "assets/img/Submission/success-icon.svg";
import errorIcon from "assets/img/Submission/error-icon.svg";
import "./HackathonSubmission.css";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import LoginPageNew from "views/LoginPage/LoginPageNew";
import { isAuthenticated } from "auth/helper";
import { useHistory } from "react-router";
import downArrow from "assets/img/downArrow.svg";
import { submitSolutionHackathon } from "shared/FirebaseEventMethods";
import amplitude from "amplitude-js";
import arrowIcon from "./img/rightArrow.svg";
import submissionFile from "../Reuseable/img/darksubmissionFile.svg";
import zipfile from "../Reuseable/img/darkzip.svg";
import darkCloseFile from "../Reuseable/img/close-file.svg";
import dataSubmitBtn from "../Reuseable/img/data-download.svg";
import submitSuccess from "assets/img/Submission/submission-success.svg";
import infoIcon from "assets/img/Blog/info-icon.svg";
import moment from "moment";
import { BootcampBackend } from "backend";
export const DarkModeFileUpload = (type, mandate) => {
  return (
    <div className="">
      <label className="file-upload-label my-2">
        Drag and drop your submission file
        {mandate && <span style={{ color: "red" }}>*</span>}
      </label>
      <label className="ml-1 darkText">
        {type === "submission"
          ? "Allowed formats .csv .xlsx - Max 10 MB"
          : type === "notebook"
          ? "Allowed formats .zip .ipynb .py"
          : ""}
      </label>
    </div>
  );
};
function ImageIcon(props) {
  return (
    <div>
      <img src={uploadIconImg} alt="upload" loading="lazy" />
      <br />
      <label className="title-medium-submission mt-2">
        Drag and drop your submission file
      </label>
      <label className="ml-1 info-text">
        Allowed formats .csv .xlsx - Max 10 MB
      </label>
    </div>
  );
}
function dataEngineeringImageIcon(props) {
  return (
    <div>
      <img src={uploadIconImg} alt="upload" loading="lazy" />
      <br />
      <label className="title-medium-submission mt-2">
        Drag and drop your submission file
      </label>
      <label className="ml-1 info-text">
        Allowed formats .csv .xlsx - Max 100 MB
      </label>
    </div>
  );
}

function ImageIconNoteBook(props) {
  return (
    <div>
      <img src={uploadIconImg} alt="upload" loading="lazy" />
      <br />
      <label className="title-medium-submission mt-2">
        Upload your notebook&nbsp;
      </label>
      <label className="info-text">(optional)</label>
      <br />
      <label className="ml-1 info-text">Allowed formats .zip .ipynb .py</label>
    </div>
  );
}

export default function HackathonNewSubmission(props) {
  const currentUser = useSelector((store) => store.auth.user);

  const [selectedFile, setSelectedFile] = useState([]);
  const [comment, setComment] = useState("");
  const [task, setTask] = useState("");
  const [status, setStatus] = useState("");
  const [submissionError, setSubmissionError] = useState("");
  const [failed, setFailed] = useState("");
  const [messageData, setMessageData] = useState("");
  const [lastFileSubmit, setLastFileSubmit] = useState("");
  const [exectionTime, setExecutionTime] = useState("");
  const [lastScore, setLastScore] = useState("");
  const [lastTime, setLastTime] = useState("");
  const [showNothing, setShowNothing] = useState(false);
  const [showRecentSubmission, setShowRecentSubmission] = useState(false);
  const [selectedNotebook, setSelectedNotebook] = useState([]);
  const [errorNotebook, setErrorNoteBook] = useState("");
  const [errorSubmission, setErrorSubmission] = useState("");
  const [openSubmissionStatus, setOpenSubmissionStatus] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [isLoading, setIsLoading] = useState(false);
  const [submissionLoader, setSubmissionLoader] = useState(false);
  const [path, setPath] = useState("");
  const [userTrackingData, setuserTrackingData] = useState({});
  const [timer, setTimer] = useState(10);
  const [start, setStart] = useState(false);
  const [submissions, setSubmissions] = useState([]);
  const history = useHistory();
  const { isDarkMode, finalDataSet } = props;

  const onDrop = useCallback(
    (acceptedFiles) => {
      setSelectedNotebook([...selectedNotebook, ...acceptedFiles]);
      getUploadParams(acceptedFiles[0]);
    },
    [selectedNotebook]
  );

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    onDrop,
    minSize: 0,
    maxFiles: 1,
    accept: {
      "file/ipynb": [".ipynb"],
      "file/zip": [".zip"],
      "file/py": [".py"],
    },
  });
  const isFileTooLarge = acceptedFiles[0]?.size > 10000000;

  const {
    acceptedFiles: acceptedFilesSub,
    getRootProps: getRootSubProps,
    getInputProps: getInputSubProps,
    isDragActive: isDragActiveSub,
    isDragReject: isDragRejectSub,
  } = useDropzone({
    onDrop: useCallback(
      (acceptedFilesSub) => {
        setSelectedFile([...selectedFile, ...acceptedFilesSub]);
        getUploadParams(acceptedFilesSub[0]);
      },
      [selectedFile]
    ),
    minSize: 0,
    maxFiles: 1,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [".csv"],
      "file/xlsx": [".xlsx"],
    },
  });
  const isFileTooLargeSub = acceptedFilesSub[0]?.size > 10000000;

  const getUploadParams = ({ file }) => {
    return { url: "https://httpbin.org/post" };
  };

  // Return the current status of files being uploaded
  const handleChangeStatus = ({ meta, file }, status) => {
    // console.log("head request");
    // console.log(status, meta, file);
  };

  const onFileUpload = (event) => {
    event.preventDefault();
    setMessageData("");
    setErrorNoteBook("");
    setSubmissionError("");
    setErrorSubmission("");

    if (selectedFile.length < 1) {
      setSubmissionError("Please upload the submission file.");
      return;
    }

    setIsLoading(true);
    setOpenSubmissionStatus(true);

    fileUpload(selectedFile[0], comment)
      .then((response) => {
        if (response.data.message) {
          setIsLoading(false);
          amplitude.getInstance().logEvent("SubmitHackathonSolution");
          submitSolutionHackathon();
          setSelectedNotebook([]);
          setSelectedFile([]);
          setComment("");
          setTask(response.data.task_id);
          getStatus(response.data.task_id);
        }
        if (response.data.error) {
          setOpenSubmissionStatus(false);
          setIsLoading(false);
          setErrorSubmission(response.data.error);
        }
      })
      .catch((err) => {
        setOpenSubmissionStatus(false);
        setIsLoading(false);
        console.log(err);
      });
  };

  const getStatus = (taskId) => {
    axios
      .get(`${API}/tasks/${taskId}`)
      .then((response) => {
        setSubmissionLoader(true);
        if (response.data.state === "STARTED") {
          getStatus(taskId);
        }
        if (response.data.state === "PENDING") {
          setStatus(
            `<b style="font-size:15px;">Evaluting your Submission..</b>
              <div className="col-12 text-center">
                <i className="fas fa-sync fa-spin" style="font-size:50px;"></i>
              </div>`
          );

          getStatus(taskId);
        }

        if (response.data.state === "REVOKED") {
          setSubmissionLoader(false);
        }

        if (
          response.data.state === "SUCCESS" &&
          response.data.status === "FAILURE"
        ) {
          setFailed("Failed");
          setErrorSubmission(`ERROR : ${response?.data?.result?.error}`);
          setLastTime(response.data?.result?.submitted_on);
          setLastFileSubmit(response?.data?.result?.file_name);
          setLastScore("Invalid");
          setExecutionTime("none");
          setShowNothing(false);
          setShowRecentSubmission(true);
          setSubmissionLoader(false);
        }

        if (
          response.data.state === "SUCCESS" &&
          response.data.status === "SUCCESS"
        ) {
          setFailed("");
          setSubmissionError("");
          setStatus("");
          setMessageData(`Submission Success`);
          setLastScore(response?.data?.result?.pub_score?.toFixed(5));
          setLastTime(response?.data?.result?.submitted_on);
          setLastFileSubmit(response?.data?.result?.file_name);
          setExecutionTime("5 sec");
          setShowNothing(false);
          setShowRecentSubmission(true);
          setSubmissionLoader(false);
        }
      })
      .catch((err) => {
        getStatus(taskId);
      });
  };

  const fileUpload = (file, comment) => {
    const url = `${API}/submissions/${props.hackathonHREFName}`;
    const formData = new FormData();
    formData.append("file", file);
    formData.append("comment", comment);
    formData.append("notebook", selectedNotebook[0]);
    const config = {
      onUploadProgress: (progress) => {
        const { total, loaded } = progress;
        const totalSizeInMB = total / 1000000;
        const loadedSizeInMB = loaded / 1000000;
        const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;
      },
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    return post(url, formData, config);
  };

  const selectNotebook = (files) => {
    setErrorNoteBook("");
    setSelectedNotebook(files);
  };

  const displayNoteBookError = (file) => {
    if (file.size > 5000000)
      setErrorNoteBook("File size should not be not more than 5 MB.");
    else setErrorNoteBook("Upload the .zip .ipynb and .zip file only.");
  };

  const displaySubmissionFileError = (file) => {
    if (props.hackathon.HCategory === "Data_Engineering") {
      if (file.size > 100000000)
        setSubmissionError("File size should not be not more than 100 MB");
      else setSubmissionError("Upload the .csv and .xlsx file only.");
    } else {
      if (file.size > 100000000)
        setSubmissionError("File size should not be not more than 100 MB");
      else setSubmissionError("Upload the .csv and .xlsx file only.");
    }
  };
  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor((seconds % (3600 * 24)) / 3600);
    var m = Math.floor((seconds % 3600) / 60);
    var s = Math.floor(seconds % 60);

    var dDisplay = d > 0 ? d + (d == 1 ? "D : " : "D : ") : "";
    var hDisplay = h + (h == 1 ? "h : " : "h : ");
    var mDisplay = m + (m == 1 ? "m : " : "m : ");
    var sDisplay = s + (s == 1 ? "s" : "s");
    return dDisplay + hDisplay + mDisplay + sDisplay;
  }

  const handleClose1 = () => {
    setOpenSubmissionStatus(!openSubmissionStatus);
  };

  const onfinalDataSetClick = () => {
    if (moment().isAfter(finalDataSet.startDate)) {
      const request = {
        startedAt: new Date(),
        userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      axios
        .post(
          `${BootcampBackend}/hackathons/userDatasetDownloadTracker/${props.hackathonHREFName}`,
          request
        )
        .then((response) => {
          const remainingTimeResponse = response.data?.result?.data;
          const link = document.createElement("a");
          link.href = `${finalDataSet.dataset}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setTimer(Math.round(remainingTimeResponse?.remainingTime));
          setStart(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return;
    }
  };

  const getFinalDataSetTimeTracking = () => {
    axios
      .get(
        `${BootcampBackend}/hackathons/userDatasetDownloadTrackingData/${props.hackathonHREFName}`
      )
      .then((response) => {
        const remainingTimeResponse = response.data?.result?.data;
        setTimer(Math.round(remainingTimeResponse?.remainingTime));
        setuserTrackingData(remainingTimeResponse);
        setStart(true);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setuserTrackingData([]);
        }
      });
  };

  const getSubmissions = async () => {
    await axios
      .get(
        `${API}/submissions/info/${props.hackathonHREFName}/${
          isAuthenticated()?.user?._id
        }`
      )
      .then((response) => {
        if (response.data.submissions.error) {
          //
        } else {
          setSubmissions(response.data.submissions);
          getLastSubmissionTaskId(response.data.submissions);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getLastSubmissionTaskId = async (submission_history) => {
    await axios
      .get(
        `${API}/submissions/recent/${props.hackathonHREFName}/${currentUser._id}`
      )
      .then((response) => {
        if (response.data.error) {
          // console.log("response", response);
        }
        if (response.data.error) {
          setShowNothing(true);
        } else {
          if (
            submission_history?.some(
              (submission) => submission?.task_id === response.data.message
            )
          ) {
            const submission_data = submission_history?.filter(
              (x) => x.task_id === response.data.message
            );
            submission_data[0]?.score === "Invalid Submission"
              ? setErrorSubmission(`ERROR : ${submission_data[0]?.score}`)
              : setMessageData(`Submission Success`);
          } else {
            getStatus(response.data.message);
          }
          setShowRecentSubmission(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getSubmissions();
  }, []);
  useEffect(() => {
    if (start) {
      const set_timer = setInterval(() => {
        timer > 0
          ? setTimer((prev) => prev - 1)
          : (setTimer(0),
            setStart(false),
            setuserTrackingData((prev) => ({
              ...prev,
              submissionClosed: true,
            })));
      }, 1000);
      return () => clearInterval(set_timer);
    }
  }, [timer, start]);

  useEffect(() => {
    if (Object.keys(finalDataSet || {})?.length > 0) {
      getFinalDataSetTimeTracking();
    }
  }, [finalDataSet]);

  return (
    <>
      <div className={`${isDarkMode ? "" : "overviewCntr"}`}>
        <div
          className={`d-flex usersubmissionInfo align-items-center ${
            Object.keys(finalDataSet || {}).length > 0
              ? "justify-content-between"
              : "justify-content-center"
          } px-4 py-3`}
        >
          <div className="d-flex align-items-center">
            <h5 className={`mb-0 mr-2 ${isDarkMode ? "darkText" : ""}`}>
              Download the Data
            </h5>
            <button
              type="button"
              className={isDarkMode ? "dark-button px-3" : "d-flex"}
              onClick={() => {
                isAuthenticated()
                  ? history.push(`/hackathons/${props.hackathonHREFName}/data`)
                  : (localStorage.setItem("prevPath", `/hackathons/${props.hackathonHREFName}/data`),
                  history.push("/login"));
              }}
            >
              Download
              <img
                src={isDarkMode ? dataSubmitBtn : downArrow}
                className={`img-fluid ml-2 ${isDarkMode ? "" : "arrowFixDown"}`}
                alt=""
              />
            </button>
          </div>
          {Object.keys(finalDataSet || {}).length > 0 && (
            <div
              className={`d-flex align-items-center col-6 ${
                moment().isAfter(finalDataSet.startDate) &&
                moment().isBefore(finalDataSet.endDate)
                  ? timer === 0
                    ? "disableEffect"
                    : ""
                  : "disableEffect"
              }`}
            >
              <img src={infoIcon} alt="" className="img-fluid mr-2 infoIcon" />
              <h6
                className={`mb-0 mr-2 ${`finalDataSetText ${
                  isDarkMode ? "whiteText" : ""
                }`}`}
              >
                Download dataset when ready for final submission. Submission
                window open for 1 hour only post download button click.
              </h6>
              {start ? (
                <button
                  type="button"
                  className={
                    isDarkMode ? "dark-new-topic px-3 col-4 col-4" : "col-4"
                  }
                >
                  {secondsToDhms(timer)}
                </button>
              ) : (
                <>
                  {moment().isAfter(finalDataSet.startDate) &&
                  moment().isBefore(finalDataSet.endDate) ? (
                    <button
                      type="button"
                      className={
                        isDarkMode ? "dark-button px-3 col-4" : "col-4"
                      }
                      onClick={onfinalDataSetClick}
                    >
                      Download
                      <img
                        src={dataSubmitBtn}
                        className="img-fluid ml-2"
                        alt=""
                      />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className={
                        isDarkMode ? "dark-new-topic px-3 col-4" : "col-4"
                      }
                    >
                      Download
                      <img
                        src={dataSubmitBtn}
                        className="img-fluid ml-2"
                        alt=""
                      />
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {props.hackathon.HREFName !== "rocket_capital_crypto_forecasting" && (
          <>
            {!isDarkMode && (
              <div
                className={`row justify-content-center bottom-border-grey ${
                  isDarkMode ? "dark-border" : ""
                }`}
              >
                <label
                  className={`title-medium-submission mb-0 py-2 ${
                    isDarkMode ? "darkText" : ""
                  }`}
                >
                  Submit Here
                </label>
              </div>
            )}
            <div
              className={`row px-2 mx-auto ${
                isDarkMode ? "dark-file-submission" : "file-submission"
              }`}
            >
              <div className="col-md-6 mt-5">
                {selectedFile.length > 0 ? (
                  <>
                    <div
                      className={`px-2 ${
                        isDarkMode ? "dark-selectedfilebox" : "selectedfilebox"
                      }`}
                    >
                      <img
                        src={isDarkMode ? submissionFile : selectedFileImg}
                        alt="file"
                        loading="lazy"
                      />
                      <br />
                      <label
                        className={`mt-2 info-text ${
                          isDarkMode ? "darkText" : ""
                        }`}
                      >
                        {`${selectedFile[0].name} selected successfully.`}
                      </label>
                      <div className={isDarkMode ? "dark-close-file-hld" : ""}>
                        <img
                          className={`mt-1 ml-1 ${
                            isDarkMode ? "dark-close-file" : ""
                          }`}
                          src={isDarkMode ? darkCloseFile : closeIconImg}
                          onClick={(e) => setSelectedFile([])}
                          style={{ cursor: "pointer", width: "20px" }}
                          alt="close"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`container contactUsZone ${
                        isDarkMode ? "darkDropZone" : "dropZoneStyles"
                      } hackathonSub`}
                    >
                      <div
                        {...getRootSubProps({
                          className:
                            "dropzone dropzone d-flex flex-column justify-content-center text-center align-items-center",
                        })}
                      >
                        <input {...getInputSubProps()} />
                        <label
                          className={`my-2 ${
                            isDarkMode ? "file-upload-label" : ""
                          }`}
                        >
                          Drag and drop your submission file
                        </label>
                        <label
                          className={`text-dark mt-2 ${
                            isDarkMode ? "placeholderText" : ""
                          }`}
                        >
                          {!isDragActiveSub && (
                            <div className={isDarkMode ? "darkText" : ""}>
                              Allowed formats .csv .xlsx - Max 10 MB{" "}
                            </div>
                          )}
                          {isDragActiveSub && !isDragRejectSub && (
                            <div className={isDarkMode ? "darkText" : ""}>
                              Drop it here....
                            </div>
                          )}
                          {isDragRejectSub && (
                            <div className="text-danger mt-2">
                              File type is not supported
                            </div>
                          )}
                          {isFileTooLargeSub && (
                            <div className="text-danger mt-2">
                              File is too large.
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                    {/* <DropzoneArea
                            onChangeStatus={handleChangeStatus}
                            getUploadParams={getUploadParams}
                            dropzoneText={""}
                            acceptedFiles={[
                              ".csv",
                              "application/vnd.ms-excel",
                              "text/csv",
                              ".xlsx",
                            ]}
                            Icon={
                              isDarkMode
                                ? () => DarkModeFileUpload("submission")
                                : props.hackathon.HCategory === "Data_Engineering"
                                  ? dataEngineeringImageIcon
                                  : ImageIcon
                            }
                            showAlerts={false}
                            onChange={(files) => setSelectedFile(files)}
                            getDropRejectMessage={displaySubmissionFileError}
                            maxFileSize={
                              props.hackathon.HCategory === "Data_Engineering"
                                ? 100000000
                                : 100000000
                            }
                            showPreviewsInDropzone={false}
                          /> */}
                  </>
                )}

                <div className="row justify-content-center mt-2">
                  <label className="error-text">{submissionError}</label>
                </div>
              </div>

              <div className="col-md-6 mt-5">
                {selectedNotebook.length > 0 ? (
                  <>
                    <div
                      className={`px-2 ${
                        isDarkMode ? "dark-selectedfilebox" : "selectedfilebox"
                      }`}
                    >
                      <img
                        src={isDarkMode ? zipfile : selectedFileImg}
                        alt="file"
                        loading="lazy"
                      />
                      <br />
                      <label
                        className={`mt-2 info-text ${
                          isDarkMode ? "darkText" : ""
                        }`}
                      >
                        {`${selectedNotebook[0].name} selected successfully.`}
                      </label>
                      <div className={isDarkMode ? "dark-close-file-hld" : ""}>
                        <img
                          className={`mt-1 ml-1 ${
                            isDarkMode ? "dark-close-file" : ""
                          }`}
                          src={isDarkMode ? darkCloseFile : closeIconImg}
                          onClick={(e) => setSelectedNotebook([])}
                          style={{ cursor: "pointer", width: "20px" }}
                          alt="close"
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`container contactUsZone ${
                        isDarkMode ? "darkDropZone" : "dropZoneStyles"
                      } hackathonSub`}
                    >
                      <div
                        {...getRootProps({
                          className:
                            "dropzone dropzone d-flex flex-column justify-content-center text-center align-items-center",
                        })}
                      >
                        <input {...getInputProps()} />
                        <label
                          className={`my-2 ${
                            isDarkMode ? "file-upload-label" : ""
                          }`}
                        >
                         Upload your notebook (optional)
                        </label>
                        <label
                          className={`text-dark mt-2 ${
                            isDarkMode ? "placeholderText" : ""
                          }`}
                        >
                          {!isDragActive && (
                            <div className={isDarkMode ? "darkText" : ""}>
                              Allowed formats .zip .ipynb .py{" "}
                            </div>
                          )}
                          {isDragActive && !isDragReject && (
                            <div className={isDarkMode ? "darkText" : ""}>
                              Drop it here....
                            </div>
                          )}
                          {isDragReject && (
                            <div className="text-danger mt-2">
                              File type is not supported
                            </div>
                          )}
                          {isFileTooLarge && (
                            <div className="text-danger mt-2">
                              File is too large.
                            </div>
                          )}
                        </label>
                      </div>
                    </div>
                  </>
                )}

                <div className="row justify-content-center mt-2">
                  <label className="error-text">{errorNotebook}</label>
                </div>
              </div>

              <div className="col-md-12 mt-4 submission-form">
                <input
                  className={`form-control shadow-none ${
                    isDarkMode ? "dark-submission-input" : ""
                  }`}
                  style={{ borderRadius: "50px" }}
                  type="text"
                  placeholder="Add a comment if any..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
              </div>
              <div className="col-md-12 mb-3">
                <div className="row justify-content-center p-3">
                  {/* <div className="mt-1 d-flex align-items-center mb-3 col-12 justify-content-center">
                         <img
                           src={errorImg}
                           loading="lazy"
                           alt="error"
                           className="img-fluid mr-2"
                           style={{ verticalAlign: "baseline" }}
                         />
                         <label className="errTxt mb-0">
                           Please wait for 10 mins after file submission for
                           upload and evaluation to happen
                         </label>
                       </div> */}
                  {errorSubmission !== "" && (
                    <div
                      className={`pr-3 py-2 ${
                        isDarkMode
                          ? "dark-persistent-error"
                          : "persistent-error"
                      }`}
                    >
                      <img
                        className="mt-2 ml-1"
                        src={errorIcon}
                        alt="error"
                        loading="lazy"
                      />
                      <label className="ml-2 toast-error mb-0">
                        {errorSubmission}
                      </label>
                    </div>
                  )}
                  {messageData !== "" && (
                    <div
                      className={isDarkMode ? "" : "persistent-success pr-3"}
                    >
                      <img
                        className="mt-2 ml-1"
                        src={successIcon}
                        alt="success"
                        loading="lazy"
                      />
                      <label className="ml-2 toast-success">
                        {messageData}
                      </label>
                    </div>
                  )}
                </div>

                <div className="row justify-content-center hackathonSubmithld">
                  {submissionLoader ? (
                    <CircularProgress
                      color="secondary"
                      className="text-center"
                    />
                  ) : (
                    <>
                      {Object.keys(finalDataSet || {}).length > 0 ? (
                        <>
                          {userTrackingData?.submissionClosed ? (
                            <button
                              className="btn hackathonSubmitbtn"
                              style={{ opacity: "0.4", cursor: "no-drop" }}
                            >
                              Submit
                              <img
                                className="img-fluid ml-2"
                                src={arrowIcon}
                                alt="arrow"
                                loading="lazy"
                              />
                            </button>
                          ) : (
                            <button
                              className="btn hackathonSubmitbtn"
                              onClick={onFileUpload}
                            >
                              Submit
                              <img
                                className="img-fluid ml-2"
                                src={arrowIcon}
                                alt="arrow"
                                loading="lazy"
                              />
                            </button>
                          )}
                        </>
                      ) : (
                        <>
                          {selectedFile.length > 0 ? (
                            <button
                              className="btn hackathonSubmitbtn"
                              onClick={onFileUpload}
                            >
                              Submit
                              <img
                                className="img-fluid ml-2"
                                src={arrowIcon}
                                alt="arrow"
                                loading="lazy"
                              />
                            </button>
                          ) : (
                            <button className="btn disabledBtn">
                              Submit
                              <img
                                className="img-fluid ml-2"
                                src={arrowIcon}
                                alt="arrow"
                                loading="lazy"
                                width={20}
                              />
                            </button>
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {props.hackathon.HREFName === "rocket_capital_crypto_forecasting" && (
          <a
            href="https://competition.rocketcapital.ai/"
            style={{ textDecoration: "none" }}
            className="d-block text-center"
            target="_blank"
          >
            https://competition.rocketcapital.ai/
          </a>
        )}
        {props.hackathon && (
          <TableSubmission
            hackathon={props.hackathon}
            hackathonHREFName={props.hackathon.HREFName}
            hackathonStartDate={props.hackathonStartDate}
            isDarkMode={isDarkMode}
            submissions={submissions}
            setSubmissions={setSubmissions}
          />
        )}
      </div>

      <Dialog
        open={openSubmissionStatus}
        onClose={handleClose1}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={isDarkMode ? "submitSuccess darkTheme" : ""}
      >
        <DialogContent className="submission-dialog">
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {!isLoading && (
              <>
                {isDarkMode && (
                  <div className="dark-close-file-hld">
                    <img
                      className={`mt-1 ml-1 dark-close-file`}
                      src={darkCloseFile}
                      onClick={handleClose1}
                      style={{ cursor: "pointer", width: "20px" }}
                      alt="close"
                      loading="lazy"
                    />
                  </div>
                )}
                <img
                  src={isDarkMode ? submitSuccess : submissionSuccessImg}
                  alt="submission"
                  loading="lazy"
                  className="img-fluid"
                  style={{ marginTop: `${isDarkMode ? "20px" : ""}` }}
                />
                <p className={`success-title ${isDarkMode ? "whiteText" : ""}`}>
                  Success!
                </p>
                <p className={`submission-msg ${isDarkMode ? "darkText" : ""}`}>
                  Your file is submitted successfully.
                </p>
              </>
            )}
            {isLoading && (
              <CircularProgress
                color="secondary"
                style={{ marginTop: "20px" }}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          {!isLoading && (
            <Button
              onClick={(e) => handleClose1()}
              style={{
                backgroundColor: "#dfdfdf",
                color: isDarkMode ? "#ffffff" : "#000",
                borderRadius: "50px",
                width: "187px",
                float: "left",
                fontSize: "18px",
                marginBottom: "20px",
                marginTop: "0px",
                textTransform: "none",
              }}
              className={isDarkMode ? "dark-new-topic" : ""}
            >
              Close
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
