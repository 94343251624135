let initialState = {
  subscriptionData: {},
  payment_status: false,
  product_payment_status: {}
};

function subscriptionReducer(state = initialState, action) {
  if (action.type === "GET_SUBSCRIPTION_DATA") {
    return {
      ...state,
      subscriptionData: action.payload,
    };
  }
  if (action.type === "USER_PAYMENT_STATUS") {
    return {
      ...state,
      payment_status: action.payload,
    };
  }
  if (action.type === "PRODUCT_PAYMENT_STATUS") {
    return {
      ...state,
      product_payment_status: action.payload,
    };
  }
  return state;
}

export default subscriptionReducer;
