import React, { lazy, Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import GoogleLogin from "views/LoginPage/GoogleLogin";
import setAuthToken from "auth/helper/setToken";
import { store } from "store/store";
import UnProtectedRoute from "auth/helper/UnProtectedRoute";
import ErrorPage from "views/ErrorPage/ErrorPage";
import OAuthHandlerPage from "./views/OauthHandler";
import NewOverviewVisualization from "views/HackathonPage/Visualization/OverviewVisualization";
import SingleDashboardNew from "views/HackathonPage/Visualization/Component/SingleDashboard";
import EventsPrivateRoute from "auth/helper/EventsPrivateRoute";
import lazyWithRetry from "./lazyWithRetry";
import FooterNew from "views/Reuseable/FooterNew";
import PrivateRoute from "auth/helper/PrivateRoute";

// import Invoice from "views/ProfilePage/ProfileComponent/Invoice";
const DataSetsLanding = lazy(()=>import("views/DataSets/DataSetsLanding"))
const Practice = lazy(() => import("views/Practices/Practice"));
const PracticeQuestion = lazy(() => import("views/Practices/PracticeQuestion"));
const SubmissionDetails = lazy(() =>
  import("views/Practices/SubmissionDetails")
);
const LLMSComponent = lazyWithRetry(()=>import("views/LLMS/LLMS"))
const newOverview = lazyWithRetry(() =>
  import("views/HackathonPage/Hackathon/Overview")
);
const NoteBookData = lazyWithRetry(() =>
  import("views/HackathonPage/Hackathon/Reuseable/NoteBookData")
);
const ContactUsNew = lazyWithRetry(() =>
  import("views/ContactUs/ContactUsNew")
);
const PlaygroundLanding = lazyWithRetry(() => import("views/Playground/PlaygroundLanding"))
const Playground = lazyWithRetry(() => import("views/Playground/Playground"))
const TermsNew = lazyWithRetry(() => import("views/ContactUs/TermsNew"));
const ShippingDeliveryPolicy = lazyWithRetry(() => import("views/ContactUs/ShippingDeliveryPolicy"));
const PrivacyNew = lazyWithRetry(() => import("views/ContactUs/PrivacyNew"));
const Hackathon = lazyWithRetry(() => import("views/AllHackathon/Hackathon"));
const NewGlobalRanking = lazyWithRetry(() =>
  import("views/GlobalRankings/NewGlobalRankings")
);
const ProfilePageNew = lazyWithRetry(() =>
  import("views/ProfilePage/ProfilePageNew")
);
const MyAccount = lazyWithRetry(() => import("views/ProfilePage/MyAccount"));
const AboutUsNew = lazyWithRetry(() => import("views/AboutUs/AboutUs"));
const JobsLandingPage = lazyWithRetry(() => import("views/UserJobs"));
const EnterpriseLandingPage = lazyWithRetry(() => import("views/Enterprise"));
const AssessEnterpriseLandingPage = lazyWithRetry(() =>
  import("views/Enterprise/LandingPages/Assess/Assess")
);
const InterviewEnterpriseLandingPage = lazyWithRetry(() =>
  import("views/Enterprise/LandingPages/Interview/Interview")
);
const SourceEnterpriseLandingPage = lazyWithRetry(() =>
  import("views/Enterprise/LandingPages/Source/Source")
);
const RankEnterpriseLandingPage = lazyWithRetry(() =>
  import("views/Enterprise/LandingPages/Rank/Rank")
);
const BootcampHomeNew = lazyWithRetry(() =>
  import("views/Bootcamp/BootcampHome")
);
const BootcampAbout = lazyWithRetry(() =>
  import("views/Bootcamp/BootcampCourse")
);
const BootcampCourse = lazyWithRetry(() =>
  import("views/Bootcamp/BootcampCoursePage")
);
const UserCertificate = lazyWithRetry(() =>
  import("views/Bootcamp/UserCertificate")
);
const BootcampDiscussionPage = lazyWithRetry(() =>
  import("views/Reuseable/discussion/SingleDiscussion.js")
);
// New
const MocksHome = lazyWithRetry(() => import("views/Assessments/Home"));
const MocksList = lazyWithRetry(() => import("views/Assessments/MocksList"));
const MockProblemsPage = lazyWithRetry(() =>
  import("views/Assessments/Problem")
);
const MockResumePage = lazyWithRetry(() => import("views/Assessments/Resume"));
const MockDescriptionPage = lazyWithRetry(() =>
  import("views/Assessments/Description")
);
const MockProblemsResult = lazyWithRetry(() =>
  import("views/Assessments/Result")
);
const Invoice = lazyWithRetry(() =>
  import("views/ProfilePage/ProfileComponent/Invoice")
);
const DiscussionSite = lazyWithRetry(() =>
  import("views/DiscussionSite/DiscussionSite")
);
const DiscussionDescription = lazyWithRetry(() =>
  import("views/DiscussionSite/DiscussionDescription")
);
const SingleDiscussionSite = lazyWithRetry(() =>
  import("views/DiscussionSite/SingleDiscussion.js")
);
const InterviewLobbyPage = lazyWithRetry(() =>
  import("views/Interview/InterviewLobby")
);
const OverviewBlogathon = lazyWithRetry(() =>
  import("views/HackathonPage/Blogathon/OverviewBlogathon")
);
const DailyWordle = lazyWithRetry(() =>
  import("views/DailyWordle/DailyWordle")
);
const DailyWordleLeaderboard = lazyWithRetry(() =>
  import("views/DailyWordle/DailyworldeLeaderboard")
);
// Machinehack Events
const MhEvents = lazyWithRetry(() => import("MH_Events/index"));
const EventFeed = lazyWithRetry(() => import("MH_Events/event-feed"));
const AttendeesPage = lazyWithRetry(() => import("MH_Events/people/Delegates"));
const SpeakersPage = lazyWithRetry(() => import("MH_Events/people/Speakers"));
const AgendaPage = lazyWithRetry(() => import("MH_Events/agenda"));
const ExhibitorsPage = lazyWithRetry(() => import("MH_Events/exhibitors"));
const Sessionpage = lazyWithRetry(() =>
  import("MH_Events/agenda/session/Session")
);
const EventsLandingPage = lazyWithRetry(() =>
  import("views/Events/EventsLandingPage")
);
const VirtualBooth = lazyWithRetry(() =>
  import("MH_Events/exhibitors/booth/Booth")
);
const SponsersPage = lazyWithRetry(() =>
  import("MH_Events/exhibitors/Sponsers")
);
const EventLoginPage = lazyWithRetry(() =>
  import("MH_Events/components/eventsLogin/Loginpage")
);
const EventresetPasswordPage = lazyWithRetry(() =>
  import("MH_Events/components/eventsLogin/EventSetPassword")
);
const EventCalenderViewPage = lazyWithRetry(() =>
  import("MH_Events/userMeetings/Meetings")
);
const SubscriptionPolicypage = lazyWithRetry(() =>
  import("views/LandingPage/Sections/SubscriptionsPolicy")
);

const RedeemCoinsPage = lazyWithRetry(() =>
  import("views/SubscriptionStore/RedeemCoins")
);
const SubscriptionPage = lazyWithRetry(() =>
  import("views/SubscriptionStore/SubscriptionPage")
);

const CartPage = lazyWithRetry(() =>
  import("views/SubscriptionStore/CartPage")
);

const StorePage = lazyWithRetry(() =>
  import("views/SubscriptionStore/StorePage")
);

const EnterpriseTestPanel = lazyWithRetry(() =>
  import("views/Enterprise/TestPanel")
);

const EnterpriseAssessFeedback = lazyWithRetry(() =>
  import("views/Enterprise/TestPanel/Feedback/Feedback")
);
const BlogOnboarding = lazyWithRetry(() =>
  import("views/Blogs/BlogOnboarding")
);
const EditorPage = lazyWithRetry(() =>
  import("./views/Blogs/Editor/EditorForBlog")
);
const BlogPage = lazyWithRetry(() =>
  import("./views/Blogs/components/BlogLandingPage/BlogLandingPage")
);
const BlogDashBoard = lazyWithRetry(() =>
  import("./views/Blogs/components/BlogDashBoard/BlogDashBoard")
);

const BlogInnerPage = lazyWithRetry(() =>
  import("./views/Blogs/components/BlogInnerPage/BlogInnerPage")
);
const BlogLandingPage = lazyWithRetry(() =>
  import("./views/Blogs/MainLandingPage/MainLandingPage")
);

const CompetitionPage = lazyWithRetry(() =>
  import("./views/Competition/CompetitionPage")
);
const MhFeed = lazyWithRetry(() => import("./views/Feed/Feed"));

const TournamentLandingPage = lazyWithRetry(() =>
  import("./views/Competition")
);

const LoginPage = lazyWithRetry(() =>
  import("./views/LoginPage/LoginPageNew")
);
const StudyPlanSignup = lazyWithRetry(()=>import("./views/LoginPage/StudyPlanSignup.js"))
const Routes = () => {
  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      let jwt = JSON.parse(localStorage.getItem("jwt"));
      setAuthToken(jwt.token);
      store.dispatch({
        type: "SET_CURRENT_USER",
        payload: jwt.user,
        token: jwt.token,
      });
    }
  },[]);

  return (
    <Suspense fallback={<div></div>}>
      <Switch>
        {/* Machinehack Landing Pages */}
        <PrivateRoute exact path="/" component={MhFeed} />
        {/* <Route exact path="/datalyze" component={LLMSComponent} /> */}
        <Route exact path="/contact-us" component={ContactUsNew} />
        <Redirect
          from="/contactus"
          to="/contact-us"
        />
        <UnProtectedRoute
          exact
          path="/login"
          component={LoginPage}
        />
        <PrivateRoute
          exact
          path="/studyplansignup"
          component={StudyPlanSignup}
        />
        <PrivateRoute
          exact
          path="/signup/:companyName"
          component={LoginPage}
        />
        <UnProtectedRoute
          exact
          path="/resetPassword/:userId/:token"
          component={LoginPage}
        />
        <Route exact path="/about-us" component={AboutUsNew} />
        <Route exact path="/terms" component={TermsNew} />
        <Route exact path="/terms-ui" component={TermsNew} />
        <Route exact path="/privacy-ui" component={PrivacyNew} />
        <Route exact path="/privacy" component={PrivacyNew} />
        <Route exact path="/shipping-and-delivery-policy" component={ShippingDeliveryPolicy} />
        {/* <Route exact path="/datasets" component={DataSetsLanding} /> */}
        {/* <Route exact path="/hackathons" component={Hackathon} />
        <Route exact path="/hackathon" component={Hackathon}>
          <Redirect to="/hackathons" />
        </Route> */}
        <Route exact path="/leaderboard" component={NewGlobalRanking} />
        <PrivateRoute exact path="/playground" component={PlaygroundLanding} />
        <PrivateRoute exact path="/playground/:studyPlanSlug" component={Playground} />
        <PrivateRoute exact path="/bootcamps" component={BootcampHomeNew} />
        {/* <Route exact path="/jobs" component={JobsLandingPage} /> */}
        {/* <Route exact path="/mh-wordle" component={DailyWordle} /> */}
        {/*Feed*/}
        <PrivateRoute exact path="/feed" component={MhFeed} />
        <PrivateRoute exact path="/posts/:feedId" component={MhFeed} />
        {/*Feed*/}
        {/* <Route
          exact
          path="/genpact-ai-mh-wordle/:wordleNumber"
          component={DailyWordle}
        />
        <Route
          exact
          path="/mh-wordle/leaderboard"
          component={DailyWordleLeaderboard}
        /> */}
        <Route
          exact
          path="/subscription-policy"
          component={SubscriptionPolicypage}
        />
        {/* Machinehack Landing Pages ends here */}

        {/* Enterprise Landing Pages */}
        <Route exact path="/enterprise" component={EnterpriseLandingPage} />
        <Route
          exact
          path="/enterprise/assess"
          component={AssessEnterpriseLandingPage}
        />
        <Route
          exact
          path="/enterprise/interview"
          component={InterviewEnterpriseLandingPage}
        />
        <Route
          exact
          path="/enterprise/source"
          component={SourceEnterpriseLandingPage}
        />
        <Route
          exact
          path="/enterprise/rank"
          component={RankEnterpriseLandingPage}
        />
        {/* Enterprise Landing Pages ends here */}

        {/* Assessments */}
        <PrivateRoute exact path="/assessments" component={MocksHome} />
        {/* <PrivateRoute
          exact
          path="/assessments/:moduleSlug/:mockSlug/result"
          component={MockProblemsResult}
        /> */}
        {/* <Route
          exact
          path="/assessments/skill/:moduleSlug"
          component={MocksList}
        />
        <Route
          exact
          path="/assessments/skill/:moduleSlug/:mockSlug"
          component={MockProblemsPage}
        />
        <Route
          exact
          path="/assessments/skill/:moduleSlug/:mockSlug/result"
          component={MockProblemsResult}
        />
        <Route
          exact
          path="/assessments/skill/:moduleSlug/:mockSlug/resume"
          component={MockResumePage}
        />
        <Route
          exact
          path="/assessments/interview/:moduleSlug"
          component={MocksList}
        />
        <Route
          exact
          path="/assessments/interview/:moduleSlug/:mockSlug"
          component={MockProblemsPage}
        />
        <Route
          exact
          path="/assessments/interview/:moduleSlug/:mockSlug/result"
          component={MockProblemsResult}
        />
        <Route
          exact
          path="/assessments/interview/:moduleSlug/:mockSlug/resume"
          component={MockResumePage}
        /> */}
        <PrivateRoute
          exact
          path="/assessments/:mockSlug/description"
          component={MockDescriptionPage}
        />
        <PrivateRoute
          exact
          path="/assessments/:mockSlug"
          component={MockProblemsPage}
        />
        <PrivateRoute
          exact
          path="/assessments/:mockSlug/result"
          component={MockDescriptionPage}
        />
        <PrivateRoute
          exact
          path="/assessments/exam/:mockSlug/resume"
          component={MockResumePage}
        />

        {/* Bootcamp Assessment */}
        <PrivateRoute
          exact
          path="/assessments/bootcamp/description/:mockSlug/:bootcampCourseId"
          component={MockDescriptionPage}
        />
        <PrivateRoute
          exact
          path="/assessments/bootcamp/:mockSlug/:bootcampCourseId"
          component={MockProblemsPage}
        />
        {/* Assessments ends here */}

        {/* Bootcamp */}
        <PrivateRoute
          exact
          path="/bootcamps/:bootcampCourseId"
          component={BootcampAbout}
        />
        <PrivateRoute
          exact
          path="/bootcamps/bootcampCourse/:bootcampCourseId"
          component={BootcampCourse}
        />
        <PrivateRoute
          exact
          path="/bootcamps/bootcampCourse/:bootcampCourseId/:discussionId"
          component={BootcampDiscussionPage}
        />
        <Route exact path="/oauth/callback" component={OAuthHandlerPage} />
        <PrivateRoute
          exact
          path="/bootcamps/certificate/:certificateId"
          component={UserCertificate}
        />
        {/* Bootcamp ends here */}

        {/* Practice Routes */}
        {/* Practice old Routes */}
        {/* <Route exact path="/practices" component={PracticeModules} />
        <Route
          exact
          path="/practices/:moduleId"
          component={ModulesPracticeList}
        />
        <Route
          exact
          path="/practices/:moduleId/:practiceId"
          component={SinglePractice}
        />
        <Route
          exact
          path="/practices/:moduleId/:practiceId/:discussionId"
          component={SinglePractice}
        /> */}
        {/* Practice old Routes ends here */}
        <PrivateRoute exact path="/practices" component={Practice}></PrivateRoute>
        <PrivateRoute
          exact
          path="/practices/details/:questionId/:submissionId"
          component={SubmissionDetails}
        />
        {/* TO design UI */}
        <PrivateRoute
          exact
          path="/practices/:practiceId/description"
          component={PracticeQuestion}
        />
        {/* <Route
          exact
          path="/practices/:practiceId/solution"
          component={PracticeQuestion}
        /> */}
        <PrivateRoute
          exact
          path="/practices/:practiceId/discussion"
          component={PracticeQuestion}
        />
        <PrivateRoute
          exact
          path="/practices/:practiceId/discussion/:discussionId"
          component={PracticeQuestion}
        />
        <PrivateRoute
          exact
          path="/practices/:practiceId/submission"
          component={PracticeQuestion}
        />
        <PrivateRoute
          exact
          path="/practices/:practiceId/leaderboard"
          component={PracticeQuestion}
        />
        {/* TO design UI */}
        <PrivateRoute
          exact
          path="/practices/:practiceId/history"
          component={PracticeQuestion}
        />
        {/* TO design UI */}
        {/* Practice ends here */}

        {/* Hackathon routes */}
        {/* <Route
          exact
          path="/hackathons/:hackathonId/overview"
          component={newOverview}
        />
        <Route
          exact
          path="/hackathons/:hackathonId/data"
          component={newOverview}
        />
        <Route
          exact
          path="/hackathons/:hackathonId/discussion"
          component={newOverview}
        />

        <Route
          exact
          path="/hackathons/:hackathonId/submission"
          component={newOverview}
        />
        <Route
          exact
          path="/hackathons/:hackathonId/leaderboard"
          component={newOverview}
        />
        <Route
          exact
          path="/hackathons/:hackathonId/notebooks"
          component={newOverview}
        />
        <Route
          exact
          path="/hackathons/:hackathonId/notebook/:scriptID"
          component={NoteBookData}
        />
        <Route
          exact
          path="/hackathons/:hackathonId/discussion/:discussionID"
          component={newOverview}
        />
        <Route
          exact
          path="/hackathons/:hackathonId/solution"
          component={newOverview}
        /> */}
        {/* Hackathon routes ends here */}

        {/* Hackathon routes */}
        {/* <Redirect
          from="/hackathon/:hackathonId/overview"
          to="/hackathons/:hackathonId/overview"
        />

        <Redirect
          from="/hackathon/:hackathonId/data"
          to="/hackathons/:hackathonId/data"
        />

        <Redirect
          from="/hackathon/:hackathonId/discussion"
          to="/hackathons/:hackathonId/discussion"
        />

        <Redirect
          from="/hackathon/:hackathonId/submission"
          to="/hackathons/:hackathonId/submission"
        />

        <Redirect
          from="/hackathon/:hackathonId/leaderboard"
          to="/hackathons/:hackathonId/leaderboard"
        />

        <Redirect
          from="/hackathon/:hackathonId/notebooks"
          to="/hackathons/:hackathonId/notebooks"
        />

        <Redirect
          from="/hackathon/:hackathonId/notebook/:scriptID"
          to="/hackathons/:hackathonId/notebook/:scriptID"
        />

        <Redirect
          from="/hackathon/:hackathonId/discussion/:discussionID"
          to="/hackathons/:hackathonId/discussion/:discussionID"
        />

        <Redirect
          from="/hackathon/:hackathonId/solution"
          to="/hackathons/:hackathonId/solution"
        /> */}

        {/* Hackathon routes ends here */}

        {/* Visualization Hackathon Routes Start */}
        {/* <Route
          exact
          path="/hackathons/visualization/:hackathonId/overview"
          component={NewOverviewVisualization}
        />

        <Route
          exact
          path="/hackathons/visualization/:hackathonId/data"
          component={NewOverviewVisualization}
        />
        <Route
          exact
          path="/hackathons/visualization/:hackathonId/discussion"
          component={NewOverviewVisualization}
        />

        <Route
          exact
          path="/hackathons/visualization/:hackathonId/submission"
          component={NewOverviewVisualization}
        />
        <Route
          exact
          path="/hackathons/visualization/:hackathonId/leaderboard"
          component={NewOverviewVisualization}
        />
        <Route
          exact
          path="/hackathons/visualization/:hackathonId/dashboards"
          component={NewOverviewVisualization}
        />
        <Route
          exact
          path="/hackathons/visualization/:hackathonId/:dashboardId"
          component={SingleDashboardNew}
        />
        <Route
          exact
          path="/hackathons/visualization/:hackathonId/discussion/:discussionID"
          component={NewOverviewVisualization}
        /> */}
        {/* Visualization Hackathon Routes End */}

        {/* Visualization Hackathon Routes Start */}
        {/* <Redirect
          from="/hackathon/visualization/:hackathonId/overview"
          to="/hackathons/visualization/:hackathonId/overview"
        />
        <Redirect
          from="/hackathon/visualization/:hackathonId/data"
          to="/hackathons/visualization/:hackathonId/data"
        />
        <Redirect
          from="/hackathon/visualization/:hackathonId/discussion"
          to="/hackathons/visualization/:hackathonId/discussion"
        />
        <Redirect
          from="/hackathon/visualization/:hackathonId/submission"
          to="/hackathons/visualization/:hackathonId/submission"
        />
        <Redirect
          from="/hackathon/visualization/:hackathonId/leaderboard"
          to="/hackathons/visualization/:hackathonId/leaderboard"
        />
        <Redirect
          from="/hackathon/visualization/:hackathonId/dashboards"
          to="/hackathons/visualization/:hackathonId/dashboards"
        />
        <Redirect
          from="/hackathon/visualization/:hackathonId/:dashboardId"
          to="/hackathons/visualization/:hackathonId/:dashboardId"
        />
        <Redirect
          from="/hackathon/visualization/:hackathonId/discussion/:discussionID"
          to="/hackathons/visualization/:hackathonId/discussion/:discussionID"
        /> */}

        {/* Visualization Hackathon Routes End */}

        {/* Blogathon Routes Start */}
        {/* <Route
          exact
          path="/blogathons/:hackathonId/overview"
          component={OverviewBlogathon}
        />

        <Route
          exact
          path="/blogathons/:hackathonId/data"
          component={OverviewBlogathon}
        />
        <Route
          exact
          path="/blogathons/:hackathonId/discussion"
          component={OverviewBlogathon}
        />
        <Route
          exact
          path="/blogathons/:hackathonId/submission"
          component={OverviewBlogathon}
        />
        <Route
          exact
          path="/blogathons/:hackathonId/leaderboard"
          component={OverviewBlogathon}
        />
        <Route
          exact
          path="/blogathons/:hackathonId/dashboards"
          component={OverviewBlogathon}
        />
        <Route
          exact
          path="/blogathons/:hackathonId/:dashboardId"
          component={SingleDashboardNew}
        />
        <Route
          exact
          path="/blogathons/:hackathonId/discussion/:discussionID"
          component={OverviewBlogathon}
        /> */}
        {/* Blogathon Routes End */}

        {/* Blogathon Routes Start */}
        {/* <Redirect
          from="/blogathon/:hackathonId/overview"
          to="/blogathons/:hackathonId/overview"
        />
        <Redirect
          from="/blogathon/:hackathonId/data"
          to="/blogathons/:hackathonId/data"
        />
        <Redirect
          from="/blogathon/:hackathonId/discussion"
          to="/blogathons/:hackathonId/discussion"
        />
        <Redirect
          from="/blogathon/:hackathonId/submission"
          to="/blogathons/:hackathonId/submission"
        />
        <Redirect
          from="/blogathon/:hackathonId/leaderboard"
          to="/blogathons/:hackathonId/leaderboard"
        />
        <Redirect
          from="/blogathon/:hackathonId/dashboards"
          to="/blogathons/:hackathonId/dashboards"
        />
        <Redirect
          from="/blogathon/:hackathonId/:dashboardId"
          to="/blogathons/:hackathonId/:dashboardId"
        />
        <Redirect
          from="/blogathon/:hackathonId/discussion/:discussionID"
          to="/blogathons/:hackathonId/discussion/:discussionID"
        />
        <Redirect
          from="/blogathons/:hackathonId"
          to="/blogathons/:hackathonId/overview"
        />
        <Redirect
          from="/hackathons/visualization/:hackathonId"
          to="/hackathons/visualization/:hackathonId/overview"
        />
        <Redirect
          from="/hackathons/:hackathonId"
          to="/hackathons/:hackathonId/overview"
        /> */}
        {/* Blogathon Routes End */}

        {/* Profile */}
        {/* <Route exact path="/user/profile/:userId" component={ProfilePageNew} /> */}
        <PrivateRoute exact path="/user/:userId" component={ProfilePageNew} />
        {/* <PrivateRoute exact path="/myaccount/:userId" component={MyAccount} /> */}
        {/* <Route
          exact
          path="/user/profile/ui/:userId/:userActivity"
          component={ProfilePageNew}
        /> */}
        <UnProtectedRoute
          exact
          path="/login/:token/:user_id/:email"
          component={GoogleLogin}
        />
        {/* <Route
          exact
          path="/interview-lobby/:interviewToken"
          component={InterviewLobbyPage}
        /> */}
        {/* Profile ends here*/}

        {/* Discussion Routes */}
        {/* <Route exact path="/discussions" component={DiscussionSite} />
        <Route
          exact
          path="/discussions/:discussionId"
          component={SingleDiscussionSite}
        />
        <Route
          exact
          path="/discussions/:discussionType/:discussionId"
          component={DiscussionDescription}
        /> */}
        {/* Discussion Routes End */}

        {/* Machinehack Events */}
        {/* <UnProtectedRoute
          exact
          path="/:event_slug/events-login"
          component={EventLoginPage}
        />
        <UnProtectedRoute
          exact
          path="/:event_slug/resetpassword/:token"
          component={EventresetPasswordPage}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/home"
          component={MhEvents}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/schedule"
          component={AgendaPage}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/booth"
          component={ExhibitorsPage}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/sponsors"
          component={SponsersPage}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/sponsors/:boothId"
          component={VirtualBooth}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/booth/:boothId"
          component={VirtualBooth}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/feed"
          component={EventFeed}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/attendees"
          component={AttendeesPage}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/speakers"
          component={SpeakersPage}
        />
        <EventsPrivateRoute
          exact
          path="/events/:event_slug/session/:sessionId"
          component={Sessionpage}
        />
        <EventsPrivateRoute
          exact
          path="/events/happening-now"
          component={MhEvents}
        />
        <Route exact path="/events" component={EventsLandingPage} />
        <Route
          exact
          path="/events/:event_slug/my-schedule"
          component={EventCalenderViewPage}
        /> */}
        {/* Machinehack Events ends here */}

        {/* Subscription */}
        {/* <Route exact path="/invoice/:subscriptionId" component={Invoice} />
        <Route exact path="/store" component={RedeemCoinsPage} />
        <Route exact path="/coins" component={StorePage} />
        <Route exact path="/subscriptions" component={SubscriptionPage} />
        <Route exact path="/cart" component={CartPage} /> */}
        {/* Subscription ends here */}

        {/* Enterprise Test Panel */}
        <PrivateRoute
          exact
          path="/enterprise/assessment/:assessmentSlug"
          component={EnterpriseTestPanel}
        />
        <PrivateRoute
          exact
          path="/feedback/:assessmentId"
          component={EnterpriseAssessFeedback}
        />
        {/* Enterprise Test Panel ends here */}

        {/* Blogs */}
        <PrivateRoute exact path="/blogs/dashboard" component={BlogDashBoard} />
        <PrivateRoute exact path="/blogs/onboard" component={BlogOnboarding} />
        <PrivateRoute exact path="/blogs/:blogSlug" component={BlogPage} />
        <PrivateRoute exact path="/story/:articleSlug" component={BlogInnerPage} />
        <PrivateRoute exact path="/create-story" component={EditorPage} />
        <PrivateRoute exact path="/edit-story/:articleSlug" component={EditorPage} />
        <PrivateRoute exact path="/blogs" component={BlogLandingPage} />
        {/* Blogs ends here */}

        {/*Competition*/}
        <PrivateRoute exact path="/tournaments" component={TournamentLandingPage} />
        <PrivateRoute
          exact
          path="/tournaments/:competitionTitle"
          component={CompetitionPage}
        />

        <PrivateRoute path="*" component={ErrorPage} />

      </Switch>
      <FooterNew />
    </Suspense>
  );
};

export default Routes;
